import axios, { AxiosRequestConfig } from 'axios';
import { GET_SERVICE_TYPES } from 'const';
import { ServiceType } from 'types';

export const catchaStates = [
  { value: true, name: 'Tiene catcha' },
  { value: false, name: 'No tiene catcha' },
];

export const companyStates = [
  { value: true, name: 'Activa' },
  { value: false, name: 'Inactiva' },
];

export const fetchServiceTypes = (
  _: any,
  config?: AxiosRequestConfig<ServiceType[]>,
) => {
  return axios.get<ServiceType[]>(GET_SERVICE_TYPES, config);
};
