import { Fragment } from 'react';
import Link from '../Link';
import Skeleton from '../Skeleton';
import Typography from '../Typography';
import { Divider, Root } from './BreadCrumbs.styles';

type breadCrumbType =
  | {
    label: string;
    href?: string;
  }
  | string;

export type BreadCrumbsProps = {
  loading?: boolean;
  preview?: number;
  breadcrumbs: breadCrumbType[];
};

const BreadCrumbs = ({
  breadcrumbs: breadcrumbsProp,
  loading,
  preview = 2,
}: BreadCrumbsProps) => {
  const breadcrumbs = breadcrumbsProp.map((breadcrumb) =>
    typeof breadcrumb === 'string' ? { label: breadcrumb } : breadcrumb,
  );

  return (
    <Root>
      {loading
        ? Array(preview)
          .fill('')
          .map((_, idx) => (
            <Fragment key={idx}>
              <Skeleton width="120px" height="22px" />
              {idx + 1 < breadcrumbs.length && (
                <Divider component="span" variant="h2">
                  /
                </Divider>
              )}
            </Fragment>
          ))
        : breadcrumbs.map(({ label, href }, idx) => (
          <Fragment key={label}>
            {href ? (
              <Link link={href}>
                <Typography component="span" variant="h2">
                  {label}
                </Typography>
              </Link>
            ) : (
              <Typography component="span" variant="h2">
                {label}
              </Typography>
            )}
            {idx + 1 < breadcrumbs.length && (
              <Divider component="span" variant="h2">
                /
              </Divider>
            )}
          </Fragment>
        ))}
    </Root>
  );
};

export default BreadCrumbs;
