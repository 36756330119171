import { Input as AtomInput, Typography } from 'components/atoms';
import { TableCell, TableRow } from 'components/molecules';
import styled from 'styled-components';

export const TableContainer = styled.div<{ $height: number }>`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: thin solid
    ${({ theme }) => (theme.isDarkMode ? theme.palette.divider : 'transparent')};
  background-color: ${({ theme }) =>
    theme.isDarkMode ? 'inherit' : theme.common.white};
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled(Typography)`
  padding: 0px 10px;
`;

export const TableFooter = styled.div`
  border-top: thin solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-grow: 0;
  padding: 0px 6px;
  min-width: 650px;
`;

export const Svg = styled.svg`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  margin-right: -28px;
`;

export const InputPagination = styled(AtomInput)`
  max-width: 60px;
  min-width: 60px;

  & input {
    text-align: center !important;
  }
`;

export const Toolbar = styled.div`
  border-bottom: thin solid ${({ theme }) => theme.palette.divider};
  display: flex;
  flex-grow: 0;
  padding: 0px 6px;
  min-width: 650px;
`;

export const Grow = styled.div`
  flex-grow: 1;
`;

export const TableHead = styled.thead`
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  background: inherit;
  min-width: 650px;

  // position: sticky;
  // top: 0;

  & > ${TableRow} {
    & > ${TableCell} {
      padding: 16px;
      border-top: none;
      font-weight: 600;
      font-size: 15px;
      white-space: nowrap;
      border-bottom: thin solid ${({ theme }) => theme.palette.divider};

      & > ${Grow} {
        flex-grow: 1;
      }
    }
  }
`;
