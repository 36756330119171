import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import CreateGroupingTemplate from '../../../../components/templates/PublicServicesTemplate/GroupingTemplate/CreateGroupingTemplate';

const breadcrumbs = [{ label: 'Servicios públicos' }];

const CreateGroupingPage = () => {
  return (
    <>
      <Header
        title="Inmuebles"
        subtitle="Crea inmuebles"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <CreateGroupingTemplate />
    </>


  )
}

export default withAuth(CreateGroupingPage);