import { defaultRowType } from 'components/molecules';
import { filterModelType, tableModelType } from 'hooks/useTable';

export const createFilterStructure = <T extends defaultRowType>(
  field: filterModelType<T>['field'],
  type: filterModelType<T>['type'],
  value?: filterModelType<T>['value'],
  table?: filterModelType<T>['table'],
) => {
  if (value) return { field, value, type, table };
};

export const joinObjectWithFormat = (
  value: object,
  char: string,
  format?: (value: any, ...rest: any[]) => string,
  ...options: any[]
) => {
  return Object.values(value)
    .reduce((acc, curr) => {
      if (curr && format && options) acc.push(format(curr, ...options));
      else if (curr && format) acc.push(format(curr));
      else if (curr) acc.push(curr);
      return acc;
    }, [])
    .join(char);
};

export const createPayload = <T extends defaultRowType>({
  filterModel,
  sortModel,
  paginationModel,
}: tableModelType<T>) =>
  `?${
    filterModel && filterModel.length > 0
      ? `filter=${JSON.stringify(filterModel)}&`
      : ''
  }${sortModel ? `sort=${JSON.stringify(sortModel)}&` : ''}${
    paginationModel
      ? `limit=${paginationModel.rowsPerPage}&page=${paginationModel.page}`
      : ''
  }`;
