import { AxiosResponseHeaders } from 'axios';
import {
  DateRangePicker,
  Grid,
  MenuItem,
  Select,
  Typography
} from 'components/atoms';
import { DateRangePickerValue, customRange } from 'components/atoms/DateRangePicker/DateRangePicker';
import { BasicTable, Svg, TableCell, TableRow } from 'components/organisms';
import { Grow, TableContainer, TableHead } from 'components/organisms/Table/Table.styles';
import { useDownloadFile } from 'hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AMLog } from 'types';
import { dateRangeToString } from 'utils';
import { CustomPaper } from './AMLogs.Styles';
import { downloadLogs, fetchLogs, getColumns } from './AMLogs.const';
import { AMLogsTemplateProps } from './AMLogsTemplate.types';



/**
 * Este componente implementa el modal para asignar un área
 * a un listado de inmuebles que se quieran activar y no tengan área
 */
const AMLogsTemplate = (
  {
    setOpen,
    onClose,
    setErrorMessage,
    automationManager,
    ...props
  }: AMLogsTemplateProps
) => {
  const ref = useRef<HTMLTableElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState<string>(automationManager.paths[0] || "");
  const [logsData, setLogsData] = useState<AMLog[]>([]);
  const [dateRangeValue, setDateRangeValue] = useState<DateRangePickerValue>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date()
  });

  //TODO: Refactorizar para que no se use esta variable global
  let logs: AMLog[];
  let selectedLogId: number;


  const onShow = () => {
    console.log(logsData)
  }

  const onDownloadLog = (id: number) => {
    selectedLogId = id;
    download();
  }

  /**
 * Block of code to use the hook to download files
 */
  const preDownloading = () => {
    setIsDownloading(true);
  };

  const postDownloading = () => {
    onClose();
    setIsDownloading(false);
  };

  const onErrorDownloadFile = (errorMessage: string | undefined) => {
    onClose();
    if (errorMessage) {
      setErrorMessage(errorMessage);
      setOpen('warning')
    } else {
      setOpen('error')
    }
  };

  //getFileName implementation to get the name from the response headers
  const getFileName = (headers?: AxiosResponseHeaders) => {
    const contentDisposition = headers && headers['content-disposition'];
    const match =
      contentDisposition &&
      contentDisposition.match(/filename=(?:"([^"]+)"|([^;]+))/);
    return match ? match[2] : "log.log";
  };

  const downloadLogsHandler = () => {
    let path: string;
    path = logs
      .find(log => log.id === selectedLogId)
      ?.logPath;

    if (!path) {
      console.log("No log found with the selected id: ", selectedLogId)
    }
    return downloadLogs(automationManager, path);
  };

  const { download } = useDownloadFile({
    apiDefinition: downloadLogsHandler,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  const columns = useMemo(
    () => getColumns(onDownloadLog),
    [],
  );

  const onPathChange = (
    event: React.FormEvent<HTMLDivElement> |
      React.SyntheticEvent<Element, Event>
  ) => {
    setCurrentPath((event.target as HTMLInputElement).value);
  }

  const onDateRangeChange = (
    value: customRange
  ) => {
    if (value.startDate && value.endDate) {
      setDateRangeValue({ startDate: value.startDate, endDate: value.endDate });
    }
    //setCurrentPath((value.target as HTMLInputElement).value);
  }

  useEffect(() => {
    if (currentPath) {
      setIsLoading(true);
      const stringifiedDateRange = dateRangeToString(dateRangeValue);

      fetchLogs(automationManager, currentPath, stringifiedDateRange)
        .then((response) => {
          setLogsData(response.data);
          logs = response.data;
        }).then(() => {
          /*         onOpen(id);
                  setAction('viewLogs'); */
        })
        .catch((error) => {
          setOpen('error');
          onClose();
        }).finally(() => {
          setIsLoading(false);
        });

    }

  }, [currentPath, dateRangeValue])


  return (
    <CustomPaper>
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={7}
      ><Grid item xs={6}  >
          <Typography variant='h2'>
            {`Logs del Automation Manager: ${automationManager.name} ('${currentPath || automationManager.paths[0]}')`}
          </Typography>
        </Grid>
        <Grid item xs={3}  >
          <DateRangePicker
            required
            label="Fecha de creación"
            fullWidth
            onChange={onDateRangeChange}
            value={dateRangeValue}
          />
        </Grid>
        <Grid item xs={3}  >
          <Select
            required
            value={currentPath}
            onChange={onPathChange}
            label="Ruta de logs"
            fullWidth
          >
            {automationManager.paths.map((path) => (
              <MenuItem
                key={path}
                value={path}>
                {path}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}  >
          <TableContainer $height={1}>
            <TableHead as="div">
              <TableRow as="div">
                {columns.map(
                  (
                    { headerName, field, flex },
                    idx,
                  ) => (
                    <TableCell
                      as="div"
                      key={`head.${field}.${headerName}`}
                      $flex={flex}
                    >
                      {headerName}
                      <Grow />
                      {columns.length - 1 > idx && (
                        <Svg
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="SeparatorIcon"
                        >
                          <path d="M11 19V5h2v14z"></path>
                        </Svg>
                      )}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <PerfectScrollbar>
              <BasicTable<AMLog>
                numberOfVisibleColumns={4}
                ref={ref}
                columns={columns}
                rows={logsData}
                rowsPerPage={1}
                loading={isLoading}
              />
            </PerfectScrollbar>
          </TableContainer>
        </Grid>
      </Grid>
    </CustomPaper>
  );
};

export default AMLogsTemplate;