import axios, { AxiosRequestConfig } from 'axios';
import { GET_ROLES } from 'const';
import { Transaction } from 'types';

export const fetchRoles = <T extends object[]>(
  data: any,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<T>(GET_ROLES, config);
};
