export function getOffsetTop(
  rect: DOMRect,
  vertical: number | 'center' | 'top' | 'bottom',
) {
  let offset = 0;

  if (typeof vertical === 'number') {
    offset = vertical;
  } else if (vertical === 'center') {
    offset = rect.height / 2;
  } else if (vertical === 'bottom') {
    offset = rect.height;
  }

  return offset;
}

export function getOffsetLeft(
  rect: DOMRect,
  horizontal: number | 'center' | 'right' | 'left',
) {
  let offset = 0;

  if (typeof horizontal === 'number') {
    offset = horizontal;
  } else if (horizontal === 'center') {
    offset = rect.width / 2;
  } else if (horizontal === 'right') {
    offset = rect.width;
  }

  return offset;
}

export function getTransformOriginValue(transformOrigin: {
  vertical: number | string;
  horizontal: number | string;
}) {
  return [transformOrigin.horizontal, transformOrigin.vertical]
    .map((n) => (typeof n === 'number' ? `${n}px` : n))
    .join(' ');
}

// Sum the scrollTop between two elements.
export function getScrollParent(parent: Element, child: Element) {
  let element = child;
  let scrollTop = 0;

  while (element && element !== parent) {
    element = element.parentElement as Element;
    scrollTop += element.scrollTop;
  }
  return scrollTop;
}

export function getAnchorEl(anchorEl: Element | (() => Element) | null) {
  return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
}
