import { object, string, TypeOf } from 'yup';

export const ListRegexTemplateFilterSchema = object({
  errorRegex: string(),
  errorName: string(),
  customer: string(),
  process: string(),
});

export type ListRegexTemplateFilterType = TypeOf<
  typeof ListRegexTemplateFilterSchema
>;
