import styled from 'styled-components';

const HelperText = styled.p`
  color: inherit;
  margin: 0;
  margin-top: 3px;
  font-family: Montserrat, sans-serif;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const RootGroup = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  padding: 0px 8px;
`;

const Root = styled.fieldset`
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  color: var(--color);
`;

const Label = styled.legend`
  color: inherit;
  padding: 0;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  display: block;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export { HelperText, Root, Label, RootGroup };
