import { COMMON_OPTIONS, DATE_FORMAT_WITH_OUT_TIME } from 'const';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useMenu } from 'hooks';
import { Calendar } from 'react-date-range';
import { AiOutlineCalendar } from 'react-icons/ai';
import Input, { InputProps } from '../Input';
import Popover from '../Popover';

export type DatePickerProps = {
  value?: string | number | Date;
  onChange: (value: Date) => void;
  label?: string;
  isFullWidth?: boolean;
} & Omit<
  InputProps,
  'onChange' | 'value' | 'endAdornment' | 'onClick' | 'readOnly'
>;
/**
 *
 * @param param0
 * @returns
 */
const DatePicker = ({ value, onChange, label, isFullWidth, ...inputProps }: DatePickerProps) => {
  const [anchor, handleOpen, handleClose] = useMenu<HTMLElement>();

  return (
    <>
      <Input
        readOnly
        label={label && label}
        endAdornment={
          <AiOutlineCalendar size="19" style={{ cursor: 'pointer' }} />
        }
        fullWidth={isFullWidth && isFullWidth}
        onClick={handleOpen}
        value={
          value
            ? format(new Date(value), DATE_FORMAT_WITH_OUT_TIME, COMMON_OPTIONS)
            : ''
        }
        {...inputProps}
      />
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Calendar
          date={value ? new Date(value) : undefined}
          onChange={onChange}
          locale={es}
        />
      </Popover>
    </>
  );
};

export default DatePicker;
