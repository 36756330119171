import { ButtonProps } from '../Button';

import { RootButton } from './IconButton.styles';

export type IconButtonProps = {
  positionEdge?: 'start' | 'end';
  edge?: number;
} & ButtonProps;

const IconButton = ({ positionEdge, edge = 3, ...props }: IconButtonProps) => {
  return <RootButton $positionEdge={positionEdge} $edge={edge} {...props} />;
};

export default IconButton;
