import { date, object, string, TypeOf } from 'yup';

export const HistoryTemplateFilterSchema = object({
  isDownloaded: string(),
  invoiceNumber: string(),
  area: string(),
  projects: string(),
  publicServices: string(),
  dateRange: object({
    startDate: date().required(),
    endDate: date().required(),
  }).required(),
});

export type HistoryTemplateFilterType = TypeOf<
  typeof HistoryTemplateFilterSchema
>;
