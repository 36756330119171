import { forwardRef, ReactNode } from 'react';
import { IconType } from 'react-icons';
import {
  MdCheckCircleOutline,
  MdErrorOutline,
  MdInfoOutline,
  MdWarningAmber,
} from 'react-icons/md';
import { ButtonProps } from '../Button';
import Typography from '../Typography';
import {
  Button,
  Content,
  Icon,
  Indication,
  Root,
  severityType,
} from './Alert.styles';

const mapIcon: Record<severityType, IconType> = {
  default: MdErrorOutline,
  error: MdErrorOutline,
  warning: MdWarningAmber,
  info: MdInfoOutline,
  success: MdCheckCircleOutline,
};

export type AlertProps = {
  title?: string;
  children: ReactNode;
  action?: string;
  severity?: severityType;
  buttonProps?: ButtonProps;
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ children, action, severity = 'default', buttonProps, ...props }, ref) => {
    const IconComponent = mapIcon[severity];

    return (
      <Root ref={ref} {...props}>
        <Indication $severity={severity} />
        <Content>
          <IconComponent size="19" />
          <Icon />
          {typeof children === 'string' ? (
            <Typography color="inherit" variant="h3" wrap={true}>
              {children}
            </Typography>
          ) : (
            children
          )}
          {action && <Button {...buttonProps}>{action}</Button>}
        </Content>
      </Root>
    );
  },
);

export default Alert;
