import styled from 'styled-components';
import { darken, lighten } from 'utils';
import Typography from '../Typography';

const Root = styled.div<{ fullWidth?: boolean; disabled?: boolean }>`
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  box-sizing: border-box;
`;

const Label = styled(Typography)<{ shrink?: boolean; $disabled?: boolean }>`
  margin: 0;
  cursor: text;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: flex-start;
  position: absolute;
  transform-origin: top left;
  cursor: ${({ $disabled: disabled }) =>
    disabled ? 'not-allowed' : 'pointer'};
  transform: ${({ shrink }) =>
    shrink
      ? 'translate(12px, 5px) scale(0.83)'
      : 'translate(17px, 15px) scale(1)'};
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: var(--color);

  // text-transform: uppercase;
  font-size: 17px !important;
  font-family: Montserrat, sans-serif;
  z-index: 1;
`;

const InputRoot = styled.div`
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: var(--color);
  box-shadow: inset 0px 0px 1px 1px var(--border);
  cursor: text;
  display: inline-flex;
  position: relative;

  background-color: ${({ theme }) =>
    theme.isDarkMode
      ? lighten(theme.palette.background, 0.05)
      : theme.palette.background};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}`};

  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.57;
  font-weight: 600;
  font-style: normal;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;

  box-sizing: border-box;
  align-items: center;

  &:after,
  &:before {
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
  }
`;

const Container = styled.div<{
  disabled?: boolean;
  $disabledTopPadding?: boolean;
}>`
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  display: block;
  min-width: 0;
  overflow: hidden;
  background: none;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  padding: ${({ $disabledTopPadding }) =>
    $disabledTopPadding ? '12px' : '28px 12px 12px'};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  border-radius: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`;

const Input = styled.input<{ $disabledTopPadding?: boolean; $show?: boolean }>`
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  display: ${({ $show: show }) => (show ? 'block' : 'none')};
  padding: ${({ $disabledTopPadding }) =>
    $disabledTopPadding ? '12px' : '28px 12px 12px'};
  min-width: 0;
  background: none;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  font-family: Montserrat, sans-serif;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${({ theme }) =>
      theme.isDarkMode ? 'currentColor' : darken(theme.palette.grey, 0.5)};
    font-weight: 400;
    font-style: italic;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill {
    font: inherit;
    color: currentColor !important;
    border-radius: ${({ theme }) => `${theme.shape.borderRadius}`};
    width: 100%;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
    text-align: right;
  }
`;

const Adornment = styled.div<{ orientation: 'start' | 'end' }>`
  height: 0.01em;
  display: flex;
  max-height: 2em;
  align-items: center;
  white-space: nowrap;
  font-family: Montserrat, sans-serif;
  color: var(--color);
  margin: ${({ orientation }) =>
    orientation === 'start' ? '17px 0px 0px 11px' : '0px 17px 0px 11px'};
`;

const HelperText = styled(Typography)`
  margin: 3px 6px 0px;
  color: var(--color);
  text-align: left;
`;

const Placeholder = styled.div`
  color: ${({ theme }) => theme.palette.grey};
  font-weight: 400;
  font-style: italic;
`;

export {
  Root,
  Label,
  Input,
  Container,
  HelperText,
  InputRoot,
  Adornment,
  Placeholder,
};
