import styled from 'styled-components';

export const Root = styled.div<{ $invisible: boolean }>`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${({ $invisible }) =>
    $invisible ? 'transparent' : 'rgba(0, 0, 0, 0.5)'};
  -webkit-tap-highlight-color: transparent;
`;
