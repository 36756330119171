import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { EditProjectTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const EditProjectPage = () => {
  return (
    <>
      <Header
        title="Proyectos"
        subtitle="Edita proyectos"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <EditProjectTemplate />
    </>
  )
}

export default withAuth(EditProjectPage);