import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/organisms';
import { COMMON_OPTIONS, DATE_FORMAT, DOWNLOAD_LOGS, LIST_LOGS } from 'const';
import { format } from 'date-fns';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { AMLog, AutomationManager } from 'types';

export const getColumns: (
  onDownloadLog: (id: number) => void,
) => columnType<AMLog>[] = (onDownloadLog) => {
  const columns: columnType<AMLog>[] = [
    {
      field: 'fileName',
      headerName: 'Nombre',
      flex: '1',
    },
    {
      field: 'size',
      headerName: 'Tamaño',
      flex: '0.5',
      render: ({ size }) => `${size} KB`,
    },
    {
      field: 'creationDate',
      headerName: 'Creación',
      flex: '0.5',
      render: ({ creationDate }) =>
        format(new Date(creationDate), DATE_FORMAT, COMMON_OPTIONS),
    },
    {
      field: 'updateDate',
      headerName: 'Actualización',
      flex: '0.5',
      render: ({ updateDate }) =>
        format(new Date(updateDate), DATE_FORMAT, COMMON_OPTIONS),
    },
    {
      field: 'id',
      headerName: 'Acciones',
      flex: '0.5',
      disabledSort: true,
      render: ({ id }) => {
        return (<IconButton
          info="Descargar logs"
          positionEdge="start"
          color="grey"
          onClick={() => onDownloadLog(id)}
        >
          <AiOutlineCloudDownload />
        </IconButton>)
      }
    },
  ];
  return columns;
};

export const fetchLogs = async (
  am: AutomationManager,
  logsPath: string,
  dateRange: string,
  config?: AxiosRequestConfig<AMLog[]>,
) => {
  return await axios.post<AMLog[]>(
    LIST_LOGS.concat('?dateRange=', dateRange),
    {
      amName: am.name,
      amUrl: am.url,
      logsPath,
    },
    config,
  );
};

export const downloadLogs = async (
  am: AutomationManager,
  logPath: string,
) => {
  console.log("Fetch funcion args: \nam:", am, "\nlogPaths:", logPath);
  return await axios.post(
    DOWNLOAD_LOGS,
    {
      amName: am.name,
      amUrl: am.url,
      logPath,
    },
    {
      responseType: "blob",
    }
  );
};
