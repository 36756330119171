import React, { FC } from 'react';

import { useTheme } from 'hooks';
import { darken, invertColor, lighten } from 'utils';

import { ButtonStyled, LabelStyled, Adornment } from './Button.styles';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../Tooltip';
import { TooltipProps } from '../Tooltip/Tooltip';

export declare type ButtonProps = {
  variant?: 'contained' | 'outlined' | 'text';
  color?:
  | 'primary'
  | 'secondary'
  | 'grey'
  | 'error'
  | 'warning'
  | 'info'
  | 'success';
  fullWidth?: boolean;
  info?: string | { position: TooltipProps['position']; message: string };
  isSmall?: boolean;
  disabled?: boolean;
  loading?: boolean;
  href?: string | 'back';
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<ButtonProps> = ({
  info,
  children,
  endAdornment,
  startAdornment,
  fullWidth = false,
  variant = 'text',
  color = 'primary',
  isSmall = false,
  href,
  disabled,
  loading = false,
  ...props
}) => {
  const { theme } = useTheme();
  const { palette, common, isDarkMode } = theme;

  const width = fullWidth ? '100%' : 'auto';

  let style: object = {
    ...props.style,
  };

  switch (variant) {
    case 'contained':
      style = {
        '--width': width,

        '--background': isDarkMode
          ? palette[color]
          : lighten(palette[color], 0.2),
        '--border': isDarkMode ? palette[color] : lighten(palette[color], 0.2),
        '--color': invertColor(palette[color], true),

        '--hover-background': isDarkMode
          ? darken(palette[color], 0.2)
          : palette[color],
        '--hover-border': isDarkMode
          ? darken(palette[color], 0.2)
          : palette[color],
        '--hover-color': invertColor(palette[color], true),

        '--disabled-background': isDarkMode
          ? palette[color]
          : lighten(palette[color], 0.2),
        '--disabled-border': isDarkMode
          ? palette[color]
          : lighten(palette[color], 0.2),
        '--disabled-color': invertColor(palette[color], true),
        ...style,
      };
      break;

    case 'outlined':
      style = {
        '--width': width,

        '--background': 'transparent',
        '--border': palette[color],
        '--color': palette[color],

        '--hover-background': 'transparent',
        '--hover-border': darken(palette[color], 0.2),
        '--hover-color': darken(palette[color], 0.2),

        '--disabled-background': lighten(palette.background, 0.4),
        '--disabled-border': lighten(palette.background, 0.4),
        '--disabled-color': common.white,
        ...style,
      };
      break;

    case 'text':
      style = {
        '--width': width,

        '--background': 'transparent',
        '--border': 'transparent',
        '--color': isDarkMode ? lighten(palette[color], 0.3) : palette[color],

        '--hover-background': 'transparent',
        '--hover-border': 'transparent',
        '--hover-color': isDarkMode
          ? lighten(palette[color], 0.6)
          : lighten(palette[color], 0.2),

        '--disabled-background': 'transparent',
        '--disabled-border': 'transparent',
        '--disabled-color': palette.grey,
        ...style,
      };
      break;
  }

  const navigate = useNavigate();

  const handleNavigate = (url: string) => {
    if (url === 'back') navigate(-1);
    else navigate(url);
  };

  const newProps = {
    ...props,
    $loading: loading,
    $small: isSmall,
    style,
    disabled: disabled || loading,
    onClick: href ? () => handleNavigate(href) : props.onClick,
  };

  const position = typeof info === 'string' ? undefined : info?.position;
  const message = typeof info === 'string' ? info : info?.message;

  return message ? (

    <ButtonStyled {...newProps} title={message}>
      <LabelStyled variant="p1" component="div" color="currentColor">
        {startAdornment && (
          <Adornment orientation="start">{startAdornment}</Adornment>
        )}
        {children}
        {endAdornment && (
          <Adornment orientation="end">{endAdornment}</Adornment>
        )}
      </LabelStyled>
    </ButtonStyled>
  ) : (
    <ButtonStyled {...newProps}>
      <LabelStyled variant="p1" component="div" color="currentColor">
        {startAdornment && (
          <Adornment orientation="start">{startAdornment}</Adornment>
        )}
        {children}
        {endAdornment && (
          <Adornment orientation="end">{endAdornment}</Adornment>
        )}
      </LabelStyled>
    </ButtonStyled>
  );
};

export default Button;
