import {
  Button,
  Grid,
  Spinner,
  Typography
} from 'components/atoms';
import { useState } from 'react';
import { updateExceptions } from '../ListExceptionTemplate/ListExceptionTemplate.const';
import { ExceptionConfirmationTemplateProps } from './ExceptionConfirmationTemplate.types';


/**
 * Este componente implementa el modal para la confirmación de activación de inmuebles
 */
const ExceptionConfirmationTemplate = (
  {
    setOpen,
    onClose,
    setErrorMessage,
    exceptionList,
    setModifiedExceptions,
    setActionSnackBar,
    filterModel,
    setFilterModel,
    isFiltering,
    currentFilterModel,
    ...props
  }: ExceptionConfirmationTemplateProps
) => {

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const onBatchUpdate = async () => {
    setIsUpdating(true);
    await updateExceptions({
      exceptions: exceptionList,
      setModifiedExceptions,
      setOpen,
      setErrorMessage,
      setActionSnackBar,
      setIsUpdating
    });
    setIsUpdating(false);
    setOpen('success');
    setActionSnackBar('resolve');
  }
  const onUpdate = async () => {
    await onBatchUpdate();
    setFilterModel([...currentFilterModel]);
    onClose();
  }

  const onDiscard = () => {
    try {
      setModifiedExceptions([])
      setFilterModel([...currentFilterModel]);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getMessage = (message: string) => {
    if (message.length > 100) { return message.substring(0, 100) + "..." } else {
      return message;
    }

  }

  const getConfirmationText = () => {
    let isFilteringMessage: string = "";
    if (isFiltering) {
      isFilteringMessage = "Antes de filtrar las excepciones, ";
    }
    if (exceptionList.length === 1) {
      return `${isFilteringMessage}¿Deseas actualizar la excepción con mensaje: '${getMessage(exceptionList[0].message)}' ?`
    }
    else {
      return `${isFilteringMessage}¿Deseas actualizar las ${exceptionList.length} excepciones seleccionadss?`
    }
  }

  return (
    <>
      {!isUpdating ?
        <Grid container spacing={3} justify="center">
          <Grid item>
            <Typography variant='h2'>
              {getConfirmationText()}
            </Typography>

          </Grid>
          <Grid container spacing={3} justify="flex-end"
            alignItems='center'>
            <Grid item>
              <Button
                info="Actualizar excepciones"
                color="primary"
                variant="contained"
                onClick={() => onUpdate()}
              >
                Actualizar
              </Button>
            </Grid>
            <Grid item>
              <Button
                info="Descartar cambios"
                color="primary"
                variant="contained"
                onClick={() => onDiscard()}
              >
                Descartar cambios
              </Button>
            </Grid>
          </Grid>
        </Grid>
        : <Spinner></Spinner>
      }
    </>
  );
};

export default ExceptionConfirmationTemplate;