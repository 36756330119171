import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_ALL_PROCESS } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiFillRobot, AiOutlineCaretRight, AiOutlinePoweroff } from 'react-icons/ai';
import { PaginationType, Process } from 'types';
import { ListBotTemplateFilterType } from './ListBotTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Process> = {
  field: 'id',
  order: 'ASC',
};

export const defaultListBotTemplateFilter: ListBotTemplateFilterType = {
  name: undefined,
};

const isRunningProcess = (_process: Process): boolean => ['Pendiente', 'En ejecución'].includes(_process.status ? _process.status.name : '')


export const getColumns: (
  startProcess: (_process: Process) => void,
  confirmStopProcess: (_process: Process) => void,
) => columnType<Process>[] = (startProcess, confirmStopProcess) => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '3',
    exportField: true,
    render: ({ name }) => (
      <>
        <AiFillRobot />
        &nbsp;{name}
      </>
    ),
  },
  {
    field: 'description',
    headerName: 'Descripción',
    flex: '5',
    exportField: true,
    render: ({ description }) => description,
  },
  {
    field: 'status',
    headerName: 'Estado',
    flex: '3',
    exportField: true,
    render: ({ status }) => (<>
      {status ? <strong style={{ color: status.color }}> {status.name}</strong> : ''}
    </>),
  },
  {
    field: 'lastExecution',
    headerName: 'Última ejecución',
    flex: '3',
    exportField: true,
    render: ({ lastExecution }) => { return lastExecution },
  },
];


