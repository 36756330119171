import { ChangeEvent } from "react";
import { Input, Label, Switch } from "./Toggle.styles";



type ToggleSwitchProps = {
  isChecked: boolean;
  onToggleChange: (
    newState: boolean,
    sourceElement?: number | string | number[] | string[]
  ) => void;
  tags?: {
    checked: string;
    unchecked: string;
  };
  sourceElement?: number | string | number[] | string[];
  toggleSize?: 'sm' | 'md' | 'lg';
}

/**
 * ToggleSwitch atom component it receives the following props:
 * 
 * isChecked: boolean: current state of the toggle
 * onToggleChange: (newState: boolean, itemsIds?: [number]) => void: function to handle the change of the toggle
 * tags?: { checked: string; unchecked: string; }: tags to show when the toggle is checked or unchecked
 * itemsId: [number]: ids of the items that are being toggled
 * toggleSize?: 'sm' | 'md' | 'lg': size of the toggle
 * @param ToggleSwitchProps 
 * @returns ToggleSwitch atom
 */
const ToggleSwitch = ({
  isChecked,
  onToggleChange,
  tags,
  sourceElement,
  toggleSize
}: ToggleSwitchProps) => {

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onToggleChange(e.target.checked, sourceElement);
  }

  return (
    <Label>
      <Input
        checked={isChecked}
        type="checkbox"
        onChange={handleChange}
        $size={toggleSize}
      />
      <Switch size={toggleSize} />
      {tags?.checked && tags?.unchecked &&
        <span> {isChecked ? tags.checked : tags.unchecked}</span>
      }
    </Label>
  );
};
export default ToggleSwitch;
