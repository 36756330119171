type ColorFormat = 'rgb' | 'rgba' | 'hsl' | 'hsla';
type ValueType = [number, number, number] | [number, number, number, number];

interface ColorObject {
  type: ColorFormat;
  values: ValueType;
}

const padZero = (str: string, len?: number) => {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

export const invertColor: (color: string, bw?: boolean) => string = (
  color,
  bw?,
) => {
  if (color.indexOf('rgb') !== -1) {
    return invertColor(rgbToHex(color));
  }

  if (color.indexOf('hsl') !== -1) {
    return invertColor(rgbToHex(hslToRgb(color)));
  }

  if (color.indexOf('#') === 0) {
    color = color.slice(1);
  }

  // convert 3-digit hex to 6-digits.
  if (color.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  if (color.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  let r: string | number = parseInt(color.slice(0, 2), 16);
  let g: string | number = parseInt(color.slice(2, 4), 16);
  let b: string | number = parseInt(color.slice(4, 6), 16);

  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
};

const hslToRgb = (color: string) => {
  const newColor = decomposeColor(color);
  const { values } = newColor;
  const h = values[0];
  const s = values[1] / 100;
  const l = values[2] / 100;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number, k = (n + h / 30) % 12) =>
    l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

  let type = 'rgb';
  const rgb = [
    Math.round(f(0) * 255),
    Math.round(f(8) * 255),
    Math.round(f(4) * 255),
  ];

  if (newColor.type === 'hsla') {
    type += 'a';
    rgb.push(values[3] as number);
  }

  return recomposeColor({
    type: type as ColorFormat,
    values: rgb as ValueType,
  });
};

const rgbToHex = (color: string) => {
  // Idempotent
  if (color.indexOf('#') === 0) {
    return color;
  }

  const { values } = decomposeColor(color);
  return `#${values
    .map((n, i) => intToHex(i === 3 ? Math.round(255 * n) : n))
    .join('')}`;
};

const intToHex = (int: number) => {
  const hex = int.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const clamp = (value: number, min: number = 0, max: number = 1) => {
  return Math.min(Math.max(min, value), max);
};

export const hexToRgb: (hex: string) => string = (color) => {
  color = color.substring(1);
  let rgbColor="";
  let aRgbHex = color.match(/.{1,2}/g);
  if(aRgbHex){
    let aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
    ];
  rgbColor=`rgb(${aRgb.map(x=>x.toString()).join(', ')})`;
  }
  return rgbColor;
}

export const decomposeColor: (color: string) => ColorObject = (color) => {
  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substring(0, marker) as ColorFormat;

  const valuesInString = color
    .substring(marker + 1, color.length - 1)
    .split(',');
  valuesInString.slice(0, 4);
  const values = valuesInString.map((value) => parseFloat(value)) as ValueType;

  return { type, values };
};

export const recomposeColor: (color: ColorObject) => string = (color) => {
  const { type } = color;
  const { values } = color;

  let valuesWithType: Array<number | string> = [...values];

  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    valuesWithType = values.map((n, i) =>
      i < 3 ? Math.round(n) : n,
    ) as ValueType;
  } else if (type.indexOf('hsl') !== -1) {
    valuesWithType[1] = `${values[1]}%`;
    valuesWithType[2] = `${values[2]}%`;
  }

  return `${type}(${valuesWithType.join(', ')})`;
};

export const darken: (color: string, coefficient: number) => string = (
  color,
  coefficient,
) => {
  const colorObj = decomposeColor(color);
  coefficient = clamp(coefficient);

  if (colorObj.type.indexOf('hsl') !== -1) {
    colorObj.values[2] *= 1 - coefficient;
  } else if (colorObj.type.indexOf('rgb') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      colorObj.values[i] *= 1 - coefficient;
    }
  }
  return recomposeColor(colorObj);
};

export const lighten: (color: string, coefficient: number) => string = (
  color,
  coefficient,
) => {
  const colorObj = decomposeColor(color);
  coefficient = clamp(coefficient);

  if (colorObj.type.indexOf('hsl') !== -1) {
    colorObj.values[2] += (100 - colorObj.values[2]) * coefficient;
  } else if (colorObj.type.indexOf('rgb') !== -1) {
    for (let i = 0; i < 3; i += 1) {
      colorObj.values[i] += (255 - colorObj.values[i]) * coefficient;
    }
  }

  return recomposeColor(colorObj);
};
