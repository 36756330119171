import { Arguments, Fetcher, Key, SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';

const useGet = <Data = any, Error = any, SWRKey extends Key = Arguments>(
  key: SWRKey,
  config?: SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>> | undefined,
) => {
  const response = useSWRImmutable(key, config);

  return {
    loading: !response.error && !response.data,
    ...response,
  };
};

export default useGet;
