import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { EditGroupingTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const EditGroupingPage = () => {
  return (
    <>
      <Header
        title="Inmuebles"
        subtitle="Edita inmuebles"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <EditGroupingTemplate />
    </>


  )
}

export default withAuth(EditGroupingPage);