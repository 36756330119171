import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { CreateCompanyTemplate } from '../../../../components/templates/PublicServicesTemplate/CompanyTemplate';

const breadcrumbs = [{ label: 'Servicios públicos' }];

const CreateCompanyPage = () => {
  return (
    <>
      <Header
        title="Compañías"
        subtitle="Crea compañías"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <CreateCompanyTemplate />
    </>


  )
}

export default withAuth(CreateCompanyPage);