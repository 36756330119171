import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_ROLES, GET_TASK } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineCheck, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { PaginationType, Role, Task } from 'types';
import { createPayload } from 'utils';
import { ListRoleTemplateFilterType } from './ListTaskTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Task> = {
  field: 'id',
  order: 'ASC',
};

export const defaultListRoleTemplateFilter: ListRoleTemplateFilterType = {
  name: undefined,
};

export const getColumns: (
  onDelete: (id: number) => void,
  onActive: (id: number) => void,
) => columnType<Task>[] = (onDelete, onActive) => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '4',
    exportField: true,
  },
  {
    field: 'description',
    headerName: 'Descripción',
    flex: '5',
    exportField: true,
  },
  {
    field: 'cronPattern',
    headerName: 'Cron',
    flex: '1',
    // disabledSort: true,
    exportField: true,
  },
  {
    field: 'timeZone',
    headerName: 'Zona Horaria',
    flex: '1',
    // disabledSort: true,
    exportField: true,
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    // align: "flex-end",
    flex: '2',
    disabledSort: true,
    render: ({ id }) => (
      <>

      </>
    ),
  },
];

export const fetchProcess = (
  pagination: tableModelType<Task>,
  config?: AxiosRequestConfig<Task>,
) => {
  return axios.get<PaginationType<Task>>(
    `${GET_TASK}${createPayload(pagination)}`,
    config,
  );
};
