import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  Grid,
  Input, MenuItem, Paper, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { useGetTable } from 'hooks';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Company, ServiceType } from 'types';
import { getErrorsFromBackend } from 'utils';
import { UPDATE_COMPANY } from '../../../../../const';
import { catchaStates, companyStates, fetchServiceTypes } from '../CreateCompanyTemplate/CreateCompanyTemplate.const';
import { defaultData, fetchCompany } from './EditCompanyTemplate.const';
import CreateUserTemplateLoading from './EditCompanyTemplate.loading';
import { EditCompanySchema, EditCompanyType } from './EditCompanyTemplate.schema';

const EditCompanyTemplate = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();


  const { data: serviceTypesFetch, loading: loadingMenus } = useGetTable<ServiceType[]>(
    fetchServiceTypes,
    [true],
  );

  const { data, loading: loadingData } = useGetTable<Company, { id: string }>(
    fetchCompany,
    { id: id as string },
  );

  const [loadingServices, setLoadingServices] = useState(true);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    register,
    formState: { errors },
  } = useForm<EditCompanyType>({
    resolver: yupResolver(EditCompanySchema),
    defaultValues: {
      name: '',
      code: '',
      nit: '',
      url: '',
      catcha: 'false',
      status: 'true',
      graceDay: '',
      typeServices: '',
    },
  });

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/servicios-publicos/management/company');
  };

  const onSubmit = async (companyForm: EditCompanyType) => {
    try {

      setLoading(true);

      const { catcha, status, typeServices, ...form } = companyForm;

      await axios.put<EditCompanyType>(UPDATE_COMPANY.replace(':id', id as string), {
        ...form,
        catcha: catcha === "true" ? true : false,
        status: status === "true" ? true : false,
        typeServices: typeServices?.split(",").map(
          service => {
            return {
              id: service.split(".")[0],
              name: service.split(".")[1],
            }
          }
        ),
      });

      setOpen('success');
    } catch (error) {
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<EditCompanyType>(error);

      if (isGeneral) {
        setError(...errorOptions);
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
      setOpen('error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('code', data.code);
      setValue('url', data.url);
      setValue('nit', data.nit);
      setValue('graceDay', data.graceday?.toString());
      if (!loadingMenus && !loadingData && !loadingServices) {
        const currentServices = data.typeServices?.map((serviceName) => {
          const found = serviceTypesFetch?.find((obj) => obj.name === serviceName);
          return found ? found.id : null;
        }).join(',');
        setValue('typeServices', currentServices);
      }
    }
  }, [data, loadingMenus, loadingData, loadingServices]);

  useEffect(() => {
    if (!loadingMenus && !loadingData && serviceTypesFetch) {
      setLoadingServices(false);
    }
  }, [loadingMenus, loadingData, serviceTypesFetch]);

  return (
    <Paper>
      {!loadingMenus || !loadingData ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={6}>
              <Input
                label="Nombre"
                fullWidth
                defaultValue={defaultData.name}
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Código"
                fullWidth
                disabled
                defaultValue={defaultData.code}
                {...register('code')}
                error={!!errors.code}
                helperText={errors.code && errors.code.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Dirección web de la compañía"
                fullWidth
                defaultValue={defaultData.url}
                {...register('url')}
                error={!!errors.url}
                helperText={errors.url && errors.url.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Nit"
                fullWidth
                defaultValue={defaultData.nit}
                {...register('nit')}
                error={!!errors.nit}
                helperText={errors.nit && errors.nit.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Días de gracia"
                fullWidth
                defaultValue={defaultData.graceday}
                {...register('graceDay')}
                error={!!errors.graceDay}
                helperText={errors.graceDay && errors.graceDay.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="catcha"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Catcha"
                      fullWidth
                    >
                      {catchaStates.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Estado"
                      fullWidth
                    >
                      {companyStates.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="typeServices"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Tipo de servicios"
                      fullWidth
                      required
                      multiple
                      enableFilter
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.typeServices}
                      helperText={errors.typeServices && errors.typeServices.message}
                    >
                      {serviceTypesFetch &&
                        serviceTypesFetch.map(({ id, name }) => (
                          <MenuItem
                            key={id}
                            label={`${name}`}
                            value={`${id}`}
                          >
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <Button
                type="button"
                fullWidth
                variant="text"
                color="grey"
                href="/pages/servicios-publicos/management/company"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se editó la compañía con éxito'}
            {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
          </SnackBar>
        </form>
      ) : (
        <CreateUserTemplateLoading />
      )}
    </Paper>
  );
};

export default EditCompanyTemplate;
