import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Alert, Button, Grid, Input, InputPassword } from 'components/atoms';
import { CUSTOMER, DEV, LOGIN } from 'const';
import { useCustomerContext } from 'contexts';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth, Customer, ResponseType } from 'types';
import { LoginFormSchema, LoginFormType } from './LoginForm.schema';

const mapErrors: Record<string, string> = {
  Unauthorized: 'Credenciales incorrectas',
};

export type LoginFormProps = {
  onSuccess: (data: Auth) => void;
};

const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const [loading, setLoading] = useState(false);
  const { loadCustomer } = useCustomerContext();

  const {
    register,
    handleSubmit,
    // control,
    setError,
    formState: { errors },
  } = useForm<LoginFormType>({
    resolver: yupResolver(LoginFormSchema),
  });

  const onSubmit = async (loginForm: LoginFormType) => {
    try {
      setLoading(true);
      const { remember, ...form } = loginForm;
      const customer = form.username?.split('@').pop() as string;

      const {
        data: { data: dataCustomer },
      } = await axios.get<ResponseType<Customer>>(
        CUSTOMER.replace(':customer', `${customer}`),
      );
      /**BACKEND-LOCAL*/
      console.log(DEV);

      if (DEV === 'true') {
        dataCustomer.apiBackend = 'http://localhost:3000'
      }

      loadCustomer({
        ...dataCustomer,
        apiBackend: `${dataCustomer.apiBackend}/api`,
      });
      const { data } = await axios.post<Auth>(LOGIN, form);
      onSuccess(data);
    } catch (error) {
      setLoading(false);
      const err = error as any;
      setError('error', {
        type: 'custom',
        message: mapErrors[err.message] || mapErrors.Unauthorized,
      });
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Input
            fullWidth
            label="Nombre de usuario"
            {...register('username')}
            error={!!errors.username}
            helperText={errors.username && errors.username.message}
            inputProps={{ autoComplete: 'username' }}
            id="username"
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="space-between"
        >
          <InputPassword
            fullWidth
            label="Contraseña"
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            inputProps={{ autoComplete: 'current-password' }}
            id="current-password"
          />
          {/* <Grid item>
            <Controller
              name="remember"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label="Remember user"
                  onChange={field.onChange}
                  checked={field.value}
                />
              )}
            />
          </Grid>
          <Grid item>
            <CustomLink align="right" component={Link}>
              Forgot Password?
            </CustomLink>
          </Grid> */}
        </Grid>
        {errors.error && (
          <Grid item xs={12}>
            <Alert severity="error">{errors.error.message}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button loading={loading} fullWidth variant="contained">
            Ingresar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
