import React, { ReactNode } from 'react';
import { AiOutlineUser } from 'react-icons/ai';

import { RootAvatar, ImgAvatar } from './Avatar.styles';

export type AvatarProps = {
  children?: ReactNode;
  src?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Avatar = ({ children, src, ...props }: AvatarProps) => {
  return (
    <>
      <RootAvatar {...props}>
        {src && <ImgAvatar src={src} />}
        {!src && children && children}
        {!src && !children && <AiOutlineUser />}
      </RootAvatar>
    </>
  );
};

export default Avatar;
