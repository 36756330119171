import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  DateRangePicker,
  Dialog,
  Grid,
  Input,
  MenuItem,
  Select,
  SnackBar,
  Typography,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { DateRangePickerValue } from 'components/atoms/DateRangePicker/DateRangePicker';
import { Table } from 'components/organisms';
import {
  DATE_FORMAT,
  DATE_FORMAT_BACK_WITH_OUT_DATE,
  DATE_FORMAT_BACK_WITH_OUT_TIME,
  GET_PROCESS,
  GET_STATUS_BY_PROCESS,
  RE_PROCESS_TRANSACTION,
} from 'const';
import { useCustomerContext } from 'contexts';
import { format } from 'date-fns';
import { useDialog, useGet, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PaginationType, Process, State, Transaction } from 'types';
import { createFilterStructure, joinObjectWithFormat } from 'utils';

import {
  defaultPagination,
  defaultSortModel,
  fetch,
  getColumns,
  getDefaultProcessTemplateFilter,
} from './ProcessTemplate.const';
import {
  ProcessTemplateFilterSchema,
  ProcessTemplateFilterType,
} from './ProcessTemplate.schema';
import { ProcessTemplateProps, responseType } from './ProcessTemplate.types';
import { Auth } from 'types';
import { loadState } from 'utils';

const ProcessTemplate = <T extends object = {}>({
  id,
}: ProcessTemplateProps) => {
  const { name } = useCustomerContext();
  const [loadingForm, setLoadingForm] = useState(false);

  const {
    loading: loadingProcesses,
    error: errorProcesses,
    data: process,
  } = useGet<Process>(GET_PROCESS.replace(':id', id));

  const {
    loading: loadingStates,
    error: errorStates,
    data: allStates,
  } = useGet<State[]>(GET_STATUS_BY_PROCESS.replace(':id', id));

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ProcessTemplateFilterType>({
    defaultValues: getDefaultProcessTemplateFilter(id),
    resolver: yupResolver(ProcessTemplateFilterSchema),
  });

  const dateRange = watch('dateRange');

  const [filterModel, setFilterModel] = useState<
    filterModelType<Transaction<T>>[]
  >([
    createFilterStructure('id_process', 'equals', id) as filterModelType<
      Transaction<T>
    >,
    createFilterStructure(
      'created_at',
      'between_date',
      joinObjectWithFormat(dateRange, '.', format, [
        DATE_FORMAT_BACK_WITH_OUT_TIME,
      ]),
    ) as filterModelType<Transaction<T>>,
    createFilterStructure(
      'created_at',
      'between_time',
      joinObjectWithFormat(dateRange, '.', format, [
        DATE_FORMAT_BACK_WITH_OUT_DATE,
      ]),
    ) as filterModelType<Transaction<T>>,
  ]);

  const {
    loading: loadingTable,
    error: errorTransactions,
    data: processes,
    handleData,
    getData,
    mutate,
  } = useGetTable<
    PaginationType<Transaction<T>>,
    tableModelType<Transaction<T>>
  >(fetch, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });

  const onReprocessed = async (id: string) => {
    try {
      setLoadingForm(true);
      const auth = loadState<Auth>('auth');
      const username: string = auth?.user?.username || '';
      await axios.put(RE_PROCESS_TRANSACTION.replace(':id', id.toString()).replace(':user', username));

      mutate(({ result, ...others }) => {
        const copyTransactions: Transaction<T>[] = [...result];
        const index = copyTransactions.findIndex(
          (transaction) => `${transaction.id}` === id,
        );
        if (index !== -1) {
          copyTransactions[index].reprocessed = false;
        }
        return { ...others, result: copyTransactions };
      });

      await handleData({
        paginationModel: tableControllers.paginationModel,
        sortModel: tableControllers.sortModel,
        filterModel,
      });
      onClose();
      setLoadingForm(false);
      setOpen('success');
    } catch (error) {
      onClose();
      setLoadingForm(false);
      setOpen('error');
    }
  };

  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();

  const onCloseSnackBar = useCallback(() => {
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const confirmRemove = useCallback(
    (id: number) => {
      onOpen(id);
    },
    [onOpen],
  );

  const columns = useMemo(
    () => getColumns(name, confirmRemove, process?.canBeReprocessed),
    [name, process?.canBeReprocessed, confirmRemove],
  );

  const tableControllers = useTable<Transaction<T>>(
    {
      filterModel,
      paginationModel: processes
        ? { ...defaultPagination, rowsCount: processes.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Transaction<T>>, 'paginationModel'>,
    ) => Promise<responseType<T>>,
  );

  const onSubmit = useCallback(
    ({ package_, status, dateRange }: ProcessTemplateFilterType) => {
      const filterProcess = createFilterStructure('id_process', 'equals', id);

      const filterPackage = createFilterStructure(
        'id_transaction',
        'contains',
        package_,
      );

      const filterStatus = createFilterStructure('id', 'in', status, 'state');

      const filterDateRange = createFilterStructure(
        'created_at',
        'between_date',
        joinObjectWithFormat(dateRange, '.', format, [
          DATE_FORMAT_BACK_WITH_OUT_TIME,
        ]),
      );

      const filterTimeRange = createFilterStructure(
        'created_at',
        'between_time',
        joinObjectWithFormat(dateRange, '.', format, [
          DATE_FORMAT_BACK_WITH_OUT_DATE,
        ]),
      );

      const modelFilter = [
        filterPackage,
        filterStatus,
        filterDateRange,
        filterTimeRange,
        filterProcess,
      ].filter((item) => item);

      setFilterModel(modelFilter as filterModelType<Transaction<T>>[]);
    },
    [id],
  );

  const loading = loadingTable || loadingStates || loadingProcesses;
  const hasError = errorStates || errorTransactions || errorProcesses;

  const selectedRole = useMemo(
    () =>
      selected !== undefined
        ? processes?.result.find((user) => user.id === selected)
        : undefined,
    [selected, processes],
  );

  return (
    <>
      <Table<Transaction<T>>
        {...tableControllers}
        numberOfVisibleColumns={10}
        // title={`Encontramos ${tableControllers.paginationModel.rowsCount} resultados`}
        exportOptions={{
          filename: `reporte_${joinObjectWithFormat(dateRange, '-', format, [
            DATE_FORMAT,
          ])}`,
        }}
        columns={columns}
        rows={processes?.result || []}
        error={!!hasError}
        loading={loading}
        filterComponent={
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              alignItems="center"
              justify="flex-end"
              spacing={4}
              wrap="nowrap"
            >
              <Grid item xs={3}>
                <Input
                  label="Filtra por paquete"
                  fullWidth
                  {...register('package_')}
                  error={!!errors.package_}
                  helperText={errors.package_ && errors.package_.message}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        label="Filtra por estado"
                        fullWidth
                        enableFilter
                        multiple
                      >
                        {allStates?.map(({ code, name, id }) => (
                          <MenuItem key={code} label={name} value={`${id}`}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field }) => (
                    <TimeRangePicker
                      label="Filtra por horas"
                      fullWidth
                      onChange={field.onChange}
                      value={field.value as TimeRangePickerValue}
                      helperText={
                        errors.dateRange &&
                        (errors.dateRange.startDate?.message ||
                          errors.dateRange.endDate?.message)
                      }
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={3}>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field }) => (
                    <DateRangePicker
                      label="Filtra por fechas"
                      fullWidth
                      onChange={field.onChange}
                      value={field.value as DateRangePickerValue}
                      error={!!errors.dateRange}
                      helperText={
                        errors.dateRange &&
                        (errors.dateRange.startDate?.message ||
                          errors.dateRange.endDate?.message)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          </form>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title="Confirma esta acción"
      >
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={12}>
            <Typography variant="h2">
              {`¿Estas seguro de re-procesar la transacción ${selectedRole?.idTransaction}?`}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="grey" onClick={onClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onReprocessed(`${selectedRole?.id}`)}
              loading={loadingForm}
            >
              Si, deseo re-procesar
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' && 'Se re-proceso la transacción exitosamente'}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default ProcessTemplate;
