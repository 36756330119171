import axios, { AxiosRequestConfig } from 'axios';
import { IconButton, Tooltip } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { COMMON_OPTIONS, DATE_FORMAT, SEARCH_TRANSACTIONS } from 'const';
import { format, startOfMonth } from 'date-fns';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineReload } from 'react-icons/ai';
import { State, Transaction, customerType } from 'types';
import {
  createFilterStructure,
  createPayload,
  selectMultipleValueAsArray,
} from 'utils';
import { messageSplitter } from '../AMTemplate/AMExceptionTemplate/ListExceptionTemplate/ListExceptionTemplate.const';
import { ProcessTemplateFilterType } from './ProcessTemplate.schema';
import { responseType } from './ProcessTemplate.types';

type ExtraDataJsonType = {
  errorMessage?: string;
  detailMessage?: string;
};


export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Transaction> = {
  field: 'id',
  order: 'ASC',
};

export const getDefaultProcessTemplateFilter: (
  status: string,
) => ProcessTemplateFilterType = (status) => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);

  return {
    dateRange: {
      startDate: startOfMonth(startDate),
      endDate,
    },
    package_: undefined,
    status: '',
  };
};

const extraColumns: Record<customerType, columnType<Transaction>[]> = {
  marval: [
    {
      field: `name`,
      headerName: 'Ultimo estado',
      align: 'flex-start',
      flex: '1',
      table: 'lastState',
      exportField: true,
      render: ({ lastState }) => lastState?.name,
    },
  ],
  prodesa: [],
  buenvivir: [],
  mayasoft: [],
  gmail: [],
  cusezar: [],
  alcabama: [],
  jaramillo_mora: [],
  triada: [],
  capital: [],
  logicem: [],
  fl_colombia: [],
};

export const getColumns: (
  customer: customerType,
  onReprocessed: (id: number) => void,
  canBeReprocessed?: boolean,
) => columnType<Transaction>[] = (
  customer,
  onReprocessed,
  canBeReprocessed,
) => {
    const additional: columnType<Transaction>[] = [];

    if (canBeReprocessed)
      additional.push({
        field: 'actions',
        headerName: 'Acciones',
        // align: 'flex-end',
        flex: '1',
        disabledSort: true,
        exportField: true,
        customExcelRender: (element) => {
          const reprocessedBy = element['reprocessedBy'] || '';
          return element['reprocessed'] ? `Re-procesado por: ${reprocessedBy}` : '';
        },
        render: ({ id, reprocessed, reprocessedBy }) => (
          <IconButton
            disabled={reprocessed}
            info={reprocessed ? `Re-procesado por: ${(reprocessedBy || '')}` : "Re-procesar paquete"}
            color="info"
            onClick={() => onReprocessed(id)}
          >
            <AiOutlineReload />
          </IconButton>
        ),
      });

    const columns: columnType<Transaction>[] = [
      {
        field: 'id_transaction',
        headerName: 'Paquete',
        flex: '3',
        exportField: true,
        render: ({ idTransaction }) => idTransaction,
      },
      {
        field: 'created_at',
        headerName: 'Fecha creación',
        align: 'flex-start',
        flex: '2',
        exportField: true,
        render: ({ createdAt }) =>
          format(new Date(createdAt), DATE_FORMAT, COMMON_OPTIONS),
      },
      {
        field: 'transaction_date',
        headerName: 'Fecha últ. mod.',
        align: 'flex-start',
        flex: '2',
        exportField: true,
        render: ({ transactionDate, updatedAt }) =>
          format(
            new Date(updatedAt ? updatedAt : transactionDate),
            DATE_FORMAT,
            COMMON_OPTIONS,
          ),
      },
      {
        field: 'id',
        headerName: 'Estado',
        table: 'state',
        align: 'flex-start',
        flex: '2',
        exportField: true,
        overflowNone: true,
        render: ({ status, extraDataJson }: { status: State, extraDataJson: ExtraDataJsonType }) => {
          const errorMessage = 'errorMessage' in extraDataJson ? extraDataJson.errorMessage : null;
          return (
            <span title={status.name}>{status.name}</span>
          );
        }
      },
      {
        field: 'extraDataJson',
        table: 'state',
        headerName: 'Detalle',
        align: 'flex-start',
        flex: '2',
        overflowNone: true,
        exportField: true,
        disabledSort: true,
        customExcelRender: (element) => {
          const { code } = element['status'];
          const { errorMessage, detailMessage } = element['extraDataJson'] || {};

          if (code.toLowerCase().includes('error')) {
            return errorMessage || detailMessage || '';
          } else {
            return detailMessage || '';
          }
        },
        render: ({ status, extraDataJson }: { status: State, extraDataJson: ExtraDataJsonType }) => {
          const errorMessage = extraDataJson?.errorMessage;
          const detailMessage = extraDataJson?.detailMessage;
          const showErrorMessage = status.code.toLowerCase().includes('error') && errorMessage;
          return (
            <>
              {showErrorMessage &&
                <Tooltip
                  messageList={messageSplitter(errorMessage, 9, 7)}
                  position='left start'
                  color="grey"
                >
                  {errorMessage.length > 30 ? `${errorMessage.substring(0, 30)}...` : errorMessage}
                </Tooltip>
              }
              {(!showErrorMessage && detailMessage) &&
                <Tooltip
                  messageList={messageSplitter(detailMessage, 9, 7)}
                  position='left start'
                  color="grey"
                >
                  {detailMessage.length > 30 ? `${detailMessage.substring(0, 30)}...` : detailMessage}
                </Tooltip>
              }
            </>
          );
        },
      },
      ...extraColumns[customer],
      ...additional,
    ];

    return columns;
  };


export const fetch = <T extends object>(
  { filterModel, ...pagination }: tableModelType<Transaction<T>>,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  const copyFilterModel = filterModel ? [...filterModel] : [];
  const filterStatusIndex = copyFilterModel.findIndex(
    ({ field, table }) => field === 'id' && table === 'state',
  );

  if (filterStatusIndex !== -1) {
    const status = createFilterStructure(
      'id',
      'in',
      selectMultipleValueAsArray(
        copyFilterModel[filterStatusIndex].value as string,
      ),
      'state',
    );
    copyFilterModel.splice(filterStatusIndex, 1);
    if (status) copyFilterModel.push(status);
  }

  return axios.get<responseType<T>>(
    `${SEARCH_TRANSACTIONS}${createPayload({
      ...pagination,
      filterModel: copyFilterModel,
    })}`,
    config,
  );
};
