import React, { FC } from 'react';
import { CustomPerfectScrollbar, Root } from './Menu.styles';

export type MenuProps = {
  maxItems?: number;
} & React.HTMLAttributes<HTMLDivElement>;

const Menu: FC<MenuProps> = ({ maxItems, children, ...props }) => {
  return (
    <Root $maxItems={maxItems} {...props}>
      <CustomPerfectScrollbar $maxItems={maxItems}>
        {children}
      </CustomPerfectScrollbar>
    </Root>
  );
};

export default Menu;
