import React, { FC } from 'react';

import Root from './Container.styles';

export type ContainerProps = {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fixed?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Container: FC<ContainerProps> = ({ maxWidth = 'lg', ...others }) => {
  return <Root {...others} maxWidth={maxWidth} />;
};

export default Container;
