import { columnType } from 'components/molecules';
import { formatDate } from 'components/templates/ServerTemplate/Shared/shared.const';
import { RegexException } from 'types/entities/regex-exception';

export const getColumns: (
) => columnType<RegexException>[] = () => [
  {
    field: 'errorName',
    headerName: 'Nombre',
    flex: '1',
    exportField: true,
    render: ({ errorName }) => (<span title={errorName}>{errorName}</span>),
  },
  {
    field: 'errorRegex',
    headerName: 'Regex',
    flex: '2',
    exportField: true,
    render: ({ errorRegex }) => (<span title={errorRegex}>{errorRegex}</span>),
  },
  {
    field: 'flags',
    headerName: 'Banderas',
    flex: '0.5',
    exportField: true,
    render: ({ flags }) => (<span title={flags}>{flags}</span>),
  },
  {
    field: 'howOftenNotify',
    headerName: 'Notificar cada',
    flex: '0.6',
    exportField: true,
    render: ({ howOftenNotify }) => (<span title={howOftenNotify.toString()}>{howOftenNotify}</span>),
  },
  {
    field: 'createdAt',
    headerName: 'Creado',
    flex: '0.5',
    exportField: true,
    render: ({ createdAt }) => (<span title={formatDate(createdAt.toString())}>{formatDate(createdAt.toString())}</span>),
  },
  {
    field: 'updatedAt',
    headerName: 'Actualizado',
    flex: '0.5',
    exportField: true,
    render: ({ updatedAt }) => (<span title={formatDate(updatedAt.toString())}>{formatDate(updatedAt.toString())}</span>),
  }
];

export const getColumnsForDetail: () => columnType<RegexException>[] = () => [
  {
    field: 'channelIdTeams',
    headerName: 'Canal de Teams',
    flex: '2',
    exportField: true,
    isRaw: true,
    overflowNone: true,
    render: ({ channelIdTeams }) => (channelIdTeams ? (<span title={channelIdTeams}>{channelIdTeams}</span>) : ''),
  },
  {
    field: 'teamsId',
    headerName: 'Equipo de Teams',
    flex: '2',
    exportField: true,
    isRaw: true,
    overflowNone: true,
    render: ({ teamsId }) => (teamsId ? (<span title={teamsId}>{teamsId}</span>) : ''),
  },
  {
    field: 'customer',
    headerName: 'Cliente',
    flex: '1',
    exportField: true,
    render: ({ customer }) => (<span title={customer ? customer.code.toString() : ''}>{customer?.code.toString() || ''}</span>),
  },
  {
    field: 'process',
    headerName: 'Proceso',
    flex: '1',
    exportField: true,
    render: ({ process }) => (<span title={process ? process.name.toString() : ''}>{process?.name.toString() || ''}</span>),
  }
];