import { Menu } from 'types';

import reduceDrawerItems from '../reduceLink';

type DrawerListProps = {
  pages: Menu[];
  deep: number;
};

const DrawerList = ({ pages, deep }: DrawerListProps) => {
  const childRoutes = pages.reduce(
    (items, page) => reduceDrawerItems({ items, page, deep }),
    [] as JSX.Element[],
  );

  return <>{childRoutes}</>;
};

export default DrawerList;
