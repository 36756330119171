import styled from 'styled-components';
import { darken } from 'utils';

const CheckRoot = styled.div`
  padding: 12px;
`;

const Root = styled.label<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  display: inline-flex;
  align-items: center;
  margin-left: -11px;
  margin-right: 16px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
`;

const Label = styled.span`
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: ${({ theme }) => darken(theme.common.white, 0.4)};
  display: block;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export { CheckRoot, Root, Label };
