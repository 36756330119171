import styled from 'styled-components';
import Typography from '../Typography';

export const Root = styled.span`
  display: flex;
  align-items: center;
  // text-transform: uppercase;
`;

export const Divider = styled(Typography)`
  margin: 0px 6px;
`;
