import axios from 'axios';
import {
  Alert,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Paper,
  PdfViewer,
  Select,
  Skeleton,
  SnackBar,
  Typography,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { FIX_DOCUMENT_VERIFICATION, REJECT_DOCUMENT_VERIFICATION } from 'const';
import { useGetTable } from 'hooks';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Document } from 'types';
import { ValidateData, ViewData } from './components';
import { fetchDocuments } from './FixVerificationTemplate.const';
import { BlockGrid } from './FixVerificationTemplate.styles';

export type FixVerificationTemplateProps = {};

const FixVerificationTemplate = <T extends object = {}>({
  ...props
}: FixVerificationTemplateProps) => {
  const idProcess = useParams<'id'>().id as string;
  const id = useParams<'idDocument'>().idDocument as string;

  const {
    loading,
    data: documents,
    mutate,
  } = useGetTable<Document<T>[], any>(fetchDocuments, {
    id,
  });
  const navigate = useNavigate();
  const [snackbarState, setSnackbarState] = useState<severityType>();
  const [form, setForm] = useState<Record<string, any>>();
  const [accept, setAccept] = useState<boolean>();
  const [acceptText, setAcceptText] = useState<string>('');
  const [document, setDocument] = useState<Document<T>>();
  const [isClosed, setIsClosed] = useState<boolean>(true);
  const [loadingUpdate, setLoading] = useState<boolean>(false);

  const handleChange = ({ target }: any) => {
    const selectedDocument = documents?.find(
      (document) => document.id === +target.value,
    );
    setDocument(selectedDocument);
    setIsClosed(
      document !== undefined
        ? Object.values(document.extraDataJson.ocr).filter(
          (prop) => prop.mustFix,
        ).length > 0
        : true,
    );
  };

  const onCloseDialog = () => {
    setAccept(undefined);
  };

  const onCloseSnackbar = () => {
    setDocument(undefined);
    setSnackbarState(undefined);
  };

  const onConfirmValidation = async () => {
    try {

      setLoading(true);
      const { data } = await axios.put(
        FIX_DOCUMENT_VERIFICATION.replace(
          ':id',
          document?.id.toString() as string,
        ),
        form,
      );
      setSnackbarState('success');
      setAccept(true);
      setAcceptText('verificado');
      verifyIfEmptyAndMutate(data.id);
    } catch (error) {
      setLoading(false);
      setSnackbarState('error');
      setAccept(undefined);
    }
  };

  const onRejectValidation = async () => {
    try {
      setLoading(true);
      const { data } = await axios.put(
        REJECT_DOCUMENT_VERIFICATION.replace(
          ':id',
          document?.id.toString() as string,
        ),
      );

      setSnackbarState('success');
      setAccept(false);
      setAcceptText('rechazado');
      verifyIfEmptyAndMutate(data.id);
    } catch (error) {
      setSnackbarState('error');
      setLoading(false);
      setAccept(undefined);
    }
  };

  const verifyIfEmptyAndMutate = (idDocument: string | number) => {
    mutate((beforeDocuments) => {
      const copyDocuments = [...beforeDocuments];
      const index = copyDocuments.findIndex(
        (document) => document.id === idDocument,
      );
      if (index !== -1) {
        copyDocuments.splice(index, 1);
        setAccept(undefined);
        setLoading(false);
      }
      if (copyDocuments?.length === 0)
        navigate(`/pages/process/${idProcess}/fix`);
      return copyDocuments;
    });
  };

  const completed = documents?.length === 0;

  return (
    <>
      <Paper>
        <Grid container justify="center" alignItems="stretch" spacing={5}>
          <Grid item md={8} xs={12} style={{ flex: 1 }}>
            {!loading ? (
              <Select
                disabled={completed}
                value={String(document?.id) || ''}
                onChange={handleChange}
                label="Selecciona el documento a corregir"
                fullWidth
                enableFilter
              >
                {documents?.map(({ id, name }, idx) => (
                  <MenuItem key={id} label={`${id}`} value={`${id}`}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Skeleton />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="stretch"
          spacing={5}
          style={{ flex: 1 }}
        >
          {document && (
            <>
              <Grid item lg={6} xs={12}>
                <PdfViewer
                  nativeVersion
                  name={document.name}
                  file={`${document.file}`}
                />
              </Grid>
              <BlockGrid
                container
                direction="column"
                spacing={5}
                alignItems="flex-end"
                lg={6}
                xs={12}
              >
                <ViewData
                  input={document.extraDataJson.ocr}
                  isClosed={isClosed}
                  onIsClosed={setIsClosed}
                />
                <Grid item>
                  <ValidateData
                    loading={loadingUpdate}
                    onReject={(e) => {
                      e.preventDefault();
                      setForm(undefined);
                      setAccept(false);

                    }}
                    onConfirm={(data) => {
                      setForm(data);
                      setAccept(true);
                    }}
                    input={document.extraDataJson.ocr}
                  />
                </Grid>
              </BlockGrid>
            </>
          )}
          {!document && !completed && (
            <Grid item lg={10} xs={12}>
              <Alert severity="info">Selecciona un documento</Alert>
            </Grid>
          )}
          {completed && (
            <Grid item lg={10} xs={12}>
              <Alert severity="success">Verificación terminada</Alert>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Dialog
        open={accept !== undefined}
        onClose={onCloseDialog}
        title="Confirma tu respuesta"
      >
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={12}>
            <Typography variant="h2">
              {`¿Estas seguro de ${accept ? 'aprobar' : 'rechazar'
                } la verificación?`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="grey"
              type="button"
              onClick={onCloseDialog}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            {accept === true ? (
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={onConfirmValidation}
              >
                Si, deseo aprobarla
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={onRejectValidation}
              >
                Si, deseo rechazarla
              </Button>
            )}
          </Grid>
        </Grid>
      </Dialog>
      <SnackBar
        wait={2000}
        open={snackbarState !== undefined}
        onClose={onCloseSnackbar}
        severity={snackbarState}
      >
        {snackbarState === 'success' &&
          `Documento ${acceptText}`}
        {snackbarState === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default FixVerificationTemplate;
