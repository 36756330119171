import mayasoft from 'assets/img/mayasoft.png';
import { Grid, Typography } from 'components/atoms';
import { Outlet } from 'react-router-dom';
import { Container, Footer, GridContent, Root } from './PublicLayout.styles';

export type PublicLayoutProps = {};

const PublicLayout = ({ ...props }: PublicLayoutProps) => {
  return (
    <Root {...props}>
      <Container maxWidth="md">
        <Grid container alignItems="stretch" justify="center">
          <GridContent item xl={5} lg={5} md={6} sm={9}>
            <Outlet />
            <Footer>
              <Typography variant="h3">Powered by</Typography>
              <img src={mayasoft} alt="Mayasoft icon" />
            </Footer>
          </GridContent>
          {/* <GridImage item xl={7} lg={7} md={6} /> */}
        </Grid>
      </Container>
    </Root>
  );
};

export default PublicLayout;
