import { createContext } from 'react';

import { Auth } from 'types';

export type AuthContextType = {
  login: (auth: Auth) => void;
  logout: () => void;
} & Partial<Auth>;

export default createContext<AuthContextType>({} as AuthContextType);
