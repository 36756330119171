import GlobToRegExp from 'glob-to-regexp';
import { number, object, string, TypeOf } from 'yup';

export const CreateEditRegexSchema = object({
  errorRegex: string()
    .max(255)
    .required('El campo es requerido')
    .test('regex', 'El regex no es valido', (value: string | undefined) => {
      if (typeof value !== 'string') return false;
      let result = true;
      try {
        new RegExp(value);
        return true;
      } catch (e) {
        result = false;
      }
      try {
        GlobToRegExp(value);
        return true;
      } catch (e) {
        result = false;
      }
      return result;
    }),
  errorName: string().max(255).required('El campo es requerido'),
  flags: string()
    .default('g')
    .max(255)
    .test(
      'flags',
      'Los flasg deben ser d, g, i, m, s, u, v, y separados por coma',
      (value: string | undefined): boolean => {
        if (typeof value !== 'string') return false;
        if (!value) return true;

        const flags = value.split(',');
        const validFlags = ['d', 'g', 'i', 'm', 's', 'u', 'v', 'y'];
        return flags.every((flag) => validFlags.includes(flag));
      },
    )
    .optional(),
  customerCode: string().max(255).optional(),
  processCode: string().max(255).optional(),
  howOftenNotify: number().default(60).integer().positive().optional(),
  channelUrl: string().max(255),
  teamsId: string().max(255),
  channelIdTeams: string().max(255),
});

export type CreateEditRegexType = TypeOf<typeof CreateEditRegexSchema>;
