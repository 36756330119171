import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { PaginationType } from '../types';

/**
 * Custom Hook para realizar una petiión get de listas de objetos
 *
 * @param {string} url - La URL de la solicitud GET.
 * @returns {Object} - Un objeto con los siguientes valores:
 *   - data: Lista de objetos obtenidos
 *   - loading: Un indicador de carga (true si está cargando, false si ha terminado).
 *   - error: Cualquier error que se haya producido durante la solicitud.
 *
 * @example
 * const { data: projectsFetch, loading: loadingProjects, error: errorProjects } = useGetData<Project[]>(
 *   GET_PROJECTS // La URL de la solicitud GET
 * );
 */
function useGetData<T extends object>(url: string): {
  data: T[] | null;
  loading: boolean;
  error: Error | null;
} {
  const [data, setData] = useState<T[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: AxiosResponse<PaginationType<T>> = await axios.get(url);
        setData(response.data.result);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [url]);

  return { data, loading, error };
}

export default useGetData;
