import { TypeOf, array, number, object, string } from 'yup';

export const EditGroupingSchema = object({
  name: string().required(),
  projectName: string().required(),
  area: string(),
  status: string().required(),
  companyServiceGroupings: array().of(
    object({
      companyServiceGroupingId: number(),
      reference: string(),
      companyServiceCode: string().optional(),
    }),
  ),
});

export type EditGroupingType = TypeOf<typeof EditGroupingSchema>;

// The following two types were implemented to use internal types of the hook library.
export type FormValues = {
  name: string | undefined;
  projectName: string | undefined;
  area: string | undefined;
  status: string | undefined;
  companyServiceGroupings: Array<{}>;
};

export type CompanyServiceGroupingField =
  `companyServiceGroupings.${number}.companyServiceCode`;
