import React from 'react';
import { Menu } from 'types';
import ListLink from './components/ListLink';
import { Root } from './Navigator.styles';

export type NavigatorProps = {
  options: Menu[];
  open: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Navigator = ({ options, open, ...props }: NavigatorProps) => {
  return (
    <Root $open={open} {...props}>
      <ListLink deep={0} pages={options} />
    </Root>
  );
};

export default Navigator;
