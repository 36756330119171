import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Grid,
  IconButton,
  Input, MenuItem, Paper, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import useGetData from 'hooks/useGetData';
import { useState } from 'react';
import { Controller, ControllerRenderProps, useFieldArray, useForm } from 'react-hook-form';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoAdd } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Company, Project } from 'types';
import { AREAS } from '../../ReportsTemplate/Shared/sharedConsts';
import { GET_ENABLED_PROJECTS_PATH } from '../ListGroupingTemplate/ListGroupingTemplate.const';
import { GET_ENABLED_COMPANIES_PATH, httpErrorMessageFormatter } from '../Shared/sharedConsts';
import { createGrouping } from './CreateGroupingTemplate.const';
import CreateGroupingTemplateLoading from './CreateGroupingTemplate.loading';
import { CompanyServiceGroupingField, CreateGroupingSchema, CreateGroupingType } from './CreateGroupingTemplate.schema';


const CreateGroupingTemplate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { data: projectsFetch, loading: loadingProjects } = useGetData<Project>(
    GET_ENABLED_PROJECTS_PATH
  );
  const { data: companiesFetch, loading: loadingCompanies } = useGetData<Company>(
    GET_ENABLED_COMPANIES_PATH
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCompanyCodes, setSelectedCompanyCodes] = useState<string[]>([]);

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<CreateGroupingType>({
    resolver: yupResolver(CreateGroupingSchema),
    defaultValues: {
      name: '',
      projectName: '',
      area: 'UNIDAD',
      companyServiceGroupings: [{ reference: '', companyServiceCode: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'companyServiceGroupings',
    control
  });

  const { name, projectName, area, companyServiceGroupings } = watch();
  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/servicios-publicos/inmueble');
  };

  const isDisabled = () => {
    if (name === '' || projectName === '' || area === '') return true;
    if (companyServiceGroupings && companyServiceGroupings.length > 0) {
      return companyServiceGroupings.some((companyServiceGrouping) => {
        return companyServiceGrouping.companyServiceCode === '' || companyServiceGrouping.reference === '';
      })
    };
    return false;
  };

  const handleCompanyServiceCodeChange = (
    event: React.FormEvent<HTMLDivElement> | React.SyntheticEvent<Element, Event>,
    fieldToRender: ControllerRenderProps<CreateGroupingType, CompanyServiceGroupingField>
  ) => {
    fieldToRender.onChange(event);

    if ((event.target as HTMLInputElement).value && companyServiceGroupings?.length) {

      const companyServiceCodes = companyServiceGroupings
        .map(
          csg => csg.companyServiceCode)
        .filter(
          (cs): cs is string => !!cs
        );

      const uniqueCompanyServiceCodes = companyServiceCodes.filter(
        (code, index) => companyServiceCodes.indexOf(code) === index
      );

      if (uniqueCompanyServiceCodes.length) {
        setSelectedCompanyCodes(uniqueCompanyServiceCodes);
      }
    }
  };


  const onSubmit = async (projectForm: CreateGroupingType) => {
    setLoading(true);
    try {
      await createGrouping(projectForm);
      setOpen('success');
    } catch (error) {
      setOpen('error');
      setErrorMessage((error as Error).message);
      /*       const { isGeneral, errorOptions } =
              getErrorsFromBackend<CreateGroupingType>(error); */

      /*       if (isGeneral) {
              setOpen('error');
              setError(...errorOptions);
            } else
              errorOptions.forEach((error) => {
                setError(...error);
              }); */
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper>
      {!loading || !loadingCompanies || !loadingProjects ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="center" alignItems='center'>
            <Grid item xs={4}>
              <Controller
                name="projectName"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value
                      }
                      required
                      onChange={field.onChange}
                      label="Proyecto"
                      fullWidth
                      enableFilter
                    >
                      {projectsFetch ?
                        projectsFetch
                          .sort((proj1, proj2) => (proj1.name > proj2.name) ? 1 : -1)
                          .map(({ id, name }) => (
                            <MenuItem
                              key={name}
                              label={`${name}`}
                              value={`${name}`}
                            >
                              {name}
                            </MenuItem>
                          )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Inmueble"
                fullWidth
                required
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="area"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Área"
                      fullWidth
                      required
                      enableFilter
                    >
                      {AREAS
                        .sort((area1, area2) => (area1.name > area2.name) ? 1 : -1)
                        .map(({ value, name }) => (
                          <MenuItem
                            key={value}
                            label={`${name}`}
                            value={`${value}`}
                          >
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={1} >
              <IconButton
                type='button'
                positionEdge="start"
                edge={5}
                variant="contained"
                onClick={() => append({ reference: "", companyServiceCode: "" })}
                info="Añadir servicios"
              >
                <IoAdd />
              </IconButton>
            </Grid>
          </Grid>

          {fields.map((field, index) => (
            <Grid container spacing={3} justify="flex-start" key={field.id} alignItems='center'>
              <Grid item xs={5}>
                <Controller
                  name={`companyServiceGroupings.${index}.companyServiceCode`}
                  control={control}
                  render={({ field: fieldToRender }) => {
                    return (
                      <Select
                        value={fieldToRender.value
                        }
                        onChange={(event) => handleCompanyServiceCodeChange(event, fieldToRender)}
                        label="Compañía"
                        fullWidth
                        required
                        enableFilter
                      >
                        {companiesFetch ?
                          companiesFetch
                            .filter(company =>
                              !selectedCompanyCodes.includes(company.code) || company.code === fieldToRender.value
                            )
                            .sort((comp1, comp2) => (comp1.name > comp2.name) ? 1 : -1)
                            .map(({ id, name, code }) => (
                              <MenuItem
                                key={code}
                                label={`${name}`}
                                value={`${code}`}
                              >
                                {name}
                              </MenuItem>
                            )) : <></>}
                      </Select>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Referencia"
                  fullWidth
                  required
                  {...register(`companyServiceGroupings.${index}.reference`)}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  info="Eliminar servicio"
                  positionEdge="start"
                  edge={5}
                  color="error"
                  onClick={() => remove(index)}
                >
                  <AiOutlineDelete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/servicios-publicos/inmueble"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled()}
              >
                Crear
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={3000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se creo el inmueble con exito'}
            {open === 'error' && (errorMessage ? httpErrorMessageFormatter(errorMessage) : 'Ocurrió un error, intenta nuevamente')}
          </SnackBar>
        </form>
      ) : (
        <CreateGroupingTemplateLoading />
      )
      }
    </Paper >
  );
};

export default CreateGroupingTemplate;
