import { Container, Grid, Typography } from 'components/atoms';
import { useState } from 'react';
import { MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { Section } from './Accordion.const';
import { StyledCollapse, StyledDivider, StyledDividerContainer, StyledIconGridContainer } from './Accordion.styles';


type AccordionProps = {
    sections: Section[];
};

const Accordion = ({ sections }: AccordionProps) => {
    const [openSections, setOpenSections] = useState<boolean[]>(sections.map(section => section.defaultOpen || false));

    const toggleSection = (index: number) => {
        const newOpenSections = [...openSections];
        newOpenSections[index] = !newOpenSections[index];
        setOpenSections(newOpenSections);
    };

    return (
        <Container>
            {sections.map((section, index) => (
                <Container key={index}>
                    <StyledDividerContainer onClick={() => toggleSection(index)}>
                        <Grid>
                            <Typography wrap={false} variant='h2' >{section.header}</Typography>
                        </Grid>
                        <StyledDivider />
                        <StyledIconGridContainer>
                            {openSections[index] ? <MdOutlineKeyboardArrowUp
                                style={{ transition: 'transform 0.3s', transform: 'rotate(0deg)' }}
                            /> : <MdOutlineKeyboardArrowUp
                                style={{ transition: 'transform 0.3s', transform: 'rotate(180deg)' }}
                            />}
                        </StyledIconGridContainer>
                    </StyledDividerContainer>
                    <StyledCollapse open={openSections[index]}>
                        {section.content}
                    </StyledCollapse>
                </Container>
            ))}
        </Container>
    );
};

export default Accordion;