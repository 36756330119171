import axios from 'axios';
import { history } from 'router';
import { Auth, Customer } from 'types';
import { loadState, removeAll } from 'utils';

axios.interceptors.request.use(
  (config) => {
    const auth = loadState<Auth>('auth');
    const customer = loadState<Customer>('customer');
    const accessToken = auth?.token.accessToken || false;

    config.headers = { ...config.headers };

    if (customer?.apiBackend && !config.baseURL)
      config.baseURL = customer?.apiBackend;

    if (!config.headers.Authorization && !config.auth)
      Object.assign(config.headers, {
        Authorization: accessToken
          ? `Bearer ${accessToken}`
          : `Basic ${process.env.REACT_APP_BASIC_AUTH}`,
        WithCredentials: true,
      });

    if (config.data instanceof FormData)
      Object.assign(config.headers, {
        'Content-Type': 'multipart/form-data',
      });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const error = err.response.data;
    const accessToken = loadState<Auth>('auth')?.token.accessToken;

    if (error.statusCode === 401 && accessToken) {
      removeAll(['theme', 'menu']);
      history.replace('/login');
    }

    return Promise.reject(error);
  },
);
