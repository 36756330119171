import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { ListGroupingTemplate } from '../../../../components/templates/PublicServicesTemplate';

const breadcrumbs = [{ label: 'Servicios públicos' }];

const ListGroupingPage = () => {
  return (
    <>
      <Header
        title="Inmuebles"
        subtitle="Lista y edita inmuebles"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListGroupingTemplate />
    </>


  )
}

export default withAuth(ListGroupingPage);