import { Typography } from 'components/atoms';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-size: 48px !important;
`;

export const Intro = styled(Typography)`
  font-size: 26px !important;
`;
