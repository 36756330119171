import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import io, { Socket } from 'socket.io-client';

import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  SnackBar,
  Typography,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { DELETE_USER, GET_ALL_AM, GET_ALL_PROCESS } from 'const';
import { useDialog, useGetTable, useTheme } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';
import { AMProcess, AutomationManager, PaginationType, Process, SocketAMProcess } from 'types';
import { createFilterStructure } from 'utils';
import {
  calcularDiferenciaEnFormatoHHMMSS,
  defaultListBotTemplateFilter,
  defaultPagination,
  defaultSortModel,
  getColumns,
} from './ListAMBotTemplate.const';
import {
  ListBotTemplateFilterSchema,
  ListBotTemplateFilterType,
} from './ListAMBotTemplate.schema';
import { ListAMBotTemplateProps } from './ListAMBotTemplate.types';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import { useParams } from 'react-router-dom';
import { GridContainer } from 'layouts/PrivateLayout/PrivateLayout.styles';
import { defaultTheme } from 'theme';
import theme from 'theme/theme';
const automationManagerSockets = new Map<string, Socket>();
const socketAMProcessMap = new Map<string, SocketAMProcess[]>();
const dataMapByProcess = new Map<string, SocketAMProcess>();
let updateRowsBySocket = () => { };

const ListAMBotTemplate = ({ ...props }: ListAMBotTemplateProps) => {
  const { id } = useParams();

  moment.locale('es');
  const [loadingForm, setLoadingForm] = useState(false);
  const [dataRows, setDataRows] = useState<SocketAMProcess[]>([]);

  let processData: SocketAMProcess[] = [];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ListBotTemplateFilterType>({
    defaultValues: defaultListBotTemplateFilter,
    resolver: yupResolver(ListBotTemplateFilterSchema),
  });

  const [selectedProcess, onOpen, onCloseModal] = useDialog<SocketAMProcess | undefined>(
    undefined,
  );

  const { theme, toggleTheme } = useTheme();

  const [action, setAction] = useState<'stop' | 'edit' | 'history' | 'start' | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();

  const confirmProcess = useCallback(
    (_process: SocketAMProcess, action: 'start' | 'stop') => {
      if (_process !== undefined) {
        onOpen(_process);
        setAction(action);
      }
    },
    [onOpen],
  );

  const openHistory = useCallback(
    (_process: SocketAMProcess) => {
      if (_process !== undefined) {
        onOpen(_process);
        setAction('history');
      }
    },
    [onOpen],
  );

  const executeProcess = useCallback(
    async (_process?: SocketAMProcess) => {
      if (_process) {
        onClose();
        await axios.get(`${_process.amApiUrl}/execute`);
      }
    },
    [onOpen],
  );

  const stopProcess = useCallback(
    async (_process?: SocketAMProcess) => {
      if (_process) {
        onClose();
        await axios.get(`${_process.amApiUrl}/stop`);
      }
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setAction(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const columns = useMemo(
    () => getColumns(confirmProcess, openHistory),
    [confirmProcess, openHistory],
  );

  const [filterModel, setFilterModel] = useState<filterModelType<SocketAMProcess>[]>([]);

  const fetchProcess = async (
    { filterModel, sortModel, paginationModel }: tableModelType<SocketAMProcess>,
    config?: AxiosRequestConfig<Process>,
  ) => {
    console.log('fetchProcess', processData)
    return new Promise<AxiosResponse<PaginationType<SocketAMProcess>>>(async resolve => {
      const data = await axios.get<PaginationType<AutomationManager>>(
        `${GET_ALL_AM}?page=0&limit=10000`,
        config,
      )
      for (const amObject of data.data.result) {
        if (id && id !== amObject.id.toString()) {
          continue;
        }
        try {
          const processdataResponse = await axios.get<{ [clave: string]: AMProcess }>(
            `${amObject.url}/process`,
            config,
          )
          if (processdataResponse.status === 200) {
            for (const key in processdataResponse.data) {
              const processObject = processdataResponse.data[key]
              const amApiUrl = amObject.url + "/process/" + key
              const processFind = processData.find(_process => _process.amApiUrl === amApiUrl)
              if (!processFind) {
                processData.push({
                  id: processData.length,
                  processName: key,
                  labels: processObject.tags,
                  addedDate: undefined,
                  startDate: undefined,
                  endDate: undefined,
                  status: {
                    name: '',
                    color: ''
                  },
                  error: '',
                  amApiUrl: amApiUrl,
                  amId: amObject.id
                })
              }
            }
          }
        } catch (error) {

        }

      }
      sortPaginateRows({ filterModel, sortModel, paginationModel });
      const responseData: AxiosResponse<PaginationType<SocketAMProcess>, any> = {
        status: data.status, statusText: data.statusText, headers: data.headers, config: data.config,
        data: {
          page: 0,
          limit: data.data.limit,
          rowsCount: processData.length,
          result: [...processData]
        }
      }
      resolve(responseData);
    })

    /*?${filterModel && filterModel.length > 0
          ? `filter=${JSON.stringify(filterModel)}&`
          : ''
        }${sortModel ? `sort=${JSON.stringify(sortModel)}&` : ''}${paginationModel
          ? `limit=${paginationModel.rowsPerPage}&page=${paginationModel.page}`
          : ''
        }
    const results: PaginationType<Process> = {
      page: 0,
      limit: 100,
      rowsCount: 2,
      result: data.data.result,
    }
    return results;
    */
  };
  const updateSocketProcessData = () => {
    for (let entry of Array.from(socketAMProcessMap.entries())) {
      let amApiUrl = entry[0];
      let data = entry[1];
      processData.forEach((dataRow: SocketAMProcess) => {
        if (dataRow.amApiUrl && dataRow.amApiUrl.indexOf('/process/') !== -1) {
          const amApiUrlSplit = dataRow.amApiUrl.split('/process/');
          const amUrl = amApiUrlSplit[0]
          const processName = amApiUrlSplit[1]
          if (amUrl === amApiUrl) {
            const socketAMProcess: SocketAMProcess | undefined = getLastStatusSocketAMProcess(data, processName);
            if (socketAMProcess) {
              dataRow.status = socketAMProcess.status;
              const startDate = socketAMProcess.startDate ? socketAMProcess.startDate : socketAMProcess.addedDate;
              dataRow.lastExecution = moment(startDate).format('D [de] MMM hh:mm:ss a');
              dataRow.startDate = socketAMProcess.startDate;
              dataRow.endDate = socketAMProcess.endDate;
              dataRow.addedDate = socketAMProcess.addedDate;
            }
          }
        }
      });
    }
  }
  const sortPaginateRows = ({ filterModel, sortModel, paginationModel }: tableModelType<SocketAMProcess>) => {
    updateSocketProcessData();
    let processes: SocketAMProcess[] = [...processData];
    console.log('sortPaginate', processes)
    if (filterModel?.length && filterModel[0].value !== "") {
      const filterText = filterModel[0].value.toLowerCase();
      processes = processData.filter(data => data.processName.toLowerCase().includes(filterText) || data.labels.find(label => label.toLowerCase().includes(filterText)) || data.status?.name.toLowerCase().includes(filterText) || data.lastExecution?.toLocaleLowerCase().includes(filterText))
    }
    if (sortModel) {
      const { field, order } = sortModel;
      processes = processes.sort((a, b) => {
        let firstItem = b;
        let secondItem = a;
        if (order === 'DESC') {
          secondItem = b;
          firstItem = a;
        }
        let compare = firstItem.id.toString().localeCompare(secondItem.id.toString())
        switch (field) {
          case 'name':
            compare = firstItem.processName.localeCompare(secondItem.processName)
            break;
          case 'duration':
            let durationFirstItem = firstItem.startDate && firstItem.endDate ? moment(firstItem.endDate).diff(moment(firstItem.startDate), 'seconds') : 0;
            let durationSecondItem = secondItem.startDate && secondItem.endDate ? moment(secondItem.endDate).diff(moment(secondItem.startDate), 'seconds') : 0;

            compare = durationFirstItem > durationSecondItem ? 1 : -1
            break;
          case 'status':
            compare = (firstItem.status?.name || '').localeCompare(secondItem.status?.name || '')
            break;
          case 'lastExecution':
            let startDateFirstItem = firstItem.startDate ? firstItem.startDate : firstItem.addedDate;
            let startDateSecondItem = secondItem.startDate ? secondItem.startDate : secondItem.addedDate;

            compare = moment(startDateFirstItem).isAfter(moment(startDateSecondItem)) ? 1 : -1;
            break;

        }
        return compare;
      })
    }
    let { rowsPerPage, page } = paginationModel;
    const processesLength = processes.length;
    if (processesLength < rowsPerPage || page > processesLength / rowsPerPage) {
      page = 0;
      paginationModel.page = 0;
    }
    let maxRows = (page + 1) * rowsPerPage;
    paginationModel.rowsCount = processesLength;
    if (maxRows > paginationModel.rowsCount) {
      maxRows = paginationModel.rowsCount
    }
    processes = processes.slice((page) * rowsPerPage, maxRows);
    setDataRows(processes);
  }

  const {
    loading,
    error,
    data: process,
    handleData,
    getData,
  } = useGetTable<PaginationType<SocketAMProcess>, tableModelType<SocketAMProcess>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });


  const tableControllers = useTable<SocketAMProcess>(
    {
      filterModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<SocketAMProcess>, 'paginationModel'>,
    ) => Promise<PaginationType<SocketAMProcess>>,
  );



  const editUser = async (idUser: number) => {

  };

  const onSubmit = useCallback(
    (filterListBotTemplate: ListBotTemplateFilterType) => {
      const filterUsername = createFilterStructure(
        'username',
        'contains',
        filterListBotTemplate.name,
      );

      const modelFilter = [filterUsername].filter((item) => item);
      setFilterModel(modelFilter as filterModelType<SocketAMProcess>[]);
    },
    [],
  );

  updateRowsBySocket = () => {
    for (let entry of Array.from(socketAMProcessMap.entries())) {
      let amApiUrl = entry[0];
      let data = entry[1];
      dataRows.forEach((dataRow: SocketAMProcess) => {
        if (dataRow.amApiUrl && dataRow.amApiUrl.indexOf('/process/') !== -1) {
          const amApiUrlSplit = dataRow.amApiUrl.split('/process/');
          const amUrl = amApiUrlSplit[0]
          const processName = amApiUrlSplit[1]
          if (amUrl === amApiUrl) {
            const socketAMProcess: SocketAMProcess | undefined = getLastStatusSocketAMProcess(data, processName);
            if (socketAMProcess) {
              dataRow.status = socketAMProcess.status;
              const startDate = socketAMProcess.startDate ? socketAMProcess.startDate : socketAMProcess.addedDate;
              dataRow.lastExecution = moment(startDate).format('D [de] MMM hh:mm:ss a');
              dataRow.startDate = socketAMProcess.startDate;
              dataRow.endDate = socketAMProcess.endDate;
              dataRow.addedDate = socketAMProcess.addedDate;
              handleRowChange(dataRow);
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    updateRowsBySocket();
  }, [process]);


  const getLastStatusSocketAMProcess = (data: SocketAMProcess[], processName: string) => {
    return data.filter((socketAmProcess: SocketAMProcess) => socketAmProcess.processName === processName).pop();
  }

  const getIndexFromId = (id: number): number => dataRows ? dataRows.findIndex(r => r.id === id) : -1;


  const handleRowChange = (newRow: SocketAMProcess) => {
    if (dataRows) {
      const index = getIndexFromId(newRow.id);
      if (index !== -1) {
        setDataRows([...dataRows.slice(0, index), newRow, ...dataRows.slice(index + 1)])
      }
    }
  }



  useEffect(() => {
    dataRows.forEach((row) => {
      if (row.amApiUrl && row.amApiUrl.indexOf('/process/') !== -1) {
        const amUrlSplit = row.amApiUrl.split('/process/')
        const amUrl = amUrlSplit[0];
        if (!automationManagerSockets.has(amUrl)) {
          const url = new URL(amUrl);
          const socket = io(url.protocol + "//" + url.hostname + (url.port ? ':' + url.port : ''), { transports: ['websocket', 'polling', 'flashsocket'] });
          socketAMProcessMap.set(amUrl, []);
          socket.on('processes', (data: SocketAMProcess[]) => {
            socketAMProcessMap.set(amUrl, data);
            updateRowsBySocket();
          });
          socket.on('error', (errorSocket) => {
            console.log('on error socket', errorSocket);
          });
          socket.on('connect', () => {
            updateRowsBySocket();
            console.log('on connect socket', socket.id);
          });
          socket.on('disconnect', (event) => {
            updateRowsBySocket();
            console.log('on disconnect socket', event);
          });
          automationManagerSockets.set(amUrl, socket);
        }
      }
    })

  }, [dataRows]);

  return (
    <>
      <Table<SocketAMProcess>
        {...tableControllers}
        numberOfVisibleColumns={4}
        title={`Encontramos ${tableControllers.paginationModel.rowsCount} bots`}
        columns={columns}
        rows={dataRows}
        error={!!error}
        loading={loading}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <Grid item>

            </Grid>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={4}
                  wrap="nowrap"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      placeholder="Busca por nombre"
                      {...register('name')}
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      isSmall
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
      <Dialog
        open={selectedProcess !== undefined}
        onClose={onClose}
        maxWidth={action === 'history' ? 'lg' : 'sm'}
        title={action === 'history' ? `Historial - ${selectedProcess?.processName}` : 'Confirma esta acción'}
      >
        <Grid container spacing={5} justify="flex-end">
          <Grid item xs={12}>
            {action === 'stop' && (
              <Typography variant="h2">
                {`¿Estas seguro de detener la ejecución de ${selectedProcess?.processName}?`}
              </Typography>
            )}
            {action === 'start' && (
              <Typography variant="h2">
                {`¿Estas seguro de iniciar la ejecución de ${selectedProcess?.processName}?`}
              </Typography>
            )}
            {action === 'edit' && (
              <Typography variant="h2">
                {`¿Estas seguro de editar el estado del usuario ${selectedProcess?.processName}?`}
              </Typography>
            )}
            {action === 'history' && (
              <>
                <Grid container style={{ textAlign: 'center', marginBottom: '10px' }} >
                  <Grid item xs={2}>
                    <h2>Agregado</h2>
                  </Grid>
                  <Grid item xs={2}>
                    <h2>Ejecución</h2>
                  </Grid>
                  <Grid item xs={2}>
                    <h2>Duración (hh:mm:ss)</h2>
                  </Grid>
                  <Grid item xs={2}>
                    <h2>Estado</h2>
                  </Grid>
                  <Grid item xs={4}>
                    <h2> Detalle</h2>
                  </Grid>
                </Grid>
                <Grid spacing={7} container style={{ maxHeight: '50vh', overflow: 'auto', textAlign: 'center' }} >
                  {
                    socketAMProcessMap.get(selectedProcess?.amApiUrl?.split('/process/')[0] || '')?.filter(_process => _process.processName === selectedProcess?.processName).sort((a, b) => moment(a.addedDate).isBefore(moment(b.addedDate)) ? 1 : -1).map((_process, index) => (
                      <Grid container style={{ background: index % 2 ? theme.palette.background : '' }}>
                        <Grid item xs={2}>
                          {_process.addedDate && moment(_process.addedDate).format('D [de] MMM hh:mm:ss a')}
                        </Grid>
                        <Grid item xs={2}>
                          {_process.startDate && moment(_process.startDate).format('D [de] MMM hh:mm:ss a')}
                        </Grid>
                        <Grid item xs={2}>
                          {_process.endDate && _process.startDate && calcularDiferenciaEnFormatoHHMMSS(_process.startDate, _process.endDate)}
                        </Grid>
                        <Grid item xs={2}>
                          {_process.status ? <strong style={{ color: _process.status.color }}> {_process.status.name}</strong> : ''}
                        </Grid>
                        <Grid className='rdrBreakWord' item xs={4}>
                          {_process.error}
                        </Grid>
                      </Grid>
                    ))
                  }
                </Grid>
              </>
            )}
          </Grid>
          <Grid item>
            <Button variant="contained" color="grey" onClick={onClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            {(action === 'stop' || action === 'start') && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  action === 'stop' ?
                    stopProcess(selectedProcess) : executeProcess(selectedProcess)
                }
                loading={loadingForm}
              >
                Sí, deseo {action === 'stop' ? 'detenerlo' : 'iniciarlo'}
              </Button>
            )}

            {action === 'edit' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  editUser(selectedProcess?.id ? selectedProcess?.id : 0)
                }
                loading={loadingForm}
              >
                Si, deseo modificarlo
              </Button>
            )}
          </Grid>
        </Grid>
      </Dialog >
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' &&
          action === 'edit' &&
          'Modificamos el estado del usuario exitosamente'}
        {open === 'success' &&
          action === 'stop' &&
          'Eliminamos el usuarios exitosamente'}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default ListAMBotTemplate;
