import { boolean, number, object, string, TypeOf } from 'yup';

export const CreateEditServiceSchema = object({
  idServer: number().required(),
  server: string().required().max(255),
  name: string().required().max(255),
  url: string().optional().nullable().max(255),
  isHttps: boolean().required(),
  port: string()
    .optional()
    .nullable()
    .test(
      'is-valid-port',
      'El puerto debe ser mayor a 0',
      (value: string | null | undefined) => {
        if (value && parseInt(value) <= 0) {
          return false;
        }
        return true;
      },
    ),
  maxRetry: number().required().moreThan(0),
  timeout: number().required().moreThan(0),
  disabled: boolean().required(),
});

export type CreateEditServiceType = TypeOf<typeof CreateEditServiceSchema>;
