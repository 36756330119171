import styled from 'styled-components';
import { darken, lighten } from 'utils';

const Root = styled.span<{ $height: string; $width?: string }>`
  display: inline-block;
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  position: relative;
  overflow: hidden;

  background-color: ${({ theme }) =>
    theme.isDarkMode
      ? lighten(theme.palette.background, 0.05)
      : darken(theme.palette.background, 0.05)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    transform: translateX(-100%);
    background-image: ${({ theme }) => `linear-gradient(
      90deg,
      ${
        theme.isDarkMode
          ? lighten(theme.palette.background, 0.05)
          : darken(theme.palette.background, 0.05)
      } 0,
      ${
        theme.isDarkMode
          ? lighten(theme.palette.background, 0.1)
          : darken(theme.palette.background, 0.1)
      } 20%,
      ${
        theme.isDarkMode
          ? lighten(theme.palette.background, 0.15)
          : darken(theme.palette.background, 0.15)
      } 60%,
      ${
        theme.isDarkMode
          ? lighten(theme.palette.background, 0.2)
          : darken(theme.palette.background, 0.2)
      }
    )`};
    animation: shimmer 2s infinite;
    content: '';
  }
`;

export default Root;
