import styled from "styled-components";

export const CenteredSpinner = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 25vh; /*TODO: pass it as argument?, maybe with the xs, m ... etc */
`;

export const StyledSpinner = styled.svg`
animation: rotate 2s linear infinite;
margin: -25px 0 0 -25px;
width: 50px;
height: 50px;

& .path {
  stroke: #5652BF;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
`;