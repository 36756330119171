import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { DOWNLOAD_REPORT, GET_PROJECTS, UPDATE_PROJECT_STATUS } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineCheck, AiOutlineEdit } from 'react-icons/ai';
import { PaginationType, Project } from 'types';
import { createPayload } from 'utils';
import { ListProjectTemplateFilterType } from './ListProjectTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Project> = {
  field: 'name',
  order: 'ASC',
};

export const defaultListProjectTemplateFilter: ListProjectTemplateFilterType = {
  name: undefined,
};

export const getColumns: (
  onEdit: (id: number) => void,
  canEdit: () => boolean,
  // costCenter: 1 | 0,
  // constructionSite: 1 | 0,
) => columnType<Project>[] = (onEdit, canEdit) => {

  const columns: columnType<Project>[] = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: '0.7',
      exportField: true,
    },
    // {
    //   field: 'cost',
    //   headerName: 'Centro de costo',
    //   notDisplay: costCenter === 0,
    //   flex: '1',
    //   exportField: true,
    //   render: ({ numberconstcenter, descriptionconstcenter }) => (`${numberconstcenter} - ${descriptionconstcenter}`),
    // },
    // {
    //   field: 'construction',
    //   headerName: 'Obra',
    //   flex: '1',
    //   notDisplay: constructionSite === 0,
    //   exportField: true,
    //   render: ({ numberconstructionsite, descriptionconstructionsite }) => (`${numberconstructionsite} - ${descriptionconstructionsite}`),
    // },
    {
      field: 'state',
      headerName: 'Departamento',
      flex: '1',
      exportField: true,
    },
    {
      field: 'city',
      headerName: 'Ciudad',
      flex: '1',
      exportField: true,
    },
    {
      field: 'address',
      headerName: 'Dirección',
      flex: '1',
      disabledSort: true,
      exportField: true,
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: '1',
      // disabledSort: true,
      exportField: true,
      isStatus: true,
      render: ({ status }) => (status ? 'Activo' : 'Inactivo'),
    }
  ]

  if (/* canEdit() */ true) {
    // If the user has edit permission, add the "actions" column
    columns.push({
      field: 'actions',
      headerName: 'Acciones',
      flex: '1',
      disabledSort: true,
      render: ({ id, status }) => (
        <>
          <IconButton
            info={status ? 'Desactivar proyecto' : 'Activar proyecto'}
            color={status ? 'success' : 'grey'}
            onClick={() => onEdit(id)}
          >
            <AiOutlineCheck />
          </IconButton>
          <IconButton
            info="Editar proyecto"
            positionEdge="start"
            color="grey"
            href={`/pages/servicios-publicos/management/project/${id}/edit`}
          >
            <AiOutlineEdit />
          </IconButton>
        </>
      ),
    });
  }
  return columns;
};


export const fetchProcess = (
  pagination: tableModelType<Project>,
  config?: AxiosRequestConfig<Project>,
) => {

  return axios.get<PaginationType<Project>>(
    `${GET_PROJECTS}${createPayload(pagination)}`,
    config,
  );
}

export const updateStatus = (
  id: number
) => {

  return axios.patch(
    UPDATE_PROJECT_STATUS.replace(':id', id.toString()),
  );
}

export const downloadExcelReport = async () => {
  return await axios.get(
    DOWNLOAD_REPORT,
    {
      responseType: "blob",
    }
  );
};