export const fillStartWithZero = (numberOfZeros: number, text: string) => {
  return numberOfZeros === text.length
    ? text
    : `${new Array(numberOfZeros)
        .fill('0')
        .splice(numberOfZeros - text.length - 1, text.length)
        .join()}${text}`;
};

export const messageSplitter = (
  message: string,
  wordsPerLine: number,
  maxLines: number,
) => {
  const words = message.split(' ');
  const messageList = words.reduce(
    (result: string[], word: string, index: number) => {
      if (result.length >= maxLines + 1) return result; // stop processing words after reaching the maximum number of lines
      if (index % wordsPerLine === 0) result.push(''); // start a new string every 7 words
      result[result.length - 1] += (index % wordsPerLine ? ' ' : '') + word; // add the word to the last string in the result
      return result;
    },
    [],
  );

  if (messageList.length >= maxLines + 1) {
    messageList[maxLines] = '...'; // replace the last element with '...'
  }

  return messageList;
};