import { DatePicker, Input } from 'components/atoms';

export const errorsMap = {
  number: 'Este campo debe ser numerico',
  string: 'Este campo debe ser una cadena de texto',
  date: 'Este campo debe ser una fecha',
};

export const regExp = {
  number: new RegExp('^[0-9]*$'),
  string: undefined,
  date: undefined,
};

export const inputComponent = {
  number: Input,
  string: Input,
  date: DatePicker,
};
