import { HomeTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

// const breadcrumbs = [
//   {
//     label: "Inicio",
//     href: "/pages/home",
//   },
// ];

const HomePage = () => {
  return (
    <>
      {/* <Header
        // title="Bienvenido"
        // breadCrummbsProps={{ breadcrumbs: breadcrumbs }}
      /> */}
      <HomeTemplate />
    </>
  );
};

export default withAuth(HomePage);
