import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { HistoryPendingTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const HistoryPendingPage = () => {
  return (
    <>
      <Header
        title="Pagos pendientes"
        subtitle="Lista y descarga registros de pagos pendientes"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <HistoryPendingTemplate />
    </>
  );
}

export default withAuth(HistoryPendingPage);