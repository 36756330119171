import { Header } from 'components/molecules';
import { CreateTaskTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Task' }];

const CreateTaskPage = () => {
  return (
    <>
      <Header
        title="Task"
        subtitle="Crea un task"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <CreateTaskTemplate />
    </>
  );
};

export default withAuth(CreateTaskPage);
