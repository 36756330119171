/* eslint-disable react-hooks/rules-of-hooks */
import { Collapse } from 'components/atoms';
import { useState } from 'react';
import type { LinkProps } from 'react-router-dom';
import { useMatch, useResolvedPath } from 'react-router-dom';
import { ExpandIcon, FatherLink, Link } from './CustomLink.styles';

export type CustomLinkProps = {
  children?: React.ReactNode;
  label: string;
  icon?: string;
  badge?: string;
  deep: number;
} & LinkProps;

const CustomLink = ({
  to,
  children,
  label,
  deep,
  icon,
  ...props
}: CustomLinkProps) => {
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen((before) => !before);
  };

  if (children)
    return (
      <>
        <FatherLink $deep={deep} $open={open} onClick={toggleCollapse}>
          {label}
          <ExpandIcon size={20} />
        </FatherLink>
        <Collapse open={open}>{children}</Collapse>
      </>
    );
  else {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
      <Link $deep={deep} $active={!!match} to={to} {...props}>
        {label}
      </Link>
    );
  }
};

export default CustomLink;
