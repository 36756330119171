import styled from 'styled-components';

export const Root = styled.div<{ $hide: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.modal};
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  visibility: ${({ $hide }) => ($hide ? 'hidden' : 'auto')};
`;
