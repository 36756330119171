import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Grid,
  Input,
  SnackBar,
  Typography
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { useDialog, useGetPermissions, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Company, PaginationType } from 'types';
import { createFilterStructure } from 'utils';
import {
  defaultListCompanyTemplateFilter,
  defaultPagination,
  defaultSortModel,
  fetchProcess,
  getColumns,
  updateStatus
} from './ListCompanyTemplate.const';
import ListTemplateApiLoading from './ListCompanyTemplate.loading';
import {
  ListCompanyTemplateFilterSchema,
  ListCompanyTemplateFilterType
} from './ListCompanyTemplate.schema';
import { ListCompanyTemplateProps } from './ListCompanyTemplate.types';

const ListCompanyTemplate = ({ ...props }: ListCompanyTemplateProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ListCompanyTemplateFilterType>({
    defaultValues: defaultListCompanyTemplateFilter,
    resolver: yupResolver(ListCompanyTemplateFilterSchema),
  });

  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [action, setAction] = useState<'remove' | 'edit' | undefined>(
    undefined,
  );
  const [actionSnackBar, setActionSnackBar] = useState<'remove' | 'edit' | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();

  const [isUpdating, setIsUpdating] = useState(false);

  const { canEdit } = useGetPermissions();

  const confirmEdit = useCallback(
    (id: number) => {
      onOpen(id);
      setAction('edit');
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setActionSnackBar(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const columns = useMemo(
    () => getColumns(confirmEdit, canEdit),
    [confirmEdit, canEdit],
  );

  const [filterModel, setFilterModel] = useState<filterModelType<Company>[]>([]);

  const {
    loading,
    error,
    handleData,
    getData,
    data: process,
  } = useGetTable<PaginationType<Company>, tableModelType<Company>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });

  const tableControllers = useTable<Company>(
    {
      filterModel,
      sortModel: defaultSortModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Company>, 'paginationModel'>,
    ) => Promise<PaginationType<Company>>,
  );

  const editCompany = async (idCompany?: number) => {
    setIsUpdating(true);
    if (idCompany) {
      try {
        await updateStatus(idCompany);
        setOpen("success");
        //Sorting to refresh
        tableControllers.onSortModel(defaultSortModel);
      }
      catch (error) {
        setOpen("error");
      } finally {
        setActionSnackBar("edit");
        setIsUpdating(false);
        onClose();
      }
    }
  };

  const onSubmit = useCallback(
    (filterListCompanyTemplate: ListCompanyTemplateFilterType) => {
      const filterCompanyname = createFilterStructure(
        'code',
        'contains',
        filterListCompanyTemplate.code,
      );

      tableControllers.paginationModel.page = 0;

      const modelFilter = [filterCompanyname].filter((item) => item);
      setFilterModel(modelFilter as filterModelType<Company>[]);
    },
    [tableControllers],
  );

  const selectedCompany = useMemo(
    () =>
      selected !== undefined
        ? process?.result.find((company) => company.id === selected)
        : undefined,
    [selected, process],
  );

  return (
    <>
      <Table<Company>
        {...tableControllers}
        numberOfVisibleColumns={4}
/*         title={`Encontramos ${tableControllers.paginationModel.rowsCount} compañías`}
 */        columns={columns}
        rows={process?.result || []}
        error={!!error}
        loading={loading}
        exportOptions={{
          filename: `Compañías`,
        }}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            {/* {canInsert() &&
              <Grid item>
                <IconButton
                  variant="contained"
                  href="/pages/servicios-publicos/management/company/create"
                  info="Crear Compañía"
                >
                  <IoAdd />
                </IconButton>
              </Grid>} */}
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={4}
                  wrap="nowrap"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      placeholder="Busca por nombre"
                      {...register('code')}
                      error={!!errors.code}
                      helperText={errors.code && errors.code.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      isSmall
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title="Confirma esta acción"
      >
        {!isUpdating ?
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={12}>
              {action === 'remove' && (
                <Typography variant="h2">
                  {`¿Estas seguro de eliminar la compañía ${selectedCompany?.name}?`}
                </Typography>
              )}
              {action === 'edit' && (
                <Typography variant="h2">
                  {`¿Estas seguro de editar el estado de la compañía ${selectedCompany?.name}?`}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Button variant="contained" color="grey" onClick={onClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              {action === 'edit' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    editCompany(selectedCompany?.id)
                  }
                >
                  Si, deseo modificarla
                </Button>
              )}
            </Grid>
          </Grid> :
          <ListTemplateApiLoading />}
      </Dialog>
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' &&
          actionSnackBar === 'edit' &&
          'Se editó el estado de la compañía  exitosamente'}
        {open === 'success' &&
          actionSnackBar === 'remove' &&
          'Eliminamos la compañía exitosamente'}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default ListCompanyTemplate;
