import { useForkRef } from 'hooks';
import React, {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { MdPassword } from 'react-icons/md';
import { randomPassword } from 'utils';
// import { Button } from "./InputPassword.styles";
import IconButton from '../IconButton';
import type { InputProps } from '../Input';
import Input from '../Input';
import SnackBar from '../SnackBar';

export declare type InputPasswordProps = {
  defaultVisibility?: boolean;
  enabledGenerate?: boolean;
  disabledSnackBar?: boolean;
  onGenerate?: (newPassword: string) => void;
  textWhenPasswordWasAddedToClipboard?: string;
} & InputProps;

const InputPassword = memo(
  forwardRef<HTMLInputElement, InputPasswordProps>(
    (
      {
        defaultVisibility = false,
        textWhenPasswordWasAddedToClipboard = 'La contraseña fue agregada al portapapeles',
        enabledGenerate,
        disabledSnackBar,
        onGenerate: onGenerateProp,
        ...props
      },
      ref,
    ) => {
      const inputRef = useRef(null);
      const handleRef = useForkRef(ref, inputRef);
      const [show, setShow] = useState<boolean>(defaultVisibility);
      const [copied, setCopied] = useState<boolean>(false);

      const disabled = useMemo(
        () => props.disabled || props.readOnly || false,
        [props.disabled, props.readOnly],
      );

      const handleClick = useCallback(
        (e: React.MouseEvent) => {
          e.preventDefault();
          if (disabled) return;
          setShow((state) => !state);
        },
        [disabled],
      );

      const onGenerate = useCallback(
        (e: React.MouseEvent) => {
          e.preventDefault();
          const password = randomPassword(15);

          const event = (type?: string) => ({
            target: { name: props.name, value: password, type },
          });

          if (!disabledSnackBar) {
            navigator.clipboard.writeText(password);
            setCopied(true);
          }

          if (props.onChange) {
            props.onChange(event() as React.ChangeEvent<HTMLInputElement>);
          }

          if (onGenerateProp) onGenerateProp(password);
        },
        [props, onGenerateProp, disabledSnackBar],
      );

      const newProps = useMemo(
        () => ({
          ...props,
          type: show ? 'text' : 'password',
        }),
        [show, props],
      );

      return (
        <>
          <Input
            {...newProps}
            ref={handleRef}
            endAdornment={
              <>
                {enabledGenerate && (
                  <IconButton
                    // info={{
                    //   position: "bottom end",
                    //   message: "Generar contraseña",
                    // }}
                    onClick={onGenerate}
                    color="grey"
                    tabIndex={-1}
                  >
                    <MdPassword size="1.5em" />
                  </IconButton>
                )}
                <IconButton
                  // info={{
                  //   position: "bottom end",
                  //   message: show ? "Mostrar contraseña" : "Ocultar contraseña",
                  // }}
                  onClick={handleClick}
                  color="grey"
                  tabIndex={-1}
                >
                  {show ? (
                    <AiOutlineEyeInvisible size="1.5em" />
                  ) : (
                    <AiOutlineEye size="1.5em" />
                  )}
                </IconButton>
              </>
            }
          />
          {enabledGenerate && !disabledSnackBar && (
            <SnackBar
              wait={2000}
              open={copied}
              onClose={() => setCopied(false)}
              severity="info"
            >
              {textWhenPasswordWasAddedToClipboard}
            </SnackBar>
          )}
        </>
      );
    },
  ),
);

InputPassword.displayName = 'InputPassword';

export default InputPassword;
