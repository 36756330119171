import { MdClose } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { darken, lighten } from 'utils';
import Typography from '../Typography';

export type PaperProps = {
  $maxWidth: 'sm' | 'md' | 'lg' | 'xl';
};

export const DialogRoot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: visible;
  outline: 0;
  padding: ${({ theme }) => theme.shape.spacing(2)};
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
`;

export const Paper = styled.div<PaperProps>`
  ${({ theme, $maxWidth }) => css`
    width: ${theme.breakpoints.values[$maxWidth]};
    max-width: ${theme.breakpoints.values[$maxWidth]};
  `}
  padding: ${({ theme }) => theme.shape.spacing(5)};
  background-color: ${({ theme }) =>
    theme.isDarkMode
      ? darken(theme.palette.background, 0.2)
      : lighten(theme.palette.background, 0.2)};
  color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode
      ? 'none'
      : 'rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px'};
`;

export const Header = styled.div<{ $margin?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  margin-bottom: ${({ theme, $margin }) => $margin && theme.shape.spacing(4)};
`;

export const Title = styled(Typography)`
  display: flex;
  font-size: 20px !important;
  flex: 1;
`;

export const CloseIcon = styled(MdClose)`
  color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};
  cursor: pointer;
`;
