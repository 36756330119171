import { object, string, TypeOf } from 'yup';

export const ListServerTemplateFilterSchema = object({
  name: string(),
  ip: string(),
  status: string(),
  provider: string(),
});

export type ListServerTemplateFilterType = TypeOf<
  typeof ListServerTemplateFilterSchema
>;
