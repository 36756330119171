import { Root } from './Collapse.styles';

type CollapseProps = {
  children: React.ReactNode;
  open?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Collapse = ({ open, ...props }: CollapseProps) => {
  return <Root $open={open} {...props} />;
};
export default Collapse;
