import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { DATE_FORMAT, SEARCH_TRANSACTIONS_PER_CHECK } from 'const';
import { endOfYear, format, startOfYear } from 'date-fns';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineEdit } from 'react-icons/ai';
import { Transaction } from 'types';
import { createPayload } from 'utils';
import { VerificationTemplateFilterType } from './VerificationTemplate.shema';
import { responseType } from './VerificationTemplate.types';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Transaction> = {
  field: 'id',
  order: 'ASC',
};

export const defaultVerificationTemplateFilter: () => VerificationTemplateFilterType =
  () => {
    return {
      dateRange: {
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
      },
      package: undefined,
      status: undefined,
    };
  };

export const getColumns: (
  IdTransaction: string,
) => columnType<Transaction>[] = (IdTransaction) => [
  {
    field: 'id_transaction',
    headerName: 'Paquete',
    description: 'Nombre del negocio',
    flex: '7',
    exportField: true,
    render: ({ idTransaction }) => idTransaction,
  },
  {
    field: 'created_at',
    headerName: 'Fecha',
    align: 'center',
    flex: '2',
    exportField: true,
    render: ({ createdAt }) => format(new Date(createdAt), DATE_FORMAT),
  },
  {
    field: 'status',
    headerName: 'Acción',
    align: 'center',
    flex: '1',
    disabledSort: true,
    render: ({ id }) => (
      <IconButton
        info="Corregir"
        color="grey"
        href={`/pages/process/${IdTransaction}/fix/${id}`}
      >
        <AiOutlineEdit />
      </IconButton>
    ),
  },
];

export const fetch = <T extends object>(
  pagination: tableModelType<Transaction<T>>,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<responseType<T>>(
    `${SEARCH_TRANSACTIONS_PER_CHECK}${createPayload(pagination)}`,
    config,
  );
};
