import axios, { AxiosRequestConfig } from 'axios';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { COMMON_OPTIONS, DATE_FORMAT_BACK_WITH_OUT_TIME, DATE_FORMAT_WITH_OUT_TIME, DOWNLOAD_INVOICE, DOWNLOAD_INVOICES_COMBINED_PENDING, GET_HISTORY_PENDING_RECORDS, getCurrentTimeToString } from 'const';
import { format, parseISO, startOfMonth } from 'date-fns';
import { filterModelType, tableModelType } from 'hooks/useTable';
import { AiOutlineBarChart, AiOutlineCloudDownload, AiOutlineEye } from 'react-icons/ai';
import { FlatHistory, PaginationType } from 'types';
import { Consumption } from 'types/entities/consumption';
import { createFilterStructure, createPayload, joinObjectWithFormat } from 'utils';
import { RequiredDateSchema } from 'yup/lib/date';
import { AnyObject, TypeOfShape } from 'yup/lib/object';
import { IconButton } from '../../../../atoms';
import { HistoryTemplateFilterType } from './HistoryPendingTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<FlatHistory> = {
  field: 'deadline',
  order: 'DESC',
};

export const DOWNLOADED = [
  { value: 'true', name: 'Descargado' },
  { value: 'false', name: 'Sin descargar' },
  { value: 'all', name: 'Todo' },
];

export type HistoryDownloadFilterModel = {
  invoiceNumber: string | undefined,
  area: string | undefined,
  projects: string | undefined,
  publicServices: string | undefined,
  dateRange: TypeOfShape<{
    startDate: RequiredDateSchema<Date | undefined, AnyObject>;
    endDate: RequiredDateSchema<Date | undefined, AnyObject>;
  }>
}


export const getDefaultHistoryTemplateFilter: () => HistoryTemplateFilterType = () => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);

  return {
    invoiceNumber: '',
    area: '',
    projects: '',
    publicServices: '',
    isDownloaded: DOWNLOADED[1].value,
    dateRange: {
      startDate: startOfMonth(startDate),
      endDate,
    },
  };
}

export type HistoryDownloadAPIFilter = {
  downloaded: boolean;
  area?: string;
  dateRange: {
    startDate: Date | undefined,
    endDate: Date | undefined
  }
}

export const getColumns: (
  onDownload: (id: number, invoiceNumber: string) => Promise<void>,
  ref: React.MutableRefObject<HTMLAnchorElement | null>,
  url: string | undefined,
  name: string | undefined,
  onViewOne: (id: number, invoiceNumber: string) => Promise<void>,
  onViewConsumption: (consumption: Consumption[]) => void,
) => columnType<FlatHistory>[] = (onDownload, ref, url, name, onViewOne, onViewConsumption) => {
  const columns: columnType<FlatHistory>[] = [
    {
      field: 'area',
      headerName: 'Area',
      flex: '0.6',
      disabledSort: true,
      exportField: true,
      isArea: true,
      render: ({ area }) => {
        switch (area) {
          case "UNIDAD":
            return "Unidad"
          case "OBRA":
            return "Obra"
          case "SALA_VENTA":
            return "Sala de venta"
          default:
            return ""
        }
      }
    },
    {
      field: 'projectName',
      headerName: 'Proyecto',
      flex: '0.8',
      disabledSort: true,
      exportField: true,
    },
    {
      field: 'groupingName',
      headerName: 'Inmueble',
      flex: '0.8',
      disabledSort: true,
      exportField: true,
    },
    {
      field: 'code',
      headerName: 'Compañía',
      flex: '0.8',
      disabledSort: true,
      exportField: true,
    },
    {
      field: 'invoiceNumber',
      headerName: 'Factura',
      flex: '0.8',
      exportField: true,
      disabledSort: true,
      isString: true,
      render: ({ invoiceNumber }) => (
        <>
          {invoiceNumber}
        </>
      )
    },
    {
      field: 'deadline',
      headerName: 'Fecha límite',
      flex: '1',
      exportField: true,
      isDate: true,
      render: ({ deadline }) => {
        if (deadline !== null) {
          const datePart = deadline.split('T')[0];
          return format(parseISO(datePart), DATE_FORMAT_WITH_OUT_TIME, COMMON_OPTIONS);
        } else {
          return '';
        }
      },
    },
    {
      field: 'value',
      headerName: 'Valor',
      flex: '0.7',
      exportField: true,
      align: 'flex-end',
      isCurrency: true,
      render: ({ value }) => (
        <>
          {value % 1 === 0 ? Math.floor(value).toLocaleString() : value.toLocaleString()}
        </>
      )
    },
    {
      field: 'actions',
      headerName: 'Acción',
      disabledSort: true,
      flex: '0.6',
      align: 'center',
      render: ({ id, invoiceNumber, consumption }) => (
        <>
          {consumption && consumption.length > 0 &&
            <IconButton
              info="Ver consumos"
              positionEdge="start"
              color="grey"
              onClick={() => onViewConsumption(consumption)}
            >
              <AiOutlineBarChart />
            </IconButton>
          }
          <IconButton
            info="Ver factura"
            positionEdge="start"
            color="grey"
            onClick={() => onViewOne(id, invoiceNumber)}
          >
            <AiOutlineEye />
          </IconButton>
          <a href={url} download={name} className="hidden" ref={ref} />
          <IconButton
            info="Descagar factura"
            positionEdge="start"
            color="grey"
            onClick={() => onDownload(id, invoiceNumber)}
          >
            <AiOutlineCloudDownload />
          </IconButton>
        </>
      ),
    },
  ];

  return columns;
}

export const createFilterModel = (
  { invoiceNumber, area, dateRange, projects, publicServices }: HistoryDownloadFilterModel) => {


  let filterInvoiceNumber;

  if (invoiceNumber?.includes(',')) {
    const invoiceNumbers = invoiceNumber.split(',').map((invoice) => invoice.trim());
    filterInvoiceNumber = createFilterStructure(
      'invoiceNumber',
      'in',
      invoiceNumbers,
    );
  } else {
    filterInvoiceNumber = createFilterStructure(
      'invoiceNumber',
      'contains',
      invoiceNumber,
    );
  }

  const filterArea = createFilterStructure(
    'area',
    'contains',
    area,
  );

  const filterDateRange = createFilterStructure(
    'deadline',
    'between_date',
    joinObjectWithFormat(dateRange, '.', format, [
      DATE_FORMAT_BACK_WITH_OUT_TIME,
    ]),
  );


  let modelFilter = [
    filterInvoiceNumber,
    filterArea,
    filterDateRange,
  ].filter((item) => item);

  if (projects) {
    const filterProject = createFilterStructure(
      'id',
      'in',
      projects?.split(','),
      'project',
    );
    modelFilter = [
      filterProject,
      ...modelFilter
    ]
  }

  if (publicServices) {
    const filterPublicService = createFilterStructure(
      'id',
      'in',
      publicServices?.split(','),
      'companyService',
    );
    modelFilter = [
      filterPublicService,
      ...modelFilter
    ].filter((item) => item);
  }


  return modelFilter;
}

export const getDialogTitle = (action: "remove" | "edit" | "upload" | "download" | "downloadOne" | "consumption" | undefined): string => {
  if (action === "upload") {
    return "Carga el documento";
  }
  if (action === "consumption") {
    return "Información de consumos";
  }
  if (action === "download") {
    return "Descarga de facturas pendientes por pagar";
  } else {
    return "";
  }
};

//If no sort model is sent, the default one is sent
export const fetchProcess = (
  pagination: tableModelType<FlatHistory>,
  config?: AxiosRequestConfig<FlatHistory>,
) => {


  if (!pagination.sortModel) {
    pagination.sortModel = defaultSortModel;
  }

  return axios.get<PaginationType<FlatHistory>>(
    `${GET_HISTORY_PENDING_RECORDS}${createPayload(pagination)}`,
    config,
  );
}

export const getFileNameByInvoiceNumber = (invoiceNumber: string) => {
  return `Factura_pendiente_${invoiceNumber}_${getCurrentTimeToString()}.pdf`;
};

export const downloadPdf = (
  filterModel: filterModelType<FlatHistory>[] | undefined
) => {
  return axios.get(
    `${DOWNLOAD_INVOICES_COMBINED_PENDING}${filterModel && filterModel.length > 0
      ? `?filter=${JSON.stringify(filterModel)}&`
      : ''
    }`,
    {
      responseType: "blob",
    }
  );
};

export const downloadOnePdf = (id: number) => {
  return axios.get(
    DOWNLOAD_INVOICE.replace(':id', id.toString()),
    {
      responseType: "blob",
    }
  );

};