import axios, { AxiosRequestConfig } from 'axios';
import { GET_ROLES, GET_USER } from 'const';
import { Transaction, User } from 'types';

export const fetchRoles = <T extends object[]>(
  data: any,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<T>(GET_ROLES, config);
};

export const fetchUsers = ({ id }: any, config?: AxiosRequestConfig<User>) => {
  return axios.get<User>(`${GET_USER.replace(':id', id)}`, config);
};
