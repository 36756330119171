import styled from 'styled-components';
import { lighten } from 'utils';

export const Root = styled.div`
  background: ${({ theme }) =>
    theme.isDarkMode ? lighten(theme.palette.background, 0.09) : 'transparent'};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: 10px;
  display: flex;
  flex: 1;
`;
