import React, { FC } from 'react';
// import PropTypes from "prop-types";

import { useTheme } from 'hooks';

import Paragraph from './Typography.styles';

import { colorType } from 'types';
import type { variantType } from './Typography.constants';
import { fontVariant, variantMapping } from './Typography.constants';

export type TypographyProps = {
  component?: React.ElementType;
  bold?: boolean;
  color?: colorType | string;
  variant?: variantType;
  align?: 'center' | 'left' | 'right';
  wrap?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const Typography: FC<TypographyProps> = ({
  bold = false,
  color = 'inherit',
  component,
  variant = 'p',
  align = 'left',
  wrap = true,
  style: defaultStyle = {},
  ...props
}: TypographyProps) => {
  const { theme } = useTheme();

  const colorDefinition = {
    black: theme.common.black,
    inherit: 'inherit',
    primary: theme.palette.primary,
    secondary: theme.palette.secondary,
    error: theme.palette.error,
    warning: theme.palette.warning,
    info: theme.palette.info,
    success: theme.palette.success,
    grey: theme.palette.grey,
  };

  let colorApply: string = color;

  if (color in colorDefinition) {
    colorApply = colorDefinition[color as keyof typeof colorDefinition];
  }

  const style = {
    '--color': colorApply,
    ...fontVariant[variant],
    '--bold': bold ? '700' : fontVariant[variant]['--bold'],
    textAlign: align,
    ...defaultStyle,
  };

  const newProps = {
    ...props,
    $wrap: wrap,
    style,
    as: component || variantMapping[variant],
  };

  return <Paragraph {...newProps} />;
};

export default Typography;
