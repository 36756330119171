export const dataToHTMLTable = (rows: any[][]) => {
  let result = '<table>';
  rows.forEach((columns) => {
    result += '<tr>';
    columns.forEach((value) => {
      result += '<td>' + value + '</td>';
    });
    result += '</tr>';
  });
  result += '</table>';
  return result;
};
