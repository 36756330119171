import axios from 'axios';
import {
  Button,
  Grid,
  Input,
  InputPassword,
  MenuItem,
  Paper,
  Select,
  SnackBar,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { CREATE_USER } from 'const';
import { states } from 'const/const';
import { useCustomerContext } from 'contexts';
import { useGetTable, useYupResolve } from 'hooks';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Role, User } from 'types';
import { getErrorsFromBackend } from 'utils';
import { fetchRoles } from './CreateUserTemplate.const';
import CreateUserTemplateLoading from './CreateUserTemplate.loading';
import { CreateUserSchema, CreateUserType } from './CreateUserTemplate.schema';

const CreateUserTemplate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { domain } = useCustomerContext();

  const { data: roles, loading: loadingRoles } = useGetTable<Role[]>(
    fetchRoles,
    [{}],
  );

  const validation = useCallback(
    async (data: any) => {
      const currentSchema = CreateUserSchema(domain);

      const values = await currentSchema.validate(data, {
        abortEarly: false,
      });

      return values;
    },
    [domain],
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<CreateUserType>({
    resolver: useYupResolve(validation),
    defaultValues: {
      enabled: 'true',
      roles: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/user');
  };

  const onSubmit = async (createUserForm: CreateUserType) => {
    try {
      setLoading(true);
      const { roles, ...form } = createUserForm;
      const plainRoles = roles?.split(',').filter((item) => item !== '');
      const enabled = form.enabled === 'true';
      await axios.post<User>(CREATE_USER, {
        ...form,
        enabled,
        roles: plainRoles,
      });
      setLoading(false);
      setOpen('success');
    } catch (error) {
      setLoading(false);
      const { isGeneral, errorOptions } = getErrorsFromBackend<CreateUserType>(
        error,
        {
          username: {
            Unique: 'Este usuario ya existe, pruebe con otro nuevamente',
          },
        },
      );

      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    }
  };
  return (
    <Paper>
      {!loadingRoles ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={6}>
              <Input
                label="Usuario (email)"
                fullWidth
                required
                {...register('username')}
                error={!!errors.username}
                helperText={
                  errors.username
                    ? errors.username.message
                    : `Ingrese su correo empresarial, este termina en @${domain}`
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Estado"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => {
                  return (
                    <InputPassword
                      label="Contraseña"
                      fullWidth
                      required
                      enabledGenerate
                      value={field.value}
                      onChange={field.onChange}
                      onGenerate={(value) =>
                        setValue('passwordConfirmation', value)
                      }
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="passwordConfirmation"
                control={control}
                render={({ field }) => {
                  return (
                    <InputPassword
                      label="Confirma tu contraseña"
                      fullWidth
                      required
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.passwordConfirmation}
                      helperText={
                        errors.passwordConfirmation &&
                        errors.passwordConfirmation.message
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="roles"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Roles"
                      fullWidth
                      required
                      multiple
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.roles}
                      helperText={errors.roles && errors.roles.message}
                    >
                      {roles &&
                        roles.map(({ id, name }) => (
                          <MenuItem key={id} label={`${name}`} value={`${id}`}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/user"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Crear
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se creo el usuario con exito'}
            {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
          </SnackBar>
        </form>
      ) : (
        <CreateUserTemplateLoading />
      )}
    </Paper>
  );
};

export default CreateUserTemplate;
