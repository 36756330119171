import axios, { AxiosRequestConfig } from 'axios';
import { GET_AM, LIST_LOGS } from 'const';
import { AMLog, AutomationManagerDetail } from 'types';

export const fetchAM = (
  { id }: { id: string },
  config?: AxiosRequestConfig<AutomationManagerDetail>,
) => {
  return axios.get<AutomationManagerDetail>(
    `${GET_AM.replace(':id', id)}`,
    config,
  );
};

export const fetchLogs = async (
  amName: string,
  amUrl: string,
  logsPath: string,
  config?: AxiosRequestConfig<AMLog[]>,
) => {
  console.log('logsPath', logsPath);
  return await axios.get<AMLog[]>(
    LIST_LOGS.replace(':amName', encodeURIComponent(amName))
      .replace(':amUrl', encodeURIComponent(amUrl))
      .replace(':logsPath', encodeURIComponent(logsPath)),
    config,
  );
};
