import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Select,
  SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { GET_ALL_CUSTOMERS } from 'const';
import { useGetTable } from 'hooks';
import useGetData from 'hooks/useGetData';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { AiOutlineDelete, AiOutlineQuestion } from 'react-icons/ai';
import { IoAdd } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import { AutomationManagerCustomer, AutomationManagerDetail } from 'types';
import { fetchAM, fetchLogs } from '../Shared/shared.const';
import { defaultAM, defaultData, updateAM } from './EditAMTemplate.const';
import EditAmTemplateLoading from './EditAMTemplate.loading';
import { EditAMSchema, EditAMType } from './EditAMTemplate.schema';

const EditAMTemplate = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loadingData = loading;
  const [open, setOpen] = useState<severityType>();
  const [actionSnackBar, setActionSnackBar] = useState<'pathError' | undefined>(
    undefined,
  );

  const { data, loading: loadingAM } = useGetTable<AutomationManagerDetail, { id: string }>(
    fetchAM,
    { id: id as string },
  );
  const { data: customersFetch, loading: loadingCustomers } = useGetData<AutomationManagerCustomer>(
    GET_ALL_CUSTOMERS
  );

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/automationmanager');
  };


  /**
   * Block of code to handle the form
   */
  const {
    register,
    handleSubmit,
    watch,
    setError,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<EditAMType>({
    resolver: yupResolver(EditAMSchema),
    defaultValues: {
      name: '',
      url: '',
      customer: '',
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'logsPaths',
    control
  });

  const { name, url, logsPaths, customer } = watch();

  const isDisabled = () => {
    if (name === '' || url === '' || customer === '') return true;
    if (logsPaths?.length && logsPaths.some((f) => !f.path || !f.description)) return true;
    return false;
  };

  const onValidate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    if (!name || !url) return;
    fetchLogs(name, url, getValues(`logsPaths.${index}.path`)!).then((res) => {
      setOpen('info');
    }).catch(() => { // Add parentheses here
      setActionSnackBar('pathError');
      setOpen('warning');
    });
    event.preventDefault();
    getValues(`logsPaths.${index}`);
  };



  const onSubmit = async (loginForm: EditAMType) => {
    /*     const customLoginForm = loginForm.logsPaths?.map((logPath) => {
          return {
            id: data?.paths.find(path => path.path === logPath.path)?.id,
            path: logPath.path,
            description: logPath.description,
          };
        }); */
    try {
      setLoading(true);
      await updateAM(id, loginForm);
      setLoading(false);
      setOpen('success');
    } catch (error) {
      setLoading(false);
      setOpen('error');
      /*       setLoading(false);
            const { isGeneral, errorOptions } =
              getErrorsFromBackend<CreateAMType>(error);
      
            if (isGeneral) {
              setError(...errorOptions);
              setOpen('error');
            } else
              errorOptions.forEach((error) => {
                setError(...error);
              }); */
    } finally {
      //navigate('/pages/management/automationmanager');
    }
  };


  useEffect(() => {
    if (data && !loadingAM) {
      setValue('name', data.name);
      setValue('url', data.url);
      setValue('customer', data.customer?.code);
      if (data.paths && data.paths.length) {
        data.paths.forEach(path => {
          append({
            id: path.id,
            path: path.path,
            description: path.description,
          })
        })
      }

    }
  }, [data, setValue]);

  return (
    <Paper>
      {!loadingData ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={8}>
              <Input
                label="Nombre"
                fullWidth
                required
                {...register('name')}
                defaultValue={defaultData.name}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="customer"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Cliente"
                      fullWidth
                    >
                      {customersFetch ?
                        customersFetch
                          .sort((cust1, cust2) => (cust1.code > cust2.code) ? 1 : -1)
                          .map(({ id, code }) => (
                            <MenuItem
                              key={code}
                              label={`${code}`}
                              value={`${code}`}
                            >
                              {code}
                            </MenuItem>
                          )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <Input
                label="URL"
                fullWidth
                required
                defaultValue={defaultData.url}
                info={"Ruta del AM, ejemplo: https://www.micasayamarval.mayasoft.ai/manager"}
                {...register('url')}
                error={!!errors.url}
                helperText={errors.url && errors.url.message}
              />
            </Grid>
            <Grid item xs={1} >
              <IconButton
                type='button'
                positionEdge="start"
                edge={5}
                variant="contained"
                onClick={() => append({ id: "", path: "", description: "" })}
                info="Añadir rutas de archivos"
              >
                <IoAdd />
              </IconButton>
            </Grid>
          </Grid>
          {fields.map((field, index) => (
            <Grid container spacing={3} justify="flex-start" key={field.id} alignItems='center'>
              <Grid item xs={5}>
                <Input
                  label="Ruta de logs"
                  fullWidth
                  required
                  info={"Ruta de directorio que contiene los logs, ejemplo: C:/Users/Administrator/AppData/Local/UiPath/Logs"}
                  defaultValue={defaultAM.logPaths[0].path}
                  {...register(`logsPaths.${index}.path`)}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Descripción"
                  fullWidth
                  required
                  defaultValue={defaultAM.logPaths[0].description}
                  {...register(`logsPaths.${index}.description`)}
                />
              </Grid>
              <Grid item xs={1}>
                <Grid container spacing={3} justify="center" alignItems='center'>
                  <Grid item xs={6}>
                    <IconButton
                      type='button'
                      info="Eliminar ruta"
                      color="error"
                      onClick={() => remove(index)}
                    >
                      <AiOutlineDelete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton
                      type='button'
                      info="Validar ruta"
                      color="info"
                      onClick={(event) => onValidate(event, index)}
                    >
                      <AiOutlineQuestion />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                type='button'
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/automationmanager"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled()}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'warning' && actionSnackBar === 'pathError' && 'Ruta invalida'}
            {open === 'success' && 'Se editó el Automation Manager con exito'}
            {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
            {open === 'info' && 'Ruta validada con exito.'}
          </SnackBar>
        </form>
      ) : (
        <EditAmTemplateLoading />
      )}
    </Paper>
  );
};

export default EditAMTemplate;
