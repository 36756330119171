import { mapErrors } from 'const';

type errorOptions<T extends Record<string, any>> = [
  keyof T,
  { type: string; message: string },
];

export const getErrorsFromBackend = <T extends Record<string, any>>(
  exception: unknown,
  defaultErrors?: Partial<Record<keyof T, Record<string, string>>>,
) => {
  const { data, error } = exception as {
    data?: Record<keyof T, string[]>;
    error?: string;
  };

  const errors =
    defaultErrors || ({} as Partial<Record<keyof T, Record<string, string>>>);

  if (data) {
    const keys = Object.keys(data) as Array<keyof T>;

    return {
      isGeneral: false,
      errorOptions: keys.map((key) => {
        const { constrain = data[key][0], message } =
          mapErrors[data[key][0]] || {};
        const newError: Record<string, string> = errors[key] || {};

        return [
          key,
          {
            type: constrain,
            message: message || newError[constrain] || error,
          },
        ];
      }),
    } as {
      errorOptions: errorOptions<T>[];
      isGeneral: false;
    };
  }

  return {
    errorOptions: ['error', { type: 'custom', message: error }],
    isGeneral: true,
  } as {
    errorOptions: errorOptions<T>;
    isGeneral: true;
  };
};

type selectMultipleValueAsArrayOptions = {
  separator?: string;
  disabledTrim?: boolean;
};

export const selectMultipleValueAsArray = (
  currentValue: string,
  options?: selectMultipleValueAsArrayOptions,
) => {
  const { separator = ',', disabledTrim = false } = options || {};

  let value = currentValue;
  if (!disabledTrim) value = currentValue.trim();

  return value.split(separator);
};
