//TODO: move this file to a new component
import styled from 'styled-components';
import { darken, invertColor } from 'utils';

export const Card = styled.div`
    background-color: ${({ theme }) => darken(theme.palette.grey, 0)};
    min-width: 200px;
    margin: 0px 10px;
    color: ${({ theme }) => invertColor(darken(theme.palette.grey, 0), true)};
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    padding: ${({ theme }) => theme.shape.spacing(4)};
    margin-bottom: ${({ theme }) => theme.shape.spacing(2)};
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${({ theme }) => darken(theme.palette.grey, 0.2)};
    }
};

  
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const CardTitle = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: ${({ theme }) => theme.shape.spacing(2)};
`;
