import { date, object, string, TypeOf } from 'yup';

export const ListExceptionTemplateFilterSchema = object({
  message: string(),
  customer: string(),
  process: string(),
  level: string(),
  extradata: string(),
  isResolved: string(),
  dateRange: object({
    startDate: date().required(),
    endDate: date().required(),
  }).required(),
});

export type ListExceptionTemplateFilterType = TypeOf<
  typeof ListExceptionTemplateFilterSchema
>;
