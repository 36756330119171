import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Grid, InputPassword, Paper, SnackBar } from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { CHANGE_PASSWORD } from 'const';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getErrorsFromBackend } from 'utils';
import {
  ChangePasswordTemplateSchema,
  ChangePasswordTemplateType,
} from './ChangePasswordTemplate.schema';

const ChangePasswordTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm<ChangePasswordTemplateType>({
    resolver: yupResolver(ChangePasswordTemplateSchema),
    defaultValues: {
      newPassword: '',
      passwordConfirmation: '',
      currentPassword: '',
    },
  });

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/home');
  };

  const onSubmit = async (data: ChangePasswordTemplateType) => {
    try {
      setLoading(true);
      const { ...form } = data;

      await axios.put(CHANGE_PASSWORD, {
        ...form,
      });
      setLoading(false);
      setOpen('success');
    } catch (error) {
      setLoading(false);
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<ChangePasswordTemplateType>(error, {
          currentPassword: { NotMatch: 'La contraseña actual no corresponde' },
        });

      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    }
  };

  const password = watch('newPassword');

  return (
    <Paper>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={12}>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field }) => {
                return (
                  <InputPassword
                    label="Contraseña actual"
                    fullWidth
                    required
                    value={field.value}
                    onChange={field.onChange}
                    error={!!errors.currentPassword}
                    helperText={
                      errors.currentPassword && errors.currentPassword.message
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => {
                return (
                  <InputPassword
                    label="Nueva contraseña"
                    fullWidth
                    required
                    enabledGenerate
                    value={field.value}
                    onChange={field.onChange}
                    onGenerate={(value) =>
                      setValue('passwordConfirmation', value)
                    }
                    error={!!errors.newPassword}
                    helperText={
                      errors.newPassword && errors.newPassword.message
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="passwordConfirmation"
              control={control}
              render={({ field }) => {
                return (
                  <InputPassword
                    label="Confirma tu nueva contraseña"
                    fullWidth
                    required={!!password}
                    value={field.value}
                    onChange={field.onChange}
                    error={!!errors.passwordConfirmation}
                    helperText={
                      errors.passwordConfirmation &&
                      errors.passwordConfirmation.message
                    }
                  />
                );
              }}
            />
          </Grid>

          <Grid item>
            <Button
              fullWidth
              variant="text"
              color="grey"
              href="/pages/management/user"
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              loading={loading}
            >
              Cambiar contraseña
            </Button>
          </Grid>
        </Grid>
        <SnackBar
          wait={2000}
          open={open !== undefined}
          onClose={onClose}
          severity={open}
        >
          {open === 'success' && 'Se cambio la contraseña correctamente'}
          {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
        </SnackBar>
      </form>
    </Paper>
  );
};

export default ChangePasswordTemplate;
