import { Grid, Skeleton } from 'components/atoms';

const CreateEditRegexTemplateLoading = () => {
  return (
    <Grid container spacing={3} justify="flex-end">
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
    </Grid>
  );
};

export default CreateEditRegexTemplateLoading;
