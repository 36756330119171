import { useMsal } from '@azure/msal-react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Alert, Button, Grid } from 'components/atoms';
import { loginRequest } from 'config/azure';
import { CUSTOMER, LOGIN_AZURE, LOGIN_GOOGLE } from 'const';
import { useCustomerContext } from 'contexts';
import { useTheme } from 'hooks';
import { useState } from 'react';
import { SiGoogle, SiMicrosoftoutlook } from 'react-icons/si';
import { Auth, Customer, ResponseType } from 'types';

export type LoginProvidersProps = {
  onSuccess: (auth: Auth) => void;
} & React.HTMLAttributes<HTMLDivElement>;

const LoginProviders = ({ onSuccess, ...props }: LoginProvidersProps) => {
  const { theme } = useTheme();
  const { instance } = useMsal();
  const { loadCustomer } = useCustomerContext();
  const [loading, setLoading] = useState<'google' | 'office' | false>(false);
  const [error, setError] = useState<string>();

  const setCostumer = async (domain: string) => {
    try {
      const {
        data: { data: dataCustomer },
      } = await axios.get<ResponseType<Customer>>(
        CUSTOMER.replace(':customer', domain),
      );

      loadCustomer({
        ...dataCustomer,
        apiBackend: `${dataCustomer.apiBackend}/api`,
      });

      if (dataCustomer.domain !== domain)
        setError('Dominio invalido, valida tu correo electrónico');
    } catch (error) {
      setError('Dominio invalido, valida tu correo electrónico');
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      const { data } = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: { Authorization: `Bearer ${access_token}` },
        },
      );

      onSuccessGoogle(access_token, data);
    },
    onError: () => {
      setError('Tuvimos un error, intenta nuevamente');
    },
  });

  const onSuccessGoogle = async (access_token: string, { email }: any) => {
    try {
      setLoading('google');
      const domain = email.split('@').pop();
      await setCostumer(domain);

      const { data } = await axios.post<Auth>(LOGIN_GOOGLE, {
        token: access_token,
      });

      setLoading(false);
      onSuccess(data);
    } catch (error) {
      setLoading(false);
      setError('Tuvimos un error, intenta nuevamente');
    }
  };

  const handleLoginOffice = async () => {
    try {
      setLoading('office');
      const { account, accessToken } = await instance.loginPopup(loginRequest);

      const domain = account?.username.split('@').pop() as string;
      await setCostumer(domain);

      const response = await axios.post<Auth>(LOGIN_AZURE, {
        token: accessToken,
      });

      setLoading(false);
      onSuccess(response.data);
    } catch (error) {
      setLoading(false);
      setError('Tuvimos un error, intenta nuevamente');
    }
  };

  return (
    <Grid container spacing={5} {...props}>
      <Grid item sm={6} xs={12}>
        <Button
          fullWidth
          loading={loading === 'google'}
          variant={theme.isDarkMode ? 'contained' : 'outlined'}
          color={theme.isDarkMode ? 'grey' : 'primary'}
          startAdornment={<SiGoogle />}
          onClick={() => googleLogin()}
        >
          Google
        </Button>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Button
          fullWidth
          loading={loading === 'office'}
          variant={theme.isDarkMode ? 'contained' : 'outlined'}
          color={theme.isDarkMode ? 'grey' : 'primary'}
          startAdornment={<SiMicrosoftoutlook />}
          onClick={handleLoginOffice}
        >
          Office 365
        </Button>
      </Grid>
      {error && (
        <Grid item sm={12} xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default LoginProviders;
