import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { ListProjectTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const ListProjectPage = () => {
  return (
    <>
      <Header
        title="Proyectos"
        subtitle="Crea, edita, lista y elimina proyectos"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListProjectTemplate />
    </>
  );
}

export default withAuth(ListProjectPage);