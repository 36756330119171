import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import moment from 'moment';
import 'moment/locale/es';

import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  SnackBar,
  Typography
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { DialogContent } from 'components/templates/AMTemplate/AMLogsTemplate/AMLogs.Styles';
import { GET_ALL_CUSTOMERS, GET_ALL_PROCESS_PAGINATION, GET_REGEX_EXCEPTIONS } from 'const';
import { useDialog, useGetTable } from 'hooks';
import useGetData from 'hooks/useGetData';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';
import { AutomationManagerCustomer, PaginationType, Process } from 'types';
import { RegexException } from 'types/entities/regex-exception';
import { createPayload } from 'utils';
import RegexDetailTemplate from '../RegexDetailTemplate';
import {
  createFilterModel,
  defaultListRegexTemplateFilter,
  defaultPagination,
  defaultSortModel,
  getColumns,
} from './ListRegexTemplate.const';
import {
  ListRegexTemplateFilterSchema,
  ListRegexTemplateFilterType,
} from './ListRegexTemplate.schema';
import { ListRegexTemplateProps } from './ListRegexTemplate.types';
const ListRegexExceptionsTemplate = ({ ...props }: ListRegexTemplateProps) => {
  const { data: processesFetch } = useGetData<Process>(
    GET_ALL_PROCESS_PAGINATION
  );
  const { data: customersFetch } = useGetData<AutomationManagerCustomer>(
    GET_ALL_CUSTOMERS
  );

  moment.locale('es');

  type actionType = 'viewDetails' | 'delete' | undefined;
  type regexAction = {
    message: string;
    severity: severityType;
  };

  const [selectedRegexExceptionDialog, onOpen, onCloseModal] = useDialog<RegexException | undefined>(undefined);
  const [selectedRegexException, setSelectedRegexException] = useState<RegexException | undefined>(undefined);
  const [action, setAction] = useState<actionType>(undefined);
  const [regexActionMessage, setRegexExceptionActionMessage] = useState<regexAction | undefined>(undefined);
  const [filterModel, setFilterModel] = useState<filterModelType<RegexException>[]>([]);
  const [loadingForm, setLoadingForm] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ListRegexTemplateFilterType>({
    defaultValues: defaultListRegexTemplateFilter,
    resolver: yupResolver(ListRegexTemplateFilterSchema),
  });


  const confirmProcess = useCallback(
    (regex: RegexException, action: 'delete') => {
      if (regex !== undefined) {
        setSelectedRegexException(regex);
        onOpen(regex);
        setAction(action);
      }
    },
    [onOpen, setAction, setSelectedRegexException]
  );

  const dialogProps = (): { title: string; size: 'sm' | 'md' | 'lg' | 'xl' | undefined } => {
    return action === 'viewDetails'
      ? { title: 'Detalle de la regex', size: 'lg' }
      : { title: 'Confirmar acción', size: 'sm' };
  };


  const onViewDetails = useCallback(
    (regex: RegexException) => {
      setSelectedRegexException(regex);
      onOpen(regex);
      setAction('viewDetails');
    },
    [onOpen]
  );

  const onClose = useCallback(() => onCloseModal(), [onCloseModal]);
  const columns = useMemo(() => getColumns(onViewDetails, confirmProcess), [onViewDetails, confirmProcess]);

  const fetchRegexExceptions = async (
    tableModel: tableModelType<RegexException>,
    config?: AxiosRequestConfig<RegexException>,
  ) => {
    return await axios.get<PaginationType<RegexException>>(
      `${GET_REGEX_EXCEPTIONS}${createPayload(tableModel)}`, {
      ...config,
    });
  };

  const { loading, error, data: regex, handleData, getData } = useGetTable<PaginationType<RegexException>, tableModelType<RegexException>>(
    fetchRegexExceptions,
    { paginationModel: defaultPagination, filterModel, sortModel: defaultSortModel },
  );

  const tableControllers = useTable<RegexException>(
    {
      filterModel,
      paginationModel: regex ? { ...defaultPagination, rowsCount: regex.rowsCount } : defaultPagination,
    },
    handleData,
    getData as (param: Omit<tableModelType<RegexException>, 'paginationModel'>) => Promise<PaginationType<RegexException>>
  );

  const onSubmit = useCallback(
    (filterListRegexExceptionsTemplate: ListRegexTemplateFilterType) => {
      const { errorName, errorRegex, customer, process } = filterListRegexExceptionsTemplate;
      const filterRegexException = createFilterModel({ errorName, errorRegex, customer, process });
      setFilterModel(filterRegexException.filter((item) => item) as filterModelType<RegexException>[]);
    },
    []
  );

  const executeRegexExceptionAction = useCallback(
    async (regex?: RegexException, action?: actionType) => {
      if (!regex || !action) return;

      const handleError = (error: any) => {
        setRegexExceptionActionMessage({
          message: `Ocurrió un error, ${error?.response?.data?.['error'] ?? error['error'] ?? error.message ?? 'intente nuevamente'}`,
          severity: 'error',
        });
      };

      try {
        if (action === 'delete') {
          await axios.delete(`${GET_REGEX_EXCEPTIONS}/${regex.id}`);
          setRegexExceptionActionMessage({ message: 'Regex eliminada correctamente', severity: 'success' });
        }
      } catch (error) {
        handleError(error as AxiosError);
      } finally {
        handleData({ filterModel, paginationModel: defaultPagination, sortModel: defaultSortModel });
        onClose();
      }
    },
    [getData, onClose, filterModel]
  );


  type FormFields = 'errorName' | 'errorRegex' | 'customer' | 'process';

  return (
    <>
      <Table<RegexException>
        {...tableControllers}
        numberOfVisibleColumns={4}
        title={`Encontramos ${tableControllers.paginationModel.rowsCount} expresiones regulares`}
        columns={columns}
        rows={regex?.result ?? []}
        error={!!error}
        loading={loading}
        filterComponent={
          <form noValidate onSubmit={handleSubmit(onSubmit)} onKeyDown={
            (event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                handleSubmit(onSubmit)();
              }
            }
          }>
            <Grid container alignItems="center" justify="flex-end" spacing={2} wrap="nowrap">
              <Grid item>
                <IconButton
                  info="Crear regex"
                  variant="contained"
                  href="/pages/management/exceptions/regex/create"
                >
                  <IoAdd />
                </IconButton>
              </Grid>
              {[
                { label: 'Nombre', name: 'errorName', error: errors.errorName?.message },
                { label: 'Regex', name: 'errorRegex', error: errors.errorRegex?.message },
              ].map((input, index) => (
                <Grid item xs={2} key={index}>
                  <Input
                    fullWidth
                    label={input.label}
                    {...register(input.name as FormFields)}
                    error={!!errors[input.name as FormFields]}
                    helperText={input.error}
                  />
                </Grid>
              ))}
              <Grid item xs={2}>
                <Controller
                  name={"customer"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onChange={field.onChange}
                      label={"Cliente"}
                      fullWidth
                      multiple
                      enableFilter
                    >
                      {customersFetch ?
                        customersFetch
                          .sort((cust1, cust2) => (cust1.name > cust2.name) ? 1 : -1)
                          .map(({ name, code, id }) => (
                            <MenuItem
                              key={name}
                              label={`${name}`}
                              value={`${id}`}
                            >
                              {code}
                            </MenuItem>
                          )) : <></>}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={"process"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onChange={field.onChange}
                      label={"Proceso"}
                      fullWidth
                      multiple
                      enableFilter
                    >
                      {processesFetch ?
                        processesFetch
                          .sort((proc1, proc2) => (proc1.name > proc2.name) ? 1 : -1)
                          .map(({ name, code, id }) => (
                            <MenuItem
                              key={name}
                              label={`${name}`}
                              value={`${id}`}
                            >
                              {code}
                            </MenuItem>
                          )) : <></>}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          </form>
        }
      />
      <Dialog
        open={!!selectedRegexExceptionDialog}
        onClose={onClose}
        title={dialogProps().title}
        maxWidth={dialogProps().size}
      >
        <>
          {action === 'viewDetails' && (
            <DialogContent>
              <RegexDetailTemplate regex={selectedRegexException} />
            </DialogContent>
          )}

          <Grid container spacing={5} justify="flex-end">
            <Grid item xs={12}>
              {['delete'].includes(action ?? '') && (
                <Typography variant="h2">
                  {`¿Estás seguro de ${action === 'delete' ? 'eliminar' : ''} la expresión regular: ${selectedRegexException?.errorName}?`}
                </Typography>
              )}
            </Grid>
            {['delete'].includes(action ?? '') && (
              <>
                <Grid item>
                  <Button variant="contained" color="grey" onClick={onClose}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      executeRegexExceptionAction(selectedRegexException, action);
                    }}
                    loading={loadingForm}
                  >
                    Sí, deseo {action === 'delete' ? 'eliminarlo' : ''}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </Dialog>
      {
        regexActionMessage && (
          <SnackBar
            wait={2000}
            open={regexActionMessage !== undefined}
            onClose={() => {
              setRegexExceptionActionMessage(undefined)
              onClose();
            }}
            severity={regexActionMessage.severity}
          >
            {regexActionMessage.message}
          </SnackBar>
        )
      }
    </>
  );

};

export default ListRegexExceptionsTemplate;
