import React, { FC } from 'react';
import { Adornment, Root, SelectedIcon } from './MenuItem.styles';

export type MenuItemProps = {
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  selected?: boolean;
  value?: string;
  disabledBorder?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  deep?: number;
  'data-value'?: string;
  label?: string;
  'data-label'?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const MenuItem: FC<MenuItemProps> = ({
  disabled,
  children,
  startAdornment,
  disabledBorder,
  endAdornment,
  selected,
  deep = 1,
  ...props
}) => {
  return (
    <Root
      $disabled={disabled}
      $deep={deep}
      $selected={selected}
      $disabledBorder={disabledBorder}
      {...props}
    >
      {startAdornment && (
        <Adornment $orientation="start">{startAdornment}</Adornment>
      )}
      {children} {selected && <SelectedIcon size="10" />}
      {endAdornment && <Adornment $orientation="end">{endAdornment}</Adornment>}
    </Root>
  );
};

export default MenuItem;
