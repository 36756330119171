import { IconButton, Tooltip } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType } from 'components/organisms';
import { AiOutlineCaretRight, AiOutlineDelete, AiOutlineEdit, AiOutlineEye, AiOutlinePoweroff, AiOutlineReload, AiOutlineUnorderedList } from 'react-icons/ai';
import { Server } from 'types';
import { createFilterStructure, messageSplitter } from 'utils';
import { formatDate, StatusColor } from '../Shared/shared.const';
import { ListServerTemplateFilterType } from './ListServersTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultListServerTemplateFilter: ListServerTemplateFilterType = {
  name: undefined,
  ip: undefined,
  provider: '',
  status: '',
};

export const createFilterModel = ({ name, ip, provider, status }: ListServerTemplateFilterType) => {

  const filterName = createFilterStructure(
    'name',
    'contains',
    name,
  );

  const filterServer = createFilterStructure(
    'ip',
    'contains',
    ip,
  );

  const filterStatus = createFilterStructure(
    'status',
    'contains',
    status
  );

  const filterProvider = createFilterStructure(
    'provider',
    'contains',
    provider
  );

  let modelFilter = [
    filterName,
    filterServer,
    filterProvider,
    filterStatus
  ]

  return modelFilter;
}

// format date to Bogota time
export const getColumns: (
  onViewDetails: (server: Server) => void,
  confirmProcess: (server: Server, action: 'start' | 'stop' | 'restart' | 'delete') => void,
) => columnType<Server>[] = (onViewDetails, confirmProcess) => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '1.5',
    exportField: true,
    render: ({ name }) => (<span title={name}>{name}</span>),
  },
  {
    field: 'ip',
    headerName: 'IP',
    flex: '0.7',
    exportField: true,
    render: ({ ip }) => (<span title={ip}>{ip}</span>),
  },
  {
    field: 'status',
    headerName: 'Estado',
    flex: '0.5',
    exportField: true,
    render: ({ status }) => (<strong style={{ color: StatusColor[status] }}>{status}</strong>),
  },
  {
    field: 'errorCount',
    headerName: 'Errores',
    flex: '0.6',
    exportField: true,
    render: ({ errorCount }) => (<span title={errorCount.toString()}>{errorCount}</span>),
  },
  {
    field: 'detailError',
    headerName: 'Detalle de error',
    flex: '1',
    exportField: true,
    isRaw: true,
    overflowNone: true,
    render: ({ detailError }) => (detailError ? (
      <Tooltip
        messageList={messageSplitter(detailError, 9, 7)}
        position='left start'
        color="grey"
      >
        {detailError.length > 30 ? `${detailError.substring(0, 30)}...` : detailError}
      </Tooltip>) : ''
    ),
  },
  {
    field: 'responseTime',
    headerName: 'Latencia',
    flex: '0.7',
    exportField: true,
    render: ({ responseTime }) => (<span title={responseTime ? responseTime.toString() : ''}>{responseTime || ''}</span>),
  },
  {
    field: 'provider',
    headerName: 'Proveedor',
    flex: '0.7',
    exportField: true,
    render: ({ provider }) => (<strong>{provider}</strong>),
  },
  {
    field: 'updatedAt',
    headerName: 'Actualizado',
    flex: '0.8',
    exportField: true,
    render: ({ updatedAt }) => (<span title={formatDate(updatedAt.toString())}>{formatDate(updatedAt.toString())}</span>),
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    disabledSort: true,
    flex: '2',
    align: 'flex-start',
    render: (server) => (
      <>
        <IconButton
          info='Iniciar'
          color='success'
          onClick={() => confirmProcess(server, 'start')}
          disabled={!server.instanceId}
        >
          <AiOutlineCaretRight />
        </IconButton>
        <IconButton
          info='Reiniciar'
          color='warning'
          onClick={() => confirmProcess(server, 'restart')}
          disabled={!server.instanceId}
        >
          <AiOutlineReload />
        </IconButton>
        <IconButton
          info='Apagar'
          color='error'
          onClick={() => confirmProcess(server, 'stop')}
          disabled={!server.instanceId}
        >
          <AiOutlinePoweroff />
        </IconButton>
        <IconButton
          info="Ver Servidor"
          positionEdge="start"
          color="grey"
          onClick={() => onViewDetails(server)}
        >
          <AiOutlineEye />
        </IconButton>
        <IconButton
          info="Editar Servidor"
          positionEdge="start"
          color="info"
          href={`/pages/monitoring/servers/${server.id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>
        <IconButton
          info="Eliminar Servidor"
          positionEdge="start"
          color="error"
          onClick={() => confirmProcess(server, 'delete')}
        >
          <AiOutlineDelete />
        </IconButton>
        <IconButton
          info="Ver servicios"
          positionEdge="start"
          color="info"
          href={`/pages/monitoring/servers/${server.id}/services`}
        >
          <AiOutlineUnorderedList />
        </IconButton>
      </>
    )
  }
  ];