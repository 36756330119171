import styled from 'styled-components';

export const StyledCardItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme.isDarkMode ? theme.palette.background : theme.palette.background};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  width: 80%;
  height: 2em;
  justify-content: center;
  margin: 0.3em;
  padding: 0.5em 1em;
  color: ${({ theme }) =>
    theme.isDarkMode ? theme.common.white : theme.common.black};
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.03);
  }
`;

export const Paper = styled.div`
  background: ${({ theme }) => (theme.isDarkMode ? '#99999933' : '#ffffffaa')};
  padding: ${({ theme }) => theme.shape.spacing(3)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;
export const CardsContainer = styled.div`
  background: ${({ theme }) => (theme.isDarkMode ? '#99999933' : '#ffffffaa')};
  padding: ${({ theme }) => theme.shape.spacing(3)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
`;
