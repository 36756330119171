import styled from 'styled-components';
import { darken } from 'utils';

export type LinkProps = {
  align?: 'center' | 'left' | 'right';
} & React.HTMLAttributes<HTMLElement>;

export const LinkStyle = styled.span<LinkProps>`
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: ${({ theme }) =>
    theme.isDarkMode ? darken(theme.common.white, 0.4) : theme.common.black};
  text-align: ${({ align }) => align};
  text-decoration: underline ${({ theme }) => darken(theme.common.white, 0.4)};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.primary};
    text-decoration-color: ${({ theme }) => theme.palette.primary};
  }
`;
