import { Button, Grid, Input, Typography } from 'components/atoms';
import { DocumentExtraDataJson } from 'types';
import { Container, Root } from './ViewData.styles';

export type ViewDataProps = {
  input: DocumentExtraDataJson['ocr'];
  isClosed: boolean;
  onIsClosed: (boolean: boolean) => void;
};

const ViewData = ({ input, isClosed, onIsClosed }: ViewDataProps) => {
  const values = Object.values(input).filter(
    (row) => row.viewValue !== undefined,
  );
  return (
    <Root>
      {isClosed ? (
        ''
      ) : (
        <Container container spacing={5} justify="flex-end">
          {values.length === 0 ? (
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                No se encontraron Datos de la vista.
              </Typography>
            </Grid>
          ) : (
            values.map((row, idx) => {
              return (
                <Grid item xs={12} key={idx}>
                  <Input
                    fullWidth
                    label={row.label}
                    value={row.viewValue?.toString()}
                    readOnly
                  ></Input>
                </Grid>
              );
            })
          )}
        </Container>
      )}
      <Button
        variant="contained"
        color="info"
        type="button"
        onClick={() => onIsClosed(!isClosed)}
      >
        {isClosed ? 'Mostrar' : 'Ocultar'} Datos Vista
      </Button>
    </Root>
  );
};

export default ViewData;
