import { object, string, TypeOf } from 'yup';

export const EditRoleSchema = object({
  enabled: string().required(),
  menus: string().required(),
  users: string(),
  error: string(),
});

export type EditRoleType = TypeOf<typeof EditRoleSchema>;
