export const errors = {
  INVALID: 'El valor es invalido',
  REQUIRED: 'El valor es requerido',
  EMAIL: 'El email no es valido',
  MAX: 'Excedio el maximo de caracteres',
  INTEGER: 'No es un valor numerico',
};

export const mapErrors: Record<string, { constrain: string; message: string }> =
  {
    isDefined: { constrain: 'required', message: errors.REQUIRED },
    isNotEmpty: { constrain: 'required', message: errors.REQUIRED },
    isString: { constrain: 'string', message: errors.REQUIRED },
    isCustom: { constrain: 'custom', message: '' },
  };
