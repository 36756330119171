import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
import { lighten } from 'utils';
import Alert from '../Alert';

export type PaperProps = {
  $maxWidth: 'sm' | 'md' | 'lg' | 'xl';
};

export const SnackRoot = styled.div`
  z-index: ${({ theme }) => theme.zIndex.modal};
  position: fixed;
  top: 67px;
  right: 50%;
  transform: translate(50%, 0%);
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: visible;
  outline: 0;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
  padding: ${({ theme }) => theme.shape.spacing(4)};
`;

export const CloseIcon = styled(MdClose)`
  color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};
  margin-left: 10px;
  cursor: pointer;
`;

export const SnackAlert = styled(Alert)`
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0;
`;
