import styled, { css } from 'styled-components';

const Paragraph = styled.p<{ $wrap: boolean }>`
  color: var(--color);
  font-weight: var(--bold);
  font-family: Montserrat, sans-serif;

  ${({ $wrap }) =>
    !$wrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export default Paragraph;
