import { IconButton, Tooltip } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType } from 'components/organisms';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import { Service } from 'types';
import { createFilterStructure, messageSplitter } from 'utils';
import { ListServiceTemplateFilterType } from './ListServicesTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultListServiceTemplateFilter: ListServiceTemplateFilterType = {
  name: undefined,
  server: '',
  status: '',
};

export const createFilterModel = ({ name, server, status }: ListServiceTemplateFilterType) => {

  const filterName = createFilterStructure(
    'name',
    'contains',
    name,
  );

  const filterServer = createFilterStructure(
    'server',
    'contains',
    server,
  );

  const filterStatus = createFilterStructure(
    'status',
    'equals',
    status
  );

  let modelFilter = [
    filterName,
    filterServer,
    filterStatus
  ]

  return modelFilter;
}

export const StatusColor = {
  'Disponible': 'green',
  'No Disponible': 'red',
}

export const getColumns: (
  onViewDetails: (service: Service) => void,
  onDelete: (service: Service) => void
) => columnType<Service>[] = (onViewDetails, onDelete) => [
  {
    field: 'server',
    headerName: 'Servidor',
    flex: '1',
    exportField: true,
    render: ({ server }) => (
      <>
        <span title={server.name}>{server.name}</span>
      </>
    ),
  },
  {
    field: 'server',
    headerName: 'IP',
    flex: '0.6',
    exportField: true,
    render: ({ server }) => (<span title={server.ip}>{server.ip}</span>),
  },
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '0.8',
    exportField: true,
    render: ({ name }) => (<span title={name}>{name}</span>),
  },
  {
    field: 'url',
    headerName: 'URL',
    flex: '1',
    exportField: true,
    render: ({ url }) => (<strong>{url}</strong>),
  },
  {
    field: 'status',
    headerName: 'Estado',
    flex: '0.6',
    exportField: true,
    render: ({ status }) => (<strong style={{ color: StatusColor[status] }}>{status}</strong>),
  },
  {
    field: 'detailError',
    headerName: 'Detalle de error',
    flex: '1',
    exportField: true,
    isRaw: true,
    overflowNone: true,
    render: ({ detailError }) => (detailError ? (
      <Tooltip
        messageList={
          messageSplitter(detailError, 9, 7)}
        position='left start'
        color="grey"
      >
        {detailError.length > 30 ? `${detailError.substring(0, 30)}...` : detailError}
      </Tooltip>) : ''
    ),
  },
  {
    field: 'errorCount',
    headerName: 'Errores',
    flex: '0.5',
    exportField: true,
    render: ({ errorCount }) => (<span title={errorCount.toString()}>{errorCount}</span>),
  },
  {
    field: 'responseTime',
    headerName: 'Latencia',
    flex: '0.5',
    exportField: true,
    render: ({ responseTime }) => (<span title={responseTime ? responseTime.toString() : ''}>{responseTime || ''}</span>),
  },
  {
    field: 'expiredSSL',
    headerName: 'SSL Vencido',
    flex: '0.7',
    exportField: true,
    render: ({ expiredSSL }) => (<span title={expiredSSL.toString()}>{expiredSSL ? 'Si' : 'No'}</span>),
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    disabledSort: true,
    flex: '1',
    align: 'flex-start',
    render: (service) => (
      <>
      <IconButton
        info="Ver Servicio"
        positionEdge="start"
        color="grey"
        onClick={() => onViewDetails(service)}
      >
        <AiOutlineEye />
      </IconButton>
        <IconButton
          info="Editar Servidor"
          positionEdge="start"
          color="info"
          href={`/pages/monitoring/services/${service.id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>
        <IconButton
          info="Eliminar Servidor"
          positionEdge="start"
          color="error"
          onClick={() => onDelete(service)}
        >
          <AiOutlineDelete />
        </IconButton>
      </>
    )
  }
];


