import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_COMPANIES, UPDATE_COMPANY_STATUS } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineCheck, AiOutlineEdit } from 'react-icons/ai';
import { Company, PaginationType } from 'types';
import { ListCompanyTemplateFilterType } from './ListCompanyTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Company> = {
  field: 'code',
  order: 'ASC',
};

export const defaultListCompanyTemplateFilter: ListCompanyTemplateFilterType = {
  code: undefined,
};

export const getColumns: (
  onActive: (id: number) => void,
  onEdit: () => boolean
) => columnType<Company>[] = (onEdit, canEdit) => {

  const columns: columnType<Company>[] = [
    {
      field: 'nit',
      headerName: 'Nit',
      flex: '0.7',
      disabledSort: true,
      exportField: true,
    },
    {
      field: 'code',
      headerName: 'Nombre',
      flex: '1',
      exportField: true,
    },
    {
      field: 'typeServices',
      headerName: 'Tipo',
      flex: '1',
      disabledSort: true,
      exportField: true,
      render: ({ typeServices }) => {
        const sortedTypeServices = typeServices?.sort((a, b) => a.localeCompare(b)).join(', ');
        return (
          <>
            {sortedTypeServices}
          </>
        )
      }
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: '1',
      exportField: true,
      isStatus: true,
      render: ({ status }) => (status ? 'Activo' : 'Inactivo'),
    },
  ];

  if (canEdit()) {
    // If the user has edit permission, add the "actions" column
    columns.push({
      field: 'actions',
      headerName: 'Acciones',
      flex: '1',
      disabledSort: true,
      render: ({ id, status }) => (
        <>
          <IconButton
            info={status ? 'Desactivar compañía' : 'Activar compañía'}
            color={status ? 'success' : 'grey'}
            onClick={() => onEdit(id)}
          >
            <AiOutlineCheck />
          </IconButton>
          <IconButton
            info="Editar compañía"
            positionEdge="start"
            color="grey"
            href={`/pages/servicios-publicos/management/company/${id}/edit`}
          >
            <AiOutlineEdit />
          </IconButton>
        </>
      )
    });
  }

  return columns;
}


export const fetchProcess = (
  { filterModel, sortModel, paginationModel }: tableModelType<Company>,
  config?: AxiosRequestConfig<Company>,
) => {
  return axios.get<PaginationType<Company>>(
    `${GET_COMPANIES}?${filterModel && filterModel.length > 0
      ? `filter=${JSON.stringify(filterModel)}&`
      : ''
    }${sortModel ? `sort=${JSON.stringify(sortModel)}&` : ''}${paginationModel
      ? `limit=${paginationModel.rowsPerPage}&page=${paginationModel.page}`
      : ''
    }`,
    config,
  );
};

export const updateStatus = (
  id: number
) => {

  return axios.patch(
    UPDATE_COMPANY_STATUS.replace(':id', id.toString()),
  );
}