import { Paper } from 'components/atoms';
import styled from 'styled-components';

export const CustomPaper = styled(Paper)`
  margin: 0em 0em 0.5em 0em;
  padding: 0.5em 0.2em;
`;

export const CustomContainer = styled.div`
  max-height: 50vh;
`;

export const DialogContent = styled.div`
  max-height: 85vh;
  overflow-y: auto;
  padding-right: 0.5em;
`;
