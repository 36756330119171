import { object, ref, string, TypeOf } from 'yup';

export const ChangePasswordTemplateSchema = object({
  currentPassword: string().max(15),
  newPassword: string().max(15),
  passwordConfirmation: string().oneOf(
    [ref('newPassword'), null],
    'Las contraseñas no coinciden',
  ),
  error: string(),
});

export type ChangePasswordTemplateType = TypeOf<
  typeof ChangePasswordTemplateSchema
>;
