import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { PERMISSIONS } from '../const/permissions';
import useAuth from './useAuth';


/**
 * Interface describing the return value of the custom hook `useGetPermissions`.
 * It provides functions to check permissions for various actions.
 */
interface HookReturnInterface {
  /**
   * Function to check if the user has permission to list items.
   * @returns `true` if the user has permission to list, `false` otherwise.
   */
  canList: () => boolean;

  /**
   * Function to check if the user has permission to edit items.
   * @returns `true` if the user has permission to edit, `false` otherwise.
   */
  canEdit: () => boolean;

  /**
   * Function to check if the user has permission to insert items.
   * @returns `true` if the user has permission to insert, `false` otherwise.
   */
  canInsert: () => boolean;

  /**
   * Function to check if the user has permission to export items.
   * @returns `true` if the user has permission to export, `false` otherwise.
   */
  canExport: () => boolean;

  /**
   * Function to check if the user has permission to delete items.
   * @returns `true` if the user has permission to delete, `false` otherwise.
   */
  canDelete: () => boolean;
}

/**
 * A custom hook to check user permissions for various actions based on the current route.
 * This hook extracts the user's permissions for the current route from the flat menu data.
 * @returns An object implementing the `HookReturnInterface` interface.
 */
const useGetPermissions = () : HookReturnInterface  => {

  const { flatMenu } = useAuth();
  const { pathname: path } = useLocation();
  const [permissions, setPermissions] = useState <string[]| undefined>(undefined);


  useEffect(() => {
    setPermissions(flatMenu?.find(menu => path.includes(menu.link))?.permission)
  }, [])


  const canList = useCallback(() : boolean => {
    return !!permissions?.includes(PERMISSIONS.LIST);
  }, [permissions]);

  const canEdit = useCallback(() : boolean => {
    return !!permissions?.includes(PERMISSIONS.EDIT);
  }, [permissions]);

  const canInsert = useCallback(() : boolean => {
    return !!permissions?.includes(PERMISSIONS.INSERT);
  }, [permissions]);

  const canExport = useCallback(() : boolean => {
    return !!permissions?.includes(PERMISSIONS.EXPORT);
  }, [permissions]);

  const canDelete = useCallback(() : boolean => {
    return !!permissions?.includes(PERMISSIONS.DELETE);
  }, [permissions]);

  return {canList, canEdit, canInsert, canExport, canDelete}
};

export default useGetPermissions;