import { Company, Project } from '../../../../../types';

export const AREAS = [
  { value: 'UNIDAD', name: 'Unidad' },
  { value: 'OBRA', name: 'Obra' },
  { value: 'SALA_VENTA', name: 'Sala de venta' },
];

export type AreaValue = typeof AREAS[number]['value'];

export const areaFormatter = (area: string): string => {
  switch (area) {
    case 'UNIDAD':
      return 'Unidad';
    case 'OBRA':
      return 'Obra';
    case 'SALA_VENTA':
      return 'Sala de venta';
    default:
      return 'UNIDAD';
  }
};

export const getProjectsNamesByIds = (
  projectIds: string,
  projects: Project[],
): string => {
  return projects
    .filter((p) => projectIds.split(',').includes(p.id.toString()))
    .map((p) => p.name)
    .join(', ');
};
export const getPubliServiceCodesByIds = (
  publicServiceIds: string,
  publicServices: Company[],
): string => {
  return publicServices
    .filter((c) => publicServiceIds.split(',').includes(c.id.toString()))
    .map((c) => c.code)
    .join(', ');
};

export const currencyFormatter = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
