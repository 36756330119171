import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button, Grid,
  Input, MenuItem, Paper, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getAllDepartments, getCitiesByDepartment, getErrorsFromBackend } from 'utils';
import { CREATE_PROJECT } from '../../../../../const';
import { states } from '../../../../../const/const';
import { Project } from '../../../../../types';
import { bodyJson, fetchParameterConstructionSite, fetchParameterCostCenter } from '../ProjectTemplate.const';
import CreateProjectTemplateLoading from './CreateProjectTemplate.loading';
import { CreateProjectSchema, CreateProjectType } from './CreateProjectTemplate.schema';


const CreateProjectTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [costCenter, setcostCenter] = useState<0 | 1>(0);
  const [constructionSite, setConstructionSite] = useState<0 | 1>(0);
  const [textAlertError, setTextAlertError] = useState('Ocurrió un error, intenta nuevamente');
  const navigate = useNavigate();

  useEffect(() => {
    fetchParameterCostCenter().then((response) => {
      setcostCenter(response.data)
    })

    fetchParameterConstructionSite().then((response) => {
      setConstructionSite(response.data)
    })
  }, []);

  const {
    control,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
    formState: { errors },
  } = useForm<CreateProjectType>({
    resolver: yupResolver(CreateProjectSchema),
    defaultValues: {
      name: '',
      address: '',
      status: 'true',
      city: '',
      state: '',
      numberConstCenter: '',
      descriptionConstCenter: '',
      numberConstructionSite: '',
      descriptionConstructionSite: ''
    },
  });

  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/servicios-publicos/management/project');
  };
  const { name, state, city } = watch();

  const isDisabled = () => {
    if (name === '' || name === '' || city == '') return true;
    return false;
  };

  const onSubmit = async (projectForm: CreateProjectType) => {
    try {
      setLoading(true);
      const body = bodyJson(projectForm, costCenter, constructionSite, setOpen, setTextAlertError);
      await axios.post<Project>(CREATE_PROJECT, body);
      setOpen('success');
    } catch (error) {
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<CreateProjectType>(error);
      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    } finally {
      reset();
      setLoading(false);
    }
  };



  return (
    <Paper>
      {!loading ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={6}>
              <Input
                label="Nombre"
                fullWidth
                required
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Estado"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Departamento"
                      fullWidth
                    >
                      {getAllDepartments().map((department) => (
                        <MenuItem
                          key={department}
                          label={`${department}`}
                          value={`${department}`}
                        >
                          {department}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => {
                  const selectedState = watch("state") || "";
                  const citiesInSelectedState = getCitiesByDepartment(selectedState);

                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Ciudad"
                      fullWidth
                    >
                      {citiesInSelectedState.map((city) => (
                        <MenuItem
                          key={city.id}
                          value={city.city}>
                          {city.city}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Dirección"
                fullWidth
                required
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address && errors.address.message}
              />
            </Grid>
            {costCenter === 1 && (<>
              <Grid item xs={12}>
                <h4>Centro de costos</h4>
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Número"
                  fullWidth
                  required
                  {...register('numberConstCenter')}
                  error={!!errors.numberConstCenter}
                  helperText={errors.numberConstCenter && errors.numberConstCenter.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Descripción"
                  fullWidth
                  required
                  {...register('descriptionConstCenter')}
                  error={!!errors.descriptionConstCenter}
                  helperText={errors.descriptionConstCenter && errors.descriptionConstCenter.message}
                />
              </Grid>
            </>)}
            {constructionSite === 1 && (<>
              <Grid item xs={12}>
                <h4>Obra</h4>
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Número"
                  fullWidth
                  required
                  {...register('numberConstructionSite')}
                  error={!!errors.numberConstructionSite}
                  helperText={errors.numberConstructionSite && errors.numberConstructionSite.message}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Descripción"
                  fullWidth
                  required
                  {...register('descriptionConstructionSite')}
                  error={!!errors.descriptionConstructionSite}
                  helperText={errors.descriptionConstructionSite && errors.descriptionConstructionSite.message}
                />
              </Grid>
            </>)}
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/servicios-publicos/management/project"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled()}
              >
                Crear
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se creo el proyecto con exito'}
            {open === 'error' && textAlertError}
          </SnackBar>
        </form>
      ) : (
        <CreateProjectTemplateLoading />
      )}
    </Paper>
  );
};

export default CreateProjectTemplate;
