import { IconButton } from 'components/atoms';
import styled from 'styled-components';
import { darken, lighten } from 'utils';

export const Button = styled(IconButton)<{ $representation?: boolean }>`
  margin-left: ${({ theme }) => theme.shape.spacing(2)};
  padding: ${({ theme }) => theme.shape.spacing(1, 2)};
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:svg {
    opacity: ${({ $representation }) => ($representation ? 1 : 0.3)};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.isDarkMode
        ? lighten(theme.palette.background, 0.05)
        : darken(theme.palette.background, 0.05)} !important;
  }
`;
