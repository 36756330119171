import { Header } from 'components/molecules';
import ListRegexExceptionsTemplate from 'components/templates/RegexExceptionTemplate/ListRegexTemplate';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administración' }];

const ListRegexPage = () => {
  return (
    <>
      <Header
        title="Expresiones Regulares"
        subtitle="Administra tus expresiones regulares"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListRegexExceptionsTemplate />
    </>
  );
};

export default withAuth(ListRegexPage, ['ROLE_DEVELOPER', 'ROLE_SOPORTE'], "mayasoft");
