import { Grid, Skeleton } from 'components/atoms';

const EditAmTemplateLoading = () => {
  return (
    <Grid container spacing={3} justify="flex-end">
      <Grid item xs={8}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={11}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={1}>
        <Skeleton height="57px" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height="57px" />
      </Grid>
    </Grid>
  );
};

export default EditAmTemplateLoading;
