import React from 'react';
import { ownerDocument, createChainedFunction, getContainer } from 'utils';
import { useForkRef, useEventCallback } from 'hooks';
import {
  Backdrop,
  BackdropProps,
  ModalManager,
  ariaHidden,
  TrapFocus,
} from './components';

import Portal from '../Portal';
import { PortalProps } from '../Portal/Portal';

import { Root } from './Modal.styles';

function getHasTransition(props: any) {
  return props.children ? props.children.props.hasOwnProperty('in') : false;
}

const defaultManager = new ModalManager();

export type ModalProps = {
  BackdropComponent?: React.ElementType<BackdropProps>;
  BackdropProps?: Partial<BackdropProps>;
  children: React.ReactElement;
  closeAfterTransition?: boolean;
  container?: PortalProps['container'];
  disableAutoFocus?: boolean;
  disableBackdropClick?: boolean;
  disableEnforceFocus?: boolean;
  disableEscapeKeyDown?: boolean;
  disablePortal?: PortalProps['disablePortal'];
  disableRestoreFocus?: boolean;
  disableScrollLock?: boolean;
  hideBackdrop?: boolean;
  keepMounted?: boolean;
  manager?: ModalManager;
  onBackdropClick?: React.ReactEventHandler<{}>;
  onClose?: {
    bivarianceHack(
      event: {},
      reason: 'backdropClick' | 'escapeKeyDown' | 'iconCloseClick',
    ): void;
  }['bivarianceHack'];
  onEscapeKeyDown?: React.ReactEventHandler<{}>;
  onRendered?: PortalProps['onRendered'];
  open: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Modal = React.forwardRef<HTMLElement, ModalProps>(function Modal(
  props,
  ref,
) {
  const {
    BackdropComponent = Backdrop,
    BackdropProps,
    children,
    closeAfterTransition = false,
    container,
    disableAutoFocus = false,
    disableBackdropClick = false,
    disableEnforceFocus = false,
    disableEscapeKeyDown = false,
    disablePortal = false,
    disableRestoreFocus = false,
    disableScrollLock = false,
    hideBackdrop = false,
    keepMounted = false,
    manager = defaultManager,
    onBackdropClick,
    onClose,
    onEscapeKeyDown,
    onRendered,
    open,
    ...other
  } = props;

  const [exited, setExited] = React.useState(true);
  const modal = React.useRef<{
    modalRef?: HTMLElement | null;
    mountNode?: HTMLElement | null;
  }>({});
  const mountNodeRef = React.useRef<HTMLElement | null>(null);
  const modalRef = React.useRef<HTMLElement | null>(null);
  const handleRef = useForkRef(modalRef, ref);
  const hasTransition = getHasTransition(props);

  const getDoc = () => ownerDocument(mountNodeRef.current as HTMLElement);
  const getModal = () => {
    modal.current.modalRef = modalRef.current;
    modal.current.mountNode = mountNodeRef.current;
    return modal.current;
  };

  const handleMounted = () => {
    manager.mount(getModal(), { disableScrollLock });

    (modalRef.current as HTMLElement).scrollTop = 0;
  };

  const handleOpen = useEventCallback(() => {
    const resolvedContainer =
      getContainer(container as Exclude<HTMLElement, null | undefined>) ||
      getDoc().body;

    manager.add(getModal(), resolvedContainer);

    if (modalRef.current) {
      handleMounted();
    }
  });

  const isTopModal = React.useCallback(
    () => manager.isTopModal(getModal()),
    [manager],
  );

  const handlePortalRef = useEventCallback((node: HTMLElement) => {
    mountNodeRef.current = node;

    if (!node) {
      return;
    }

    if (onRendered) {
      onRendered();
    }

    if (open && isTopModal()) {
      handleMounted();
    } else {
      ariaHidden(modalRef.current as HTMLElement, true);
    }
  });

  const handleClose = React.useCallback(() => {
    manager.remove(getModal());
  }, [manager]);

  React.useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  React.useEffect(() => {
    if (open) {
      handleOpen();
    } else if (!hasTransition || !closeAfterTransition) {
      handleClose();
    }
  }, [open, handleClose, hasTransition, closeAfterTransition, handleOpen]);

  if (!keepMounted && !open && (!hasTransition || exited)) {
    return null;
  }

  const handleEnter = () => {
    setExited(false);
  };

  const handleExited = () => {
    setExited(true);

    if (closeAfterTransition) {
      handleClose();
    }
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    if (onBackdropClick) {
      onBackdropClick(event);
    }

    if (!disableBackdropClick && onClose) {
      onClose(event, 'backdropClick');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key !== 'Escape' || !isTopModal()) {
      return;
    }

    if (onEscapeKeyDown) {
      onEscapeKeyDown(event);
    }

    if (!disableEscapeKeyDown) {
      event.stopPropagation();

      if (onClose) {
        onClose(event, 'escapeKeyDown');
      }
    }
  };

  const childProps: Record<string, any> = {};
  if (children.props.tabIndex === undefined) {
    childProps.tabIndex = children.props.tabIndex || '-1';
  }

  if (hasTransition) {
    childProps.onEnter = createChainedFunction(
      handleEnter,
      children.props.onEnter,
    );
    childProps.onExited = createChainedFunction(
      handleExited,
      children.props.onExited,
    );
  }

  return (
    <Portal
      ref={handlePortalRef}
      container={container}
      disablePortal={disablePortal}
    >
      <Root
        ref={handleRef}
        onKeyDown={handleKeyDown}
        $hide={!open && exited}
        role="presentation"
        {...other}
      >
        {hideBackdrop ? null : (
          <BackdropComponent
            open={open}
            onClick={handleBackdropClick}
            {...BackdropProps}
          />
        )}
        <TrapFocus
          disableEnforceFocus={disableEnforceFocus}
          disableAutoFocus={disableAutoFocus}
          disableRestoreFocus={disableRestoreFocus}
          getDoc={getDoc}
          isEnabled={isTopModal}
          open={open}
        >
          {React.cloneElement(children, childProps)}
        </TrapFocus>
      </Root>
    </Portal>
  );
});

export default Modal;
