import axios from 'axios';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { GET_PARAMETER } from 'const';
import { CreateProjectType } from './CreateProjectTemplate/CreateProjectTemplate.schema';
import { EditProjectType } from './EditProjectTemplate/EditProjectTemplate.schema';

export const fetchParameterCostCenter = async () => {
  return await axios.get(GET_PARAMETER.replace(':key', 'COST_CENTER_PROJECT'));
};

export const fetchParameterConstructionSite = async () => {
  return await axios.get(
    GET_PARAMETER.replace(':key', 'CONSTRUCTION_SITE_PROJECT'),
  );
};

export const bodyJson = (
  projectForm: EditProjectType | CreateProjectType,
  costCenter: 0 | 1,
  constructionSite: 0 | 1,
  setOpen: (severityType: severityType) => void,
  setTextAlertError: (text: string) => void,
) => {
  const {
    name,
    address,
    status,
    city,
    state,
    numberConstCenter,
    descriptionConstCenter,
    numberConstructionSite,
    descriptionConstructionSite,
  } = projectForm;
  let projectStatus: boolean = status === 'true' ? true : false;
  const body = {
    name,
    address,
    status: projectStatus,
    city,
    state,
    costCenter: {},
    constructionSite: {},
  };
  if (costCenter === 1) {
    if (!(numberConstCenter && descriptionConstCenter)) {
      setOpen('error');
      setTextAlertError(
        'Por favor, validar que la información del centro de costos esté completa.',
      );
      return;
    } else {
      body.costCenter = {
        number: numberConstCenter,
        description: descriptionConstCenter.trim().toUpperCase(),
      };
    }
  }
  if (constructionSite === 1) {
    if (!(numberConstructionSite && descriptionConstructionSite)) {
      setOpen('error');
      setTextAlertError(
        'Por favor, validar que la información de la obra esté completa.',
      );
      return;
    } else {
      body.constructionSite = {
        number: numberConstructionSite,
        description: descriptionConstructionSite.trim().toUpperCase(),
      };
    }
  }
  return body;
};
