import { Collapse, Divider, Grid } from 'components/atoms';
import styled from 'styled-components';

export const StyledCollapse = styled(Collapse)`
  padding: 10px;
  margin: 10px 0;
`;
export const StyledDivider = styled(Divider)`
  flex-grow: 1;
  margin-left: 0.7rem;
`;

export const StyledIconGridContainer = styled(Grid)`
  margin-left: 0.7rem;
  font-size: 1.2rem;
`;
export const StyledDividerContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;
