import { getScrollbarSize, ownerDocument, ownerWindow } from 'utils';

function isOverflowing(container: HTMLElement) {
  const doc = ownerDocument(container);

  if (doc.body === container) {
    return ownerWindow(doc).innerWidth > doc.documentElement.clientWidth;
  }

  return container.scrollHeight > container.clientHeight;
}

export function ariaHidden(node: HTMLElement, show: boolean) {
  if (show) {
    node.setAttribute('aria-hidden', 'true');
  } else {
    node.removeAttribute('aria-hidden');
  }
}

function getPaddingRight(node: HTMLElement) {
  return parseInt(window.getComputedStyle(node)['paddingRight'], 10) || 0;
}

function ariaHiddenSiblings(
  container: HTMLElement,
  mountNode: any,
  currentNode: any,
  nodesToExclude: any[] = [],
  show: boolean,
) {
  const blacklist = [mountNode, currentNode, ...nodesToExclude];
  const blacklistTagNames = ['TEMPLATE', 'SCRIPT', 'STYLE'];

  [].forEach.call(container.children, (node) => {
    if (
      (node as any).nodeType === 1 &&
      blacklist.indexOf(node) === -1 &&
      blacklistTagNames.indexOf((node as any).tagName) === -1
    ) {
      ariaHidden(node, show);
    }
  });
}

function findIndexOf(containerInfo: any[], callback: (item: any) => boolean) {
  let idx = -1;
  containerInfo.some((item, index) => {
    if (callback(item)) {
      idx = index;
      return true;
    }
    return false;
  });
  return idx;
}

function handleContainer(containerInfo: any, props: any) {
  const restoreStyle: any[] = [];
  const restorePaddings: any[] = [];
  const container = containerInfo.container;
  let fixedNodes: NodeListOf<any>;

  if (!props.disableScrollLock) {
    if (isOverflowing(container)) {
      const scrollbarSize = getScrollbarSize();

      restoreStyle.push({
        value: container.style.paddingRight,
        key: 'padding-right',
        el: container,
      });
      container.style['padding-right'] = `${
        getPaddingRight(container) + scrollbarSize
      }px`;

      fixedNodes = ownerDocument(container).querySelectorAll('.mui-fixed');
      [].forEach.call(fixedNodes, (node) => {
        restorePaddings.push((node as any).style.paddingRight);
        (node as any).style.paddingRight = `${
          getPaddingRight(node) + scrollbarSize
        }px`;
      });
    }

    const parent = container.parentElement;
    const scrollContainer =
      parent.nodeName === 'HTML' &&
      window.getComputedStyle(parent)['overflowY'] === 'scroll'
        ? parent
        : container;

    restoreStyle.push({
      value: scrollContainer.style.overflow,
      key: 'overflow',
      el: scrollContainer,
    });
    scrollContainer.style.overflow = 'hidden';
  }

  const restore = () => {
    if (fixedNodes) {
      [].forEach.call(fixedNodes, (node, i) => {
        if (restorePaddings[i]) {
          (node as any).style.paddingRight = restorePaddings[i];
        } else {
          (node as any).style.removeProperty('padding-right');
        }
      });
    }

    restoreStyle.forEach(({ value, el, key }) => {
      if (value) {
        el.style.setProperty(key, value);
      } else {
        el.style.removeProperty(key);
      }
    });
  };

  return restore;
}

function getHiddenSiblings(container: HTMLElement) {
  const hiddenSiblings: any[] = [];
  [].forEach.call(container.children, (node) => {
    if (
      (node as any).getAttribute &&
      (node as any).getAttribute('aria-hidden') === 'true'
    ) {
      hiddenSiblings.push(node);
    }
  });
  return hiddenSiblings;
}

export default class ModalManager {
  modals: any[];
  containers: any[];

  constructor() {
    this.modals = [];
    this.containers = [];
  }

  add(modal: any, container: any) {
    let modalIndex = this.modals.indexOf(modal);
    if (modalIndex !== -1) {
      return modalIndex;
    }

    modalIndex = this.modals.length;
    this.modals.push(modal);

    if (modal.modalRef) {
      ariaHidden(modal.modalRef, false);
    }

    const hiddenSiblingNodes = getHiddenSiblings(container);
    ariaHiddenSiblings(
      container,
      modal.mountNode,
      modal.modalRef,
      hiddenSiblingNodes,
      true,
    );

    const containerIndex = findIndexOf(
      this.containers,
      (item) => item.container === container,
    );
    if (containerIndex !== -1) {
      this.containers[containerIndex].modals.push(modal);
      return modalIndex;
    }

    this.containers.push({
      modals: [modal],
      container,
      restore: null,
      hiddenSiblingNodes,
    });

    return modalIndex;
  }

  mount(modal: any, props: any) {
    const containerIndex = findIndexOf(
      this.containers,
      (item) => item.modals.indexOf(modal) !== -1,
    );
    const containerInfo = this.containers[containerIndex];

    if (!containerInfo.restore) {
      containerInfo.restore = handleContainer(containerInfo, props);
    }
  }

  remove(modal: any) {
    const modalIndex = this.modals.indexOf(modal);

    if (modalIndex === -1) {
      return modalIndex;
    }

    const containerIndex = findIndexOf(
      this.containers,
      (item) => item.modals.indexOf(modal) !== -1,
    );
    const containerInfo = this.containers[containerIndex];

    containerInfo.modals.splice(containerInfo.modals.indexOf(modal), 1);
    this.modals.splice(modalIndex, 1);

    if (containerInfo.modals.length === 0) {
      if (containerInfo.restore) {
        containerInfo.restore();
      }

      if (modal.modalRef) {
        ariaHidden(modal.modalRef, true);
      }

      ariaHiddenSiblings(
        containerInfo.container,
        modal.mountNode,
        modal.modalRef,
        containerInfo.hiddenSiblingNodes,
        false,
      );
      this.containers.splice(containerIndex, 1);
    } else {
      const nextTop = containerInfo.modals[containerInfo.modals.length - 1];
      if (nextTop.modalRef) {
        ariaHidden(nextTop.modalRef, false);
      }
    }

    return modalIndex;
  }

  isTopModal(modal: any) {
    return (
      this.modals.length > 0 && this.modals[this.modals.length - 1] === modal
    );
  }
}
