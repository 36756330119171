import axios, { AxiosRequestConfig } from 'axios';
import { GET_COMPANY } from 'const';
import { Company } from 'types';

export const defaultData = {
  name: '-',
  code: '-',
  address: '-',
  nit: '-',
  url: '-',
  catcha: 'false',
  status: 'true',
  graceday: '-',
  typeServices: '',
};

export const fetchCompany = (
  { id }: any,
  config?: AxiosRequestConfig<Company[]>,
) => {
  return axios.get<Company>(GET_COMPANY.replace(':id', id), config);
};
