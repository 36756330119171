import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

const useGetFetch = <T extends object, H = any[], E = unknown>(
  fetch: (...params: any[]) => Promise<AxiosResponse<T>>,
  enabledInitialFetch?: H,
) => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<E>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGetData, setLoadingGetData] = useState<boolean>(false);

  const handleData = useCallback(
    async (...params: any[]) => {
      try {
        setLoading(true);
        const { data } = await fetch(...params);
        setLoading(false);
        setError(undefined);
        setData(data);
      } catch (error) {
        setLoading(false);
        setError(error as E);
      }
    },
    [fetch],
  );

  const getData = useCallback(
    async (...params: any[]) => {
      try {
        setLoadingGetData(true);
        const result = await fetch(...params);
        setLoadingGetData(false);
        setError(undefined);
        return result.data;
      } catch (error) {
        setLoadingGetData(false);
        setError(error as E);
      }
    },
    [fetch],
  );

  const mutate = useCallback(
    (newData: T | ((beforeDate: T) => T)) => {
      if (typeof newData === 'function') setData((newData as Function)(data));
      else setData(newData);
    },
    [data],
  );

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (enabledInitialFetch)
      handleData(enabledInitialFetch, { cancelToken: source.token });

    return () => {
      source.cancel('Unmount');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    loading,
    loadingGetData,
    error,
    getData: getData as (param: H) => Promise<T>,
    handleData: handleData as (param: H) => Promise<void>,
    mutate,
  };
};

export default useGetFetch;
