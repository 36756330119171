import { Header } from 'components/molecules';
import { EditRoleTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administración' }];

const EditTaskPage = () => {
  return (
    <>
      <Header
        title="Roles"
        subtitle="Edita un rol"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <EditRoleTemplate />
    </>
  );
};

export default withAuth(EditTaskPage);
