import { useState, useEffect } from 'react';

type idType = string | undefined;

export default function useId(idOverride?: idType) {
  const [defaultId, setDefaultId] = useState<idType>(idOverride);
  const id = idOverride || defaultId;
  useEffect(() => {
    if (!defaultId) {
      setDefaultId(`maya-${Math.round(Math.random() * 1e5)}`);
    }
  }, [defaultId]);
  return id;
}
