import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DateRangePicker,
  Dialog,
  Grid,
  Input,
  MenuItem,
  Select,
  SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { DateRangePickerValue } from 'components/atoms/DateRangePicker/DateRangePicker';
import { Table } from 'components/organisms';
import { DATE_FORMAT_BACK_WITH_OUT_TIME, GET_ALL_CUSTOMERS, GET_ALL_PROCESS_PAGINATION } from 'const';
import { format } from 'date-fns';
import { useDialog, useGetTable } from 'hooks';
import useGetData from 'hooks/useGetData';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AutomationManagerCustomer, Exception, PaginationType, Process } from 'types';
import { joinObjectWithFormat } from 'utils';
import ExceptionConfirmationTemplate from '../ExceptionConfirmationTemplate';
import ExceptionDetailTemplate from '../ExceptionsDetailTemplate/ExceptionDetailTemplate';
import {
  EXCEPTION_LEVELS,
  EXCEPTION_STATUS,
  createFilterModel,
  defaultPagination,
  defaultSortModel,
  fetchProcess,
  getColumns,
  getDefaultListExceptionTemplateFilter,
  rowsMapper,
  updateModifiedExceptions
} from './ListExceptionTemplate.const';
import {
  ListExceptionTemplateFilterSchema,
  ListExceptionTemplateFilterType
} from './ListExceptionTemplate.schema';
import { DialogContent } from './ListExceptionTemplate.styles';
import { ListExceptionTemplateProps } from './ListExceptionTemplate.types';

const ListExceptionTemplate = ({ ...props }: ListExceptionTemplateProps) => {

  const [open, setOpen] = useState<severityType>();
  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [action, setAction] = useState<'remove' | 'edit' | 'viewDetails' | 'resolve' | 'confirm' | undefined>(
    undefined,
  );
  const [actionSnackBar, setActionSnackBar] = useState<'remove' | 'edit' | 'viewDetails' | 'resolve' | 'confirm' | undefined>(
    undefined,
  );
  const [selectedExceptionsToActivate, setSelectedExceptionsToActivate] = useState<Exception[] | undefined>();
  const [modifiedExceptions, setModifiedExceptions] = useState<Exception[]>([]);
  const [statusToggle, setStatusToggle] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [currentfilterModel, setCurrentFilterModel] = useState<filterModelType<Exception>[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [filterModel, setFilterModel] = useState<filterModelType<Exception>[]>([]);
  const { data: processesFetch, loading: loadingProcesses } = useGetData<Process>(
    GET_ALL_PROCESS_PAGINATION
  );
  const { data: customersFetch, loading: loadingCustomers } = useGetData<AutomationManagerCustomer>(
    GET_ALL_CUSTOMERS
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ListExceptionTemplateFilterType>({
    defaultValues: getDefaultListExceptionTemplateFilter(),
    resolver: yupResolver(ListExceptionTemplateFilterSchema),
  });

  const { dateRange } = watch();

  const [selectedExceptionId, setSelectedExceptionId] = useState<number | undefined>(undefined)


  const onCloseSnackBar = useCallback(() => {
    setActionSnackBar(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const onViewDetails = useCallback(
    (id: number) => {
      setSelectedExceptionId(id);
      onOpen(id);
      setAction('viewDetails');
    },
    [onOpen],
  );

  const onConfirm = useCallback(
    () => {
      onOpen(0);
      setAction('confirm');
    },
    [onOpen],
  );

  const dialogProps = (): {
    title: string,
    size: "sm" | "md" | "lg" | "xl" | undefined
  } => {
    if (action === 'confirm') return {
      title: "Confirmar edición de excepciones",
      size: "sm"
    }
    else {
      return {
        title: "Detalle de excepción",
        size: "lg"
      };
    }
  };

  const {
    loading,
    error,
    handleData,
    getData,
    data: process,
  } = useGetTable<PaginationType<Exception>, tableModelType<Exception>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel
  });

  const tableControllers = useTable<Exception>(
    {
      filterModel,
      sortModel: defaultSortModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Exception>, 'paginationModel'>,
    ) => Promise<PaginationType<Exception>>,
  );

  const onCheck = (
    newState: boolean,
    sourceElement: number | string | number[] | string[] | undefined
  ): void => {
    const checked = newState;
    const exception = process?.result.find((exception) => exception.id === sourceElement);
    if (exception) {
      setSelectedExceptionsToActivate([exception]);
      exception.isResolved = checked ? true : false;
      updateModifiedExceptions(setModifiedExceptions, [exception]);
    }
  }

  const onMultipleChecks = (
    newState: boolean,
    sourceElement: number | string | number[] | string[] | undefined
  ): void => {
    const checked = newState;
    setStatusToggle(newState);
    process?.result.forEach((exception) => {
      exception.isResolved = checked;
    });
    updateModifiedExceptions(setModifiedExceptions, process?.result || []);
  }

  const columns = () => { return getColumns(onViewDetails, onCheck) };

  const handleStatusToggle = (
    newState: boolean,
    sourceElement: string | number | number[] | string[] | undefined
  ) => {
    if (sourceElement === 'isResolved') {
      onMultipleChecks(newState, sourceElement);
    }
  }

  const getToggleOptions = () => {
    return {
      isResolved: {
        toggled: statusToggle,
        onToggleChange: handleStatusToggle
      }
    }
  }


  const onSubmit = (filterListExceptionTemplate: ListExceptionTemplateFilterType) => {

    const {
      message,
      process,
      customer,
      level,
      extradata,
      dateRange,
      isResolved
    } = filterListExceptionTemplate;

    const modelFilter = createFilterModel({
      message,
      process,
      customer,
      level,
      extradata,
      dateRange,
      isResolved
    });

    if (modifiedExceptions.length > 0) {
      setCurrentFilterModel(modelFilter as filterModelType<Exception>[])
      setIsFiltering(true);
      onConfirm();
      return;
    }

    tableControllers.paginationModel.page = 0;
    setFilterModel(modelFilter as filterModelType<Exception>[]);
  };

  return (
    <>
      <Table<Exception>
        {...tableControllers}
        numberOfVisibleColumns={4}
        columns={columns()}
        rows={rowsMapper(process?.result, modifiedExceptions) || []}
        error={!!error}
        loading={loading}
        exportOptions={{
          filename: `Reporte-excepciones_${joinObjectWithFormat(dateRange, '-', format, [
            DATE_FORMAT_BACK_WITH_OUT_TIME,
          ])}`,
        }}
        toggleOptions={getToggleOptions()}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      label="Mensaje"
                      {...register('message')}
                      error={!!errors.message}
                      helperText={errors.message && errors.message.message}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      label="Extra data"
                      {...register('extradata')}
                      error={!!errors.extradata}
                      helperText={errors.message && errors.message.message}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Controller
                      name="level"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value}
                            onChange={field.onChange}
                            label="Nivel"
                            fullWidth
                            multiple
                            enableFilter
                          >
                            {EXCEPTION_LEVELS
                              .sort((exception1, exception2) => (exception1.name > exception2.name) ? 1 : -1)
                              .map(({ value, name }) => (
                                <MenuItem
                                  key={value}
                                  label={`${name}`}
                                  value={`${value}`}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Controller
                      name="process"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value
                            }
                            onChange={field.onChange}
                            label="Proceso"
                            fullWidth
                            multiple
                            enableFilter
                          >
                            {processesFetch ?
                              processesFetch
                                .sort((process1, process2) => (process1.name > process2.name) ? 1 : -1)
                                .map(({ id, name, code }) => (
                                  <MenuItem
                                    key={name}
                                    label={`${name}`}
                                    value={`${id}`}
                                  >
                                    {code}
                                  </MenuItem>
                                )) : <></>}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Controller
                      name="customer"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value
                            }
                            onChange={field.onChange}
                            label="Cliente"
                            fullWidth
                            multiple
                            enableFilter
                          >
                            {customersFetch ?
                              customersFetch
                                .sort((cust1, cust2) => (cust1.name > cust2.name) ? 1 : -1)
                                .map(({ id, name, code }) => (
                                  <MenuItem
                                    key={name}
                                    label={`${name}`}
                                    value={`${id}`}
                                  >
                                    {code}
                                  </MenuItem>
                                )) : <></>}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Controller
                      name="isResolved"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value
                            }
                            onChange={field.onChange}
                            label="Estado"
                            fullWidth
                            enableFilter
                          >
                            {EXCEPTION_STATUS
                              .sort((exception1, exception2) => (exception1.name > exception2.name) ? 1 : -1)
                              .map(({ value, name }) => (
                                <MenuItem
                                  key={value}
                                  label={`${name}`}
                                  value={`${value}`}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Controller
                      name="dateRange"
                      control={control}
                      render={({ field }) => (
                        <DateRangePicker
                          label="Fecha"
                          fullWidth
                          onChange={field.onChange}
                          value={field.value as DateRangePickerValue}
                          error={!!errors.dateRange}
                          helperText={
                            errors.dateRange &&
                            (errors.dateRange.startDate?.message ||
                              errors.dateRange.endDate?.message)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={onConfirm}
                      disabled={modifiedExceptions.length === 0}
                      loading={isUpdating}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title={dialogProps().title}
        maxWidth={dialogProps().size}
      >
        <DialogContent>
          {action === 'viewDetails' && (
            <ExceptionDetailTemplate
              exceptionId={selectedExceptionId!}
              setOpen={setOpen}
              setErrorMessage={setErrorMessage}
              setActionSnackBar={setActionSnackBar}
              onClose={onClose}
              onCheck={onCheck}
              modifiedExceptions={modifiedExceptions}
            />)}

          {action === 'confirm' && (
            <ExceptionConfirmationTemplate
              setOpen={setOpen}
              onClose={onClose}
              setErrorMessage={setErrorMessage}
              exceptionList={modifiedExceptions}
              setModifiedExceptions={setModifiedExceptions}
              setActionSnackBar={setActionSnackBar}
              filterModel={filterModel}
              setFilterModel={setFilterModel}
              isFiltering={isFiltering}
              currentFilterModel={currentfilterModel}
            />)}
        </DialogContent>

      </Dialog>
      <SnackBar
        wait={2500}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'warning' && errorMessage}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
        {open === 'success' &&
          actionSnackBar === 'resolve' &&
          'Las excepciones se editaron exitosamente'}
      </SnackBar>
    </>
  );
};

export default ListExceptionTemplate;
