import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DateRangePicker, Grid, Input } from 'components/atoms';
import { DateRangePickerValue } from 'components/atoms/DateRangePicker/DateRangePicker';
import { Table } from 'components/organisms';
import {
  DATE_FORMAT,
  DATE_FORMAT_BACK_WITH_OUT_DATE,
  DATE_FORMAT_BACK_WITH_OUT_TIME,
} from 'const';
import { format } from 'date-fns';
import { useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Transaction } from 'types';
import { createFilterStructure, joinObjectWithFormat } from 'utils';
import {
  defaultPagination,
  defaultSortModel,
  defaultVerificationTemplateFilter,
  fetch,
  getColumns,
} from './VerificationTemplate.const';
import {
  VerificationTemplateFilterSchema,
  VerificationTemplateFilterType,
} from './VerificationTemplate.shema';
import {
  responseType,
  VerificationTemplateProps,
} from './VerificationTemplate.types';

const VerificationTemplate = <T extends object = {}>({
  id,
}: VerificationTemplateProps) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<VerificationTemplateFilterType>({
    defaultValues: defaultVerificationTemplateFilter(),
    resolver: yupResolver(VerificationTemplateFilterSchema),
  });

  const dateRange = watch('dateRange');
  const columns = useMemo(() => getColumns(id), [id]);

  const [filterModel, setFilterModel] = useState<
    filterModelType<Transaction<T>>[]
  >([
    createFilterStructure('id_process', 'equals', id) as filterModelType<
      Transaction<T>
    >,
    createFilterStructure(
      'created_at',
      'between_date',
      joinObjectWithFormat(dateRange, '.', format, [
        DATE_FORMAT_BACK_WITH_OUT_TIME,
      ]),
    ) as filterModelType<Transaction<T>>,

    createFilterStructure(
      'created_at',
      'between_time',
      joinObjectWithFormat(dateRange, '.', format, [
        DATE_FORMAT_BACK_WITH_OUT_DATE,
      ]),
    ) as filterModelType<Transaction<T>>,
  ]);

  const {
    loading,
    error,
    data: process,
    handleData,
    getData,
  } = useGetTable<responseType<T>, tableModelType<Transaction<T>>>(fetch, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });

  const tableControllers = useTable<Transaction<T>>(
    {
      filterModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Transaction<T>>, 'paginationModel'>,
    ) => Promise<responseType<T>>,
  );

  const onSubmit = useCallback(
    (filterVerificationTemplate: VerificationTemplateFilterType) => {
      const filterProcess = createFilterStructure('id_process', 'equals', id);

      const filterPackage = createFilterStructure(
        'id_transaction',
        'contains',
        filterVerificationTemplate.package,
      );

      const filterStatus = createFilterStructure(
        'status',
        'contains',
        filterVerificationTemplate.status,
      );

      const filterDateRange = createFilterStructure(
        'created_at',
        'between_date',
        joinObjectWithFormat(
          filterVerificationTemplate.dateRange,
          '.',
          format,
          [DATE_FORMAT_BACK_WITH_OUT_TIME],
        ),
      );

      const filterTimeRange = createFilterStructure(
        'created_at',
        'between_time',
        joinObjectWithFormat(
          filterVerificationTemplate.dateRange,
          '.',
          format,
          [DATE_FORMAT_BACK_WITH_OUT_DATE],
        ),
      );

      const modelFilter = [
        filterPackage,
        filterStatus,
        filterDateRange,
        filterTimeRange,
        filterProcess,
      ].filter((item) => item);
      setFilterModel(modelFilter as filterModelType<Transaction<T>>[]);
    },
    [id],
  );

  return (
    <Table<Transaction<T>>
      {...tableControllers}
      numberOfVisibleColumns={10}
      title={`Te faltan ${tableControllers.paginationModel.rowsCount} paquetes por revisar`}
      exportOptions={{
        filename: `reporte_verificacion_${joinObjectWithFormat(
          dateRange,
          '-',
          format,
          [DATE_FORMAT],
        )}`,
      }}
      columns={columns}
      rows={process?.result || []}
      loading={loading}
      error={!!error}
      filterComponent={
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <Grid item xs={5}>
              <Input
                label="Filtra por paquete"
                fullWidth
                {...register('package')}
                error={!!errors.package}
                helperText={errors.package && errors.package.message}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="dateRange"
                control={control}
                render={({ field }) => (
                  <DateRangePicker
                    label="Filtra por fechas"
                    fullWidth
                    onChange={field.onChange}
                    value={field.value as DateRangePickerValue}
                    error={!!errors.dateRange}
                    helperText={
                      errors.dateRange &&
                      (errors.dateRange.startDate?.message ||
                        errors.dateRange.endDate?.message)
                    }
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </form>
      }
    />
  );
};

export default VerificationTemplate;
