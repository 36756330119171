import React, { forwardRef, useState, useRef, memo } from 'react';

import Tooltip from '../Info';

import { useTheme, useForkRef, useId } from 'hooks';

import {
  Root,
  Label,
  Input as InputStyled,
  HelperText,
  InputRoot,
  Adornment,
} from './Input.styles';
import { darken, lighten } from 'utils';
import { themeType } from 'types';

export type InputProps = {
  id?: string;
  label?: string;
  fullWidth?: boolean;
  error?: boolean;
  info?:
    | {
        content?: string;
        title?: string;
      }
    | string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement> &
  React.HTMLAttributes<HTMLElement>;

const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        id: idProp,
        label,
        disabled,
        readOnly,
        required,
        fullWidth = false,
        error = false,
        inputProps = {},
        value,
        name,
        type,
        info,
        defaultValue,
        onChange: defaultOnChange,
        onBlur: defaultOnBlur,
        onFocus: defaultOnFocus,
        helperText,
        placeholder,
        endAdornment,
        startAdornment,
        ...props
      },
      ref,
    ) => {
      const inputRef = useRef(null);
      const id = useId(idProp);
      const handleRef = useForkRef(ref, inputRef);

      const { content, title } =
        info && typeof info === 'object'
          ? info
          : {
              content: info as string,
              title: undefined,
            };

      const [state, setState] = useState<string | number | readonly string[]>(
        value || defaultValue || '',
      );
      const [focus, setFocus] = useState<boolean>(false);

      const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (defaultOnChange) defaultOnChange(e);
        setState(e.target.value);
      };

      const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (disabled || readOnly) {
          e.stopPropagation();
          return;
        }

        if (defaultOnFocus) defaultOnFocus(e);
        setFocus(true);
      };

      const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (disabled) {
          e.stopPropagation();
          return;
        }

        if (defaultOnBlur) defaultOnBlur(e);
        setFocus(false);
      };

      const { theme } = useTheme();
      const { palette, mode, common } = theme;

      const isBlank = !state && !value;

      const styleLabel: Record<themeType, object> = {
        dark: {
          '--color': !focus ? lighten(palette.grey, 0.5) : palette.primary,
        },
        light: {
          '--color': !focus
            ? lighten(common.black, 0.1)
            : lighten(common.black, 0.3),
        },
      };

      const styleInputRoot: Record<themeType, object> = {
        dark: {
          '--color': error ? palette.error : darken(theme.common.white, 0.3),
          '--border': error
            ? palette.error
            : focus
            ? palette.primary
            : lighten(palette.background, 0.05),
          '--scale': error || focus ? 'scaleX(1)' : 'scaleX(0)',
        },
        light: {
          '--color': error ? palette.error : theme.common.black,
          '--border': error ? palette.error : theme.palette.background,
          '--scale': error || focus ? 'scaleX(1)' : 'scaleX(0)',
        },
      };

      const styleHelperText: Record<themeType, object> = {
        dark: {
          '--color': error ? palette.error : darken(theme.common.white, 0.5),
        },
        light: {
          '--color': error ? palette.error : darken(theme.common.white, 0.5),
        },
      };

      const styleAdornment: Record<themeType, object> = {
        dark: { '--color': 'inherit' },
        light: { '--color': 'inherit' },
      };

      const newInputProps = {
        ...inputProps,
        $disabledTopPadding: !label,
        type,
        id,
        value,
        name,
        defaultValue,
        onChange,
        onFocus,
        onBlur,
        ref: handleRef,
        disabled,
        readOnly,
        required,
        placeholder,
      };

      const labelProps = {
        style: styleLabel[mode],
        htmlFor: id,
        shrink: focus || !isBlank || !!startAdornment || !!placeholder,
      };

      const adornmentProps = {
        style: styleAdornment[mode],
      };

      const inputRootProps = {
        style: styleInputRoot[mode],
        disabled,
        readOnly,
      };

      const helperTextProps = {
        style: styleHelperText[mode],
        children: helperText,
      };

      return (
        <Root fullWidth={fullWidth} disabled={disabled} {...props}>
          {label &&
            (info ? (
              <Label variant="p1" component="label" {...labelProps}>
                <Tooltip content={content} title={title}>
                  {label} {required && '*'}
                </Tooltip>
              </Label>
            ) : (
              <Label variant="p1" component="label" {...labelProps}>
                {label} {required && '*'}
              </Label>
            ))}
          <InputRoot {...inputRootProps}>
            {startAdornment && (
              <Adornment orientation="start" {...adornmentProps}>
                {startAdornment}
              </Adornment>
            )}
            <InputStyled {...newInputProps} />
            {endAdornment && (
              <Adornment orientation="end" {...adornmentProps}>
                {endAdornment}
              </Adornment>
            )}
          </InputRoot>
          {helperText && <HelperText variant="p1" {...helperTextProps} />}
        </Root>
      );
    },
  ),
);

Input.displayName = 'Input';

export default Input;
