import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Select,
  SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { CREATE_AM, GET_ALL_CUSTOMERS } from 'const';
import useGetData from 'hooks/useGetData';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { AiOutlineDelete, AiOutlineQuestion } from 'react-icons/ai';
import { IoAdd } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { AutomationManagerCustomer } from 'types';
import { fetchLogs } from '../Shared/shared.const';
import { defaultAM } from './CreateAMTemplate.const';
import CreateRoleTemplateLoading from './CreateAMTemplate.loading';
import { CreateAMSchema, CreateAMType } from './CreateAMTemplate.schema';

const CreateAMTemplate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [actionSnackBar, setActionSnackBar] = useState<'pathError' | undefined>(
    undefined,
  );
  const loadingData = loading;
  const [open, setOpen] = useState<severityType>();
  const { data: customersFetch, loading: loadingCustomers } = useGetData<AutomationManagerCustomer>(
    GET_ALL_CUSTOMERS
  );

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/automationmanager');
  };


  /**
   * Block of code to handle the form
   */
  const {
    register,
    handleSubmit,
    watch,
    setError,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<CreateAMType>({
    resolver: yupResolver(CreateAMSchema),
    defaultValues: {
      name: '',
      url: '',
      customer: '',
      logsPaths: [{ path: 'C:/Users/Administrator/AppData/Local/UiPath/Logs', description: 'Ruta de logs de UiPath', validated: false }],
    },
  });


  const { fields, append, remove } = useFieldArray({
    name: 'logsPaths',
    control
  });

  const { name, url, customer, logsPaths } = watch();

  const isDisabled = () => {
    if (name === '' || url === '' || customer === '') return true;
    if (logsPaths?.length && logsPaths.some((f) => !f.path || !f.description)) return true;
    return false;
  };

  const onValidate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    if (!name || !url) return;
    fetchLogs(name, url, getValues(`logsPaths.${index}.path`)!).then((res) => {
      setOpen('info');
    }).catch(() => { // Add parentheses here
      setActionSnackBar('pathError');
      setOpen('warning');
    });
    event.preventDefault();
    getValues(`logsPaths.${index}`);
  };




  const onSubmit = async (loginForm: CreateAMType) => {
    try {
      setLoading(true);
      const { ...form } = loginForm;
      await axios.post<CreateAMType>(CREATE_AM, {
        ...form,
      });
      setLoading(false);
      setOpen('success');
    } catch (error) {
      setLoading(false);
      setOpen('error');
      /*       setLoading(false);
            const { isGeneral, errorOptions } =
              getErrorsFromBackend<CreateAMType>(error);
      
            if (isGeneral) {
              setError(...errorOptions);
              setOpen('error');
            } else
              errorOptions.forEach((error) => {
                setError(...error);
              }); */
    } finally {
      reset()
    }
  };

  /*   useEffect(() => {
      console.log(fields)
    }, [fields]); */

  return (
    <Paper>
      {!loadingData ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={8}>
              <Input
                label="Nombre"
                fullWidth
                required
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="customer"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Cliente"
                      fullWidth
                    >
                      {customersFetch ?
                        customersFetch
                          .sort((cust1, cust2) => (cust1.name > cust2.name) ? 1 : -1)
                          .map(({ id, name, code }) => (
                            <MenuItem
                              key={name}
                              label={`${name}`}
                              value={`${code}`}
                            >
                              {code}
                            </MenuItem>
                          )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <Input
                label="URL"
                fullWidth
                required
                {...register('url')}
                info={"Ruta del AM, ejemplo: https://www.micasayamarval.mayasoft.ai/manager"}
                error={!!errors.url}
                helperText={errors.url && errors.url.message}
              />
            </Grid>
            <Grid item xs={1} >
              <IconButton
                type='button'
                positionEdge="start"
                edge={5}
                variant="contained"
                onClick={() => append({ path: "", description: "", validated: false })}
                info="Añadir rutas de archivos"
              >
                <IoAdd />
              </IconButton>
            </Grid>
          </Grid>
          {fields.map((field, index) => (
            <Grid container spacing={3} justify="flex-start" key={field.id} alignItems='center'>
              <Grid item xs={5}>
                <Input
                  label="Ruta de logs"
                  fullWidth
                  required
                  info={"Ruta de directorio que contiene los logs, ejemplo: C:/Users/Administrator/AppData/Local/UiPath/Logs"}
                  defaultValue={defaultAM.logPaths[0].path}
                  {...register(`logsPaths.${index}.path`)}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Descripción"
                  fullWidth
                  required
                  defaultValue={defaultAM.logPaths[0].description}
                  {...register(`logsPaths.${index}.description`)}
                />
              </Grid>
              <Grid item xs={1}>
                <Grid container spacing={3} justify="center" alignItems='center'>
                  <Grid item xs={6}>
                    <IconButton
                      type='button'
                      info="Eliminar ruta"
                      color="error"
                      onClick={() => remove(index)}
                    >
                      <AiOutlineDelete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton
                      type='button'
                      info="Validar ruta"
                      color="info"
                      onClick={(event) => onValidate(event, index)}
                    >
                      <AiOutlineQuestion />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                type='button'
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/automationmanager"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled()}
              >
                Crear
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se creó con exito'}
            {open === 'warning' && actionSnackBar === 'pathError' && 'Ruta invalida'}
            {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
            {open === 'info' && 'Ruta validada con exito.'}
          </SnackBar>
        </form>
      ) : (
        <CreateRoleTemplateLoading />
      )}
    </Paper>
  );
};

export default CreateAMTemplate;
