import { Grid, Paper, Spinner } from 'components/atoms';
import Accordion from 'components/molecules/Accordion';
import { BasicTable, Svg, TableCell, TableRow } from 'components/organisms';
import {
  Grow,
  TableContainer,
  TableHead,
} from 'components/organisms/Table/Table.styles';
import 'moment/locale/es';
import { useMemo, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RegexException } from 'types/entities/regex-exception';
import {
  getColumns,
  getColumnsForDetail
} from './RegexDetailTemplate.const';
import { RegexDetailTemplateProps } from './RegexDetailTemplate.types';

const RegexDetailTemplate = ({ ...props }: RegexDetailTemplateProps) => {
  const ref = useRef<HTMLTableElement>(null);

  const { regex } = props;

  const columns = useMemo(() => getColumns(), []);
  const columnsAdditionalDataProvider = useMemo(
    () => getColumnsForDetail(),
    [],
  );

  return regex ? (
    <Paper>
      <Grid container alignItems="center" justify="center" spacing={4}>
        <Accordion
          sections={[
            {
              defaultOpen: true,
              header: 'Principales',
              content: (
                <Grid container alignItems="center" justify="center" spacing={7}>
                  <Grid item xl={12} style={{ width: '100%' }}>
                    <TableContainer $height={1}>
                      <TableHead as="div">
                        <TableRow as="div">
                          {columns.map(({ headerName, field, flex }, idx) => (
                            <TableCell
                              as="div"
                              key={`head.${field}.${headerName}`}
                              $flex={flex}
                            >
                              {headerName}
                              <Grow />
                              {columns.length - 1 > idx && (
                                <Svg
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="SeparatorIcon"
                                >
                                  <path d="M11 19V5h2v14z"></path>
                                </Svg>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <PerfectScrollbar>
                        <BasicTable<RegexException>
                          numberOfVisibleColumns={columns.length}
                          ref={ref}
                          columns={columns}
                          rows={[regex]}
                          rowsPerPage={1}
                        />
                      </PerfectScrollbar>
                    </TableContainer>
                  </Grid>
                </Grid>
              )
            },
            {
              defaultOpen: true,
              header: 'Extras',
              content: (
                <Grid container alignItems="center" justify="center" spacing={7}>
                  <Grid item xl={12} style={{ width: '100%' }}>
                    <TableContainer $height={1}>
                      <TableHead as="div">
                        <TableRow as="div">
                          {columnsAdditionalDataProvider.map(({ headerName, field, flex }, idx) => (
                            <TableCell
                              as="div"
                              key={`head.${field}.${headerName}`}
                              $flex={flex}
                            >
                              {headerName}
                              <Grow />
                              {columnsAdditionalDataProvider.length - 1 > idx && (
                                <Svg
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="SeparatorIcon"
                                >
                                  <path d="M11 19V5h2v14z"></path>
                                </Svg>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <PerfectScrollbar>
                        <BasicTable<RegexException>
                          numberOfVisibleColumns={columnsAdditionalDataProvider.length}
                          ref={ref}
                          columns={columnsAdditionalDataProvider}
                          rows={[regex]}
                          rowsPerPage={1}
                        />
                      </PerfectScrollbar>
                    </TableContainer>
                  </Grid>
                </Grid>
              ),
            },
          ]}
        />
      </Grid>
    </Paper>
  ) : (
    <Spinner></Spinner>
  );
};

export default RegexDetailTemplate;
