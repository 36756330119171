import { Alert, Grid, SnackBar } from 'components/atoms';
import { Intro, Title } from './HomeTemplate.styles';

import axios from 'axios';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { REQUEST_PERMISSIONS } from 'const';
import { useAuth } from 'hooks';
import { useState } from 'react';

const HomeTemplate = () => {
  const { menu } = useAuth();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();
  const [success, setSuccess] = useState(false);

  const onClose = () => {
    setOpen(undefined);
  };

  const requestPermissions = async () => {
    setLoading(true);
    try {
      await axios.post(REQUEST_PERMISSIONS);
      setOpen('success');
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setOpen('error');
    }
    setLoading(false);
  };

  return (
    <>
      <Grid
        container
        spacing={5}
        alignItems="center"
        style={{ height: '80%' }}
        justify="center"
      >
        <Grid item>
          <Intro variant="h2" align="center">Bienvenido a</Intro>
          <Title variant="h1" align="center">Batuta</Title>
          <Intro variant="h2" align="center">Portal Administrativo</Intro>
          {!menu ||
            (menu.length === 0 && (
              <Alert
                severity={success ? 'info' : 'warning'}
                action={success ? undefined : 'Solicita permisos'}
                buttonProps={{ loading, onClick: requestPermissions }}
              >
                {success
                  ? 'Se te notificara por correo electrónico cuando te hayan asignado permisos.'
                  : 'Actualmente no cuentas con ningún permisos, solicita permisos a los administradores.'}
              </Alert>
            ))}
        </Grid>
        {/*         <Grid item xs={3} sm={8} md={6} lg={3}>
          <img src={icon} alt="test" width="100%" />
        </Grid> */}
      </Grid>
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onClose}
        severity={open}
      >
        {open === 'success' &&
          'Se solicito a los administradores la asignación de permisos.'}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente.'}
      </SnackBar>
    </>
  );
};

export default HomeTemplate;
