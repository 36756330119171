import React, { FC } from 'react';

import { useTheme } from 'hooks';

import type { ContentProps } from './Tooltip.styles';
import { Content, ContentRoot, Icon, Root, Text } from './Tooltip.styles';

export type TooltipProps = {
  content?: string;
  edge?: 'start' | 'end';
  icon?: React.ReactNode;
} & ContentProps &
  React.HTMLAttributes<HTMLElement>;

const Tooltip: FC<TooltipProps> = ({
  content,
  children,
  edge = 'start',
  title,
  position = 'top right',
  icon,
  ...props
}) => {
  const { theme } = useTheme();

  const style: object = {
    '--color': theme.common.white,
    '--background-color': theme.palette.primary,
  };

  return (
    <Root {...props}>
      {edge === 'start' && children}
      <ContentRoot edge={edge}>
        {icon || <Icon style={style} size="0.8em" />}
        <Content position={position} title={title}>
          {!!title && (
            <Text variant="h3" bold color="white">
              {title}
            </Text>
          )}
          <Text variant="p" color="white" wrap={false}>
            {content}
          </Text>
        </Content>
      </ContentRoot>
      {edge === 'end' && children}
    </Root>
  );
};

export default Tooltip;
