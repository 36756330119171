import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { CreateProjectTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const CreateProjectPage = () => {
  return (
    <>
      <Header
        title="Proyectos"
        subtitle="Crea proyectos"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <CreateProjectTemplate />
    </>


  )
}

export default withAuth(CreateProjectPage);