import { object, string, TypeOf } from 'yup';

export const ListServiceTemplateFilterSchema = object({
  name: string(),
  status: string(),
  server: string(),
});

export type ListServiceTemplateFilterType = TypeOf<
  typeof ListServiceTemplateFilterSchema
>;
