import Typography from '../Typography';
import { Content, ContentProps, Root } from './Tooltip.styles';

export type TooltipProps = {
  message?: string;
  messageList?: string[];
  position?: ContentProps['$position'];
} & React.HTMLAttributes<HTMLDivElement>;

const Tooltip = ({
  children,
  message = '',
  messageList = [],
  position = 'left',
  ...props
}: TooltipProps) => {
  return (
    <Root {...props}>
      {children}
      <Content $position={position}>
        {messageList.map((messageL, index) => {
          return (
            <Typography variant="p1" color="white" wrap={false} key={messageL + index}>
              {messageL}
            </Typography>
          )
        })}
        <Typography variant="p1" color="white" wrap={false}>
          {message}
        </Typography>
      </Content>
    </Root>
  );
};

export default Tooltip;
