import { string, object, TypeOf, date } from 'yup';

export const VerificationTemplateFilterSchema = object({
  package: string(),
  status: string(),
  dateRange: object({
    startDate: date().required(),
    endDate: date().required(),
  }).required(),
});

export type VerificationTemplateFilterType = TypeOf<
  typeof VerificationTemplateFilterSchema
>;
