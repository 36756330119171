import styled from 'styled-components';
import { lighten } from 'utils';
import Typography from '../Typography';

export const NativePDF = styled.iframe`
  background-color: ${({ theme }) => lighten(theme.palette.background, 0.1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  min-height: 600px;
`;

export const ToolBar = styled(Typography)`
  padding: ${({ theme }) => theme.shape.spacing(2)};
`;
