import { createGlobalStyle } from 'styled-components';
import { invertColor, lighten } from 'utils';

const GlobalStyle = createGlobalStyle`

    // RESET

    html, body, div, span, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    abbr, address, cite, code,
    del, dfn, em, img, ins, kbd, q, samp,
    small, strong, sub, sup, var,
    b, i,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section, summary,
    time, mark, audio, video {
        margin:0;
        padding:0;
        border:0;
        outline:0;
        font-size:100%;
        vertical-align:baseline;
        background:transparent;
    }

    body {
        line-height:1;
    }

    article,aside,details,figcaption,figure,
    footer,header,hgroup,menu,nav,section {
        display:block;
    }

    nav ul {
        list-style:none;
    }

    blockquote, q {
        quotes:none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content:'';
        content:none;
    }

    a {
        margin:0;
        padding:0;
        font-size:100%;
        vertical-align:baseline;
        background:transparent;
    }

    ins {
        background-color:#ff9;
        color:#000;
        text-decoration:none;
    }

    mark {
        background-color:#ff9;
        color:#000;
        font-style:italic;
        font-weight:bold;
    }

    del {
        text-decoration: line-through;
    }

    abbr[title], dfn[title] {
        border-bottom:1px dotted;
        cursor:help;
    }

    table {
        border-collapse:collapse;
        border-spacing:0;
    }

    hr {
        display:block;
        height:1px;
        border:0;  
        border-top:1px solid #cccccc;
        margin:1em 0;
        padding:0;
    }

    input, select {
        vertical-align:middle;
    }

    // FONT

    * {
        font-family: 'Ubuntu', sans-serif !important
    }

    // PERFECT SCROLLBAR

    .scrollbar-container {
        // width: 100%;
        flex: 1;
    }

    .ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
        background: transparent;
    }
    
    .content .ps__thumb-y {
        width: 8px;
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }

    // Range date

    .rdrCalendarWrapper {
        background: inherit;
        color: ${({ theme }) => theme.palette.grey};
    }

    .rdrMonthAndYearWrapper {
       color: ${({ theme }) => theme.palette.grey};
    }

    .rdrMonthAndYearPickers select {
       color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};
    }

    .rdrDayPassive .rdrDayNumber span {
       color: ${({ theme }) => lighten(theme.palette.grey, 0.2)};
    }

    .rdrDayNumber span {
        color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        color: ${({ theme }) =>
          theme.isDarkMode
            ? lighten(theme.palette.grey, 0.8)
            : invertColor(theme.palette.grey, true)} !important;
    }

    .rdrDayDisabled {
        background: inherit;
        & .rdrDayNumber span {
            color: ${({ theme }) => theme.palette.grey};
        }
    }

    .rdrDateDisplayWrapper {
        display: none;
    }

    .rdrDefinedRangesWrapper {
        background: inherit;
        color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};
        border-right: 1px solid ${({ theme }) => theme.palette.divider};
    }

    .rdrStaticRanges {
        background: inherit;
    }

    .rdrStaticRange {
        background: inherit;
        border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
        & .rdrStaticRangeLabel {
            color: ${({ theme }) =>
              theme.isDarkMode
                ? lighten(theme.palette.grey, 0.5)
                : theme.palette.grey} !important;
           
        }

        & :hover {
            background: inherit;
            color: white;
        }
    }

    .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
        background: inherit;
        color: white;
    }

    .rdrStaticRange.rdrStaticRangeSelected {
        background: inherit;

        & .rdrStaticRangeLabel {
            color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    .rdrPprevButton {
        background-color: ${({ theme }) =>
          lighten(theme.palette.background, 0.05)};
        color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};

        & > i {
            border-color: transparent currentColor transparent transparent;
        }

        &:hover {
            background-color: ${({ theme }) =>
              lighten(theme.palette.background, 0.08)};
        }
    }

    .rdrNextButton  {
        background-color: ${({ theme }) =>
          lighten(theme.palette.background, 0.05)};
        color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};

        & > i {
            border-color: transparent transparent transparent currentColor;
        }

        &:hover {
            background-color: ${({ theme }) =>
              lighten(theme.palette.background, 0.08)};
        }
    }

    .rdrInputRanges {
        display: none;
    }

    .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
        color: inherit;
        font-weight: 600;
    }

    .rdrInputRangeInput {
        background-color: ${({ theme }) =>
          lighten(theme.palette.background, 0.05)};
        border-radius: ${({ theme }) => `${theme.shape.borderRadius}`};
        border: 0;

        &::hover {
            color: currentColor;
        }

    }

    .rdrInRange, .rdrStartEdge, .rdrEndEdge {
        color: ${({ theme }) => theme.palette.primary} !important;
    }

    .rdrBreakWord{
      word-wrap: break-word; !important;
    }

    //Track properties
    &::-webkit-scrollbar {
      width: 0.7em;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
`;
export default GlobalStyle;
