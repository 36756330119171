import { Header } from 'components/molecules';
import CreateEditServiceTemplate from 'components/templates/ServicesTemplate/CreateEditServiceTemplate';

import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administración' }];

const CreateEditServicePage = () => {
    return (
        <>
            <Header
                title="Servicios"
                subtitle="Agregar/Editar Servicio"
                breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
            />
            <CreateEditServiceTemplate />
        </>
    );
};

export default withAuth(CreateEditServicePage, ['ROLE_DEVELOPER'], "mayasoft");
