import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_ALL_AM } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiFillRobot, AiOutlineDelete, AiOutlineEdit, AiOutlineFileText, AiOutlineUnorderedList } from 'react-icons/ai';
import { PaginationType, Process } from 'types';
import { AutomationManager } from 'types/entities/automation-manager';
import { ListAMTemplateFilterType } from './ListAMTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<AutomationManager> = {
  field: 'id',
  order: 'ASC',
};

export const defaultListAMTemplateFilter: ListAMTemplateFilterType = {
  name: undefined,
};

export const getColumns: (
  callApi: (_process: Process, action: 'start' | 'stop') => void,
  onDelete: (id: number) => void,
  onViewLogs: (id: number) => void,
) => columnType<AutomationManager>[] = (callApi, onDelete, onViewLogs) => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '3',
    exportField: true,
    render: ({ name }) => (
      <>
        <AiFillRobot />
        &nbsp;<span title={name}>{name}</span>
      </>
    ),
  },
  {
    field: 'url',
    headerName: 'Url',
    flex: '3',
    exportField: true,
    render: ({ url }) => (<>
      <span title={url}>{url}</span>
    </>),
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    // align: "flex-end",
    flex: '2',
    disabledSort: true,
    render: ({ id, paths }) => (
      <>
        <IconButton
          info="Ver bots"
          positionEdge="start"
          color="info"
          href={`/pages/management/automationmanager/${id}/bot`}
        >
          <AiOutlineUnorderedList />
        </IconButton>
        <IconButton
          info="Eliminar"
          positionEdge="start"
          color="error"
          onClick={() => onDelete(id)}
        >
          <AiOutlineDelete />
        </IconButton>
        <IconButton
          info="Editar un Automation Manager"
          positionEdge="start"
          color="grey"
          href={`/pages/management/automationmanager/${id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>
        <IconButton
          info="Ver logs"
          positionEdge="start"
          color="info"
          onClick={() => onViewLogs(id)}
          disabled={paths.length === 0 || !paths[0]}
        >
          <AiOutlineFileText />
        </IconButton>
      </>)
  }
];

export const fetchProcess = (
  { filterModel, sortModel, paginationModel }: tableModelType<AutomationManager>,
  config?: AxiosRequestConfig<AutomationManager>,
) => {
  return axios.get<PaginationType<AutomationManager>>(
    `${GET_ALL_AM}?${filterModel && filterModel.length > 0
      ? `filter=${JSON.stringify(filterModel)}&`
      : ''
    }${sortModel ? `sort=${JSON.stringify(sortModel)}&` : ''}${paginationModel
      ? `limit=${paginationModel.rowsPerPage}&page=${paginationModel.page}`
      : ''
    }`,
    config,
  );
};
