import { FC, useCallback, useState } from 'react';

import { Customer } from 'types';
import { loadState, saveState } from 'utils';

import CustomerContext from './CustomerContext';

const CustomerContextProvider: FC = ({ children }) => {
  const localCostumer = loadState<Customer>('customer') as Customer;

  const [customer, setCustomer] = useState<Customer>(localCostumer);

  const loadCustomer = useCallback((newCostumer: Customer) => {
    setCustomer(newCostumer);
    saveState('customer', newCostumer);
  }, []);

  const context = {
    ...customer,
    loadCustomer,
  };

  return (
    <CustomerContext.Provider value={context}>
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContextProvider;
