import { AiOutlineCheck } from 'react-icons/ai';
import styled, { css } from 'styled-components';
import { lighten } from 'utils';

export const Root = styled.div<{
  $disabled?: boolean;
  $selected?: boolean;
  $disabledBorder?: boolean;
  $deep: number;
}>`
  font: inherit;
  background-color: inherit;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-width: 100px;
  opacity: ${({ $disabled: disabled }) => (disabled ? '0.6' : '1')};
  box-sizing: border-box;
  ${({ theme, $disabledBorder }) =>
    !$disabledBorder &&
    css`
      border-bottom: 1px solid ${theme.palette.divider};
    `};

  color: ${({ theme, $selected }) =>
    theme.isDarkMode
      ? $selected
        ? lighten(theme.palette.grey, 1)
        : lighten(theme.palette.grey, 0.5)
      : $selected
      ? lighten(theme.palette.grey, 0.3)
      : lighten(theme.palette.grey, 0.2)};

  padding: 6px 6px 6px ${({ $deep }) => $deep * 8}px;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  align-items: center;

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    ${({ theme, $disabled, $selected }) =>
      !$disabled &&
      css`
        color: ${theme.isDarkMode
          ? $selected
            ? lighten(theme.palette.grey, 0.8)
            : lighten(theme.palette.grey, 0.7)
          : $selected
          ? lighten(theme.palette.grey, 0.1)
          : lighten(theme.palette.grey, 0)};
      `}
`;

export const Adornment = styled.div<{ $orientation: 'start' | 'end' }>`
  display: flex;
  max-height: 2em;
  align-items: center;
  white-space: nowrap;
  font-family: Montserrat, sans-serif;
  color: var(--color);
  margin: ${({ $orientation: orientation }) =>
    orientation === 'start' ? '0px 8px 0px 0px' : '0px 0px 0px 8px'};
`;

export const SelectedIcon = styled(AiOutlineCheck)`
  margin-left: ${({ theme }) => theme.shape.spacing(2)};
`;
