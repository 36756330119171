import React from 'react';
import ReactDOM from 'react-dom';

import {
  debounce,
  ownerDocument,
  ownerWindow,
  getOffsetTop,
  getOffsetLeft,
  getTransformOriginValue,
  getScrollParent,
  getAnchorEl,
} from 'utils';

import Modal, { ModalProps } from '../Modal';
import { Paper } from './Popover.styles';

export interface PopoverOrigin {
  vertical: 'top' | 'center' | 'bottom' | number;
  horizontal: 'left' | 'center' | 'right' | number;
}

export interface PopoverPosition {
  top: number;
  left: number;
}

export type PopoverReference = 'anchorEl' | 'anchorPosition' | 'none';

export type PopoverProps = {
  action?: React.Ref<PopoverActions>;
  anchorEl?: null | HTMLElement | ((element: HTMLElement) => HTMLElement);
  anchorOrigin?: PopoverOrigin;
  anchorPosition?: PopoverPosition;
  anchorReference?: PopoverReference;
  children?: React.ReactNode;
  // container?: ModalProps["container"];
  getContentAnchorEl?: null | ((element: HTMLElement) => HTMLElement);
  marginThreshold?: number;
  // onClose?: ModalProps["onClose"];
  open: boolean;
  transformOrigin?: PopoverOrigin;
} & React.HTMLAttributes<HTMLElement> &
  ModalProps;

export type PopoverClassKey = 'root' | 'paper';

export interface PopoverActions {
  updatePosition(): void;
}

const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(function Popover(
  props,
  ref,
) {
  const {
    action,
    anchorEl,
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'left',
    },
    anchorPosition,
    anchorReference = 'anchorEl',
    children,
    container: containerProp,
    getContentAnchorEl,
    marginThreshold = 16,

    open,
    transformOrigin = {
      vertical: 'top',
      horizontal: 'left',
    },
    ...other
  } = props;

  const paperRef = React.useRef<HTMLElement>();

  const getAnchorOffset = React.useCallback(
    (contentAnchorOffset) => {
      if (anchorReference === 'anchorPosition') {
        return anchorPosition as PopoverPosition;
      }

      const resolvedAnchorEl = getAnchorEl(anchorEl as HTMLElement);

      const anchorElement =
        resolvedAnchorEl && resolvedAnchorEl.nodeType === 1
          ? resolvedAnchorEl
          : ownerDocument(paperRef.current as HTMLElement).body;
      const anchorRect = anchorElement.getBoundingClientRect();

      const anchorVertical =
        contentAnchorOffset === 0 ? anchorOrigin.vertical : 'center';

      return {
        top: anchorRect.top + getOffsetTop(anchorRect, anchorVertical),
        left:
          anchorRect.left + getOffsetLeft(anchorRect, anchorOrigin.horizontal),
      };
    },
    [
      anchorEl,
      anchorOrigin.horizontal,
      anchorOrigin.vertical,
      anchorPosition,
      anchorReference,
    ],
  );

  const getContentAnchorOffset = React.useCallback(
    (element) => {
      let contentAnchorOffset = 0;

      if (getContentAnchorEl && anchorReference === 'anchorEl') {
        const contentAnchorEl = getContentAnchorEl(element);

        if (contentAnchorEl && element.contains(contentAnchorEl)) {
          const scrollTop = getScrollParent(element, contentAnchorEl);
          contentAnchorOffset =
            (contentAnchorEl as HTMLElement).offsetTop +
              contentAnchorEl.clientHeight / 2 -
              scrollTop || 0;
        }
      }

      return contentAnchorOffset;
    },
    [anchorReference, getContentAnchorEl],
  );

  const getTransformOrigin = React.useCallback(
    (elemRect, contentAnchorOffset = 0) => {
      return {
        vertical:
          getOffsetTop(elemRect, transformOrigin.vertical) +
          contentAnchorOffset,
        horizontal: getOffsetLeft(elemRect, transformOrigin.horizontal),
      };
    },
    [transformOrigin.horizontal, transformOrigin.vertical],
  );

  const getPositioningStyle = React.useCallback(
    (element) => {
      const contentAnchorOffset = getContentAnchorOffset(element);
      const elemRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };

      const elemTransformOrigin = getTransformOrigin(
        elemRect,
        contentAnchorOffset,
      );

      if (anchorReference === 'none') {
        return {
          top: null,
          left: null,
          transformOrigin: getTransformOriginValue(elemTransformOrigin),
        };
      }

      const anchorOffset = getAnchorOffset(contentAnchorOffset);

      let top = anchorOffset.top - elemTransformOrigin.vertical;
      let left = anchorOffset.left - elemTransformOrigin.horizontal;
      const bottom = top + elemRect.height;
      const right = left + elemRect.width;

      const containerWindow = ownerWindow(
        getAnchorEl(anchorEl as HTMLElement) as Node,
      );

      const heightThreshold = containerWindow.innerHeight - marginThreshold;
      const widthThreshold = containerWindow.innerWidth - marginThreshold;

      if (top < marginThreshold) {
        const diff = top - marginThreshold;
        top -= diff;
        elemTransformOrigin.vertical += diff;
      } else if (bottom > heightThreshold) {
        const diff = bottom - heightThreshold;
        top -= diff;
        elemTransformOrigin.vertical += diff;
      }

      if (left < marginThreshold) {
        const diff = left - marginThreshold;
        left -= diff;
        elemTransformOrigin.horizontal += diff;
      } else if (right > widthThreshold) {
        const diff = right - widthThreshold;
        left -= diff;
        elemTransformOrigin.horizontal += diff;
      }

      return {
        top: `${Math.round(top)}px`,
        left: `${Math.round(left)}px`,
        transformOrigin: getTransformOriginValue(elemTransformOrigin),
      };
    },
    [
      anchorEl,
      anchorReference,
      getAnchorOffset,
      getContentAnchorOffset,
      getTransformOrigin,
      marginThreshold,
    ],
  );

  const setPositioningStyles = React.useCallback(() => {
    const element = paperRef.current;

    if (!element) {
      return;
    }

    const positioning = getPositioningStyle(element);

    // element.style.visibility = 'hidden'

    if (positioning.top !== null) {
      element.style.top = positioning.top;
    }
    if (positioning.left !== null) {
      element.style.left = positioning.left;
    }
    element.style.transformOrigin = positioning.transformOrigin;

    element.style.visibility = 'visible';
  }, [getPositioningStyle]);

  const handlePaperRef = React.useCallback((instance) => {
    paperRef.current = ReactDOM.findDOMNode(instance) as HTMLElement;
  }, []);

  React.useEffect(() => {
    const handleResize = debounce(() => {
      setPositioningStyles();
    });

    if (open) {
      handleResize();
    }
  });

  React.useImperativeHandle(
    action,
    () =>
      (open
        ? {
            updatePosition: () => {
              setPositioningStyles();
            },
          }
        : null) as PopoverActions,
    [open, setPositioningStyles],
  );

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }

    const handleResize = debounce(() => {
      setPositioningStyles();
    });

    window.addEventListener('resize', handleResize);
    return () => {
      handleResize.clear();
      window.removeEventListener('resize', handleResize);
    };
  }, [open, setPositioningStyles]);

  const container =
    containerProp ||
    (anchorEl
      ? ownerDocument(getAnchorEl(anchorEl as HTMLElement) as Node).body
      : undefined);

  return (
    <Modal
      container={container}
      open={open}
      ref={ref}
      BackdropProps={{ invisible: true }}
      {...other}
    >
      <Paper ref={handlePaperRef}>{children}</Paper>
    </Modal>
  );
});

export default Popover;
