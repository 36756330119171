import { DateRangePicker, Grid, Typography } from "components/atoms";
import { DateRangePickerValue, customRange } from "components/atoms/DateRangePicker/DateRangePicker";
import { Section } from "components/molecules/Accordion/Accordion.const";
import { COMMON_OPTIONS, DATE_FORMAT_WITH_OUT_TIME } from "const";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTheme } from "styled-components";
import { DashboardData, DueHistoriesData } from "types/entities/dashboard-history-data";
import { dateRangeToString } from "utils";
import { fetchDashboardData, fetchDashboardDataDueHistories, getPieChartOptions } from "./DashboardTemplate.const";
import { CardsContainer, Paper, StyledCardItem } from "./DashboardTemplate.styles";

//TODO: move the styles and charts options to the const file
const DashboardTemplate = (
) => {

    const theme = useTheme();
    const [dashboardData, setDashboardData] = useState<DashboardData>()
    const [dueHistoriesData, setDueHistories] = useState<DueHistoriesData>()
    const [dateRangeValue, setDateRangeValue] = useState<DateRangePickerValue>({
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date()
    });
    const { totalHistories, totalPendingHistories, totalDueHistories, totalPaidHistories, projects, historiesPerProject, companies, historiesPerCompany, projectsToComeDue, historiesToComeDue, totalHistoriesToComeDue } = dashboardData || {};
    const { dateIntervals, dueHistories } = dueHistoriesData || {};


    const historyPerProjectPieOptions = getPieChartOptions({
        chartTitle: 'Facturas procesadas por proyecto',
        labels: projects || [],
        theme
    });
    const historyPerCompanyPieOptions = getPieChartOptions({
        chartTitle: 'Facturas procesadas por compañía',
        labels: companies || [],
        theme,
    });
    const projectstoComeDuePieOptions = getPieChartOptions({
        chartTitle: 'Facturas por vencerse por proyecto',
        labels: projectsToComeDue || [],
        theme,
    });

    //TODO: this function is related to the line chart, it is triggered when a marker is clicked and is intended to render something to expand details, should be moved to the const file
    const onDueMarkerClick = (e?: any) => {
        console.log(e);
    }

    //TODO: this options object is related to the line chart, should be moved to the const file
    const options2 = {
        chart: {
            events: {
                markerClick: function (event: any, chartContext: any, { seriesIndex, dataPointIndex }: { seriesIndex: number, dataPointIndex: number }) {
                    console.log('Marker clicked at series: ' + seriesIndex + ', dataPoint: ' + dataPointIndex + (dateIntervals ?? [])[dataPointIndex]);
                }
            }
        },
        xaxis: {
            categories: dateIntervals,
            labels: {
                style: {
                    colors: 'white', // Set the x-axis label color to white
                },
            },
            type: 'datetime' as const,
        },
        yaxis: [
            {
                seriesName: 'Facturas con pago oportuno',
                labels: {
                    style: {
                        colors: 'white', // Set the y-axis label color to white
                    },
                },
            },
        ],
        legend: {
            labels: {
                colors: 'white', // Set the legend label color to white
            },
            theme: 'dark',
        },
        tooltip: {
            theme: 'dark', // Set the tooltip theme to dark
        },
        title: {
            text: "Alertas de pago oportuno",
            align: 'left' as const,
            margin: 15,
            offsetX: 0,
            floating: false,
            style: {
                size: '30px',
                fontWeight: 'bold',
                color: 'white'
            },
        },
        markers: {
            onClick: onDueMarkerClick
        }
    };

    const renderFormat = (dateRange: DateRangePickerValue) => {
        const startDate = dateRange?.startDate
            ? format(
                new Date(dateRange?.startDate),
                DATE_FORMAT_WITH_OUT_TIME,
                COMMON_OPTIONS,
            )
            : '';
        const endDate = dateRange?.endDate
            ? format(
                new Date(dateRange?.endDate),
                DATE_FORMAT_WITH_OUT_TIME,
                COMMON_OPTIONS,
            )
            : '';

        return `${startDate} - ${endDate}`;
    };

    const onDateRangeChange = (
        value: customRange
    ) => {
        if (value.startDate && value.endDate) {
            setDateRangeValue({ startDate: value.startDate, endDate: value.endDate });
        }
    }

    useEffect(() => {
        const stringifiedDateRange = dateRangeToString(dateRangeValue);
        fetchDashboardData(stringifiedDateRange).then((response) => {
            setDashboardData(response.data);
        })
    }, [dateRangeValue])

    useEffect(() => {
        fetchDashboardDataDueHistories().then((response) => {
            const dates = response.data.dateIntervals.map((date) => {
                console.log(date);
                console.log(new Date(date).getTime());
                return new Date(date).getTime();
            })
            response.data.dateIntervals = dates;
            setDueHistories(response.data);
        })
    }, [])

    /**
     * En evolución, implemntación de acordeón para mostrar más gráficos?
     * 
     */
    const getDetailSections = () => {
        let sections: Section[] = [];

        sections.push(
            {
                header: 'Resumen',
                defaultOpen: true,
                content:
                    <>
                        {
                            (historiesPerCompany && historiesPerCompany.length && historiesPerProject && historiesPerProject.length ? <Grid container spacing={1} justify="space-around" alignItems="center" xs={12}>
                                <Grid item xs={4}>
                                    <Chart
                                        options={historyPerCompanyPieOptions}
                                        series={historiesPerCompany}
                                        type="pie"
                                        theme="dark" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Chart
                                        options={historyPerProjectPieOptions}
                                        series={historiesPerProject}
                                        type="pie"
                                        theme="dark" />
                                </Grid>
                                {(historiesToComeDue && historiesToComeDue.length) ?
                                    <Grid item xs={4}>
                                        <Chart
                                            options={projectstoComeDuePieOptions}
                                            series={historiesToComeDue}
                                            type="pie"
                                            theme="dark" />
                                    </Grid>
                                    : <></>}
                            </Grid>
                                :
                                <Grid item xs={12}>
                                    <Typography variant="h3" align="left">No hay datos para mostrar</Typography>
                                </Grid>
                            )

                        }

                    </>
            });
        /* 
                {
                    dueHistories && dueHistories.length &&
                    sections.push(
                        {
                            header: 'Alertas de pago oportuno',
                            content: <Grid item xs={4}>
                                {dueHistories && dueHistories.length ?
                                    <Chart
                                        options={options2}
                                        series={[{ name: 'Pago oportuno', data: dueHistories }]}
                                        type="line"
                                        theme="dark"
                                    /> : <Typography variant="h3" align="left">No hay datos para mostrar</Typography>}
                            </Grid>
                        }
                    );
                } */

        return sections;
    }

    return (
        <Paper>
            <Grid container spacing={2} justify="space-around" alignItems="center">
                <Grid item xs={9}>
                    <Typography variant="h2" align="left">{
                        `Información de facturas descargadas en: ${renderFormat(dateRangeValue)}`}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <DateRangePicker
                        required
                        label="Rango de fechas"
                        fullWidth
                        onChange={onDateRangeChange}
                        value={dateRangeValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CardsContainer>
                        <StyledCardItem>
                            <Typography variant="h3" align="center">Procesadas</Typography>
                            <Typography variant="p" align="center">{totalHistories}</Typography>
                        </StyledCardItem>
                        <StyledCardItem>
                            <Typography variant="h3" align="center">Pagadas</Typography>
                            <Typography variant="p" align="center">{totalPaidHistories ?? 0}</Typography>
                        </StyledCardItem>
                        <StyledCardItem>
                            <Typography variant="h3" align="center">Pendientes por pagar</Typography>
                            <Typography variant="p" align="center">{totalPendingHistories}</Typography>
                        </StyledCardItem>
                        <StyledCardItem>
                            <Typography variant="h3" align="center">Por vencerse</Typography>
                            <Typography variant="p" align="center">{totalHistoriesToComeDue}</Typography>
                        </StyledCardItem>
                        {/* <StyledCardItem>
                            <Typography variant="h3" align="center">De pago oportuno</Typography>
                            <Typography variant="p" align="center">{totalDueHistories}</Typography>
                    </StyledCardItem>*/}
                    </CardsContainer>
                </Grid>
                <Grid container spacing={1} justify="flex-start" alignItems="center" xs={12}>
                    {/*             <Accordion
                        sections={getDetailSections()}
                    /> */}
                    {
                        (historiesPerCompany && historiesPerCompany.length && historiesPerProject && historiesPerProject.length ? <Grid container spacing={1} justify="space-around" alignItems="center" xs={12}>
                            <Grid item xs={4}>
                                <Chart
                                    options={historyPerCompanyPieOptions}
                                    series={historiesPerCompany}
                                    type="pie"
                                    theme="dark" />
                            </Grid>
                            <Grid item xs={4}>
                                <Chart
                                    options={historyPerProjectPieOptions}
                                    series={historiesPerProject}
                                    type="pie"
                                    theme="dark" />
                            </Grid>
                            {(historiesToComeDue && historiesToComeDue.length) ?
                                <Grid item xs={4}>
                                    <Chart
                                        options={projectstoComeDuePieOptions}
                                        series={historiesToComeDue}
                                        type="pie"
                                        theme="dark" />
                                </Grid>
                                : <></>}
                        </Grid>
                            :
                            <Grid item xs={12}>
                                <Typography variant="h3" align="left">No hay datos para mostrar</Typography>
                            </Grid>
                        )

                    }
                </Grid>
            </Grid>
        </Paper>
    );
};


export default DashboardTemplate;