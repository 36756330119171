import styled from 'styled-components';
import { darken, invertColor } from 'utils';

export type ContentProps = {
  $open?: boolean;
  $position:
    | 'top start'
    | 'top end'
    | 'top'
    | 'bottom start'
    | 'bottom end'
    | 'bottom'
    | 'left start'
    | 'left end'
    | 'left'
    | 'right start'
    | 'right end'
    | 'right';
};

export const Content = styled.div<ContentProps>`
  user-select: none;
  pointer-events: none;
  box-sizing: border-box;
  visibility: hidden;
  background-color: ${({ theme }) => darken(theme.palette.grey, 0)};
  color: ${({ theme }) =>
    invertColor(darken(theme.palette.grey, 0), true)} !important;
  text-align: center;
  padding: ${({ theme }) =>
    `${theme.shape.spacing(1)} ${theme.shape.spacing(2)}`};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  ${({ $position, theme }) =>
    $position === 'top start' &&
    `
    left: 0;
    top: -${theme.shape.spacing(1)};
    transform: translate(0%, -100%);
  `}

  ${({ $position, theme }) =>
    $position === 'top' &&
    `
    left: 0;
    top: -${theme.shape.spacing(1)};
    transform: translate(-50%, -100%);
  `}

  ${({ $position, theme }) =>
    $position === 'top end' &&
    `
    left: 0;
    top: -${theme.shape.spacing(1)};
    transform: translate(100%, -100%);
  `}

  ${({ $position, theme }) =>
    $position === 'bottom start' &&
    `
    left: 0;
    bottom: -${theme.shape.spacing(1)};
    transform: translate(0%, 100%);
  `}

  ${({ $position, theme }) =>
    $position === 'bottom' &&
    `
    left: 0;
    bottom: -${theme.shape.spacing(1)};
    transform: translate(50%, 100%);
  `}

  ${({ $position, theme }) =>
    $position === 'bottom end' &&
    `
    left: 0;
    bottom: -${theme.shape.spacing(1)};
    transform: translate(100%, 100%);
  `}

  ${({ $position, theme }) =>
    $position === 'left start' &&
    `
    transform: translate(-100%, 0%);
    top: 0;
    left: -${theme.shape.spacing(1)};
  `}

  ${({ $position, theme }) =>
    $position === 'left' &&
    `
    transform: translate(-100%, -50%);
    top: 50%;
    left: -${theme.shape.spacing(1)};
  `}

  ${({ $position, theme }) =>
    $position === 'left end' &&
    `
    transform: translate(-100%, -100%);
    top: 100%;
    left: -${theme.shape.spacing(1)};
  `}

  ${({ $position, theme }) =>
    $position === 'right start' &&
    `
    transform: translate(100%, 0%);
    top: 0;
    right: -${theme.shape.spacing(1)};
  `}

  ${({ $position, theme }) =>
    $position === 'right' &&
    `
    transform: translate(100%, -50%);
    top: 50%;
    right: -${theme.shape.spacing(1)};
  `}

  ${({ $position, theme }) =>
    $position === 'right end' &&
    `
    transform: translate(100%, -100%);
    top: 100%;
    right: -${theme.shape.spacing(1)};
  `}
`;

export const Root = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    & > ${Content} {
      visibility: visible;
    }
  }
`;
