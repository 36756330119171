import styled from 'styled-components';
import { darken, lighten } from 'utils';

export const Paper = styled.div`
  position: absolute;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 16px;
  min-height: 16px;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  outline: 0;
  padding: ${({ theme }) => theme.shape.spacing(2)};
  background-color: ${({ theme }) =>
    theme.isDarkMode
      ? darken(theme.palette.background, 0.2)
      : lighten(theme.palette.background, 0.2)};
  color: ${({ theme }) => lighten(theme.palette.grey, 0.5)};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode
      ? 'none'
      : ' rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px'};
`;
