import { Button, Grid, Typography } from 'components/atoms';
import { MouseEventHandler, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DocumentExtraDataJson } from 'types';
import { errorsMap, inputComponent, regExp } from './ValidateData.const';
import { Root } from './ValidateData.styles';

export type ValidateDataProps = {
  onConfirm: (data: any) => void;
  onReject: MouseEventHandler<HTMLButtonElement>;
  input: DocumentExtraDataJson['ocr'];
  loading?: boolean;
};

const ValidateData = ({
  onConfirm,
  onReject,
  input,
  loading,
}: ValidateDataProps) => {
  const inputs = useMemo(() => Object.keys(input), [input]);

  const defaultValues = useMemo(
    () =>
      inputs.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]:
            input[curr as keyof typeof input]?.viewValue &&
            !input[curr as keyof typeof input].mustFix
              ? input[curr as keyof typeof input].viewValue
              : input[curr as keyof typeof input].value || '',
        }),
        {},
      ),
    [inputs, input],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Record<string, string>>({
    reValidateMode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const haveInputPerValidate =
    inputs.filter((key) => input[key].mustFix).length === 0;

  return (
    <Root>
      <form
        noValidate
        onSubmit={handleSubmit(onConfirm)}
        style={{ flexGrow: 1 }}
      >
        <Grid container spacing={5} justify="flex-end">
          <Grid item xs={12}>
            <Typography
              variant="h2"
              align={haveInputPerValidate ? 'left' : 'center'}
            >
              {haveInputPerValidate
                ? '¿Desea validar el documento?'
                : 'Valide los datos del documento'}
            </Typography>
          </Grid>
          {inputs
            .filter((key) => input[key].mustFix)
            .map((key) => {
              const inputItem = input[key as keyof typeof input];
              const Component =
                inputComponent[inputItem.type as keyof typeof inputComponent];

              return (
                <Grid item xs={12} key={key}>
                  <Controller
                    control={control}
                    name={key}
                    rules={{
                      required: 'Campo requerido',
                      pattern: {
                        value: regExp[
                          inputItem.type as keyof typeof regExp
                        ] as RegExp,
                        message: errorsMap[
                          inputItem.type as keyof typeof regExp
                        ] as string,
                      },
                    }}
                    render={({ field }) => (
                      <Component
                        fullWidth
                        label={inputItem.label}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors[key]}
                        helperText={errors[key] && errors[key]?.message}
                      />
                    )}
                  />
                </Grid>
              );
            })}
          <Grid item>
            <Button
              variant="contained"
              color="grey"
              type="button"
              loading={loading}
              disabled={loading}
              onClick={onReject}
            >
              Rechazar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
              disabled={loading}
            >
              Validar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Root>
  );
};

export default ValidateData;
