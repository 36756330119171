import styled, { css } from 'styled-components';
import Button from '../Button';

export type RootButtonProps = {
  $positionEdge?: 'start' | 'end';
  $edge: number;
};

export const RootButton = styled(Button)<RootButtonProps>`
  padding: ${({ theme }) => theme.shape.spacing(1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  ${({ theme, $positionEdge, $edge }) =>
    $positionEdge === 'start' &&
    css`
      margin-left: ${theme.shape.spacing($edge)};
    `};
  ${({ theme, $positionEdge, $edge }) =>
    $positionEdge === 'end' &&
    css`
      margin-right: ${theme.shape.spacing($edge)};
    `};
`;
