import { GET_COMPANIES } from 'const';
import { Project } from '../../../../../types';

export const areaFormatter = (area: string): string => {
  switch (area) {
    case 'UNIDAD':
      return 'Unidad';
    case 'OBRA':
      return 'Obra';
    case 'SALA_VENTA':
      return 'Sala de venta';
    default:
      return 'UNIDAD';
  }
};

export const getProjectsNamesByIds = (
  projectIds: string,
  projects: Project[],
): string => {
  return projects
    .filter((p) => projectIds.split(',').includes(p.id.toString()))
    .map((p) => p.name)
    .join(', ');
};

export const httpErrorMessageFormatter = (error: string) => {
  let errorMessage = error.toString();

  if (errorMessage.includes('400') || errorMessage.includes('bad request')) {
    errorMessage = errorMessage.replace('400.', '').replace('Bad Request', '');
  }

  return errorMessage.trim();
};

export const states = [
  { value: 'ACTIVO', name: 'Activo' },
  { value: 'INACTIVO', name: 'Inactivo' },
];

export const areas = [
  { value: 'UNIDAD', name: 'Unidad' },
  { value: 'OBRA', name: 'Obra' },
  { value: 'SALA_VENTA', name: 'Sala de venta' },
];

export const GET_ENABLED_COMPANIES_PATH: string = GET_COMPANIES.concat(
  `?filter=[{%22field%22:%22status%22,%22value%22:%22true%22,%22type%22:%22equals%22}]`,
);
