import styled from 'styled-components';

export const Root = styled.div<{ $open?: boolean }>`
  color: inherit;
  width: 100%;
  box-sizing: border-box;
  background-color: #00000011;
  font: inherit;
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  max-height: ${({ $open }) => ($open ? '1000px' : '0px')};
  overflow-y: auto;
  transition: all 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;
