import {
  Button,
  Grid,
  Typography
} from 'components/atoms';
import { useState } from 'react';
import { updateGroupings } from '../ListGroupingTemplate/ListGroupingTemplate.const';
import GroupingTemplateApiLoading from '../Shared/GroupingTemplate.loading';
import { GroupingConfirmationTemplateProps } from './GroupingConfirmationTemplate.types';


/**
 * Este componente implementa el modal para la confirmación de activación de inmuebles
 */
const GroupingConfirmationTemplate = (
  {
    setOpen,
    onClose,
    setErrorMessage,
    groupingList,
    setModifiedGroupings,
    setActionSnackBar,
    filterModel,
    setFilterModel,
    isFiltering,
    currentFilterModel,
    ...props
  }: GroupingConfirmationTemplateProps
) => {

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const onBatchUpdate = async () => {
    setIsUpdating(true);
    await updateGroupings({
      groupings: groupingList,
      setModifiedGroupings,
      setOpen,
      setErrorMessage,
      setActionSnackBar,
      setIsUpdating
    });
    setIsUpdating(false);
    setOpen('success');
    setActionSnackBar('activate');
  }
  const onUpdate = async () => {
    await onBatchUpdate();
    setFilterModel([...currentFilterModel]);
    onClose();
  }

  const onDiscard = () => {
    try {
      setModifiedGroupings([])
      setFilterModel([...currentFilterModel]);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getConfirmationText = () => {
    let isFilteringMessage: string = "";
    if (isFiltering) {
      isFilteringMessage = "Antes de filtrar los inmuebles ";
    }
    if (groupingList.length === 1) {
      return `${isFilteringMessage}¿Deseas actualizar el inmueble ${groupingList[0].name}?`
    }
    else {
      return `${isFilteringMessage}¿Deseas actualizar los ${groupingList.length} inmuebles seleccionados?`
    }
  }

  return (
    <>
      {!isUpdating ?
        <Grid container spacing={3} justify="center">
          <Grid item>
            <Typography variant='h2'>
              {getConfirmationText()}
            </Typography>

          </Grid>
          <Grid container spacing={3} justify="flex-end"
            alignItems='center'>
            <Grid item>
              <Button
                info="Actualizar inmuebles"
                color="primary"
                variant="contained"
                onClick={() => onUpdate()}
              >
                Actualizar
              </Button>
            </Grid>
            <Grid item>
              <Button
                info="Descartar cambios"
                color="primary"
                variant="contained"
                onClick={() => onDiscard()}
              >
                Descartar cambios
              </Button>
            </Grid>
          </Grid>
        </Grid>
        : <GroupingTemplateApiLoading />
      }
    </>
  );
};

export default GroupingConfirmationTemplate;