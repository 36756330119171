import styled from 'styled-components';
import { darken } from 'utils';

const switchSizes = {
  sm: {
    width: '2.2em',
    height: '0.625em',
    knobSize: '1.25em',
    knobLeft: '0em',
    xTransform: '1.6em',
  },
  md: {
    width: '2.5em',
    height: '0.9375em',
    knobSize: '1.5625em',
    knobLeft: '0em',
    xTransform: '1.5625em',
  },
  lg: {
    width: '3.75em',
    height: '1.25em',
    knobSize: '1.875em',
    knobLeft: '-0.3125em',
    xTransform: '2.8125em',
  },
};
export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Switch = styled.div<{ size?: 'sm' | 'md' | 'lg' }>`
  margin: 0em 0.7em;
  position: relative;
  width: ${({ size }) => switchSizes[size || 'md'].width};
  height: ${({ size }) => switchSizes[size || 'md'].height};
  background: ${({ theme }) => theme.palette.divider};
  border-radius: 35px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: ${({ size }) => switchSizes[size || 'md'].knobSize};
    height: ${({ size }) => switchSizes[size || 'md'].knobSize};
    border-radius: 35px;
    top: 50%;
    left: ${({ size }) => switchSizes[size || 'md'].knobLeft};
    background: ${({ theme }) =>
      theme.isDarkMode
        ? 'currentColor'
        : darken(theme.palette.background, 0.3)};
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input<{ $size?: 'sm' | 'md' | 'lg' }>`
  display: none;

  &:checked + ${Switch} {
    background: ${({ theme }) => theme.palette.primary};

    &:before {
      transform: translate(
        ${({ $size }) => switchSizes[$size || 'md'].xTransform},
        -50%
      );
    }
  }
`;
