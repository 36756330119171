import { Grid } from 'components/atoms';
import styled from 'styled-components';
import { lighten } from 'utils';

export const Root = styled.div`
  display: flex;
  width: calc(100% - 20px);
  align-self: center;
  justify-content: space-around;
  flex-flow: column;
  padding: 10px;
  flex: 1;
`;

export const Container = styled(Grid)`
  padding: 10px;
  margin-bottom: 10px;
  background: ${({ theme }) =>
    theme.isDarkMode ? lighten(theme.palette.background, 0.09) : 'transparent'};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;
