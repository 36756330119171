import { Grid } from 'components/atoms';
import Zoom from 'react-medium-image-zoom';
import styled from 'styled-components';

export const ImgScreenshot = styled('img')`
  margin: auto;
  height: 300px;
`;
export const StyledZoom = styled(Zoom)`
  margin: auto;
  height: 300px;
`;

export const ScrollableGrid = styled(Grid)`
  overflow-y: auto;
  max-height: 50vh;

  
//Track properties
5;&::-webkit-scrollbar {
  width: 0.5em;
}
`;
