import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { ListCompanyTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const ListCompanyPage = () => {
  return (
    <>
      <Header
        title="Compañías"
        subtitle="Crea, edita, lista y elimina compañías"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListCompanyTemplate />
    </>
  );
}

export default withAuth(ListCompanyPage);