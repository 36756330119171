import { useAuth } from 'hooks';
import { FC, useCallback, useState } from 'react';

import { ThemeProvider as SCThemeProvider } from 'styled-components';

import { defaultTheme, GlobalStyle, palettes } from 'theme';
import { themeType } from 'types';
import { loadState, saveState } from 'utils';

import ThemeContext from './ThemeContext';

const ThemeProvider: FC = ({ children }) => {
  const { menu: menus } = useAuth();
  const localTheme = loadState<themeType>('theme');
  const localMenu =
    loadState<boolean>('menu') || loadState<boolean>('menu') === undefined;
  const menuIsOpen = menus?.length && localMenu;

  const [mode, setMode] = useState<themeType>(localTheme || 'dark');
  const [menu, setMenu] = useState<boolean>(menuIsOpen || false);

  const changeTheme = useCallback((newTheme: themeType) => {
    setMode(newTheme);
    saveState('theme', newTheme);
  }, []);

  const toggleTheme = useCallback(() => {
    setMode((theme) => {
      const newTheme = theme === 'dark' ? 'light' : 'dark';
      saveState('theme', newTheme);
      return newTheme;
    });
  }, []);

  const toggleMenu = useCallback(() => {
    setMenu((menu) => {
      const newMenu = !menu;
      saveState('menu', newMenu);
      return newMenu;
    });
  }, []);

  const context = {
    theme: {
      ...defaultTheme,
      isDarkMode: mode === 'dark',
      palette: palettes[mode],
      mode,
    },
    menuIsOpen: menu,
    changeTheme,
    toggleTheme,
    toggleMenu,
  };

  return (
    <ThemeContext.Provider value={context}>
      <SCThemeProvider theme={context.theme}>
        <GlobalStyle />
        {children}
      </SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
