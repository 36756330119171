import { boolean, object, string, TypeOf } from 'yup';

export const LoginFormSchema = object({
  username: string().email().required(),
  password: string().required(),
  remember: boolean(),
  error: string(),
});

export type LoginFormType = TypeOf<typeof LoginFormSchema>;
