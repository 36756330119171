import ReactDOM from 'react-dom';

export const ownerDocument = (node: Node) =>
  (node && node.ownerDocument) || document;

export const ownerWindow = (node: Node) => {
  const doc = ownerDocument(node);
  return doc.defaultView || window;
};

export function getContainer(
  container: React.ReactInstance | (() => React.ReactInstance | null) | null,
) {
  container = typeof container === 'function' ? container() : container;
  return ReactDOM.findDOMNode(container) as Element;
}
