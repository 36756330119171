import { Menu } from 'types';

import CustomLink from './CustomLink';
import ListLink from './ListLink';

type ReduceChildRoutesProps = {
  page: Menu;
  deep: number;
  items: JSX.Element[];
};

const reduceChildRoutes = ({ items, page, deep }: ReduceChildRoutesProps) => {
  if (page.children && page.children.length > 0) {
    items.push(
      <CustomLink
        deep={deep}
        key={page.title}
        icon={page.icon}
        label={page.title}
        to={page.link}
      >
        <ListLink pages={page.children} deep={deep + 1} />
      </CustomLink>,
    );
  } else {
    items.push(
      <CustomLink
        deep={deep}
        key={page.title}
        to={page.link}
        icon={page.icon}
        label={page.title}
      />,
    );
  }

  return items;
};

export default reduceChildRoutes;
