export const randomPassword = (length?: number) => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+-*~@{}[]/&%$#()=|!¡¿?';
  const default_: string = '';

  return Array(length || 8)
    .fill(default_)
    .reduce<string>(
      (acc) => acc + charset.charAt(Math.floor(Math.random() * charset.length)),
      default_,
    );
};
