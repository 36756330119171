import { date, object, string, TypeOf } from 'yup';

export const ProcessTemplateFilterSchema = object({
  package_: string(),
  status: string(),
  dateRange: object({
    startDate: date().required(),
    endDate: date().required(),
  }).required(),
});

export type ProcessTemplateFilterType = TypeOf<
  typeof ProcessTemplateFilterSchema
>;
