import axios, { AxiosRequestConfig } from "axios";
import { startOfMonth } from "date-fns";
import { filterModelType } from "hooks/useTable";
import { DOWNLOAD_INVOICES_COMBINED, DOWNLOAD_INVOICES_ZIP, GET_SERVICE_TYPES, getCurrentTimeToString } from "../../../../../const";
import { FlatHistory, ServiceType } from "../../../../../types";
import { HistoryDownloadTemplateFilterType } from "./HistoryDownloadTemplate.schema";

export type HistoryDownloadFilter = {
  project: string;
  area: string;
  publicServices: string;
}

export type HistoryDownloadAPIFilter = {
  projectIds?: string[];
  area?: string;
  publicServicesIds?: string[];
  dateRange?: {
    startDate: Date | undefined,
    endDate: Date | undefined
  }
}

export const getDefaultHistoryDownloadFilter: () => HistoryDownloadTemplateFilterType = () => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);

  return {
    project: "",
    area: "",
    publicServices: "",
    dateRange: {
      startDate: startOfMonth(startDate),
      endDate,
    },
    invoiceNumber: "",
  };
}

export const fetchServiceTypes = (_: any, config?: AxiosRequestConfig<ServiceType[]>) => {
  return axios.get<ServiceType[]>(GET_SERVICE_TYPES, config)
};

export const getFileNameByFileType = (fileType: string) => {
  let fileName: string = `facturas_${getCurrentTimeToString()}`
  switch (fileType) {
    case 'pdf':
      return `${fileName}.pdf`;
    case 'zip':
      return `${fileName}.zip`;
    default:
      return `${fileName}.pdf`;
  }
};

export const downloadPdf = (fileType: string, filterModel: filterModelType<FlatHistory>[] | undefined) => {
  if (fileType === 'pdf') {
    return axios.get(
      `${DOWNLOAD_INVOICES_COMBINED}${filterModel && filterModel.length > 0
        ? `?filter=${JSON.stringify(filterModel)}&`
        : ''
      }`,
      {
        responseType: "blob",
      }
    );
  }
  return axios.get(
    `${DOWNLOAD_INVOICES_ZIP}${filterModel && filterModel.length > 0
      ? `?filter=${JSON.stringify(filterModel)}&`
      : ''
    }`,
    {
      responseType: "blob",
    }
  );
};
