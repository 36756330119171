import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { HistoryTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const HistoryPage = () => {
  return (
    <>
      <Header
        title="Históricos de pagos"
        subtitle="Lista y gestiona registros históricos de pagos"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <HistoryTemplate />
    </>
  );
}

export default withAuth(HistoryPage);