import { CustomError } from '../types';

/**
 * Función para obtener el mensaje de detalle de
 * errores http emitidos por el backend
 * @param error: CustomError
 * @returns
 */
export const getHttpErrorDetail = (error: CustomError) => {
  if (
    error.error.includes(
      'QueryFailedError: duplicate key value violates unique constraint "unique_grouping_company_service"',
    )
  ) {
    return 'Inmuebles duplicados. '.concat(error.error.split(' columna')[0]);
  }
  if (
    error.error.includes("(reading 'toUpperCase')") ||
    error.error.includes('not-null')
  ) {
    return 'Los campos de proyecto y nombre del inmueble no pueden ser vacíos';
  }
  if (error.error.includes('no such file or directory')) {
    return 'No se encuentra el archivo solicitado';
  }
  if (error.error.includes('HttpException:')) {
    return error.error.split('DETALLE: ')[1].replace('HttpException', 'Error');
  }
  return error.error.split('DETALLE: ')[1];
};
