import React from 'react';
import ReactDOM from 'react-dom';
import Modal, { ModalProps } from '../Modal';
import {
  DialogRoot,
  PaperProps,
  Paper,
  Header,
  Title,
  CloseIcon,
} from './Dialog.styles';

export type DialogProps = {
  children?: React.ReactNode;
  onClose?: ModalProps['onClose'];
  maxWidth?: PaperProps['$maxWidth'];
  open: boolean;
  title?: React.ReactNode;
} & React.HTMLAttributes<HTMLElement> &
  ModalProps;

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(function Dialog(
  props,
  ref,
) {
  const { children, open, title, maxWidth = 'sm', onClose, ...other } = props;

  const paperRef = React.useRef<HTMLElement>();

  const handlePaperRef = React.useCallback((instance) => {
    paperRef.current = ReactDOM.findDOMNode(instance) as HTMLElement;
  }, []);

  return (
    <Modal open={open} ref={ref} onClose={onClose} {...other}>
      <DialogRoot>
        <Paper ref={handlePaperRef} $maxWidth={maxWidth}>
          <Header $margin={!!title}>
            {title && typeof title === 'string' ? (
              <Title variant="h2">{title}</Title>
            ) : (
              title
            )}
            <CloseIcon
              size="23"
              onClick={(e) => onClose && onClose(e, 'iconCloseClick')}
            />
          </Header>
          {children}
        </Paper>
      </DialogRoot>
    </Modal>
  );
});

export default Dialog;
