import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_ROLES } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineCheck, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { PaginationType, Role } from 'types';
import { createPayload } from 'utils';
import { ListRoleTemplateFilterType } from './ListRoleTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Role> = {
  field: 'id',
  order: 'ASC',
};

export const defaultListRoleTemplateFilter: ListRoleTemplateFilterType = {
  name: undefined,
};

export const getColumns: (
  onDelete: (id: number) => void,
  onActive: (id: number) => void,
) => columnType<Role>[] = (onDelete, onActive) => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '4',
    exportField: true,
  },
  {
    field: 'code',
    headerName: 'Código',
    flex: '5',
    exportField: true,
  },
  {
    field: 'enabled',
    headerName: 'Estado',
    flex: '1',
    // disabledSort: true,
    render: ({ enabled }) => (enabled ? 'Activo' : 'Inactivo'),
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    // align: "flex-end",
    flex: '2',
    disabledSort: true,
    render: ({ id, enabled }) => (
      <>
        <IconButton
          info={enabled ? 'Desactivar role' : 'Activar role'}
          color={enabled ? 'success' : 'grey'}
          onClick={() => onActive(id)}
        >
          <AiOutlineCheck />
        </IconButton>
        <IconButton
          info="Editar role"
          positionEdge="start"
          color="grey"
          href={`/pages/management/role/${id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>
        <IconButton
          info="Eliminar role"
          positionEdge="start"
          color="error"
          onClick={() => onDelete(id)}
        >
          <AiOutlineDelete />
        </IconButton>
      </>
    ),
  },
];

export const fetchProcess = (
  pagination: tableModelType<Role>,
  config?: AxiosRequestConfig<Role>,
) => {
  return axios.get<PaginationType<Role>>(
    `${GET_ROLES}${createPayload(pagination)}`,
    config,
  );
};
