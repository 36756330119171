import React from 'react';

type useControlledParams<T> = {
  controlled?: T;
  default?: T;
};

const useControlled = <T = any>({
  controlled,
  default: defaultProp,
}: useControlledParams<T>) => {
  const { current: isControlled } = React.useRef(controlled !== undefined);
  const [valueState, setValue] = React.useState<T | undefined>(defaultProp);
  const value = isControlled ? controlled : valueState;

  const setValueIfUncontrolled = React.useCallback(
    (newValue?: T) => {
      if (!isControlled) {
        setValue(newValue);
      }
    },
    [isControlled],
  );

  return [value, setValueIfUncontrolled] as [T, (newValue?: T) => void];
};

export default useControlled;
