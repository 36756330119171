import {
  Grid,
  Paper,
  Spinner,
  Typography
} from 'components/atoms';
import Accordion from 'components/molecules/Accordion';
import { BasicTable, Svg, TableCell, TableRow } from 'components/organisms';
import { Grow, TableContainer, TableHead } from 'components/organisms/Table/Table.styles';
import 'moment/locale/es';
import { useMemo, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Service } from 'types';
import {
  getColumns
} from './ServiceDetailTemplate.const';
import { SeviceDetailTemplateProps } from './ServiceDetailTemplate.types';

const ServiceDetailTemplate = ({ ...props }: SeviceDetailTemplateProps) => {

  const ref = useRef<HTMLTableElement>(null);

  const { service } = props;

  const columns = useMemo(
    () => getColumns(),
    [],
  );

  return (
    (service ? <Paper>
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={3}
      >
        <Grid item xl={12} style={{ width: '100%' }} >
          <TableContainer $height={1} >
            <TableHead as="div">
              <TableRow as="div">
                {columns.map(
                  (
                    { headerName, field, flex },
                    idx,
                  ) => (
                    <TableCell
                      as="div"
                      key={`head.${field}.${headerName}`}
                      $flex={flex}
                    >
                      {headerName}
                      <Grow />
                      {columns.length - 1 > idx && (
                        <Svg
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="SeparatorIcon"
                        >
                          <path d="M11 19V5h2v14z"></path>
                        </Svg>
                      )}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <PerfectScrollbar>
              <BasicTable<Service>
                numberOfVisibleColumns={columns.length}
                ref={ref}
                columns={columns}
                rows={[service]}
                rowsPerPage={1}
              />
            </PerfectScrollbar>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={4}
      >
        {service.detailError ? <Accordion
          sections={[
            {
              header: 'Detalle de error',
              content: <Typography variant='p'>{service.detailError}</Typography>
            }
          ]}
        /> : null}
      </Grid>
    </Paper>
      : <Spinner></Spinner>)
  );
};

export default ServiceDetailTemplate;
