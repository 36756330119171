/* eslint-disable no-console */
import { useCustomerContext } from 'contexts';
import { useAuth } from 'hooks';
import { ComponentType } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Auth, customerType } from 'types';
import { loadState } from 'utils';

function withAuth<T extends JSX.IntrinsicAttributes>(
  Component: ComponentType<T>,
  roles?: string[],
  customer?: customerType,
  // redirect?: To,
) {
  return (props: T) => {
    const { user, roles: userRoles } = useAuth();
    const { name } = useCustomerContext();
    const auth = loadState<Auth>('auth');
    const location = useLocation();

    const shouldRedirect =
      !user ||
      !auth ||
      (roles && !userRoles?.some((role) => roles.includes(role))) ||
      (customer && name !== customer);

    if (shouldRedirect)
      return <Navigate to="/login" state={{ from: location }} replace />;

    return <Component {...props} />;
  };
}

export default withAuth;
