import { Header } from 'components/molecules';
import { ListTaskTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administración' }];

const ListRolePage = () => {
  return (
    <>
      <Header
        title="Tasks"
        subtitle="Crea, edita, lista y elimina Task"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListTaskTemplate />
    </>
  );
};

export default withAuth(ListRolePage);
