import axios, { AxiosRequestConfig } from 'axios';
import { GET_GROUPING, UPDATE_GROUPING } from 'const';
import { Grouping } from 'types';

export const defaultData = {
  projectName: '-',
  name: '-',
  area: '-',
  status: '-',
  companyServiceGroupings: [
    {
      reference: '-',
      companyServiceCode: '',
    },
  ],
};

export type updateGroupingRequestBody = {
  name?: string;
  projectName?: string;
  area?: string;
};

//TODO: remove the ids when id is falsy
export const updateGrouping = (id: any, body: updateGroupingRequestBody) => {
  return axios.put<Grouping>(UPDATE_GROUPING.replace(':id', id), {
    ...body,
  });
};

export const fetchGrouping = (
  { id }: { id: string },
  config?: AxiosRequestConfig<Grouping[]>,
) => {
  return axios.get<Grouping>(GET_GROUPING.replace(':id', id), config);
};
