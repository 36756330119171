import { Header } from 'components/molecules';
import { VerificationTemplate } from 'components/templates';
import { withAuth } from 'HOCs';
import { useBreadcrumbs } from 'hooks';
import { useParams } from 'react-router-dom';

const TransactionPageVerification = () => {
  const { id } = useParams();
  const [breadcrumbs, title] = useBreadcrumbs();

  return (
    <>
      <Header
        title={title}
        subtitle={`Corrige las ${breadcrumbs[0].toLocaleLowerCase()}`}
        breadCrumbsProps={{
          breadcrumbs,
        }}
      />
      <VerificationTemplate key={id} id={id as string} />
    </>
  );
};

export default withAuth(TransactionPageVerification);
