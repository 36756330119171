import axios from 'axios';
import { UPDATE_AM } from 'const';
import { AutomationManager } from 'types';
import { EditAMType } from './EditAMTemplate.schema';

export const defaultAM = {
  logPaths: [
    {
      path: 'C:/Users/Administrator/AppData/Local/UiPath/Logs',
      description: 'Ruta de logs de UiPath',
    },
  ],
};

export const defaultData = {
  name: '-',
  url: '-',
};

export const updateAM = (id: any, body: EditAMType) => {
  return axios.put<AutomationManager>(UPDATE_AM.replace(':id', id), {
    ...body,
  });
};
