import { HiArrowSmDown, HiArrowSmUp } from 'react-icons/hi';

import { orderType } from '../../Table.types';
import { Button } from './SortTable.styles';

export type SortTableProps = {
  sort?: orderType;
  disabled?: boolean;
  onSortModel: (sort?: orderType) => void;
};

const SortTable = ({ sort, onSortModel }: SortTableProps) => {
  return (
    <>
      {sort === undefined && (
        <Button
          // info={{ position: "left", message: "Ascendente" }}
          $representation
          onClick={() => onSortModel('ASC')}
        >
          <HiArrowSmUp size="18" style={{ opacity: 0.3 }} />
        </Button>
      )}
      {sort === 'ASC' && (
        <Button
          // info={{ position: "left", message: "Desendente" }}
          onClick={() => onSortModel('DESC')}
        >
          <HiArrowSmUp size="18" />
        </Button>
      )}
      {sort === 'DESC' && (
        <Button
          // info={{ position: "left", message: "Quitar ordenamiento" }}
          onClick={() => onSortModel()}
        >
          <HiArrowSmDown size="18" />
        </Button>
      )}
    </>
  );
};

export default SortTable;
