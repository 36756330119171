import React from 'react';

import { Root } from './Backdrop.styles';

export type BackdropProps = {
  invisible?: boolean;
  open?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const SimpleBackdrop = React.forwardRef<HTMLDivElement, BackdropProps>(
  function SimpleBackdrop(props, ref) {
    const { invisible = false, open, ...other } = props;

    return open ? (
      <Root aria-hidden ref={ref} $invisible={invisible} {...other} />
    ) : null;
  },
);

export default SimpleBackdrop;
