import { FileRejection } from 'react-dropzone';
import { Typography } from '../../../../atoms';


export const rejectedFilesMessage = (
  rejectedFiles: FileRejection[],
  hasTypeError: (rejectedFiles: FileRejection[], errorCode: string) => boolean,
) => {
  if (rejectedFiles?.length > 1) {
    return <Typography
      key="one-file-limit"
      variant="h3"
      align="center"
      color="warning">
      Carga un archivo a la vez
    </Typography>
  }

  if (hasTypeError(rejectedFiles, "file-invalid-type")) {
    return rejectedFiles.map(file => (
      <Typography
        key="file-invalid-type"
        variant="h3"
        align="center"
        color="warning" >
        Debe ser un archivo excel con extensión .xlsx o .xls
      </Typography>
    ))
  }
}

export const zoneStyle = (isDragActive: boolean) => ({
  border: '2px dashed white',
  borderRadius: '8px',
  padding: '20px',
  margin: '20px',
  ...(isDragActive && { backgroundColor: '#323541' }),
});