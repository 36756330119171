import {
  Button,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography
} from 'components/atoms';
import { useEffect, useState } from 'react';
import { Grouping } from 'types';
import { AREAS, AreaValue } from '../../ReportsTemplate/Shared/sharedConsts';
import { GROUPING_STATUS, updateModifiedGroupings } from '../ListGroupingTemplate/ListGroupingTemplate.const';
import { ActivateGroupingTemplateProps } from './ActivateGroupingTemplate.types';
import { CustomPaper } from './ActivationGroupingStyles';


/**
 * Este componente implementa el modal para asignar un área
 * a un listado de inmuebles que se quieran activar y no tengan área
 */
const ActivateGroupingTemplate = (
  {
    setOpen,
    onClose,
    setErrorMessage,
    groupingList,
    pageGroupingList,
    selectedGroupings,
    setModifiedGroupings,
    setStatusToggle,
    isGlobal,
    ...props
  }: ActivateGroupingTemplateProps
) => {

  const [areas, setAreas] = useState<Record<string, AreaValue | undefined>>((selectedGroupings ?? []).reduce(
    (acc: Record<string, AreaValue | undefined>, grouping) => {
      return { ...acc, [grouping.id]: "" };
    }, {}));
  const [globalArea, setglobalArea] = useState<AreaValue | undefined>("");
  const [groupings, setGroupings] = useState<Grouping[] | undefined>();
  const [pageGroupings, setPageGroupings] = useState<Grouping[] | undefined>(pageGroupingList);

  const getGroupingsWithArea = () => { return (pageGroupings ?? []).filter(grouping => grouping.area) };
  const [groupingsWithArea, setGroupingsWithArea] = useState<Grouping[] | undefined>(getGroupingsWithArea);



  const updateGrouping = (groupingToModify: Grouping | undefined, area: AreaValue | undefined): Grouping => {
    const updatedGrouping = {
      ...groupingToModify,
      status: GROUPING_STATUS[0].value,
      area: area,
    }
    return updatedGrouping as Grouping;
  }

  const updateGroupingsWithArea = (groupings: Grouping[]): Grouping[] => {
    if (!isGlobal) return [];
    return groupings.map(grouping => {
      return {
        ...grouping,
        status: GROUPING_STATUS[0].value,
      }
    });
  }

  const getGroupingsFromOtherPages = () => {
    return (groupingList ?? []).filter(grouping => !pageGroupings?.includes(grouping))
  };

  const getUpdatedGroupings = () => {
    const updatedGroupings = (selectedGroupings ?? [])
      .map(
        grouping => updateGrouping(grouping, areas[grouping.id])
      );
    const currentPageUpdatedGroupingsWithAreas = updateGroupingsWithArea(groupingsWithArea ?? []);

    return [
      ...getGroupingsFromOtherPages(),
      ...currentPageUpdatedGroupingsWithAreas,
      ...updatedGroupings
    ]
  };

  const handleChange = (id: number) => ({ target }: any) => {
    setAreas(prevAreas => ({
      ...prevAreas,
      [id]: target.value,
    }));
  };

  const handleGlobalChange = ({ target }: any) => {
    setglobalArea(target.value)
    const newAreas = selectedGroupings?.reduce((acc: Record<number, AreaValue>, grouping) => {
      return { ...acc, [grouping.id]: target.value };
    }, {});
    if (newAreas) {
      setAreas(newAreas);
      setGroupings((selectedGroupings ?? []).map(grouping => updateGrouping({ ...grouping }, newAreas[grouping.id])));
    }
  };

  const submitDisabled = (): boolean => {
    return (selectedGroupings ?? []).some(grouping => !areas[grouping.id]);
  }


  const onSubmit = () => {
    try {
      updateModifiedGroupings(setModifiedGroupings, getUpdatedGroupings());
      setStatusToggle(true);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setGroupings(selectedGroupings);
  }, []);

  return (
    <>
      {((selectedGroupings?.length) ?? 0) > 1 &&
        <CustomPaper>
          <Grid container spacing={3} justify="center" alignContent='center' alignItems='center'>
            <Grid item xs={8}>
              <Typography variant='h2' >
                Asignar área a todos los inmuebles
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Select
                value={globalArea}
                onChange={handleGlobalChange}
                label="Área"
                fullWidth
              >
                {AREAS
                  .sort((area1, area2) => (area1.name > area2.name) ? 1 : -1)
                  .map(({ value, name }) => (
                    <MenuItem
                      key={value}
                      label={`${name}`}
                      value={`${value}`}
                    >
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </CustomPaper>
      }
      {groupings?.map(grouping => (
        <Grid container spacing={3} justify="center" key={grouping.id}>
          <Grid item xs={4}>
            <Input
              label="Proyecto"
              fullWidth
              info={grouping?.project_name}
              disabled
              value={grouping?.project_name}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="Inmueble"
              fullWidth
              info={grouping?.project_name}
              disabled
              value={grouping?.name}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              value={areas[grouping.id]}
              onChange={handleChange(grouping.id)}
              label="Área"
              fullWidth
            >
              {AREAS
                .sort((area1, area2) => (area1.name > area2.name) ? 1 : -1)
                .map(({ value, name }) => (
                  <MenuItem
                    key={value}
                    label={`${name}`}
                    value={`${value}`}
                  >
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={3} justify="flex-end"
        alignItems='center'>
        <Grid item>
          <Button
            info="Asigna áreas"
            color="primary"
            variant="contained"
            disabled={submitDisabled()}
            onClick={() => onSubmit()}
          >
            Asignar área
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ActivateGroupingTemplate;