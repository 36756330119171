import { object, string, TypeOf } from 'yup';

export const CreateCompanySchema = object({
  name: string().required(),
  code: string().required(),
  nit: string().matches(
    /^[1-9][0-9]*$/,
    'El NIT debe ser un valor numérico.',
  ).required(),
  url: string().required(),
  catcha: string(),
  status: string(),
  graceDay: string().matches(
    /^(0|[1-9]\d?)$/,
    'Debe ser entre 0 y 99 días.',
  ),
  typeServices: string().required()
});

export type CreateCompanyType = TypeOf<typeof CreateCompanySchema>;

