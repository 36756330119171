import { FC, useCallback, useState } from 'react';
import { Auth, Menu } from 'types';
import { loadState, removeAll, saveState } from 'utils';
import AuthContext from './AuthContext';

const flatObj: (elements: Menu[]) => Menu[] = (elements: Menu[]) => {
  const elem = elements.reduce<Menu[]>((acc, curr) => {
    const copy = [...acc, curr];
    if (curr.children) return [...copy, ...flatObj(curr.children)];
    return copy;
  }, []);
  return elem;
};

const ThemeProvider: FC = ({ children }) => {
  const localAuth = loadState<Auth>('auth');

  const [auth, setAuth] = useState<Auth | undefined>(localAuth);

  const login = useCallback((auth: Auth) => {
    const flatMenu = flatObj(auth.menu);
    const newAuth = { ...auth, flatMenu };
    setAuth(newAuth);
    saveState('auth', newAuth);
  }, []);

  const logout = useCallback(() => {
    setAuth(undefined);
    removeAll(['theme', 'menu']);
  }, []);

  const value = {
    ...auth,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default ThemeProvider;
