import { Header } from 'components/molecules';
import { CreateAMTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Automation Manager' }];

const CreateAMPage = () => {
  return (
    <>
      <Header
        title="Automation Manager"
        subtitle="Agrega un automation manager"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <CreateAMTemplate />
    </>
  );
};

export default withAuth(CreateAMPage);
