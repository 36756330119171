import { ApexOptions } from 'apexcharts';
import axios from 'axios';
import {
  GET_DASHBOARD_DATA_HISTORY,
  GET_DASHBOARD_DATA_HISTORY_DUE_HISTORY,
} from 'const';
import { DefaultTheme } from 'styled-components';
import {
  DashboardData,
  DueHistoriesData,
} from 'types/entities/dashboard-history-data';

export type InputDataOptions = {
  chartTitle: string;
  labels: string[];
  customColors?: string[];
  theme: DefaultTheme;
};

export const getPieChartOptions = ({
  labels,
  chartTitle,
  customColors,
  theme,
}: InputDataOptions): ApexOptions => {
  const defaultColors = [
    '#155184',
    '#64b5f6',
    '#219653',
    '#90BE6D',
    '#97B2C9',
    '#0B321C',
    '#01656d',
  ];
  const textColor = theme.isDarkMode ? theme.common.white : theme.common.black;
  return {
    labels: labels,
    legend: {
      labels: {
        colors: textColor,
      },
      position: 'bottom' as const,
      height: 30,
    },
    title: {
      text: chartTitle,
      align: 'center' as const,
      margin: 15,
      offsetX: 0,
      offsetY: -5,
      floating: false,
      style: {
        fontSize: '17px',
        fontWeight: 'bold',
        color: textColor,
      },
    },
    dataLabels: {
      style: {
        fontWeight: 100,
      },
      formatter: (val: number) => {
        return `${Math.round(val)}%`;
      },
    },
    colors: customColors || defaultColors,
  };
};

export const fetchDashboardData = async (dateRange: string) => {
  return await axios.get<DashboardData>(
    GET_DASHBOARD_DATA_HISTORY.concat('?dateRange=', dateRange),
  );
};
export const fetchDashboardDataDueHistories = async () => {
  return await axios.get<DueHistoriesData>(
    GET_DASHBOARD_DATA_HISTORY_DUE_HISTORY,
  );
};
