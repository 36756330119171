import { DefaultTheme } from 'styled-components';
import { themeType } from 'types';

const defaultTheme: Omit<DefaultTheme, 'palette' | 'isDarkMode' | 'mode'> = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: '0px',
      sm: '600px',
      md: '960px',
      lg: '1280px',
      xl: '1920px',
    },
  },
  common: {
    black: '#3F3F3F',
    white: '#ffffff',
  },

  shape: {
    borderRadius: '12px',
    spacing: (value: number, div?: number) => {
      if (div === 0)
        throw new Error('No valid, the param div should more than 0');

      const copy = div || 1;
      return `${(4 * value) / copy}px`;
    },
  },
  zIndex: {
    appBar: 1100,
    modal: 1300,
    tooltip: 1500,
    popover: 1400,
  },
};

export const palettes: Record<themeType, DefaultTheme['palette']> = {
  dark: {
    primary: '#155184',
    secondary: '#bf1650',
    gradient: 'linear-gradient(180deg, #002859 0%, #bf1650 100%)',
    error: '#ff3333',
    warning: '#FFC43D',
    info: '#64b5f6',
    success: '#219653',
    grey: '#3d404b',
    divider: '#5a5a5a',
    background: '#15192a',
  },
  light: {
    primary: '#155184',
    secondary: '#bf1650',
    gradient: 'linear-gradient(180deg, #002859 0%, #bf1650 100%)',
    error: '#ff3333',
    warning: '#FFC43D',
    info: '#64b5f6',
    success: '#219653',
    grey: '#3d404b',
    divider: '#cecece',
    background: '#dddddd',
  },
};

export default defaultTheme;
