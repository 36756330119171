import { useLocation } from 'react-router-dom';
import useAuth from './useAuth';

const useBreadcrumbs: (
  transform?: (pathname: string) => string,
) => [string[], string | undefined] = (transform) => {
  const { pathname } = useLocation();
  const { flatMenu } = useAuth();
  const path = transform ? transform(pathname) : pathname;

  const elements =
    flatMenu
      ?.find((menu) => menu.link === path)
      ?.breadcrumb?.split('/')
      .filter((x) => x) || [];

  const last = elements.pop();

  return [elements, last];
};

export default useBreadcrumbs;
