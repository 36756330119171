import styled from 'styled-components';
import { lighten } from 'utils';

const Icon = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: ${({ theme }) => lighten(theme.palette.background, 0.05)};
`;

const Radio = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;

  &:checked ~ ${Icon} {
    border: 10px solid currentColor;
  }
`;

const RadioRoot = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  color: var(--color);
  position: relative;
`;

export { Radio, RadioRoot, Icon };
