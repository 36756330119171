import { errors } from 'const';
import * as yup from 'yup';

yup.setLocale({
  mixed: { default: 'default', required: errors.REQUIRED },
  string: {
    email: errors.EMAIL,
    max: errors.MAX,
  },
  number: { integer: errors.INTEGER },
});
