import axios, { AxiosRequestConfig } from 'axios';
import { Grid, IconButton, Tooltip, Typography } from 'components/atoms';
import Toggle from 'components/atoms/Toggle';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_COMPANY_SERVICE_GOUPING_EXCEL, GET_GOUPING_EXCEL, GET_GROUPINGS, GET_PROJECTS, UPDATE_GROUPINGS } from 'const';
import { filterModelType, tableModelType } from 'hooks/useTable';
import { FileRejection } from 'react-dropzone';
import { AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import { CompanyServiceGrouping, Grouping, PaginationType } from 'types';
import { createFilterStructure, createPayload } from 'utils';
import { ListGroupingTemplateFilterType } from './ListGroupingTemplate.schema';
import { updateGroupingProps } from './ListGroupingTemplate.types';

export const GROUPING_STATUS = [
  { value: 'ACTIVO', name: 'Activo' },
  { value: 'INACTIVO', name: 'Inactivo' },
];
export const COMPANY_SERVICE_GROUPING_STATUS = [
  { value: 'ERROR', name: 'Error' },
];

export const GET_ENABLED_PROJECTS_PATH: string = GET_PROJECTS.concat(`?filter=[{%22field%22:%22status%22,%22value%22:%22true%22,%22type%22:%22equals%22}]`);

/**
 * Function to map the incoming rows from the server with the updated rows, and show the updated rows in the table
 * @param incomingRows rows from the server
 * @param updatedRows rows updated by the user
 * @returns 
 */
export const rowsMapper = (
  incomingRows: Grouping[] | undefined,
  updatedRows: Grouping[]): Grouping[] => {
  if (!incomingRows) {
    return [];
  }
  if (updatedRows.length === 0) {
    return incomingRows;
  }
  const updatedIncomingRows = incomingRows.map(incomingRow => {
    const updatedRow = updatedRows.find(updatedRow => updatedRow.id === incomingRow.id);
    if (!updatedRow) {
      return incomingRow;
    }
    return updatedRow;
  });
  return updatedIncomingRows;
};

/**
 * Function to update a list of groupings in the modifiedGroupings state list variable
 * it checks if each grouping already exists in the list to avoid duplicates
 * @param setModifiedGroupings 
 * @param grouping 
 */
export const updateModifiedGroupings = (
  setModifiedGroupings: (value: React.SetStateAction<Grouping[]>) => void,
  newGroupings: Grouping[]
) => {
  setModifiedGroupings((groupings) => {
    const updatedGroupings = [...groupings];

    newGroupings.forEach(newGrouping => {
      const existingIndex = groupings.findIndex(g => g.id === newGrouping.id);
      if (existingIndex > -1) {
        // If the grouping already exists in modifiedGroupings, update its status
        updatedGroupings[existingIndex] = newGrouping;
      } else {
        // If the grouping doesn't exist in modifiedGroupings, add it
        updatedGroupings.push(newGrouping);
      }
    });
    return updatedGroupings;
  });
}

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Grouping> = {
  field: 'id',
  order: 'ASC',
};

export const defaultFilterModel: filterModelType<Grouping>[] = [
  {
    field: 'status',
    type: 'equals',
    value: true,
    table: 'project'
  }
]

export type GroupingDownloadFilterModel = {
  groupingName: string | undefined,
  area: string | undefined,
  projects: string | undefined,
  publicServices: string | undefined,
  status: string | undefined,
  referenceStatus: string | undefined,
}

export const defaultListGroupingTemplateFilter: ListGroupingTemplateFilterType = {
  name: undefined,
  area: "",
  projectsIds: "",
  publicServices: '',
  status: '',
  referenceStatus: '',
};

let isChecked = true;
const handleToggleChange = (newState: boolean) => {
  isChecked = newState ? true : false;
};

const getTooltipProps = (csg: CompanyServiceGrouping) => {
  if (csg.companyServiceCode && !csg.companyServiceGroupingReference) {
    return {
      message: `${csg.companyServiceCode}- Sin referencia`,
      color: "error"
    }
  }
  if (!csg.companyServiceCode || !csg.companyServiceGroupingReference) {
    return {
      message: "Sin servicios suscritos",
      color: "error"
    }
  }
  return {
    message: `${csg.companyServiceCode}-${csg.companyServiceGroupingReference}`,
    color: "info"
  }

}

export const getColumns: (
  action: 'remove' | 'edit' | 'upload' | 'download' | 'activate' | 'confirm' | undefined,
  setAction: React.Dispatch<React.SetStateAction<'remove' | 'edit' | 'upload' | 'download' | 'activate' | 'confirm' | undefined>>,
  onCheck: (newState: boolean, sourceElement?: number | string | number[] | string[]) => void,
  canEdit: () => boolean
) => columnType<Grouping>[] = (action, setAction, onCheck, canEdit) => {

  const columns: columnType<Grouping>[] = [
    {
      field: 'area',
      headerName: 'Área',
      flex: '1',
      exportField: true,
      isArea: true,
      overflowNone: true,
      render: ({ area }) => {
        switch (area) {
          case "UNIDAD":
            return "Unidad"
          case "OBRA":
            return "Obra"
          case "SALA_VENTA":
            return "Sala de venta"
          default:
            return ""
        }
      }
    },
    {
      field: 'project_name',
      headerName: 'Proyecto',
      flex: '1',
      exportField: true,
      overflowNone: true,
    },
    {
      field: 'name',
      headerName: 'Inmueble',
      flex: '0.7',
      exportField: true,
      overflowNone: true,
    },
    {
      field: 'companyServiceGrouping',
      headerName: 'Servicios',
      flex: '1',
      overflowNone: true,
      disabledSort: true,
      render: ({ companyServiceGrouping }) => {
        return (
          <Grid wrap='nowrap' style={{ overflowX: 'unset' }}>
            <Tooltip
              messageList={
                companyServiceGrouping.map(
                  csg => {
                    return getTooltipProps(csg).message;
                  }

                )}
              position='right start'
              color="grey"
            >
              <IconButton
                positionEdge="start"
                edge={0}
                color={
                  companyServiceGrouping.some(
                    csg => csg.companyServiceCode && !csg.companyServiceGroupingReference
                  )
                    ? "error"
                    : companyServiceGrouping.some(
                      csg => !csg.companyServiceCode || !csg.companyServiceGroupingReference
                    )
                      ? "error"
                      : "info"
                }
              >
                <AiOutlineEye />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      }
      ,
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: '1',
      exportField: true,
      overflowNone: true,
      isState: true,
      enableToggle: true,
      render: ({ status, id }) => (
        <Toggle
          isChecked={status === 'ACTIVO'}
          onToggleChange={onCheck}
          sourceElement={id}
          toggleSize='sm'
        />
      ),
    }
  ]

  if (/* canEdit() */ true) {
    // If the user has edit permission, add the "actions" column
    columns.push({
      field: 'actions',
      headerName: 'Acciones',
      flex: '1',
      disabledSort: true,
      render: ({ id }) => (
        <IconButton
          info="Editar agrupación"
          positionEdge="start"
          color="grey"
          href={`/pages/servicios-publicos/inmueble/${id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>

      ),
    });
  }
  return columns;
}

export const zoneStyle = (isDragActive: boolean) => ({
  border: '2px dashed white',
  borderRadius: '8px',
  padding: '20px',
  margin: '20px',
  ...(isDragActive && { backgroundColor: '#323541' }),
});


export const rejectedFilesMessage = (
  rejectedFiles: FileRejection[],
  hasTypeError: (rejectedFiles: FileRejection[], errorCode: string) => boolean,
) => {
  if (rejectedFiles?.length > 1) {
    return <Typography
      key="one-file-limit"
      variant="h3"
      align="center"
      color="warning">
      Carga un archivo a la vez
    </Typography>
  }

  if (hasTypeError(rejectedFiles, "file-invalid-type")) {
    return rejectedFiles.map(file => (
      <Typography
        key="file-invalid-type"
        variant="h3"
        align="center"
        color="warning" >
        Debe ser un archivo excel con extensión .xlsx o .xls
      </Typography>
    ))
  }
}

export const fetchProcess = (
  pagination: tableModelType<Grouping>,
  config?: AxiosRequestConfig<Grouping>,
) => {
  let paginationCopy = JSON.parse(JSON.stringify(pagination))
  if (paginationCopy.sortModel) {
    if (paginationCopy.sortModel.field === 'project_name') {
      paginationCopy.sortModel.field = 'name';
      paginationCopy.sortModel.table = 'project';
    }
  }

  return axios.get<PaginationType<Grouping>>(
    `${GET_GROUPINGS}${createPayload(paginationCopy)}`,
    config,
  );
}


/**
 * Function to conditionally fetch the excel report from the server
 * @param filterModel 
 * @param type 
 * @returns 
 */
export const downloadExcelReport = (
  filterModel: filterModelType<Grouping>[] | undefined,
  type: "grouping" | "companyServiceGrouping" | undefined
) => {
  if (type === "grouping") {
    return axios.get(
      `${GET_GOUPING_EXCEL}${filterModel && filterModel.length > 0
        ? `?filter=${JSON.stringify(filterModel)}&`
        : ''
      }`,
      {
        responseType: "blob",
      }
    );
  }
  return axios.get(
    `${GET_COMPANY_SERVICE_GOUPING_EXCEL}${filterModel && filterModel.length > 0
      ? `?filter=${JSON.stringify(filterModel)}&`
      : ''
    }`,
    {
      responseType: "blob",
    }
  );
};

export const updateGroupings = async (
  { groupings, setModifiedGroupings, setOpen, setErrorMessage, setActionSnackBar, setIsUpdating }: updateGroupingProps
) => {
  return axios.put(
    `${UPDATE_GROUPINGS}`,
    {
      batchUpdateGroupingDto: groupings,
    },
  ).then(() => {
    setOpen('success');
    setActionSnackBar('activate');
  }).catch((error) => {
    setOpen('error');
    console.log(error);
    if (error.response && error.response.data && error.response.data.message) {
      setErrorMessage(error.response.data.message);
      setOpen('warning');
    } else {
      setErrorMessage(undefined);
      setOpen('error');
    }
  }).finally(() => {
    setModifiedGroupings([]);
    setIsUpdating(false);
  });
}


export const createFilterModel = (name: string | undefined, area: string | undefined, projects: string | undefined, publicServices: string | undefined, status: string | undefined, referenceStatus: string | undefined) => {

  let filterGroupingName;

  if (name?.includes(',')) {
    const names = name.split(',').map((name) => name.trim());
    filterGroupingName = createFilterStructure(
      'name',
      'in',
      names,
    );
  } else {
    filterGroupingName = createFilterStructure(
      'name',
      'contains',
      name,
    );
  }

  const filterArea = createFilterStructure(
    'area',
    'equals',
    area,
  );

  const filterState = createFilterStructure(
    'status',
    'equals',
    status,
  );

  const filterReferenceStatus = createFilterStructure(
    'status',
    'equals',
    referenceStatus,
    'companyServiceGrouping',
  );

  const filterProjectStatus = createFilterStructure(
    'status',
    'equals',
    true,
    'project',
  );

  let modelFilter = [
    filterGroupingName,
    filterArea,
    filterState,
    filterProjectStatus,
    filterReferenceStatus
  ].filter((item) => item);

  if (projects) {
    const filterProject = createFilterStructure(
      'id',
      'in',
      projects?.split(','),
      'project',
    );
    modelFilter = [
      filterProject,
      ...modelFilter
    ].filter((item) => item);
  }

  if (publicServices) {
    const filterPublicService = createFilterStructure(
      'id',
      'in',
      publicServices?.split(','),
      'companyService',
    );
    modelFilter = [
      filterPublicService,
      ...modelFilter
    ].filter((item) => item);
  }

  return modelFilter;
}
