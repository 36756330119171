import { useCallback, useState } from "react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";

const defaultAcceptObject: Accept | undefined = {
  'text/excel': ['.xlsx', '.xls']
};

/**
 * Custom Hook para gestionar la funcionalidad de una zona de arrastre personalizada. Esta zona de arrastre permite subir archivos y proporciona funciones para gestionar los archivos aceptados y rechazados.
 *
 * @param acceptValidator Configuración de tipos de archivo y extensiones permitidas (por defecto, se permite 'text/excel' con extensiones '.xlsx' y '.xls').
 * @returns Un objeto con las propiedades y funciones para gestionar la zona de arrastre personalizada.
 *
 */
const useCustomDropZone = (
  acceptValidator: Accept = defaultAcceptObject
) => {

  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {

    if (rejectedFiles?.length) {
      onRemoveFiles();
      setRejectedFiles([...rejectedFiles])
    }
    if (acceptedFiles?.length && !rejectedFiles?.length) {
      setFiles(previousFiles => [
        ...acceptedFiles.map(file =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      ])
      setRejectedFiles([]);
    }
  }, [])


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptValidator,
    maxFiles: 1
  })

  const onRemoveFiles = () => {
    setFiles([]);
  }

  const hasTypeError = (rejectedFiles: FileRejection[], errorCode: string): boolean => {
    return rejectedFiles.some(file => {
      return file.errors.some(error => {
        return error.code === errorCode;
      });
    });
  }

  return {
    files,
    setFiles,
    rejectedFiles,
    setRejectedFiles,
    getRootProps,
    getInputProps,
    onRemoveFiles,
    hasTypeError,
    isDragActive,
  }
}

export default useCustomDropZone;




