import React, { useCallback, useState } from 'react';

const useMenu = <T extends HTMLElement = HTMLElement>(
  anchor?: T,
): [T | undefined, (event: React.MouseEvent<T>) => void, () => void] => {
  const [anchorEl, setAnchorEl] = useState<T | undefined>(anchor);

  const handleClick = useCallback((event: React.MouseEvent<T>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  return [anchorEl, handleClick, handleClose];
};

export default useMenu;
