import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError } from 'axios';
import {
  Button,
  Grid,
  Input,
  MenuItem,
  Paper,
  Select,
  SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import Toggle from 'components/atoms/Toggle';
import { serverMonitoringConfig } from 'components/templates/ServerTemplate/Shared/shared.const';
import { GET_ALL_SERVERS, GET_ALL_SERVICES } from 'const';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Server } from 'types';
import CreateEditServiceTemplateLoading from './CreateEditServiceTemplate.loading';
import { CreateEditServiceSchema, CreateEditServiceType } from './CreateEditServiceTemplate.schema';
import { CreateEditServiceTemplateProps } from './CreateEditServiceTemplate.types';
const CreateEditServiceTemplate = ({ ...props }: CreateEditServiceTemplateProps) => {

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [fetchService, setFetchService] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const navigate = useNavigate();
  const loadingData = loading;
  const [open, setOpen] = useState<severityType>();
  const [servers, setServers] = useState<Server[]>([]);

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/monitoring/services');
  };

  if (id && !fetchService) {
    setFetchService(true);
  }

  useEffect(() => {
    const fetchServers = async () => {
      try {
        const response = await axios.get(`${GET_ALL_SERVERS}/all`, serverMonitoringConfig);
        setServers(response.data);
      } catch (error) {
        const axiosError = error as AxiosError;
        setOpen('error');
        setMessage(axiosError.message);
        setTimeout(() => {
          navigate('/pages/monitoring/services');
        }, 2000);
      }
    };
    fetchServers();
  }, []);

  useEffect(() => {
    const fetchServiceById = async () => {
      if (id) {
        try {
          setLoading(true);
          const response = await axios.get(`${GET_ALL_SERVICES}?filter=[{"field":"id","value":"${id}","type":"equals"}]`, serverMonitoringConfig);
          const service = response.data.result[0];
          reset({
            server: `${service.server?.name} - ${service.server?.ip}`,
            idServer: service.server.id,
            name: service.name,
            isHttps: service.isHttps,
            port: service.port,
            maxRetry: service.maxRetry,
            timeout: service.timeout,
            url: service.url,
            disabled: service.disabled,
          })
          setLoading(false);
        } catch (error) {
          const axiosError = error as AxiosError;
          setOpen('error');
          setMessage(axiosError.message);
          setTimeout(() => {
            navigate('/pages/monitoring/services');
          }, 2000);
        }
      }
    };
    fetchServiceById();
  }, [id]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<CreateEditServiceType>({
    resolver: yupResolver(CreateEditServiceSchema),
    defaultValues: {
      server: '',
      idServer: undefined,
      name: '',
      isHttps: true,
      port: null,
      maxRetry: 10,
      timeout: 10000,
      url: null,
      disabled: false,
    },
  });

  const isDisabled = () => {
    // port is optional
    const { server, name, maxRetry, timeout } = getValues();
    return !server || !name || !maxRetry || !timeout;
  };

  const onSubmit = async (serverForm: CreateEditServiceType) => {
    try {
      setLoading(true);
      const { ...form } = serverForm;
      delete form.server;
      const url = id ? `${GET_ALL_SERVICES}/${id}` : `${GET_ALL_SERVICES}`;
      const body = {
        ...form,
        port: form.port ? parseInt(form.port) : undefined,
      }
      if (id) {
        await axios.put<CreateEditServiceType>(url, body, serverMonitoringConfig);
      } else {
        await axios.post<CreateEditServiceType>(url, body, serverMonitoringConfig);
      }
      setLoading(false);
      setMessage(`Se ${id ? 'actualizó' : 'creó'} con éxito`);
      setOpen('success');
    } catch (error) {
      const axiosError = error as AxiosError;
      setLoading(false);
      setOpen('error');
      setMessage(axiosError.message);
    }
  };

  return (
    <Paper>
      {!loadingData ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)} >
          <Grid container spacing={3} justify="flex-start">
            <Grid item xs={6}>
              <Controller
                name="server"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value}
                      onChange={(e) => {
                        const target = e.target as HTMLSelectElement;
                        if (target) {
                          const server = servers.find(server => `${server.name} - ${server.ip}` === target.value);
                          field.onChange(e);
                          reset({ ...getValues(), idServer: server?.id });
                        }
                      }}
                      label="Servidores"
                      enableFilter={true}
                      fullWidth
                    >
                      {servers ?
                        servers.map((server) => (
                          <MenuItem
                            key={`${server.name} - ${server.ip}`}
                            label={`${server.name} - ${server.ip}`}
                            value={`${server.name} - ${server.ip}`}
                          >
                            {`${server.name} - ${server.ip}`}
                          </MenuItem>
                        )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                fullWidth
                label="Nombre"
                value={watch('name')}
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                fullWidth
                label="URL"
                value={watch('url') ?? ''}
                {...register('url')}
                error={!!errors.url}
                helperText={errors.url && errors.url.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                fullWidth
                label="Puerto"
                value={watch('port') ?? ''}
                type="number"
                {...register('port')}
                error={!!errors.port}
                helperText={errors.port && errors.port.message}
              />
            </Grid>

            <Grid item xs={3}>
              <Input
                fullWidth
                label="Timeout"
                value={watch('timeout')}
                type="number"
                {...register('timeout')}
                error={!!errors.timeout}
                helperText={errors.timeout && errors.timeout.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                fullWidth
                label="Maximo de reintentos"
                type="number"
                value={watch('maxRetry')}
                {...register('maxRetry')}
                error={!!errors.maxRetry}
                helperText={errors.maxRetry && errors.maxRetry.message}
              />
            </Grid>
            <Grid item xs={6} alignItems='center' alignContent='center'>
              <Grid container spacing={0} alignItems='center'>
                <Grid item xs={3}>
                  <label>Es HTTPS</label>
                </Grid>
                <Grid item xs={3}>
                  <Toggle
                    isChecked={watch('isHttps', false) as boolean}
                    {...register('isHttps')}
                    onToggleChange={(value) => {
                      reset({ ...getValues(), isHttps: value });
                    }}
                    tags={{ checked: 'Si', unchecked: 'No' }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <label>Deshabilitado</label>
                </Grid>
                <Grid item xs={3}>
                  <Toggle
                    isChecked={watch('disabled', false) as boolean}
                    {...register('disabled')}
                    onToggleChange={(value) => {
                      reset({ ...getValues(), disabled: value });
                    }}
                    tags={{ checked: 'Si', unchecked: 'No' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                type='button'
                fullWidth
                variant="text"
                color="grey"
                href="/pages/monitoring/services"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled() && !id}
              >
                {id ? 'Actualizar' : 'Crear'}
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={3000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {message}
          </SnackBar>
        </form>
      ) : (
        <CreateEditServiceTemplateLoading />
      )
      }
    </Paper >
  );
};

export default CreateEditServiceTemplate;
