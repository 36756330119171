import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { formatDate } from 'components/templates/ServerTemplate/Shared/shared.const';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import { RegexException } from 'types/entities/regex-exception';
import { createFilterStructure } from 'utils';
import { ListRegexTemplateFilterType } from './ListRegexTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<RegexException> = {
  field: 'id',
  order: 'ASC',
};

export const defaultListRegexTemplateFilter: ListRegexTemplateFilterType = {
  errorName: '',
  errorRegex: '',
  customer: '',
  process: '',
};

export const createFilterModel = ({ errorName, errorRegex, customer, process }: ListRegexTemplateFilterType) => {

  const filterName = createFilterStructure(
    'errorName',
    'contains',
    errorName,
  );

  const filterRegex = createFilterStructure(
    'errorRegex',
    'contains',
    errorRegex,
  );

  const modelFilter = [
    filterName,
    filterRegex,
  ]

  if (customer) {
    const filterCustomer = createFilterStructure(
      'customer',
      'in',
      customer?.split(','),
    );
    modelFilter.push(filterCustomer);
  }

  if (process) {
    const filterProcess = createFilterStructure(
      'process',
      'in',
      process?.split(','),
    );
    modelFilter.push(filterProcess);
  }

  return modelFilter;
}

// format date to Bogota time
export const getColumns: (
  onViewDetails: (regex: RegexException) => void,
  confirmProcess: (regex: RegexException, action: 'delete') => void
) => columnType<RegexException>[] = (onViewDetails, confirmProcess) => [
  {
    field: 'errorName',
    headerName: 'Nombre',
    flex: '1',
    exportField: true,
    render: ({ errorName }) => (<span title={errorName}>{errorName}</span>),
  },
  {
    field: 'errorRegex',
    headerName: 'Regex',
    flex: '1',
    exportField: true,
    render: ({ errorRegex }) => (<span title={errorRegex}>{errorRegex}</span>),
  },
  {
    field: 'flags',
    headerName: 'Banderas',
    flex: '1',
    exportField: true,
    render: ({ flags }) => (<span title={flags}>{flags}</span>),
  },
  {
    field: 'howOftenNotify',
    headerName: 'Notificar cada',
    flex: '1',
    exportField: true,
    render: ({ howOftenNotify }) => (<span title={howOftenNotify.toString()}>{howOftenNotify}</span>),
  },
  {
    field: 'customer',
    headerName: 'Cliente',
    flex: '1',
    exportField: true,
    render: ({ customer }) => (<span title={customer ? customer.code.toString() : ''}>{customer?.code.toString() || ''}</span>),
  },
  {
    field: 'process',
    headerName: 'Proceso',
    flex: '1',
    exportField: true,
    render: ({ process }) => (<span title={process ? process.code.toString() : ''}>{process?.code.toString() || ''}</span>),
  },
  {
    field: 'updatedAt',
    headerName: 'Actualizado',
    flex: '1',
    exportField: true,
    render: ({ updatedAt }) => (<span title={formatDate(updatedAt.toString())}>{formatDate(updatedAt.toString())}</span>),
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    disabledSort: true,
    flex: '1',
    align: 'flex-start',
    render: (regex) => (
      <>
        <IconButton
          info="Ver Servidor"
          positionEdge="start"
          color="grey"
          onClick={() => onViewDetails(regex)}
        >
          <AiOutlineEye />
        </IconButton>
        <IconButton
          info="Editar Servidor"
          positionEdge="start"
          color="info"
          href={`/pages/management/exceptions/regex/${regex.id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>
        <IconButton
          info="Eliminar Servidor"
          positionEdge="start"
          color="error"
          onClick={() => confirmProcess(regex, 'delete')}
        >
          <AiOutlineDelete />
        </IconButton>
      </>
    )
  }
];