import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError } from 'axios';
import {
  Button,
  Grid,
  Input,
  MenuItem,
  Paper,
  Select,
  SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { GET_ALL_CUSTOMERS, GET_ALL_PROCESS_PAGINATION, GET_REGEX_EXCEPTIONS } from 'const';
import useGetData from 'hooks/useGetData';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AutomationManagerCustomer, Process } from 'types';
import { RegexException } from 'types/entities/regex-exception';
import CreateEditRegexTemplateLoading from './CreateEditRegexTemplate.loading';
import { CreateEditRegexSchema, CreateEditRegexType } from './CreateEditRegexTemplate.schema';
import { CreateEditRegexTemplateProps } from './CreateEditRegexTemplate.types';
const CreateEditRegexTemplate = ({ ...props }: CreateEditRegexTemplateProps) => {

  const { data: processesFetch, loading: loadingProcesses } = useGetData<Process>(
    GET_ALL_PROCESS_PAGINATION
  );
  const { data: customersFetch, loading: loadingCustomers } = useGetData<AutomationManagerCustomer>(
    GET_ALL_CUSTOMERS
  );

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const navigate = useNavigate();
  const loadingData = loading;
  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/exceptions/regex');
  };

  useEffect(() => {
    const fetchRegexById = async () => {
      if (id) {
        try {
          setLoading(true);
          const response = await axios.get(`${GET_REGEX_EXCEPTIONS}?filter=[{"field":"id","value":"${id}","type":"equals"}]`);
          const regex: RegexException = response.data.result[0];

          let channelUrl = '';
          if (regex.channelIdTeams && regex.teamsId) {
            channelUrl = `https://teams.microsoft.com/l/channel/${encodeURIComponent(regex.channelIdTeams)}/default?groupId=${encodeURIComponent(regex.teamsId)}&tenantId=default`;
          }

          reset({
            errorName: regex.errorName,
            errorRegex: regex.errorRegex,
            customerCode: regex.customer?.code ?? undefined,
            processCode: regex.process?.code ?? undefined,
            flags: regex.flags,
            howOftenNotify: regex.howOftenNotify,
            channelIdTeams: regex.channelIdTeams,
            teamsId: regex.teamsId,
            channelUrl,
          })
          setLoading(false);
        } catch (error) {
          const axiosError = error as AxiosError;
          setOpen('error');
          setMessage(axiosError.message);
          setTimeout(() => {
            navigate('/pages/management/exceptions/regex');
          }, 1000);
        }
      }
    };
    fetchRegexById();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<CreateEditRegexType>({
    resolver: yupResolver(CreateEditRegexSchema),
    defaultValues: {
      errorName: '',
      errorRegex: '',
      customerCode: undefined,
      processCode: undefined,
      flags: 'g',
      howOftenNotify: 60,
      channelUrl: '',
      teamsId: '',
      channelIdTeams: '',
    },
  });

  const isDisabled = () => {
    const { errorRegex, errorName, teamsId, channelIdTeams } = getValues();

    return !errorRegex || !errorName || !teamsId || !channelIdTeams;
  };

  const onSubmit = async (regexForm: CreateEditRegexType) => {
    try {
      setLoading(true);
      const form: Partial<CreateEditRegexType> = { ...regexForm };
      delete form.channelUrl;
      (Object.keys(form) as (keyof CreateEditRegexType)[]).forEach((key) => {
        if (form[key] === '') form[key] = undefined as any;
      });

      const url = id ? `${GET_REGEX_EXCEPTIONS}/${id}` : `${GET_REGEX_EXCEPTIONS}`;
      if (id) {
        await axios.put<CreateEditRegexType>(url, { ...form });
      } else {
        await axios.post<CreateEditRegexType>(url, { ...form });
      }
      setLoading(false);
      setMessage(id ? 'Expresion regular actualizada correctamente' : 'Expresion regular creada correctamente');
      setOpen('success');
    } catch (error) {
      const err = error as {
        statusCode: number;
        status: string;
        error: string;
      }
      const axiosError = error as AxiosError;
      setLoading(false);
      setOpen('error');
      setMessage(axiosError.response?.data.message ?? err['error'] ?? axiosError.message);
    }
  };

  useEffect(() => {
    const channelUrl = watch('channelUrl');

    const resetFields = (url: string, channelId: string, teamsId: string) => {
      reset({
        ...getValues(),
        channelUrl: url,
        channelIdTeams: channelId,
        teamsId: teamsId,
      });
    };

    const handleInvalidUrl = () => {
      setMessage('URL de canal no válida');
      setOpen('warning');
      resetFields('', '', '');
    };

    if (channelUrl?.match(/channel\/[^/]+\/[^/]+groupId=[^&]+&/)) {
      let decodedUrl = '';

      try {
        decodedUrl = decodeURIComponent(channelUrl);
      } catch (error) {
        return handleInvalidUrl();
      }

      const channelIdMatch = decodedUrl.match(/channel\/([^/]+)/);
      const groupIdMatch = decodedUrl.match(/groupId=([^&]+)/);

      const channelId = channelIdMatch?.[1] ?? '';
      const groupId = groupIdMatch?.[1] ?? '';

      if (channelId && groupId) {
        resetFields(decodedUrl, channelId, groupId);
      } else {
        handleInvalidUrl();
      }
    } else if (channelUrl) {
      handleInvalidUrl();
    } else {
      resetFields('', '', '');
    }
  }, [watch('channelUrl')]);

  const flags = [
    {
      flag: 'd',
      description: 'Generar índices para coincidencias de subcadenas.',
    },
    {
      flag: 'g',
      description: 'Búsqueda global.',
    },
    {
      flag: 'i',
      description: 'No distingue entre mayúsculas y minúsculas.',
    },
    {
      flag: 'm',
      description: 'Permite que ^ y $ coincidan junto a caracteres de nueva línea.',
    },
    {
      flag: 's',
      description: 'Permite que . coincida con caracteres de nueva línea.',
    },
    {
      flag: 'u',
      description: 'Patrón como secuencia de código Unicode.',
    },
    {
      flag: 'v',
      description: 'Actualización del modo u con más funciones Unicode.',
    },
    {
      flag: 'y',
      description: 'Realiza una búsqueda "sticky" que coincide a partir de la posición actual en la cadena de destino.',
    },
  ]

  return (
    <Paper>
      {!loadingData ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={4}>
              <Input
                label="URL canal teams"
                value={watch('channelUrl') ?? ''}
                fullWidth
                {...register('channelUrl')}
                error={!!errors.channelUrl}
                helperText={errors.channelUrl && errors.channelUrl.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="ID Team"
                value={watch('teamsId') ?? ''}
                fullWidth
                disabled
                {...register('teamsId')}
                error={!!errors.teamsId}
                helperText={errors.teamsId && errors.teamsId.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="ID del canal"
                value={watch('channelIdTeams') ?? ''}
                fullWidth
                disabled
                {...register('channelIdTeams')}
                error={!!errors.channelIdTeams}
                helperText={errors.channelIdTeams && errors.channelIdTeams.message}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end">

            <Grid item xs={4}>
              <Input
                label="Nombre"
                value={watch('errorName')}
                fullWidth
                {...register('errorName')}
                error={!!errors.errorName}
                helperText={errors.errorName && errors.errorName.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Regex"
                value={watch('errorRegex')}
                fullWidth
                {...register('errorRegex')}
                error={!!errors.errorRegex}
                helperText={errors.errorRegex && errors.errorRegex.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Notificar cada(Seg)"
                value={watch('howOftenNotify')}
                fullWidth
                required={false}
                {...register('howOftenNotify')}
                error={!!errors.howOftenNotify}
                helperText={errors.howOftenNotify && errors.howOftenNotify.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="flags"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value ?? ''}
                      enableFilter={true}
                      onChange={field.onChange}
                      label="Bandera"
                      fullWidth
                      multiple
                      required={false} 
                    >
                      {flags ?
                        flags.map((flags) => (
                          <MenuItem
                            key={flags.flag}
                            label={`${flags.description}`}
                            value={`${flags.flag}`}
                          >
                            {`${flags.flag} - ${flags.description}`}
                          </MenuItem>
                        )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="processCode"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value}
                      enableFilter={true}
                      onChange={field.onChange}
                      label="Proceso"
                      fullWidth
                      required={false}
                    >
                      {processesFetch ?
                        processesFetch.map((process) => (
                          <MenuItem
                            key={process.id}
                            label={`${process.name}`}
                            value={`${process.code}`}
                          >
                            {process.code}
                          </MenuItem>
                        )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="customerCode"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value}
                      enableFilter={true}
                      onChange={field.onChange}
                      label="Cliente"
                      fullWidth
                      required={false}
                    >
                      {customersFetch ?
                        customersFetch.map((customer) => (
                          <MenuItem
                            key={customer.id}
                            label={`${customer.name}`}
                            value={`${customer.code}`}
                          >
                            {customer.code}
                          </MenuItem>
                        )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                type='button'
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/exceptions/regex"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled() && !id}
              >
                {id ? 'Actualizar' : 'Crear'}
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {message}
          </SnackBar>
        </form>
      ) : (
        <CreateEditRegexTemplateLoading />
      )
      }
    </Paper >
  );
};

export default CreateEditRegexTemplate;
