import { boolean, number, object, string, TypeOf } from 'yup';
import { OperatingSystems, Providers } from '../Shared/shared.const';

export const CreateEditServerSchema = object({
  name: string().required().max(255),
  ip: string()
    .required()
    .max(255)
    .matches(/^(\d{1,3}\.){3}\d{1,3}$/, 'Invalid IP Address'),
  domain: string().required().max(255),
  timeout: number().required(),
  provider: string().required().max(255).oneOf(Providers),
  operatingSystem: string().required().max(255).oneOf(OperatingSystems),
  supportEmail: string().required().email(),
  maxRetry: number().required(),
  disabled: boolean().required(),
  channerUrl: string().max(255).notRequired().nullable().optional(),
  teamIdTeams: string().max(255).notRequired().nullable().optional(),
  channelIdTeams: string().max(255).notRequired().nullable().optional(),
});

export type CreateEditServerType = TypeOf<typeof CreateEditServerSchema>;
