import { Header } from 'components/molecules';
import CreateEditServerTemplate from 'components/templates/ServerTemplate/CreateEditServerTemplate';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administración' }];

const CreateEditServerPage = () => {
    return (
        <>
            <Header
                title="Servidores"
                subtitle="Agregar/Editar Servidor"
                breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
            />
            <CreateEditServerTemplate />
        </>
    );
};

export default withAuth(CreateEditServerPage, ['ROLE_DEVELOPER'], "mayasoft");
