import styled from 'styled-components';

import { lighten } from 'utils';
import ButtonBase from '../Button';

export type severityType = 'error' | 'warning' | 'info' | 'success' | 'default';

export const Root = styled.div`
  position: relative;
  display: block;
  flex: 1;
  align-items: flex-start;
  padding: 10px;
  background-color: ${({ theme }) =>
    theme.isDarkMode
      ? lighten(theme.palette.background, 0.05)
      : lighten(theme.palette.background, 0.1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;

export const Indication = styled.div<{ $severity: severityType }>`
  width: 10px;
  height: 100%;
  background-color: ${({ theme, $severity }) =>
    $severity === 'default'
      ? lighten(theme.palette.grey, 0.3)
      : theme.palette[$severity]};
  border-radius: ${({ theme }) =>
    `${theme.shape.borderRadius} 0px 0px ${theme.shape.borderRadius}`};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 0px 12px;
`;

export const Button = styled(ButtonBase)`
  padding: 0;
  margin-left: ${({ theme }) => theme.shape.spacing(3.5)};
  font-size: 14px;
`;

export const Icon = styled.div`
  margin-right: 10px;
`;
