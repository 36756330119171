import { Menu, plainMenu } from 'types';

export const flatMenus = (menus?: Menu[]) =>
  menus
    ? menus.reduce<plainMenu[]>((acc, curr) => {
        const toAdd = curr.children
          ? curr.children.map(({ id, title, ...others }) => ({
              ...others,
              id: `${curr.id}-${id}`,
              title: `${curr.title} / ${title}`,
            }))
          : [];
        return [...acc, ...toAdd];
      }, [])
    : [];
