import { Container as AtomContainer, Grid, IconButton } from 'components/atoms';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import { darken, invertColor } from 'utils';

export const Root = styled.div`
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) =>
    darken(invertColor(theme.palette.background, true), 0.2)};
  height: 100vh;

  & ::selection {
    background: ${({ theme }) => theme.palette.primary};
  }
  & ::-webkit-selection {
    background: ${({ theme }) => theme.palette.primary};
  }
  & ::-moz-selection {
    background: ${({ theme }) => theme.palette.primary};
  }
`;

export const Container = styled(AtomContainer)`
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;

  padding: ${({ theme }) => theme.shape.spacing(2)};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}) {
    padding: ${({ theme }) => theme.shape.spacing(3)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}) {
    padding: ${({ theme }) => theme.shape.spacing(4)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}) {
    padding: ${({ theme }) => theme.shape.spacing(6)};
  }
`;

export const CollapseIcon = styled(IconButton)<{ $open: boolean }>`
  position: absolute;
  right: -17px;
  bottom: calc(50% - 7px);
  cursor: pointer;

  border-radius: 50%;
  transform: ${({ $open }) => (!$open ? 'rotate(270deg)' : 'rotate(90deg)')};
  z-index: 1;
`;

export const GridMenu = styled(Grid)`
  ${({ theme }) =>
    theme.isDarkMode
      ? `border-right: thin solid ${theme.palette.divider}`
      : ``};
  box-sizing: border-box;
  position: relative;
  max-height: 100vh;
  background-size: auto 130%;
  background-image: url('https://img.freepik.com/free-vector/abstract-organic-pattern-design-background_1048-19286.jpg');
  background-position: left center;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}) {
    width: auto;
  }
`;

export const GridContent = styled(Grid)`
  max-height: 100vh;
  box-sizing: border-box;
`;

export const GridContainer = styled(Grid)`
  display: -webkit-inline-box !important;
`;

export const CustomPerfectScrollbar = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}) {
    align-items: flex-end;
  }
  background-color: ${({ theme }) =>
    theme.isDarkMode ? '#171e2ff2' : '#114776f2'};
  backdrop-filter: blur(2px);
`;

export const Footer = styled.div<{ $open: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $open }) => (!$open ? '25px' : '280px')};
  opacity: ${({ $open }) => (!$open ? '0' : '1')};
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  & > img {
    height: 45px;
  }
`;
