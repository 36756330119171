import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { EditCompanyTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const EditCompanyPage = () => {
  return (
    <>
      <Header
        title="Compañías"
        subtitle="Edita compañías"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <EditCompanyTemplate />
    </>
  )
}

export default withAuth(EditCompanyPage);