import {
  Button,
  Grid,
  Typography
} from 'components/atoms';
import { useState } from 'react';
import { getCurrentTimeToString } from '../../../../../const';
import { useDownloadFile } from '../../../../../hooks';
import { filterModelType } from '../../../../../hooks/useTable';
import { Grouping } from '../../../../../types';
import { getPubliServiceCodesByIds } from '../../ReportsTemplate/Shared/sharedConsts';
import { downloadExcelReport } from '../ListGroupingTemplate/ListGroupingTemplate.const';
import GroupingTemplateApiLoading from '../Shared/GroupingTemplate.loading';
import { areaFormatter, getProjectsNamesByIds } from '../Shared/sharedConsts';
import { createFilterModel } from './GroupingDownloadTemplate.const';
import { GroupingDownloadTemplateProps } from './GroupingDownloadTemplate.types';


/**
 * Este componente implementa el modal para realizar
 * descargas masivas de facturas.
 */
const GroupingDownloadTemplate = (
  {
    setOpen,
    onClose,
    setErrorMessage,
    groupingDownloadFilterModel,
    projectList,
    publicServicesList,
    ...props
  }: GroupingDownloadTemplateProps
) => {

  const { groupingName: name, area, projects, publicServices, status, referenceStatus } = groupingDownloadFilterModel;
  let isChecked: boolean = true;
  const [isDownloading, setIsDownloading] = useState(false);
  let downloadType: "grouping" | "companyServiceGrouping" | undefined;

  /**
   * Block of code to use the hook to download files
   */
  const preDownloading = () => {
    setIsDownloading(true);
  };

  const postDownloading = () => {
    onClose();
    setIsDownloading(false);
  };

  const onErrorDownloadFile = () => {
    setIsDownloading(false);
    onClose();
    setOpen('error')
  };

  const getFileName = () => {
    if (downloadType === "grouping") {
      return `inmuebles_${getCurrentTimeToString()}.xlsx`
    }
    return `servicios_públicos_${getCurrentTimeToString()}.xlsx`;

  };


  const downloadFiles = () => {
    const newFilterModel = createFilterModel(name, area, projects, publicServices, status, referenceStatus);
    const result = downloadExcelReport(newFilterModel as filterModelType<Grouping>[], downloadType);
    return result
  }

  const { download } = useDownloadFile({
    apiDefinition: downloadFiles,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });


  const onSubmit = async (type: "grouping" | "companyServiceGrouping") => {
    downloadType = type;
    try {
      await download();
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      {(!isDownloading) ? (
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography align='center' variant='h2' >
                ¿Estas seguro que deseas descargar el reporte de

                {(!area && !projects && !publicServices) ? ' todos los inmuebles' : ' inmuebles'}

                {(status && (status === 'ACTIVO')) ? ' activos' : (status && (status === 'INACTIVO')) && ' inactivos'}

                {(area) && `, para el área: ${areaFormatter(area)}`}

                {projects && (
                  `; de los proyectos: ${getProjectsNamesByIds(projects, projectList!)
                  }`
                )}
                {publicServices && (
                  `; asociados a las compañías: ${getPubliServiceCodesByIds(publicServices, publicServicesList!)
                  }`
                )}
                ?
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end"
            alignItems='center'>
            <Grid item>
              <Button
                info="Descarga reporte de inmuebles"
                color="primary"
                variant="contained"
                onClick={() => onSubmit("grouping")}
              >
                Descargar inmuebles
              </Button>
            </Grid>
            <Grid item>
              <Button
                info="Descarga reporte de servicios públicos"
                color="primary"
                variant="contained"
                onClick={() => onSubmit("companyServiceGrouping")}
              >
                Descargar servicios públicos
              </Button>
            </Grid>
          </Grid>
        </Grid>) : (
        <GroupingTemplateApiLoading />
      )}
    </>
  );
};

export default GroupingDownloadTemplate;