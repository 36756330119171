import { useMemo } from 'react';
import { NativePDF } from './PdfViewer.styles';

/**
 * Componente PdfViewer
 * 
 * Este componente se utiliza para mostrar un archivo PDF en un visor.
 * De momento los parametros props.name y props.nativeVersion no se están usando
 * 
 * @component
 * @param {string} props.file - Los datos del archivo PDF en formato base64.
 * @param {string} props.name - El nombre del archivo PDF.
 * @param {boolean} [props.nativeVersion] - Opcional. Indica si se debe utilizar el visor PDF nativo del navegador.
 * 
 * @example
 *  Ejemplo de uso:
 * <PdfViewer file={base64Data} name="documento.pdf" nativeVersion={true} />
 */
export type PdfViewerProps = {
  file: string;
  name: string;
  nativeVersion?: boolean;
};
const _base64ToArrayBuffer = (base64: string) => {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};
const PdfViewer = ({ file, name, nativeVersion }: PdfViewerProps) => {
  const url = useMemo(() => URL.createObjectURL(new Blob([_base64ToArrayBuffer(file)], { type: 'application/pdf' })), [file]);
  return (
    <>
      <NativePDF src={url} aria-label="file" width="100%" height="100%" />
    </>
  );
};

export default PdfViewer;
