import styled from 'styled-components';

import Typography from '../Typography';

const DividerRoot = styled.div`
  width: 100%;
  height: 46%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  text-align: center;
  background-color: inherit;
  color: ${({ theme }) => theme.palette.divider};
`;

const TypographyStyles = styled(Typography)`
  padding: ${({ theme }) => theme.shape.spacing(3)};
  background-color: inherit;
  color: inherit;
  display: inline-block;
  font-size: 16px !important;
`;

export { DividerRoot, TypographyStyles };
