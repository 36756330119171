import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { msalConfig } from 'config/azure';
import {
  AuthContextProvider,
  CustomerContextProvider,
  ThemeContextProvider,
} from 'contexts';
import React from 'react';
import ReactDOM from 'react-dom';
import { history, HistoryRouter } from 'router';
import { SWRConfig } from 'swr';
import App from './App';
import reportWebVitals from './reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
    >
      <MsalProvider instance={msalInstance}>
        <HistoryRouter history={history}>
          <CustomerContextProvider>
            <AuthContextProvider>
              <ThemeContextProvider>
                <SWRConfig
                  value={{
                    fetcher: (resource, init) =>
                      axios.get(resource, init).then((res) => res.data),
                  }}
                >
                  <App />
                </SWRConfig>
              </ThemeContextProvider>
            </AuthContextProvider>
          </CustomerContextProvider>
        </HistoryRouter>
      </MsalProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
