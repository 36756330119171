import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { AiFillRobot, AiOutlineCaretRight, AiOutlineHistory, AiOutlinePoweroff } from 'react-icons/ai';
import { SocketAMProcess } from 'types';
import { ListBotTemplateFilterType } from './ListAMBotTemplate.schema';
import moment from 'moment';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<SocketAMProcess> = {
  field: 'processName',
  order: 'ASC',
};

export const defaultListBotTemplateFilter: ListBotTemplateFilterType = {
  name: undefined,
};

export const calcularDiferenciaEnFormatoHHMMSS = (fecha1: Date | undefined | null, fecha2: Date | undefined | null): string => {
  if (!fecha1 || !fecha2) {
    return '';
  }

  const momentFecha1 = moment(fecha1);
  const momentFecha2 = moment(fecha2);

  const diferenciaEnMilisegundos = momentFecha2.diff(momentFecha1);
  const duracion = moment.duration(diferenciaEnMilisegundos);

  const horas = duracion.hours();
  const minutos = duracion.minutes();
  const segundos = duracion.seconds();

  return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
}

const isRunningProcess = (_process: SocketAMProcess): boolean => ['Pendiente', 'En ejecución'].includes(_process.status ? _process.status.name : '')


export const getColumns: (
  confirmProcess: (_process: SocketAMProcess, action: 'start' | 'stop') => void,
  openHistory: (_process: SocketAMProcess) => void,
) => columnType<SocketAMProcess>[] = (confirmProcess, openHistory) => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '3',
    exportField: true,
    render: ({ processName }) => (
      <>
        <AiFillRobot />
        <span title={processName}>&nbsp;{processName}</span>
      </>
    ),
  },
  {
    field: 'lastExecution',
    headerName: 'Última ejecución',
    flex: '3',
    exportField: true,
    render: ({ lastExecution }) => (<span title={lastExecution}>{lastExecution}</span>),
  },
  {
    field: 'duration',
    headerName: 'Duración (hh:mm:ss)',
    flex: '2',
    exportField: true,
    render: ({ startDate, endDate }) => { return endDate && startDate && calcularDiferenciaEnFormatoHHMMSS(startDate, endDate) },
  },
  {
    field: 'status',
    headerName: 'Estado',
    flex: '2',
    exportField: true,
    render: ({ status }) => (<>
      {status ? <strong style={{ color: status.color }}> {status.name}</strong> : ''}
    </>),
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    // align: "flex-end",
    flex: '2',
    disabledSort: true,
    render: (_process) => (
      <>
        {_process.amApiUrl ?
          <><IconButton
            info={isRunningProcess(_process) ? 'Detener Bot' : 'Iniciar Bot'}
            color={isRunningProcess(_process) ? 'error' : 'success'}
            onClick={() => confirmProcess(_process, isRunningProcess(_process) ? 'stop' : 'start')}
          >
            {isRunningProcess(_process) ?
              <AiOutlinePoweroff /> : <AiOutlineCaretRight />
            }
          </IconButton></> : ''
        }
        <IconButton
          info="Historial"
          positionEdge="start"
          color="info"
          onClick={() => openHistory(_process)}
        >
          <AiOutlineHistory />
        </IconButton>

      </>
    ),
  },
];


