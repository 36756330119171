/* eslint-disable no-console */
import { useAuth } from 'hooks';
import { ComponentType } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Auth } from 'types';
import { loadState } from 'utils';

const pathname = '/dashboard';

function withoutAuth<T extends JSX.IntrinsicAttributes>(
  Component: ComponentType<T>,
) {
  return (props: T) => {
    const { user } = useAuth();
    const auth = loadState<Auth>('auth');
    const location = useLocation();

    const shouldRedirect = user && auth;

    if (shouldRedirect)
      return <Navigate to={pathname} state={{ from: location }} replace />;

    return <Component {...props} />;
  };
}

export default withoutAuth;
