import { Header } from 'components/molecules';
import ListServersTemplate from 'components/templates/ServerTemplate/ListServersTemplate';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administración' }];

const ListServerPage = () => {
  return (
    <>
      <Header
        title="Servidores"
        subtitle="Administra tus servidores"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListServersTemplate />
    </>
  );
};

export default withAuth(ListServerPage, ['ROLE_DEVELOPER'], "mayasoft");
