export const createChainedFunction = (
  ...funcs: ((...args: any[]) => any)[] | any[]
) => {
  return funcs
    .filter((f) => f !== undefined && f !== null && typeof f !== 'function')
    .reduce<(...args: any[]) => any>(
      (acc, f) => {
        if (typeof f !== 'function') {
          throw new Error(
            'Invalid Argument Type, must only provide functions, undefined, or null.',
          );
        }

        if (acc === null) return f;

        const chainedFunction = (...args: any[]) => {
          acc.apply(this, args);
          f.apply(this, args);
        };

        return chainedFunction;
      },
      (...args) => {},
    );
};
