import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import { Socket } from 'socket.io-client';


import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  SnackBar,
  Typography,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { DELETE_AM } from 'const';
import { useDialog, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';
import { PaginationType, Process, SocketAMProcess } from 'types';
import { AutomationManager } from 'types/entities/automation-manager';
import { createFilterStructure } from 'utils';
import AMLogsTemplate from '../AMLogsTemplate';
import { DialogContent } from '../AMLogsTemplate/AMLogs.Styles';
import {
  defaultListAMTemplateFilter,
  defaultPagination,
  defaultSortModel,
  fetchProcess,
  getColumns,
} from './ListAMTemplate.const';
import {
  ListAMTemplateFilterSchema,
  ListAMTemplateFilterType,
} from './ListAMTemplate.schema';
import { ListAMTemplateProps } from './ListAMTemplate.types';
const automationManagerSockets = new Map<string, Socket>();
const socketAMProcessMap = new Map<string, SocketAMProcess[]>();
let updateRowsBySocket = () => { };

const ListAMBotTemplate = ({ ...props }: ListAMTemplateProps) => {

  moment.locale('es');
  const [loadingForm, setLoadingForm] = useState(false);
  const [dataRows, setDataRows] = useState<AutomationManager[]>([]);
  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [action, setAction] = useState<'remove' | 'edit' | 'viewLogs' | undefined>(
    undefined,
  );
  const [actionSnackBar, setActionSnackBar] = useState<'remove' | 'edit' | 'upload' | 'download' | 'activate' | 'confirm' | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ListAMTemplateFilterType>({
    defaultValues: defaultListAMTemplateFilter,
    resolver: yupResolver(ListAMTemplateFilterSchema),
  });

  const callAutomationManager = useCallback(
    async (_process: Process, _action: 'start' | 'stop') => {
      switch (_action) {
        case 'start':
          await axios.get(`${_process.amApiUrl}/execute`);
          break;
        case 'stop':
          await axios.get(`${_process.amApiUrl}/stop`);
          break;
      }
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setActionSnackBar(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const confirmRemove = useCallback(
    (id: number) => {
      onOpen(id);
      setAction('remove');
    },
    [onOpen],
  );


  const [filterModel, setFilterModel] = useState<filterModelType<AutomationManager>[]>([]);

  const {
    loading,
    error,
    data: process,
    handleData,
    getData,
    mutate,
  } = useGetTable<PaginationType<AutomationManager>, tableModelType<AutomationManager>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });


  const tableControllers = useTable<AutomationManager>(
    {
      filterModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<AutomationManager>, 'paginationModel'>,
    ) => Promise<PaginationType<AutomationManager>>,
  );

  const removeAM = async (id: number) => {
    try {
      setLoadingForm(true);
      await axios.delete(DELETE_AM.replace(':id', id.toString()));
      await handleData({
        paginationModel: tableControllers.paginationModel,
        sortModel: tableControllers.sortModel,
        filterModel,
      });
      onClose();
      setLoadingForm(false);
      setOpen('success');
    } catch (error) {
      onClose();
      setLoadingForm(false);
      setOpen('error');
    }
  };

  const editUser = async (idUser: number) => {

  };

  const onSubmit = useCallback(
    (filterListBotTemplate: ListAMTemplateFilterType) => {
      const filterUsername = createFilterStructure(
        'name',
        'contains',
        filterListBotTemplate.name,
      );

      const modelFilter = [filterUsername].filter((item) => item);
      setFilterModel(modelFilter as filterModelType<AutomationManager>[]);
    },
    [],
  );

  const selectedAM = useMemo(
    () =>
      selected !== undefined
        ? process?.result.find((user) => user.id === selected)
        : undefined,
    [selected, process],
  );


  const getTitle = () => {
    if (action === 'remove' || action === 'edit') {
      return 'Confirma esta acción';
    }
    if (action === 'viewLogs') {
      return 'Logs de ejecución';
    }
    else return "";
  }

  const getModalSize = (): "lg" | "md" | "sm" | "xl" | undefined => {
    if (action === 'viewLogs') {
      return 'lg';
    }
    else return undefined;
  }

  updateRowsBySocket = () => {
    /*for (let entry of Array.from(socketAMProcessMap.entries())) {
      let amApiUrl = entry[0];
      let data = entry[1];
      dataRows.forEach((dataRow: AutomationManager) => {
        if (dataRow.amApiUrl && dataRow.amApiUrl.indexOf('/process/') !== -1) {
          const amApiUrlSplit = dataRow.amApiUrl.split('/process/');
          const amUrl = amApiUrlSplit[0]
          const processName = amApiUrlSplit[1]
          if (amUrl === amApiUrl) {

            const socketAMProcess: SocketAMProcess | undefined = getLastStatusSocketAMProcess(data, processName);
            if (socketAMProcess) {
              dataRow.status = socketAMProcess.status;
              const startDate = socketAMProcess.startDate ? socketAMProcess.startDate : socketAMProcess.addedDate;
              dataRow.lastExecution = moment(startDate).format('D [de] MMM hh:mm:ss a');
              handleRowChange(dataRow);
            }

          }
        }
      });
    }*/
  }

  useEffect(() => {
    setDataRows(process?.result || []);
    updateRowsBySocket();
  }, [process]);


  const getLastStatusSocketAMProcess = (data: SocketAMProcess[], processName: string) => {
    return data.filter((socketAmProcess: SocketAMProcess) => socketAmProcess.processName === processName).pop();
  }

  const getIndexFromId = (id: number): number => dataRows ? dataRows.findIndex(r => r.id === id) : -1;


  const handleRowChange = (newRow: AutomationManager) => {
    if (dataRows) {
      const index = getIndexFromId(newRow.id);
      setDataRows([...dataRows.slice(0, index), newRow, ...dataRows.slice(index + 1)])
    }
  }

  const onViewLogs =
    async (id: number) => {
      const AM = process?.result.find((am) => am.id === id);
      onOpen(id);
      setAction('viewLogs');
    };

  const columns = useMemo(
    () => getColumns(callAutomationManager, confirmRemove, onViewLogs),
    [callAutomationManager, process, selectedAM],
  );



  useEffect(() => {

    dataRows.forEach((row) => {
      /*if (row.amApiUrl && row.amApiUrl.indexOf('/process/') !== -1) {
        const amUrlSplit = row.amApiUrl.split('/process/')
        const amUrl = amUrlSplit[0];
        if (!automationManagerSockets.has(amUrl)) {
          const socket = io(amUrl, { transports: ['websocket', 'polling', 'flashsocket'] });
          socketAMProcessMap.set(amUrl, []);
          socket.on('processes', (data: SocketAMProcess[]) => {
            socketAMProcessMap.set(amUrl, data);
            updateRowsBySocket();
          });
          socket.on('error', (errorSocket) => {
            console.log('on error socket', errorSocket);
          });
          socket.on('connect', () => {
            updateRowsBySocket();
            console.log('on connect socket', socket.id);
          });
          socket.on('disconnect', (event) => {
            updateRowsBySocket();
            console.log('on disconnect socket', event);
          });
          automationManagerSockets.set(amUrl, socket);
        }
      }*/
    })

  }, [dataRows]);



  const onShow = () => {
    console.log(selected)
  }



  return (
    <>
      <Table<AutomationManager>
        {...tableControllers}
        numberOfVisibleColumns={4}
        title={`Encontramos ${tableControllers.paginationModel.rowsCount} Automation Manager`}
        columns={columns}
        rows={dataRows}
        error={!!error}
        loading={loading}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <Grid item>
              <IconButton
                info="Crear automation manager"
                variant="contained"
                href="/pages/management/automationmanager/create"
              >
                <IoAdd />
              </IconButton>
            </Grid>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={4}
                  wrap="nowrap"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      placeholder="Busca por nombre"
                      {...register('name')}
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      isSmall
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title={getTitle()}
        maxWidth={getModalSize()}
      >
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={12}>
            {action === 'remove' && (
              <Typography variant="h2">
                {`¿Estas seguro de eliminar el automation manager ${selectedAM?.name}?`}
              </Typography>
            )}
            {action === 'edit' && (
              <Typography variant="h2">
                {`¿Estas seguro de editar el estado del usuario ${selectedAM?.name}?`}
              </Typography>
            )}
            {action === 'viewLogs' && (
              <DialogContent>
                <AMLogsTemplate
                  setOpen={setOpen}
                  onClose={onClose}
                  setErrorMessage={setErrorMessage}
                  automationManager={selectedAM as AutomationManager}
                />
              </DialogContent>
            )}
          </Grid>
          <Grid item>
            {(action === 'remove' || action === 'edit') && (
              <Button variant="contained" color="grey" onClick={onClose}>
                Cancelar
              </Button>
            )}
          </Grid>
          <Grid item>
            {action === 'remove' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  removeAM(selectedAM?.id ? selectedAM?.id : 0)
                }
                loading={loadingForm}
              >
                Si, deseo eliminarlo
              </Button>
            )}
            {action === 'edit' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  editUser(selectedAM?.id ? selectedAM?.id : 0)
                }
                loading={loadingForm}
              >
                Si, deseo modificarlo
              </Button>
            )}

          </Grid>
        </Grid>
      </Dialog>
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' &&
          action === 'edit' &&
          'Modificamos el estado del usuario exitosamente'}
        {open === 'success' &&
          action === 'remove' &&
          'Eliminamos el usuarios exitosamente'}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default ListAMBotTemplate;
