import { useMsal } from '@azure/msal-react';
import { googleLogout } from '@react-oauth/google';
import alcabama from 'assets/img/alcabama.png';
import buenvivir from 'assets/img/buenvivir.png';
import capital from 'assets/img/capital.png';
import cusezar from 'assets/img/cusezar.png';
import jaramillo_mora from 'assets/img/jaramillo_mora.png';
import logicem from 'assets/img/logicem.png';
import fl_colombia from 'assets/img/fl_colombia.png';
import marval from 'assets/img/marval.png';
import mayasoft from 'assets/img/mayasoft.png';
import prodesa from 'assets/img/prodesa.png';
import triada from 'assets/img/triada.png';
import { Avatar, IconButton, Popover } from 'components/atoms';
import Menu from 'components/atoms/Menu';
import MenuItem from 'components/atoms/MenuItem';
import { logoutRequest } from 'config/azure';
import { useCustomerContext } from 'contexts/CustomerContext';
import { useAuth, useMenu, useTheme } from 'hooks';
import { HiOutlineLogout } from 'react-icons/hi';
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { customerType } from 'types';
import { Container, Header } from './AppBar.styles';

const imgs: Record<customerType, string> = {
  prodesa,
  marval,
  buenvivir,
  mayasoft,
  gmail: mayasoft,
  cusezar,
  alcabama,
  jaramillo_mora,
  capital,
  triada,
  logicem,
  fl_colombia
};

const AppBar = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { logout, user } = useAuth();
  const { theme, toggleTheme } = useTheme();
  const { name } = useCustomerContext();
  const [anchor, handleOpen, handleClose] = useMenu<HTMLDivElement>();

  const open = Boolean(anchor);
  const id = open ? 'simple-popover' : undefined;

  const logoutOffice = async () => {
    await instance.logoutPopup(logoutRequest(instance));
    logout();
  };

  const logoutGoogle = async () => {
    googleLogout();
    logout();
  };

  const goToChangePassword = () => {
    navigate('/pages/account/change_password');
  };

  const goToHome = () => {
    navigate('/pages/home');
  };

  return (
    <Header>
      <Container>
        <img
          src={imgs[name]}
          alt="marval-logo"
          height="50px"
          onClick={goToHome}
        />
        <div style={{ display: 'flex', flexGrow: 1 }} />
        <IconButton
          onClick={toggleTheme}
          positionEdge="end"
          color="grey"
          info={{
            position: 'bottom start',
            message: theme.isDarkMode ? 'Tema claro' : 'Tema oscuro',
          }}
        >
          {theme.isDarkMode ? (
            <MdLightMode size="24" />
          ) : (
            <MdDarkMode size="24" />
          )}
        </IconButton>
        <Avatar onClick={handleOpen}>
          {user?.username[0].toUpperCase() || 'M'}
        </Avatar>
        <Popover
          id={id}
          open={open}
          anchorEl={anchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Menu>
            {/* <MenuItem
              disabled
              disabledBorder
              startAdornment={<MdAccountCircle size={22} />}
            >
              Cuenta
            </MenuItem> */}
            {user?.provider === null && (
              <MenuItem
                disabledBorder
                startAdornment={<RiLockPasswordLine size={22} />}
                onClick={goToChangePassword}
              >
                Cambiar contraseña
              </MenuItem>
            )}

            {user?.provider === 'GOOGLE' && (
              <MenuItem
                disabledBorder
                startAdornment={<HiOutlineLogout size={22} />}
                onClick={logoutGoogle}
              >
                Cerrar sesión
              </MenuItem>
            )}
            {user?.provider === 'OFFICE365' && (
              <MenuItem
                disabledBorder
                startAdornment={<HiOutlineLogout size={22} />}
                onClick={logoutOffice}
              >
                Cerrar sesión
              </MenuItem>
            )}
            {user?.provider === null && (
              <MenuItem
                disabledBorder
                startAdornment={<HiOutlineLogout size={22} />}
                onClick={logout}
              >
                Cerrar sesión
              </MenuItem>
            )}
          </Menu>
        </Popover>
      </Container>
    </Header>
  );
};

export default AppBar;
