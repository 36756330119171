import { IPublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string,
    authority: 'https://login.microsoftonline.com/common/', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_DOMAIN as string,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};

export const logoutRequest = (instance: IPublicClientApplication) => ({
  account: instance.getActiveAccount(),
  mainWindowRedirectUri: process.env.REACT_APP_DOMAIN as string,
  postLogoutRedirectUri: process.env.REACT_APP_DOMAIN as string,
});
