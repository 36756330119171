import { useEffect, useState } from "react";
import { Grid, PdfViewer } from "../../../../atoms";
import HistoryTemplateApiLoading from "./HistoryTemplate.loading";

const HistoryTemplatePdf = ({ fileView }: { fileView: Blob | undefined }) => {

  const [loading, setLoading] = useState(true);
  const [pdfBase64, setPdfBase64] = useState<string | undefined>("");


  const converter = async (fileView: Blob) => {
    let base64;

    const reader = new FileReader();
    reader.readAsDataURL(fileView);

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        base64 = reader.result;
        base64 = base64?.toString();
        base64 = base64?.split(',')[1];
        setPdfBase64(base64);
        resolve(base64);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };


  useEffect(() => {
    const execute = async () => {
      setLoading(true);
      if (fileView) {
        await converter(fileView);
      }
      setLoading(false);
    }
    execute();
  }, [fileView]);

  return (
    <>
      {!loading && pdfBase64 !== "" ? (
        <Grid container lg xs={12}>
          <PdfViewer
            nativeVersion
            name={"fileName"}
            file={pdfBase64!}
          />
        </Grid>

      ) : <HistoryTemplateApiLoading />}
    </>

  );
};

export default HistoryTemplatePdf;
