import { Cities } from "../const";

export function getAllDepartments(): string[] {
  return Cities.map(city => city.departamento);
}

type City = {
  id: number;
  city: string;
}

export function getCitiesByDepartment(departmentName: string): City[] {
  if (departmentName === "") {
    const allCities: City[] = [];
    Cities.forEach((cityObject, index) => {
      cityObject.ciudades.forEach((city) => {
        allCities.push({
          id: index + 1, // Auto-incremental ID
          city: city,
        });
      });
    });
    return [];
  }
  const department = Cities.find((cityObject) => cityObject.departamento === departmentName);
  if (department) {
    return department.ciudades.map((city, index) => ({
      id: index + 1, // Auto-incremental ID within the department
      city: city,
    }));
  } else {
    return [];
  }
}