import { array, boolean, object, string, TypeOf } from 'yup';

export const CreateAMSchema = object({
  name: string().required().max(95),
  url: string()
    .required()
    .matches(
      /^(http|https):\/\/([a-z0-9]+([\-.]{1}[a-z0-9]+)*|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:[0-9]{1,5})?(\/.*)?$/,
      'URL no válida',
    ),
  customer: string(),
  logsPaths: array().of(
    object({
      path: string(),
      description: string(),
      validated: boolean(),
    }),
  ),
});

export type CreateAMType = TypeOf<typeof CreateAMSchema>;
