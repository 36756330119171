import ReactJson from '@microlink/react-json-view';
import { Grid, IconButton, Paper, Typography } from 'components/atoms';
import Toggle from 'components/atoms/Toggle';
import Accordion from 'components/molecules/Accordion';
import { Section } from 'components/molecules/Accordion/Accordion.const';
import { BasicTable, Svg, TableCell, TableRow } from 'components/organisms';
import { Grow, TableContainer, TableHead } from 'components/organisms/Table/Table.styles';
import { getCurrentTimeToString } from 'const';
import { useDownloadFile } from 'hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import 'react-medium-image-zoom/dist/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Exception } from 'types';
import { rowsMapper } from '../ListExceptionTemplate/ListExceptionTemplate.const';
import ListExceptionApiLoading from '../ListExceptionTemplate/ListExceptionTemplate.loading';
import { fetchException, fetchExceptionExtraDAtaFile, getColumns } from './ExceptionDetailTemplate.const';
import { ScrollableGrid, StyledZoom } from './ExceptionDetailTemplate.styles';
import { ExceptionDetailTemplateProps } from './ExceptionDetailTemplate.types';

const ExceptionDetailTemplate = ({
  exceptionId,
  setOpen,
  setErrorMessage,
  setActionSnackBar,
  onClose,
  onCheck,
  modifiedExceptions,
  ...props }: ExceptionDetailTemplateProps) => {
  const [exception, setException] = useState<Exception | undefined>();
  const ref = useRef<HTMLTableElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const getJsonException = () => {
    if (exception?.extradata) {
      const { extradata } = exception;
      return extradata;
    }
    return {};
  }

  const columns = useMemo(
    () => getColumns(),
    [],
  );

  /**
   * Block of code related to the custom hook to download files
   *  
   */

  const onDownload = () => {
    try {
      download()
    }
    catch (error) {
      console.log(error)
    }
  }

  const downloadExtradataFile = () => {
    return fetchExceptionExtraDAtaFile(exception?.id!)
  }

  const preDownloading = () => {
    setIsDownloading(true);
  };
  const postDownloading = () => {
    setIsDownloading(false);
  };
  const onErrorDownloadFile = (errorMessage: string | undefined) => {
    setIsDownloading(false);
    setOpen('error');
  };
  const getFileName = () => {
    return `extradata__${getCurrentTimeToString()}.json`;
  };

  const { download } = useDownloadFile({
    apiDefinition: downloadExtradataFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  /**
   * End of block of code related to the custom hook to download files
   */


  const getDetailSections = () => {
    let sections: Section[] = [];

    if (exception?.stack) sections.push(
      {
        header: 'Stack trace',
        content: <Typography variant='p'>{exception?.stack}</Typography>
      });

    if (exception?.extradata || exception?.isLargeExtradata) {
      if (!exception.isLargeExtradata) {
        sections.push(
          {
            header: 'Extra data',
            content:
              <Grid
                container
                alignItems="baseline"
                justify="center"
                spacing={1}
              >
                <Grid item xs={12} justify='flex-end' >
                  <IconButton
                    info="Descarga extradata"
                    color="primary"
                    positionEdge="start"
                    edge={295}
                    loading={isDownloading}
                    onClick={onDownload}
                  >
                    <AiOutlineCloudDownload />
                  </IconButton>
                </Grid>
                <ScrollableGrid item xs={12} >
                  <ReactJson
                    src={getJsonException()}
                    theme="monokai"
                    displayDataTypes={false}
                    displayObjectSize={false}
                    enableClipboard={false}
                    name={false}
                  />
                </ScrollableGrid>
              </Grid>
          },
        )
      } else {
        sections.push(
          {
            header: 'Extra data',
            content:
              <Grid
                container
                alignItems="center"
                justify="flex-end"
                spacing={4}
              >
                <Grid item xs={11}  >
                  <Typography variant='p'>Extra data muy grande para ser mostrado</Typography>
                </Grid>
                <Grid item xs={1} >
                  <IconButton
                    positionEdge="start"
                    edge={15}
                    info="Descarga extradata"
                    color="primary"
                    onClick={onDownload}
                    loading={isDownloading}
                  >
                    <AiOutlineCloudDownload />
                  </IconButton>
                </Grid>
              </Grid>
          },
        )
      }
    }

    if (exception?.screenshot) sections.push(
      {
        header: 'Screenshot',
        content:
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {exception?.screenshot &&
              <StyledZoom>
                <img
                  alt="Error screenshot"
                  src={`data:image/jpeg;base64,${exception.screenshot}`}
                  height="500"
                />
              </StyledZoom>

            }
          </Grid>
      }
    );
    return sections;
  }

  const onToggleChange = (newState: boolean, sourceElement: number | string | number[] | string[] | undefined): void => {
    onCheck(newState, sourceElement);
    const modifiedException = { ...exception, isResolved: newState } as Exception;
    setException(modifiedException);

  }

  useEffect(() => {
    fetchException({ id: exceptionId }).then((response) => {
      setException(rowsMapper(response.data, modifiedExceptions)[0]);
    }).catch((error) => {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
        setOpen('warning');
      } else {
        setErrorMessage(undefined);
        setOpen('error');
      }
      onClose();
    });
  }, [])

  return (
    exception ?
      <Paper>
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={9}
        >
          <Grid item xs={11}  >
            <Typography variant='h2' align='left'>Mensaje: {exception.message}</Typography>
          </Grid>
          <Grid item xs={1}  >
            <Toggle
              isChecked={exception.isResolved}
              onToggleChange={onToggleChange}
              sourceElement={exception.id}
              toggleSize='sm'
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={7}
        >
          <Grid item xs={12}  >
            <TableContainer $height={1}>
              <TableHead as="div">
                <TableRow as="div">
                  {columns.map(
                    (
                      { headerName, field, flex },
                      idx,
                    ) => (
                      <TableCell
                        as="div"
                        key={`head.${field}.${headerName}`}
                        $flex={flex}
                      >
                        {headerName}
                        <Grow />
                        {columns.length - 1 > idx && (
                          <Svg
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="SeparatorIcon"
                          >
                            <path d="M11 19V5h2v14z"></path>
                          </Svg>
                        )}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <PerfectScrollbar>
                <BasicTable<Exception>
                  numberOfVisibleColumns={4}
                  ref={ref}
                  columns={columns}
                  rows={[exception]}
                  rowsPerPage={1}
                />
              </PerfectScrollbar>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          spacing={4}
        >
          <Accordion
            sections={getDetailSections()}
          />

        </Grid>
      </Paper>
      : <ListExceptionApiLoading />
  );

};

export default ExceptionDetailTemplate;
