import axios, { AxiosRequestConfig } from 'axios';
import { GET_ROLE, GET_ROLE_MENU, GET_USERS } from 'const';
import { Menu, Role, User } from 'types';

export const fetchMenus = (_: any, config?: AxiosRequestConfig<Menu[]>) => {
  return axios.get<Menu[]>(GET_ROLE_MENU, config);
};

export const fetchUsers = (_: any, config?: AxiosRequestConfig<User[]>) => {
  return axios.get<User[]>(GET_USERS, config);
};

export const fetchRole = ({ id }: any, config?: AxiosRequestConfig<Menu[]>) => {
  return axios.get<Role>(GET_ROLE.replace(':id', id), config);
};
