import PerfectScrollbar from 'react-perfect-scrollbar';
import styled, { css } from 'styled-components';
import Button from '../Button';
import Grid from '../Grid';

export const GridRoot = styled(Grid)`
  max-width: 180px;
`;

export const CustomPerfectScrollbar = styled(PerfectScrollbar)<{
  $maxItems?: number;
  $maxWidth?: number;
}>`
  width: ${({ $maxWidth }) => $maxWidth || 64}px;
  ${({ $maxItems }) =>
    $maxItems &&
    css`
      max-height: ${$maxItems * 55}px;
    `}
`;

export const Item = styled(Button)`
  padding: ${({ theme }) => theme.shape.spacing(2)};
  margin: ${({ theme }) => theme.shape.spacing(1)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};

  & > div {
    line-height: 1 !important;
  }
`;
