import { object, string, TypeOf } from 'yup';

export const ListGroupingTemplateFilterSchema = object({
  name: string(),
  area: string(),
  projectsIds: string(),
  publicServices: string(),
  status: string(),
  referenceStatus: string(),
});

export type ListGroupingTemplateFilterType = TypeOf<
  typeof ListGroupingTemplateFilterSchema
>;
