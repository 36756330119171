import { columnType } from 'components/molecules';
import { Server } from 'types';
import { formatDate, StatusColor } from '../Shared/shared.const';

export const getColumns: (
) => columnType<Server>[] = () => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '2',
    exportField: true,
    render: ({ name }) => (<span title={name}>{name}</span>),
  },
  {
    field: 'ip',
    headerName: 'IP',
    flex: '2 ',
    exportField: true,
    render: ({ ip }) => (<span title={ip}>{ip}</span>),
  },
  {
    field: 'domain',
    headerName: 'Dominio',
    flex: '4.5',
    exportField: true,
    render: ({ domain }) => (<strong>{domain}</strong>),
  },
  {
    field: 'provider',
    headerName: 'Proveedor',
    flex: '1.5',
    exportField: true,
    render: ({ provider }) => (<span title={provider}>{provider}</span>),
  },
  {
    field: 'operatingSystem',
    headerName: 'OS',
    flex: '1.5',
    exportField: true,
    render: ({ operatingSystem }) => (<span title={operatingSystem}>{operatingSystem}</span>),
  },
  {
    field: 'status',
    headerName: 'Estado',
    flex: '1.5',
    exportField: true,
    render: ({ status }) => (<strong style={{ color: StatusColor[status] }}>{status}</strong>),
  },
  {
    field: 'responseTime',
    headerName: 'Latencia',
    flex: '1',
    exportField: true,
    render: ({ responseTime }) => (<span title={responseTime ? responseTime.toString() : ''}>{responseTime}</span>),
  },
  {
    field: 'timeout',
    headerName: 'Timeout',
    flex: '1',
    exportField: true,
    render: ({ timeout }) => (<strong>{timeout}</strong>),
  },
  {
    field: 'maxRetry',
    headerName: 'Reintentos',
    flex: '1.5',
    exportField: true,
    render: ({ maxRetry }) => (<span title={maxRetry ? maxRetry.toString() : ''}>{maxRetry}</span>),
  },
  {
    field: 'errorCount',
    headerName: 'Errores',
    flex: '1',
    exportField: true,
    render: ({ errorCount }) => (<span title={errorCount ? errorCount.toString() : ''}>{errorCount}</span>),
  },
  {
    field: 'disabled',
    headerName: 'Deshabilitado',
    flex: '2',
    exportField: true,
    render: ({ disabled }) => (<span title={disabled !== undefined && disabled ? 'Si' : 'No'}>{disabled ? 'Si' : 'No'}</span>),
  },
];

export const getColumnsAdditionalDataProvider: () => columnType<Server>[] = () => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '4',
    exportField: true,
    render: ({ additionalDataProvider }) => (<span title={additionalDataProvider?.name}>{additionalDataProvider?.name}</span>),
  },
  {
    field: 'cpuCores',
    headerName: 'CPU Cores',
    flex: '1.5',
    exportField: true,
    render: ({ additionalDataProvider }) => (<span title={additionalDataProvider?.cpuCores}>{additionalDataProvider?.cpuCores}</span>),
  },
  {
    field: 'ram',
    headerName: 'RAM',
    flex: '1',
    exportField: true,
    render: ({ additionalDataProvider }) => (<span title={additionalDataProvider?.ram}>{additionalDataProvider?.ram}</span>),
  },
  {
    field: 'disk',
    headerName: 'Disco',
    flex: '1',
    exportField: true,
    render: ({ additionalDataProvider }) => (<span title={additionalDataProvider?.disk}>{additionalDataProvider?.disk}</span>),
  },
  {
    field: 'createdDate',
    headerName: 'Fecha de creación',
    flex: '3',
    exportField: true,
    render: ({ additionalDataProvider }) => (<span title={formatDate(additionalDataProvider?.createdDate?.toString() ?? '')}>{formatDate(additionalDataProvider?.createdDate?.toString() ?? '')}</span>),
  },
  {
    field: 'cancelDate',
    headerName: 'Fecha de cancelación',
    flex: '3',
    exportField: true,
    render: ({ additionalDataProvider }) => (<span title={formatDate(additionalDataProvider?.cancelDate?.toString() ?? '')}>{formatDate(additionalDataProvider?.cancelDate?.toString() ?? '')}</span>),
  },
  {
    field: 'dataCenter',
    headerName: 'Data Center',
    flex: '4',
    exportField: true,
    render: ({ additionalDataProvider }) => (<span title={additionalDataProvider?.dataCenter}>{additionalDataProvider?.dataCenter}</span>),
  },
  {
    field: 'status',
    headerName: 'Estado',
    flex: '1',
    exportField: true,
    render: ({ additionalDataProvider }) => (<span title={additionalDataProvider?.status}>{additionalDataProvider?.status}</span>),
  },
];