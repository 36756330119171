import { Header } from 'components/molecules';
import CreateEditRegexTemplate from 'components/templates/RegexExceptionTemplate/CreateEditRegexTemplate';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administración' }];

const CreateEditRegexPage = () => {
    return (
        <>
            <Header
                title="Expresiones Regulares"
                subtitle="Agregar/Editar Expresión Regular"
                breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
            />
            <CreateEditRegexTemplate />
        </>
    );
};

export default withAuth(CreateEditRegexPage, ['ROLE_DEVELOPER', 'ROLE_SOPORTE'], "mayasoft");
