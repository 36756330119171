import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import io, { Socket } from 'socket.io-client';


import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  SnackBar,
  Typography,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { DELETE_USER, GET_ALL_PROCESS } from 'const';
import { useDialog, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';
import { PaginationType, Process, SocketAMProcess } from 'types';
import { createFilterStructure } from 'utils';
import {
  defaultListBotTemplateFilter,
  defaultPagination,
  defaultSortModel,
  getColumns,
} from './ListBotTemplate.const';
import {
  ListBotTemplateFilterSchema,
  ListBotTemplateFilterType,
} from './ListBotTemplate.schema';
import { ListBotTemplateProps } from './ListBotTemplate.types';
import { name } from '@azure/msal-browser/dist/packageMetadata';
const automationManagerSockets = new Map<string, Socket>();
const socketAMProcessMap = new Map<string, SocketAMProcess[]>();
let updateRowsBySocket = () => { };

const ListBotTemplate = ({ ...props }: ListBotTemplateProps) => {

  moment.locale('es');
  const [loadingForm, setLoadingForm] = useState(false);
  const [dataRows, setDataRows] = useState<Process[]>([]);

  let processData: Process[] = [];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ListBotTemplateFilterType>({
    defaultValues: defaultListBotTemplateFilter,
    resolver: yupResolver(ListBotTemplateFilterSchema),
  });

  const [selectedProcess, onOpen, onCloseModal] = useDialog<Process | undefined>(
    undefined,
  );
  const [action, setAction] = useState<'remove' | 'edit' | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();

  const confirmStopProcess = useCallback(
    (_process: Process) => {
      if (_process !== undefined) {
        onOpen(_process);
        setAction('remove');
      }
    },
    [onOpen],
  );

  const executeProcess = useCallback(
    async (_process: Process) => {
      await axios.get(`${_process.amApiUrl}/execute`);
    },
    [onOpen],
  );

  const stopProcess = useCallback(
    async (_process?: Process) => {
      if (_process) {
        onClose();
        await axios.get(`${_process.amApiUrl}/stop`);
      }
    },
    [onOpen],
  );


  const callAutomationManager = useCallback(
    async (_process: Process, _action: 'start' | 'stop', confirmRemove = true) => {
      switch (_action) {
        case 'start':
          await axios.get(`${_process.amApiUrl}/execute`);
          break;
        case 'stop':
          onOpen(_process);
          setAction('remove');
          await axios.get(`${_process.amApiUrl}/stop`);
          break;
      }
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setAction(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const columns = useMemo(
    () => getColumns(executeProcess, confirmStopProcess),
    [executeProcess, confirmStopProcess],
  );

  const [filterModel, setFilterModel] = useState<filterModelType<Process>[]>([]);

  const fetchProcess = async (
    { filterModel, sortModel, paginationModel }: tableModelType<Process>,
    config?: AxiosRequestConfig<Process>,
  ) => {
    return new Promise<AxiosResponse<PaginationType<Process>>>(async resolve => {
      const data = await axios.get<PaginationType<Process>>(
        `${GET_ALL_PROCESS}?page=0&limit=10000`,
        config,
      )
      if (processData.length === 0) {
        processData = [...data.data.result];
      }
      sortPaginateRows({ filterModel, sortModel, paginationModel });
      data.data.result = [...processData];
      resolve(data);
    })

    /*?${filterModel && filterModel.length > 0
          ? `filter=${JSON.stringify(filterModel)}&`
          : ''
        }${sortModel ? `sort=${JSON.stringify(sortModel)}&` : ''}${paginationModel
          ? `limit=${paginationModel.rowsPerPage}&page=${paginationModel.page}`
          : ''
        }
    const results: PaginationType<Process> = {
      page: 0,
      limit: 100,
      rowsCount: 2,
      result: data.data.result,
    }
    return results;
    */
  };
  const updateSocketProcessData = () => {
    for (let entry of Array.from(socketAMProcessMap.entries())) {
      let amApiUrl = entry[0];
      let data = entry[1];
      processData.forEach((dataRow: Process) => {
        if (dataRow.amApiUrl && dataRow.amApiUrl.indexOf('/process/') !== -1) {
          const amApiUrlSplit = dataRow.amApiUrl.split('/process/');
          const amUrl = amApiUrlSplit[0]
          const processName = amApiUrlSplit[1]
          if (amUrl === amApiUrl) {
            const socketAMProcess: SocketAMProcess | undefined = getLastStatusSocketAMProcess(data, processName);
            if (socketAMProcess) {
              dataRow.status = socketAMProcess.status;
              const startDate = socketAMProcess.startDate ? socketAMProcess.startDate : socketAMProcess.addedDate;
              dataRow.lastExecution = moment(startDate).format('D [de] MMM hh:mm:ss a');
            }
          }
        }
      });
    }
  }
  const sortPaginateRows = ({ filterModel, sortModel, paginationModel }: tableModelType<Process>) => {
    updateSocketProcessData();
    let processes: Process[] = [...processData];
    if (filterModel?.length && filterModel[0].value !== "") {
      const filterText = filterModel[0].value.toLowerCase();
      processes = processData.filter(data => data.name.toLowerCase().includes(filterText) || data.description?.toLowerCase().includes(filterText) || data.status?.name.toLowerCase().includes(filterText) || data.lastExecution?.toLocaleLowerCase().includes(filterText))
    }
    if (sortModel) {
      const { field, order } = sortModel;
      processes = processes.sort((a, b) => {
        let fistItem = b;
        let secondItem = a;
        if (order === 'DESC') {
          secondItem = b;
          fistItem = a;
        }
        let compare = fistItem.id.toString().localeCompare(secondItem.id.toString())
        switch (field) {
          case 'name':
            compare = fistItem.name.localeCompare(secondItem.name)
            break;
          case 'description':
            compare = (fistItem.description || '').localeCompare(secondItem.description || '')
            break;
          case 'status':
            compare = (fistItem.status?.name || '').localeCompare(secondItem.status?.name || '')
            break;
          case 'lastExecution':
            compare = (fistItem.lastExecution || '').localeCompare(secondItem.lastExecution || '')
            break;

        }
        return compare;
      })
    }
    let { rowsPerPage, page } = paginationModel;
    const processesLength = processes.length;
    if (processesLength < rowsPerPage || page > processesLength / rowsPerPage) {
      page = 0;
      paginationModel.page = 0;
    }
    let maxRows = (page + 1) * rowsPerPage;
    paginationModel.rowsCount = processesLength;
    if (maxRows > paginationModel.rowsCount) {
      maxRows = paginationModel.rowsCount
    }
    processes = processes.slice((page) * rowsPerPage, maxRows);
    setDataRows(processes);
  }

  const {
    loading,
    error,
    data: process,
    handleData,
    getData,
  } = useGetTable<PaginationType<Process>, tableModelType<Process>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });


  const tableControllers = useTable<Process>(
    {
      filterModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Process>, 'paginationModel'>,
    ) => Promise<PaginationType<Process>>,
  );

  const removeUser = async (idUser: number) => {
    try {
      setLoadingForm(true);
      await axios.delete(DELETE_USER.replace(':id', idUser.toString()));
      await handleData({
        paginationModel: tableControllers.paginationModel,
        sortModel: tableControllers.sortModel,
        filterModel,
      });
      onClose();
      setLoadingForm(false);
      setOpen('success');
    } catch (error) {
      onClose();
      setLoadingForm(false);
      setOpen('error');
    }
  };

  const editUser = async (idUser: number) => {

  };

  const onSubmit = useCallback(
    (filterListBotTemplate: ListBotTemplateFilterType) => {
      const filterUsername = createFilterStructure(
        'username',
        'contains',
        filterListBotTemplate.name,
      );

      const modelFilter = [filterUsername].filter((item) => item);
      setFilterModel(modelFilter as filterModelType<Process>[]);
    },
    [],
  );

  updateRowsBySocket = () => {
    for (let entry of Array.from(socketAMProcessMap.entries())) {
      let amApiUrl = entry[0];
      let data = entry[1];
      dataRows.forEach((dataRow: Process) => {
        if (dataRow.amApiUrl && dataRow.amApiUrl.indexOf('/process/') !== -1) {
          const amApiUrlSplit = dataRow.amApiUrl.split('/process/');
          const amUrl = amApiUrlSplit[0]
          const processName = amApiUrlSplit[1]
          if (amUrl === amApiUrl) {
            const socketAMProcess: SocketAMProcess | undefined = getLastStatusSocketAMProcess(data, processName);
            if (socketAMProcess) {
              dataRow.status = socketAMProcess.status;
              const startDate = socketAMProcess.startDate ? socketAMProcess.startDate : socketAMProcess.addedDate;
              dataRow.lastExecution = moment(startDate).format('D [de] MMM hh:mm:ss a');
              handleRowChange(dataRow);
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    updateRowsBySocket();
  }, [process]);


  const getLastStatusSocketAMProcess = (data: SocketAMProcess[], processName: string) => {
    return data.filter((socketAmProcess: SocketAMProcess) => socketAmProcess.processName === processName).pop();
  }

  const getIndexFromId = (id: number): number => dataRows ? dataRows.findIndex(r => r.id === id) : -1;


  const handleRowChange = (newRow: Process) => {
    if (dataRows) {
      const index = getIndexFromId(newRow.id);
      if (index !== -1) {
        setDataRows([...dataRows.slice(0, index), newRow, ...dataRows.slice(index + 1)])
      }
    }
  }

  useEffect(() => {
    dataRows.forEach((row) => {
      if (row.amApiUrl && row.amApiUrl.indexOf('/process/') !== -1) {
        const amUrlSplit = row.amApiUrl.split('/process/')
        const amUrl = amUrlSplit[0];
        if (!automationManagerSockets.has(amUrl)) {
          const url = new URL(amUrl);
          const socket = io(url.protocol + "//" + url.hostname + (url.port ? ':' + url.port : ''), { transports: ['websocket', 'polling', 'flashsocket'] });
          socketAMProcessMap.set(amUrl, []);
          socket.on('processes', (data: SocketAMProcess[]) => {
            socketAMProcessMap.set(amUrl, data);
            updateRowsBySocket();
          });
          socket.on('error', (errorSocket) => {
            console.log('on error socket', errorSocket);
          });
          socket.on('connect', () => {
            updateRowsBySocket();
            console.log('on connect socket', socket.id);
          });
          socket.on('disconnect', (event) => {
            updateRowsBySocket();
            console.log('on disconnect socket', event);
          });
          automationManagerSockets.set(amUrl, socket);
        }
      }
    })

  }, [dataRows]);

  return (
    <>
      <Table<Process>
        {...tableControllers}
        numberOfVisibleColumns={4}
        title={`Encontramos ${tableControllers.paginationModel.rowsCount} bots`}
        columns={columns}
        rows={dataRows}
        error={!!error}
        loading={loading}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <Grid item>

            </Grid>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={4}
                  wrap="nowrap"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      placeholder="Busca por nombre"
                      {...register('name')}
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      isSmall
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
      <Dialog
        open={selectedProcess !== undefined}
        onClose={onClose}
        title="Confirma esta acción"
      >
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={12}>
            {action === 'remove' && (
              <Typography variant="h2">
                {`¿Estas seguro de detener la ejecución de ${selectedProcess?.name}?`}
              </Typography>
            )}
            {action === 'edit' && (
              <Typography variant="h2">
                {`¿Estas seguro de editar el estado del usuario ${selectedProcess?.name}?`}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button variant="contained" color="grey" onClick={onClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            {action === 'remove' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  stopProcess(selectedProcess)
                }
                loading={loadingForm}
              >
                Sí, deseo detenerlo
              </Button>
            )}

            {action === 'edit' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  editUser(selectedProcess?.id ? selectedProcess?.id : 0)
                }
                loading={loadingForm}
              >
                Si, deseo modificarlo
              </Button>
            )}
          </Grid>
        </Grid>
      </Dialog>
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' &&
          action === 'edit' &&
          'Modificamos el estado del usuario exitosamente'}
        {open === 'success' &&
          action === 'remove' &&
          'Eliminamos el usuarios exitosamente'}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default ListBotTemplate;
