import styled, { css } from 'styled-components';
import Typography from '../Typography';

export const ButtonStyled = styled.button<{
  $loading: boolean;
  $small: boolean;
}>`
  width: var(--width);
  background: var(--background);
  border: 2px solid var(--border);
  color: var(--color);
  padding: ${({ $small }) => ($small ? '4px 15px' : '12px 20px')};
  display: inline-flex;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  position: relative;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  ${({ theme, $loading }) =>
    $loading &&
    css`
      :before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        border-top-color: #000000;
        animation: spinner 0.8s linear infinite;
      }
    `}

  &:disabled {
    cursor: not-allowed;
    color: var(--disabled-color);
    background: var(--disabled-background);
    border: 2px solid var(--disabled-border);
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
    background: var(--hover-background);
    color: var(--hover-color) !important;
    border: 2px solid var(--hover-border);
  }

  &:hover:not(:disabled) {
    background: var(--hover-background);
    color: var(--hover-color) !important;
    border: 2px solid var(--hover-border);
  }
`;

export const LabelStyled = styled(Typography)`
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  margin: 0px;
  font-size: inherit !important;
  font-weight: inherit;
`;

export const Adornment = styled.div<{ orientation: 'start' | 'end' }>`
  display: inherit;
  align-items: center;
  margin-left: ${({ orientation }) =>
    orientation === 'end' ? '12px' : 'auto'};
  margin-right: ${({ orientation }) =>
    orientation === 'start' ? '12px' : 'auto'};
  color: inherit;
  svg {
    fill: currentColor;
  }
`;
