import { DividerRoot, TypographyStyles } from './Divider.styles';

export type DividerProps = {
  children?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Divider = ({ children, ...others }: DividerProps) => {
  return (
    <DividerRoot {...others}>
      {children && (
        <TypographyStyles variant="p" color="inherit" align="center">
          {children}
        </TypographyStyles>
      )}
    </DividerRoot>
  );
};

export default Divider;
