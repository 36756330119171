import { createFilterStructure } from 'utils';

export const createFilterModel = (
  name: string | undefined,
  area: string | undefined,
  projects: string | undefined,
  publicServices: string | undefined,
  status: string | undefined,
  referenceStatus: string | undefined,
) => {
  let filterGroupingName;

  if (name?.includes(',')) {
    const names = name.split(',').map((name) => name.trim());
    filterGroupingName = createFilterStructure('name', 'in', names, 'grouping');
  } else {
    filterGroupingName = createFilterStructure(
      'name',
      'contains',
      name,
      'grouping',
    );
  }

  const filterArea = createFilterStructure('area', 'equals', area, 'grouping');

  const filterState = createFilterStructure(
    'status',
    'equals',
    status,
    'grouping',
  );

  const filterReferenceStatus = createFilterStructure(
    'status',
    'equals',
    referenceStatus,
  );
  const filterProjectStatus = createFilterStructure(
    'status',
    'equals',
    true,
    'project',
  );

  let modelFilter = [
    filterGroupingName,
    filterArea,
    filterState,
    filterReferenceStatus,
    filterProjectStatus,
  ].filter((item) => item);

  if (projects) {
    const filterProject = createFilterStructure(
      'id',
      'in',
      projects?.split(','),
      'project',
    );
    modelFilter = [filterProject, ...modelFilter].filter((item) => item);
  }

  if (publicServices) {
    const filterPublicService = createFilterStructure(
      'id',
      'in',
      publicServices?.split(','),
      'companyService',
    );
    modelFilter = [filterPublicService, ...modelFilter].filter((item) => item);
  }

  return modelFilter;
};
