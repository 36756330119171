import { forwardRef, memo, useEffect } from 'react';
import { debounce } from 'utils';
import { AlertProps } from '../Alert';
import Typography from '../Typography';
import { CloseIcon, SnackAlert, SnackRoot } from './SnackBar.styles';

export type SnackBarProps = {
  open: boolean;
  wait?: number;
  onClose?: () => void;
} & AlertProps &
  React.HTMLAttributes<HTMLDivElement>;

const SnackBar = memo(
  forwardRef<HTMLDivElement, SnackBarProps>(
    ({ title, children, severity, wait, onClose, open, ...props }, ref) => {
      useEffect(() => {
        const handleDisplay = debounce(() => {
          if (onClose) onClose();
        }, wait);

        if (wait && open) handleDisplay();

        return () => {
          handleDisplay.clear();
        };
      }, [wait, onClose, open]);

      return (
        <SnackRoot {...props}>
          {open && (
            <SnackAlert ref={ref} title={title} severity={severity}>
              <Typography color="inherit" variant="h3">
                {children}
              </Typography>
              <div style={{ display: 'flex', flexGrow: 1 }} />
              <CloseIcon size="19" onClick={() => onClose && onClose()} />
            </SnackAlert>
          )}
        </SnackRoot>
      );
    },
  ),
);

SnackBar.displayName = 'SnackBar';

export default SnackBar;
