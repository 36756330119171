import { useEnhancedEffect, useForkRef } from 'hooks';
import React, { Attributes } from 'react';
import * as ReactDOM from 'react-dom';
import { setRef } from 'utils';

function getContainer(
  container: React.ReactInstance | (() => React.ReactInstance | null) | null,
) {
  container = typeof container === 'function' ? container() : container;
  return ReactDOM.findDOMNode(container) as Element;
}

export type PortalProps = {
  children?: React.ReactNode;
  container?: React.ReactInstance | (() => React.ReactInstance | null) | null;
  disablePortal?: boolean;
  onRendered?: () => void;
};

const Portal = React.forwardRef<HTMLElement, PortalProps>(function Portal(
  props,
  ref,
) {
  const { children, container, disablePortal = false, onRendered } = props;
  const [mountNode, setMountNode] = React.useState<Element | null>(null);

  const handleRef = useForkRef(
    React.isValidElement(children) ? (children as any).ref : null,
    ref,
  );

  useEnhancedEffect(() => {
    if (!disablePortal) {
      setMountNode(getContainer(container || null) || document.body);
    }
  }, [container, disablePortal]);

  useEnhancedEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(ref, mountNode);
      return () => {
        setRef(ref, null);
      };
    }

    return undefined;
  }, [ref, mountNode, disablePortal]);

  useEnhancedEffect(() => {
    if (onRendered && (mountNode || disablePortal)) {
      onRendered();
    }
  }, [onRendered, mountNode, disablePortal]);

  if (disablePortal) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        ref: handleRef,
      } as Attributes);
    }
    return children as Exclude<React.ReactNode, undefined | {}>;
  }

  return mountNode ? ReactDOM.createPortal(children, mountNode) : mountNode;
});

export default Portal;
