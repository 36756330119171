import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button, Grid,
  Input, MenuItem, Paper, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getErrorsFromBackend } from 'utils';
import { CREATE_COMPANY } from '../../../../../const';
import { useGetTable } from '../../../../../hooks';
import { ServiceType } from '../../../../../types';
import { catchaStates, companyStates, fetchServiceTypes } from './CreateCompanyTemplate.const';
import CreateCompanyTemplateLoading from './CreateCompanyTemplate.loading';
import { CreateCompanySchema, CreateCompanyType } from './CreateCompanyTemplate.schema';


const CreateCompanyTemplate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const { data: serviceTypesFetch, loading: loadingMenus } = useGetTable<ServiceType[]>(
    fetchServiceTypes,
    [true],
  );

  const {
    control,
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors },
  } = useForm<CreateCompanyType>({
    resolver: yupResolver(CreateCompanySchema),
    defaultValues: {
      name: '',
      code: '',
      nit: '',
      url: '',
      catcha: 'false',
      status: 'true',
      graceDay: '',
      typeServices: '',
    },
  });

  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/servicios-publicos/management/company');
  };

  const onSubmit = async (companyForm: CreateCompanyType) => {
    try {
      setLoading(true);
      const { catcha, status, typeServices, ...form } = companyForm;

      await axios.post<CreateCompanyType>(CREATE_COMPANY, {
        ...form,
        catcha: catcha ? true : false,
        status: status ? true : false,
        typeServices: typeServices?.split(",").map(
          service => {
            return {
              id: service.split(".")[0],
              name: service.split(".")[1],
            }
          }
        ),
      });
      setOpen('success');
    } catch (error) {
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<CreateCompanyType>(error);

      if (isGeneral) {
        setError(...errorOptions);
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
      setOpen('error');
    } finally {
      setLoading(false);
      reset();
    }
  };

  return (
    <Paper>
      {!loadingMenus ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={6}>
              <Input
                label="Nombre"
                fullWidth
                required
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Código"
                fullWidth
                required
                {...register('code')}
                error={!!errors.code}
                helperText={errors.code && errors.code.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Dirección url de la compañía"
                fullWidth
                required
                {...register('url')}
                //No olvidar configurar esta parte
                error={!!errors.url}
                helperText={errors.url && errors.url.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Nit"
                fullWidth
                required
                {...register('nit')}
                error={!!errors.nit}
                helperText={errors.nit && errors.nit.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Días de gracia"
                fullWidth
                required
                {...register('graceDay')}
                error={!!errors.graceDay}
                helperText={errors.graceDay && errors.graceDay.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="catcha"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value
                      }
                      onChange={field.onChange}
                      label="Catcha"
                      fullWidth
                    >
                      {catchaStates.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Estado"
                      fullWidth
                    >
                      {companyStates.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid >
            <Grid item xs={6}>
              <Controller
                name="typeServices"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Tipo de servicios"
                      fullWidth
                      required
                      multiple
                      enableFilter
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.typeServices}
                      helperText={errors.typeServices && errors.typeServices.message}
                    >
                      {serviceTypesFetch &&
                        serviceTypesFetch.map(({ id, name }) => (
                          <MenuItem
                            key={id}
                            label={`${name}`}
                            value={`${id}.${name}`}
                          >
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/servicios-publicos/management/company"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Crear
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se creo la compañía con exito'}
            {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
          </SnackBar>
        </form>
      ) : (
        <CreateCompanyTemplateLoading />
      )}
    </Paper>
  );
};

export default CreateCompanyTemplate;
