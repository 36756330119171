import { columnType } from 'components/molecules';
import { formatDate } from 'components/templates/ServerTemplate/Shared/shared.const';
import { Service } from 'types';

export const StatusColor = {
  'Disponible': 'green',
  'No Disponible': 'red',
}

export const getColumns: (
) => columnType<Service>[] = () => [
  {
    field: 'name',
    headerName: 'Nombre',
    flex: '2',
    exportField: true,
    render: ({ name }) => (<span title={name}>{name}</span>),
  },
  {
    field: 'url',
    headerName: 'URL',
    flex: '2',
    exportField: true,
    render: ({ url }) => (<strong>{url}</strong>),
  },
  {
    field: 'port',
    headerName: 'Puerto',
    flex: '0.6',
    exportField: true,
    render: ({ port }) => (<strong>{port}</strong>),
  },
  {
    field: 'isHttps',
    headerName: 'HTTPS',
    flex: '0.6',
    exportField: true,
    render: ({ isHttps }) => (<span title={isHttps !== undefined && isHttps ? 'Si' : 'No'}>{isHttps ? 'Si' : 'No'}</span>),
  },
  {
    field: 'status',
    headerName: 'Estado',
    flex: '1.2',
    exportField: true,
    render: ({ status }) => (<strong style={{ color: StatusColor[status] }}>{status}</strong>),
  },
  {
    field: 'responseTime',
    headerName: 'Latencia',
    flex: '1',
    exportField: true,
    render: ({ responseTime }) => (<span title={responseTime ? responseTime.toString() : ''}>{responseTime}</span>),
  },
  {
    field: 'timeout',
    headerName: 'Timeout',
    flex: '1',
    exportField: true,
    render: ({ timeout }) => (<strong>{timeout}</strong>),
  },
  {
    field: 'errorCount',
    headerName: 'Errores',
    flex: '1',
    exportField: true,
    render: ({ errorCount }) => (<span title={errorCount ? errorCount.toString() : ''}>{errorCount}</span>),
  },
  {
    field: 'maxRetry',
    headerName: 'Reintentos',
    flex: '1.1',
    exportField: true,
    render: ({ maxRetry }) => (<span title={maxRetry ? maxRetry.toString() : ''}>{maxRetry}</span>),
  },
  {
    field: 'expiredSSL',
    headerName: 'SSL Vencido',
    flex: '1.2',
    exportField: true,
    render: ({ expiredSSL }) => (<span title={expiredSSL !== undefined && expiredSSL ? 'Si' : 'No'}>{expiredSSL ? 'Si' : 'No'}</span>),
  },
  {
    field: 'disabled',
    headerName: 'Deshabilitado',
    flex: '2',
    exportField: true,
    render: ({ disabled }) => (<span title={disabled !== undefined && disabled ? 'Si' : 'No'}>{disabled ? 'Si' : 'No'}</span>),
  },
  {
    field: 'updatedAt',
    headerName: 'Actualizado',
    flex: '2',
    exportField: true,
    render: ({ updatedAt }) => (<span title={updatedAt ? updatedAt.toString() : ''}>{formatDate(updatedAt ? updatedAt.toString() : '')}</span>),
  }
];


