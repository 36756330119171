import axios, { AxiosRequestConfig } from 'axios';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { COMMON_OPTIONS, DATE_FORMAT_BACK_WITH_OUT_TIME, DATE_FORMAT_WITH_OUT_TIME, DOWNLOAD_INVOICE, GET_HISTORY_RECORDS, getCurrentTimeToString } from 'const';
import { format, parseISO, startOfMonth } from 'date-fns';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineBarChart, AiOutlineCloudDownload, AiOutlineEye } from 'react-icons/ai';
import { FlatHistory, PaginationType } from 'types';
import { Consumption } from 'types/entities/consumption';
import { createFilterStructure, createPayload, joinObjectWithFormat } from 'utils';
import { RequiredDateSchema } from 'yup/lib/date';
import { AnyObject, TypeOfShape } from 'yup/lib/object';
import { IconButton } from '../../../../atoms';
import { HistoryTemplateFilterType } from './HistoryTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<FlatHistory> = {
  field: 'pay_date',
  order: 'DESC',
};

export type HistoryDownloadFilterModel = {
  invoiceNumber: string | undefined,
  area: string | undefined,
  projects: string | undefined,
  publicServices: string | undefined,
  dateRange: TypeOfShape<{
    startDate: RequiredDateSchema<Date | undefined, AnyObject>;
    endDate: RequiredDateSchema<Date | undefined, AnyObject>;
  }>
}

export const getDefaultHistoryTemplateFilter: () => HistoryTemplateFilterType = () => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);

  return {
    invoiceNumber: '',
    area: '',
    projects: '',
    publicServices: '',
    dateRange: {
      startDate: startOfMonth(startDate),
      endDate,
    },
  };
}

export const getColumns: (
  onActive: (id: number) => void,
  onDownload: (id: number, invoiceNumber: string) => Promise<void>,
  ref: React.MutableRefObject<HTMLAnchorElement | null>,
  url: string | undefined,
  name: string | undefined,
  onViewOne: (id: number, invoiceNumber: string) => Promise<void>,
  onViewConsumption: (consumption: Consumption[]) => void,
) => columnType<FlatHistory>[] = (onActive, onDownload, ref, url, name, onViewOne, onViewConsumption) => [
  {
    field: 'area',
    headerName: 'Area',
    flex: '0.35',
    exportField: true,
    disabledSort: true,
    isArea: true,
    render: ({ area }) => {
      switch (area) {
        case "UNIDAD":
          return "Unidad"
        case "OBRA":
          return "Obra"
        case "SALA_VENTA":
          return "Sala de venta"
        default:
          return ""
      }
    }
  },
  {
    field: 'projectName',
    headerName: 'Proyecto',
    flex: '0.65',
    disabledSort: true,
    exportField: true,
  },
  {
    field: 'groupingName',
    headerName: 'Inmueble',
    flex: '0.40',
    disabledSort: true,
    exportField: true,
  },
  {
    field: 'code',
    headerName: 'Compañía',
    flex: '0.55',
    disabledSort: true,
    exportField: true,
  },
  {
    field: 'invoiceNumber',
    headerName: 'Factura',
    flex: '0.6',
    disabledSort: true,
    exportField: true,
    isString: true,
    render: ({ invoiceNumber }) => (
      <>
        {invoiceNumber}
      </>
    )
  },
  {
    field: 'payDate',
    headerName: 'Fecha de pago',
    flex: '1',
    exportField: true,
    isDate: true,
    render: ({ payDate }) => {
      if (payDate !== null) {
        const datePart = payDate.split('T')[0];
        return format(parseISO(datePart), DATE_FORMAT_WITH_OUT_TIME, COMMON_OPTIONS);
      } else {
        return '';
      }
    },
  },
  {
    field: 'value',
    headerName: 'Valor',
    flex: '0.6',
    exportField: true,
    align: 'flex-end',
    isCurrency: true,
    render: ({ value }) => (
      <>
        {value % 1 === 0 ? Math.floor(value).toLocaleString() : value.toLocaleString()}
      </>
    )
  },
  {
    field: 'actions',
    headerName: 'Acción',
    disabledSort: true,
    flex: '1',
    align: 'center',
    render: ({ id, invoiceNumber, consumption }) => {
      return (
        <>
          {consumption && consumption.length > 0 &&
            <IconButton
              info="Ver consumos"
              positionEdge="start"
              color="grey"
              onClick={() => onViewConsumption(consumption)}
            >
              <AiOutlineBarChart />
            </IconButton>
          }
          <IconButton
            info="Ver factura"
            positionEdge="start"
            color="grey"
            onClick={() => onViewOne(id, invoiceNumber)}
          >
            <AiOutlineEye />
          </IconButton>
          <a href={url} download={name} className="hidden" ref={ref} />
          <IconButton
            info="Descagar factura"
            positionEdge="start"
            color="grey"
            onClick={() => onDownload(id, invoiceNumber)}
          >
            <AiOutlineCloudDownload />
          </IconButton>
        </>
      );
    },
  },
];

/**
 * Función para crear filtros del paginador
 * @param param0 
 * @returns 
 */
export const createFilterModel = (
  { invoiceNumber, area, dateRange, projects, publicServices }: HistoryDownloadFilterModel) => {

  let filterInvoiceNumber;

  if (invoiceNumber?.includes(',')) {
    const invoiceNumbers = invoiceNumber.split(',').map((invoice) => invoice.trim());
    filterInvoiceNumber = createFilterStructure(
      'invoiceNumber',
      'in',
      invoiceNumbers,
    );
  } else {
    filterInvoiceNumber = createFilterStructure(
      'invoiceNumber',
      'contains',
      invoiceNumber,
    );
  }

  const filterArea = createFilterStructure(
    'area',
    'contains',
    area,
  );

  const filterDateRange = createFilterStructure(
    'pay_date',
    'between_date',
    joinObjectWithFormat(dateRange, '.', format, [
      DATE_FORMAT_BACK_WITH_OUT_TIME,
    ]),
  );

  let modelFilter = [
    filterInvoiceNumber,
    filterArea,
    filterDateRange,
  ].filter((item) => item);

  if (projects) {
    const filterProject = createFilterStructure(
      'id',
      'in',
      projects?.split(','),
      'project',
    );
    modelFilter = [
      filterProject,
      ...modelFilter
    ]
  }

  if (publicServices) {
    const filterPublicService = createFilterStructure(
      'id',
      'in',
      publicServices?.split(','),
      'companyService',
    );
    modelFilter = [
      filterPublicService,
      ...modelFilter
    ].filter((item) => item);
  }

  return modelFilter;
}

//If no sort model is sent, the default one is sent
export const fetchProcess = (
  pagination: tableModelType<FlatHistory>,
  config?: AxiosRequestConfig<FlatHistory>,
) => {

  if (!pagination.sortModel) {
    pagination.sortModel = defaultSortModel;
  }

  return axios.get<PaginationType<FlatHistory>>(
    `${GET_HISTORY_RECORDS}${createPayload(pagination)}`,
    config,
  );
}

export const getFileNameByInvoiceNumber = (invoiceNumber: string) => {
  return `Factura_${invoiceNumber}_${getCurrentTimeToString()}.pdf`;
};

export const downloadPdf = (id: number) => {
  return axios.get(
    DOWNLOAD_INVOICE.replace(':id', id.toString()),
    {
      responseType: "blob",
    }
  );
};