import axios from 'axios';
import {
  Button, Grid, IconButton, Input, Typography
} from 'components/atoms';
import { useCustomDropZone } from 'hooks';
import { useCallback } from 'react';
import { AiOutlineCloseCircle, AiOutlineUpload } from 'react-icons/ai';
import { UPLOAD_COMPANY_SERVICE_GROUPING, UPLOAD_GROUPING } from '../../../../../const';
import { CustomError } from '../../../../../types';
import { getHttpErrorDetail } from '../../../../../utils/errorHandler';
import { rejectedFilesMessage, zoneStyle } from './GroupingUploadTemplate.const';
import { GroupingUploadTemplateProps } from './GroupingUploadTemplate.types';

/**
 * 
 * Este componente implementa el modal para cargar el archivo
 * que crea y edita inmuebles. 
 * 
 * Se usa el customHook useDropZone para gestionar
 * la zona de arrastre de archivos del componente.
 * 
 */
const GroupingUploadTemplate = ({
  waitingResponse,
  setWaitingResponse,
  action,
  setAction,
  open,
  setOpen,
  onClose,
  onOpen,
  setErrorMessage,
  filterModel,
  setFilterModel,
  ...props
}: GroupingUploadTemplateProps) => {

  const onUpload = useCallback(
    () => {
      onOpen(0);
      setAction('upload');
    },
    [onOpen],
  );


  const {
    files,
    setFiles,
    rejectedFiles,
    onRemoveFiles,
    hasTypeError,
    getRootProps, getInputProps, isDragActive
  } = useCustomDropZone();

  const checkFiles = (acceptedFiles: File[]): boolean => {
    if (acceptedFiles.length > 0) return true;
    return false;
  }

  const onConfirmUpload = async (acceptedFiles: File[], type: "grouping" | "companyServiceGrouping") => {
    if (acceptedFiles.length > 0) {
      setWaitingResponse(true);
      const formData = new FormData();

      formData.append('file', acceptedFiles[0]);

      try {
        if (type === "grouping") {
          const response = await axios.post(UPLOAD_GROUPING, formData);
        }
        else if (type === "companyServiceGrouping") {
          const response = await axios.post(UPLOAD_COMPANY_SERVICE_GROUPING, formData);
        }
        setFiles([]);
        setOpen('success');
      } catch (error) {
        setFiles([]);
        setErrorMessage(getHttpErrorDetail(error as CustomError));
        setOpen('warning')
      }
      finally {
        onClose();
        setWaitingResponse(false);
        setFilterModel([...filterModel])
      }
    }
  };

  return (
    <Grid>
      <div {...getRootProps({
        style: zoneStyle(isDragActive),
      })}>
        <Input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="h2" align="center">Suelta los archivos aquí</Typography>
        ) : (
          <Typography variant="h2" align="center">Arrastra y suelta tus archivos aquí para cargarlos a la plataforma</Typography>
        )}
        <IconButton
          info="Carga archivo excel"
          color="primary"
          fullWidth
          onClick={() => onUpload()}
        >
          <AiOutlineUpload
            size="2em"
          />
        </IconButton>
      </div>
      <Grid>
        {files.map(file => (
          <Grid direction='column' key={file.name} >
            <Typography variant="h3" align="center">{file.name}
              <IconButton
                info="Remueve el archivo cargado"
                color="primary"
                onClick={() => onRemoveFiles()}
              >
                <AiOutlineCloseCircle
                  size="1em"
                />
              </IconButton>
            </Typography>
          </Grid>
        ))}
        {rejectedFilesMessage(rejectedFiles, hasTypeError)}
      </Grid>
      <Grid container spacing={3} justify="flex-end">
        <Grid >
          <Button
            info="Carga el archivo"
            color="primary"
            variant="contained"
            disabled={!checkFiles(files)}
            onClick={() => onConfirmUpload(files, "grouping")}>
            Editar inmuebles
          </Button>
        </Grid>
        <Grid >
          <Button
            info="Carga el archivo"
            color="primary"
            variant="contained"
            disabled={!checkFiles(files)}
            onClick={() => onConfirmUpload(files, "companyServiceGrouping")}>
            Cargar servicios públicos
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupingUploadTemplate;
