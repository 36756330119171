import { Grid, Paper, Spinner, Typography } from 'components/atoms';
import Accordion from 'components/molecules/Accordion';
import { BasicTable, Svg, TableCell, TableRow } from 'components/organisms';
import {
  Grow,
  TableContainer,
  TableHead,
} from 'components/organisms/Table/Table.styles';
import 'moment/locale/es';
import { useMemo, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Server } from 'types';
import {
  getColumns,
  getColumnsAdditionalDataProvider,
} from './ServerDetailTemplate.const';
import { ServerDetailTemplateProps } from './ServerDetailTemplate.types';

const ServerDetailTemplate = ({ ...props }: ServerDetailTemplateProps) => {
  const ref = useRef<HTMLTableElement>(null);

  const { server } = props;

  const columns = useMemo(() => getColumns(), []);

  const columnsAdditionalDataProvider = useMemo(
    () => getColumnsAdditionalDataProvider(),
    [],
  );

  return server ? (
    <Paper>
      <Grid container alignItems="center" justify="center" spacing={7}>
        <Grid item xl={12} style={{ width: '100%' }}>
          <TableContainer $height={1}>
            <TableHead as="div">
              <TableRow as="div">
                {columns.map(({ headerName, field, flex }, idx) => (
                  <TableCell
                    as="div"
                    key={`head.${field}.${headerName}`}
                    $flex={flex}
                  >
                    {headerName}
                    <Grow />
                    {columns.length - 1 > idx && (
                      <Svg
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="SeparatorIcon"
                      >
                        <path d="M11 19V5h2v14z"></path>
                      </Svg>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <PerfectScrollbar>
              <BasicTable<Server>
                numberOfVisibleColumns={columns.length}
                ref={ref}
                columns={columns}
                rows={[server]}
                rowsPerPage={1}
              />
            </PerfectScrollbar>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="center" spacing={4}>
        {server.detailError && (
          <Accordion
            sections={[
              {
                header: 'Detalle de error',
                content: (
                  <Typography variant="p">{server.detailError}</Typography>
                ),
              },
            ]}
          />
        )}
      </Grid>
      <Grid container alignItems="center" justify="center" spacing={4}>
        {server.additionalDataProvider && (
          <Accordion
            sections={[
              {
                header: 'Datos del proveedor',
                defaultOpen: true,
                content: (
                  <Grid item xl={12} style={{ width: '100%' }}>
                    <TableContainer $height={1}>
                      <TableHead as="div">
                        <TableRow as="div">
                          {columnsAdditionalDataProvider.map(
                            ({ headerName, field, flex }, idx) => (
                              <TableCell
                                as="div"
                                key={`head.${field}.${headerName}`}
                                $flex={flex}
                              >
                                {headerName}
                                <Grow />
                                {columns.length - 1 > idx && (
                                  <Svg
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    data-testid="SeparatorIcon"
                                  >
                                    <path d="M11 19V5h2v14z"></path>
                                  </Svg>
                                )}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>
                      <PerfectScrollbar>
                        <BasicTable<Server>
                          numberOfVisibleColumns={
                            columnsAdditionalDataProvider.length
                          }
                          ref={ref}
                          columns={columnsAdditionalDataProvider}
                          rows={[server]}
                          rowsPerPage={1}
                        />
                      </PerfectScrollbar>
                    </TableContainer>
                  </Grid>
                ),
              },
            ]}
          />
        )}
      </Grid>
    </Paper>
  ) : (
    <Spinner></Spinner>
  );
};

export default ServerDetailTemplate;
