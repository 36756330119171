import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { saveAs } from 'file-saver';
import { useRef, useState } from 'react';

interface DownloadFileProps {
  /**
   * Función que define cómo se debe obtener el archivo a descargar.
   * Debe devolver una promesa que resuelva en una respuesta Axios con datos de tipo Blob.
   */
  readonly apiDefinition: () => Promise<AxiosResponse<Blob>>;
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: (error?: string) => void;
  /**
   * Función para generar el nombre del archivo descargado. La implementación puede depender de los headers de la respuesta http.
   * @param headers
   * @returns
   */
  readonly getFileName: (headers?: AxiosResponseHeaders) => string;
}

/**
 * Interfaz que describe la información de un archivo descargado.
 */
interface DownloadedFileInfo {
  /**
   * Función para iniciar la descarga del archivo.
   * Debe llamarse cuando el usuario inicie la descarga (por ejemplo, al hacer clic en un botón).
   */
  readonly download: () => Promise<void>;
  /**
   * Ref (referencia) mutable que apunta al elemento ancla (tag <a>) utilizado para descargar el archivo.
   * Puede ser útil para manipular dinámicamente el comportamiento del enlace.
   */
  readonly ref: React.MutableRefObject<HTMLAnchorElement | null>;
  /**
   * Nombre del archivo descargado. Puede ser `undefined` si no se ha descargado un archivo todavía.
   */
  readonly name: string | undefined;
  /**
   * URL del archivo descargado. Puede ser `undefined` si no se ha descargado un archivo todavía.
   * Esta URL es temporal y se revocará automáticamente después de la descarga.
   */
  readonly url: string | undefined;
  readonly view: () => Promise<Blob | undefined>;
  readonly base64: string | undefined; // New state for base64 representation
}

/**
 * Custom hook para gestionar la descarga de archivos.
 *
 * @param apiDefinition Función que define cómo obtener el archivo a descargar.
 * @param preDownloading Función que se ejecuta antes de iniciar la descarga.
 * @param postDownloading Función que se ejecuta después de finalizar la descarga.
 * @param onError Función que se ejecuta en caso de error durante la descarga.
 * @param getFileName Función que devuelve el nombre del archivo descargado.
 * @returns Información y funcionalidad relacionada con la descarga del archivo.
 */
const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();
  const [file, setFile] = useState<Blob>();
  const [base64, setBase64] = useState<string>();

  const view = async () => {
    try {
      preDownloading();
      const { data } = await apiDefinition();
      const url = URL.createObjectURL(new Blob([data]));
      setFileUrl(url);
      setFileName(getFileName());
      setFile(data);

      // Convert Blob to base64
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        setBase64(reader.result as string);
      };

      postDownloading();
      URL.revokeObjectURL(url);
      return data;
    } catch (error) {
      onError((error as Error).message);
    }
  };

  const download = async () => {
    try {
      preDownloading();
      const { data, headers } = await apiDefinition();

      const fileName = getFileName(headers);

      const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(blob, fileName);
      postDownloading();
    } catch (error) {
      onError((error as Error).message);
    }
  };

  return { download, ref, url, name, view, base64 };
};

export default useDownloadFile;
