import { Header } from 'components/molecules';
import ListExceptionTemplate from 'components/templates/AMTemplate/AMExceptionTemplate/ListExceptionTemplate';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administración' }];

const ListExceptionPage = () => {
  return (
    <>
      <Header
        title="Excepciones"
        subtitle="Consulta las excepciones de los bots "
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListExceptionTemplate />
    </>
  );
};

export default withAuth(ListExceptionPage);
