import { Spinner } from "../../../../atoms";



const ListTemplateApiLoading = () => {
  return (
    <Spinner></Spinner>
  );
};

export default ListTemplateApiLoading;
