import { useCallback, useState } from 'react';

const useDialog = <T>(initialValue: T) => {
  const [value, setAccept] = useState<T | undefined>(initialValue);

  const onClose = useCallback(() => {
    setAccept(undefined);
  }, []);

  const onOpen = useCallback((newResponse: T) => {
    setAccept(newResponse);
  }, []);

  return [value, onOpen, onClose] as [
    T | undefined,
    (value: T) => void,
    () => void,
  ];
};

export default useDialog;
