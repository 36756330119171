import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  SnackBar,
  Typography
} from 'components/atoms';
import { Table } from 'components/organisms';
import { DialogContent } from 'components/templates/AMTemplate/AMLogsTemplate/AMLogs.Styles';
import { serverMonitoringConfig } from 'components/templates/ServerTemplate/Shared/shared.const';
import { GET_ALL_SERVICES } from 'const';
import { useDialog, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import moment from 'moment';
import 'moment/locale/es';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { PaginationType, Service } from 'types';
import ServiceDetailTemplate from '../ServiceDetailTemplate';
import {
  createFilterModel,
  defaultListServiceTemplateFilter,
  defaultPagination,
  getColumns,
  StatusColor
} from './ListServicesTemplate.const';
import { ListServiceTemplateFilterSchema, ListServiceTemplateFilterType } from './ListServicesTemplate.schema';
import { ListSevicesTemplateProps } from './ListServicesTemplate.types';
const ListServicesTemplate = ({ ...props }: ListSevicesTemplateProps) => {
  const { id } = useParams<{ id: string }>();

  moment.locale('es');
  const [dataRows, setDataRows] = useState<Service[]>([]);
  const [servers, setServers] = useState<string[]>([]);
  const [serversAlredyFetched, setServersAlredyFetched] = useState<boolean>(false);
  const [selectedServiceDialog, onOpen, onCloseModal] = useDialog<Service | undefined>(undefined,);
  const [selectedService, setSelectedService] = useState<Service | undefined>(undefined);
  const [action, setAction] = useState<'viewDetails' | 'delete' | undefined>(undefined);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [message, setMessage] = useState<{ message: string, severity: 'success' | 'error' | 'info' | 'warning' | undefined }>({ message: '', severity: 'success' });
  let servicesData: Service[] = [];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ListServiceTemplateFilterType>({
    defaultValues: defaultListServiceTemplateFilter,
    resolver: yupResolver(ListServiceTemplateFilterSchema),
  });

  const onViewDetails = useCallback(
    (service: Service) => {
      setSelectedService(service);
      onOpen(service);
      setAction('viewDetails');
    },
    [onOpen, setSelectedService, setAction],
  );

  const onDelete = useCallback(
    (service: Service) => {
      setSelectedService(service);
      onOpen(service);
      setAction('delete');
    },
    [onOpen, setSelectedService, setAction],
  );

  const deleteService = useCallback(
    async (service: Service) => {
      try {
        await axios.delete(`${GET_ALL_SERVICES}/${service.id}`, serverMonitoringConfig);
        setMessage({ message: 'Servicio eliminado correctamente', severity: 'success' });
        setOpenSnackBar(true);
        getData({ filterModel, paginationModel: defaultPagination });
      } catch (error) {
        const axiosError = error as AxiosError;
        setMessage({ message: axiosError.message, severity: 'error' });
        setOpenSnackBar(true);
      } finally {
        setTimeout(() => {
          setOpenSnackBar(false);
        }, 2000);
      }
    },
    [setAction, setMessage, setOpenSnackBar],
  );

  const onClose = useCallback(() => onCloseModal(), [onCloseModal]);
  const columns = useMemo(() => getColumns(onViewDetails, onDelete), [onViewDetails, onDelete]);

  const dialogProps = (): {
    title: string,
    size: "sm" | "md" | "lg" | "xl" | undefined
  } => {
    if (action === 'viewDetails') {
      return { title: 'Detalle del servicio', size: 'lg' };
    }
    if (action === 'delete') {
      return { title: 'Confirmar acción', size: 'sm' };
    }
    return {
      title: "Detalle del servicio",
      size: "lg"
    };
  };

  const [filterModel, setFilterModel] = useState<filterModelType<Service>[]>([]);

  const fetchServices = async (
    { paginationModel, filterModel }: tableModelType<Service>,
    config?: AxiosRequestConfig<Service>,
  ) => {
    return new Promise<AxiosResponse<PaginationType<Service>>>(async resolve => {
      const data = await axios.get<PaginationType<Service>>(
        `${GET_ALL_SERVICES}?page=0&limit=1000`,
        {
          ...config,
          ...serverMonitoringConfig
        }
      )

      if (data.status === 200) {
        servicesData = data.data.result.map((service) => {
          return {
            ...service,
            server: {
              ...service.server,
              name: service.server.name.replace(',', ''),
            }
          }
        });

        if (id) {
          servicesData = servicesData.filter((service) => service.server.id.toString() === id);
        }
      }

      if (filterModel && filterModel.length > 0) {
        servicesData = servicesData.filter((service) =>
          filterModel.every((filter) => {
            const { field, value } = filter;
            if (field === 'server') {
              return value.toLowerCase().includes(service.server.name.toLowerCase());
            }
            if (field === 'status') {
              return value.toLowerCase().split(',').includes(service.status.toLowerCase());
            }
            if (field === 'name') {
              return service.name.toLowerCase().includes(value.toLowerCase());
            }
            return false;
          })
        );
      }

      let rows = [...servicesData];

      let { rowsPerPage, page } = paginationModel;
      const rowsLength = rows.length;
      if (rowsLength < rowsPerPage || page > rowsLength / rowsPerPage) {
        page = 0;
        paginationModel.page = 0;
      }
      let maxRows = (page + 1) * rowsPerPage;
      paginationModel.rowsCount = rowsLength;
      if (maxRows > paginationModel.rowsCount) {
        maxRows = paginationModel.rowsCount
      }

      rows = rows.slice((page) * rowsPerPage, maxRows);

      setDataRows(rows);

      if (!serversAlredyFetched) {
        setServers(Array.from(new Set(servicesData.map((service) => service.server.name))));
        setServersAlredyFetched(true);
      }

      const responseData: AxiosResponse<PaginationType<Service>, any> = {
        status: data.status, statusText: data.statusText, headers: data.headers, config: data.config,
        data: {
          page: paginationModel.page,
          limit: data.data.limit,
          rowsCount: servicesData.length,
          result: [...servicesData]
        }
      }

      resolve(responseData);
    })
  };

  const {
    loading,
    error,
    data: service,
    handleData,
    getData,
  } = useGetTable<PaginationType<Service>, tableModelType<Service>>(fetchServices, {
    paginationModel: defaultPagination,
    filterModel,
  });

  const tableControllers = useTable<Service>(
    {
      filterModel,
      paginationModel: service ? { ...defaultPagination, rowsCount: service.rowsCount } : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Service>, 'paginationModel'>,
    ) => Promise<PaginationType<Service>>,
  );

  const onSubmit = useCallback(
    (filters: ListServiceTemplateFilterType) => {
      const modelFilter = createFilterModel(filters).filter(Boolean);
      setFilterModel(modelFilter as filterModelType<Service>[]);
    },
    [],
  );

  const colors = Object.keys(StatusColor);
  return (
    <>
      <Table<Service>
        {...tableControllers}
        numberOfVisibleColumns={9}
        title={`Encontramos ${tableControllers.paginationModel.rowsCount} servicios.`}
        columns={columns}
        rows={dataRows}
        error={!!error}
        loading={loading}
        filterComponent={
          <form noValidate onSubmit={handleSubmit(onSubmit)} onKeyDown={
            (event) => {
              // submit form on enter key and not go to create service page
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                handleSubmit(onSubmit)();
              }
            }
          }>
            <Grid
              container
              alignItems="center"
              justify="flex-end"
              spacing={2}
              wrap="nowrap"
            >
              <Grid item>
                <IconButton
                  info="Crear servicio"
                  variant="contained"
                  href="/pages/monitoring/services/create"
                >
                  <IoAdd />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <Input
                  fullWidth
                  label="Nombre"
                  {...register('name')}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="server"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        label="Servidor"
                        fullWidth
                        multiple
                        enableFilter
                      >
                        {servers.map((server) => (
                          <MenuItem
                            key={server}
                            label={`${server}`}
                            value={`${server}`}
                          >
                            {server}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        label="Estado"
                        fullWidth
                        multiple
                        enableFilter
                      >
                        {colors.map((status) => (
                          <MenuItem
                            key={status}
                            label={`${status}`}
                            value={`${status}`}
                          >
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          </form>
        }
      />
      <Dialog
        open={selectedServiceDialog !== undefined}
        onClose={onClose}
        title={dialogProps().title}
        maxWidth={dialogProps().size}
      >
        <DialogContent>
          {action === 'viewDetails' && (
            <ServiceDetailTemplate service={selectedService} />
          )}
          <Grid container spacing={5} justify="flex-end">
            <Grid item xs={12}>
              {action === 'delete' && (
                <Typography variant="h2">
                  {`¿Estás seguro de eliminar el servicio: ${selectedService?.name}?`}
                </Typography>
              )}
            </Grid>
            {['start', 'stop', 'restart', 'delete'].includes(action ?? '') && (
              <>
                <Grid item>
                  <Button variant="contained" color="grey" onClick={onClose}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      deleteService(selectedService as Service);
                      onClose();
                    }}
                  >
                    Sí, deseo eliminarlo
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>          
      <SnackBar
        wait={3000}
        open={openSnackBar}
        onClose={onClose}
        severity={message.severity}
      >
        {message.message}
      </SnackBar>

    </>
  );
};

export default ListServicesTemplate;
