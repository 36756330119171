import {
  Grid,
  Typography
} from 'components/atoms';
import { BsLightning } from "react-icons/bs";
import { FaFaucet, FaGripfire, FaRegFileAlt, FaTrafficLight, FaTrashAlt } from "react-icons/fa";
import { IoWaterOutline } from 'react-icons/io5';
import { Consumption } from 'types/entities/consumption';
import { HistoryPendingDownloadTemplateProps } from './HistoryConsumptionTemplate.types';
import { Card, CardContainer, CardTitle } from './styles.styles';


/**
 * Este componente implementa el modal para visualizar los consumos de facturas
 */
const HistoryConsumptionTemplate = (
  {
    setOpen,
    onClose,
    setErrorMessage,
    consumptionList,
    ...props
  }: HistoryPendingDownloadTemplateProps
) => {

  const formatValue = (value: number) => {
    return value % 1 === 0 ? Math.floor(value).toLocaleString() : value.toLocaleString();
  }

  const getServiceIcon = (service: string | undefined) => {
    switch (service?.toLowerCase()) {
      case "gas":
        return <FaGripfire />
      case "energia":
        return <BsLightning />
      case "acueducto":
        return <IoWaterOutline />
      case "alcantarillado":
        return <FaFaucet />
      case "alumbrado_publico":
        return <FaTrafficLight />
      case "aseo":
        return <FaTrashAlt />
      default:
        return <FaRegFileAlt />
    }
  }

  const getOrderedConsumptionList = (consumptionList: Consumption[]) => {
    const orderedConsumptions = consumptionList.sort((a, b) => {
      if (a.typeService.code && b.typeService.code) {
        return a.typeService.code.localeCompare(b.typeService.code);
      } else if (!a.typeService.code && b.typeService.code) {
        return -1; // or 1, depending on how you want to handle undefined values
      } else if (a.typeService.code && !b.typeService.code) {
        return 1; // or -1, depending on how you want to handle undefined values
      } else {
        return 0;
      }
    });
    return orderedConsumptions;
  }

  const getConsumptionText = (consumption: Consumption) => {
    const formattedConsumption =
      !(consumption.typeService.code?.toLocaleLowerCase() == 'otros') ?
        // `Consumo: ${formatValue(consumption.consumption)} ${consumption.typeService.unit}` :
        `Consumo: ${formatValue(consumption.consumption)}` :
        `Consumo: NA`

    return formattedConsumption;
  }

  //TODO: create components for cards, etc?
  return (
    <>

      <CardContainer>
        {getOrderedConsumptionList(consumptionList)
          .map((consumption, index) => (
            <Card key={index}>
              <Grid container justify="space-between">
                <CardTitle>{consumption.typeService.code} </CardTitle>
                <Typography variant='h1'>{getServiceIcon(consumption.typeService.name)}</Typography>
              </Grid>
              <Typography>
                {getConsumptionText(consumption)}
              </Typography>
              <Typography>Valor: ${formatValue(consumption.value)}</Typography>
              <Typography>Lectura actual: {consumption.currentRead}</Typography>
            </Card>
          ))}
      </CardContainer>

    </>
  );
};


export default HistoryConsumptionTemplate;
