import { Header } from 'components/molecules';
import { ProcessTemplate } from 'components/templates';
import { GET_PROCESS } from 'const';
import { withAuth } from 'HOCs';
import { useBreadcrumbs, useGet } from 'hooks';
import { useParams } from 'react-router-dom';
import { Process } from 'types';

const TransactionPageReport = () => {
  const { id } = useParams();

  const [breadcrumbs, title] = useBreadcrumbs();

  const { loading, data: process } = useGet<Process>(
    GET_PROCESS.replace(':id', id as string),
  );

  return (
    <>
      <Header
        title={title}
        loading={loading}
        subtitle={`Lista los paquetes del proceso ${process?.name.toLocaleLowerCase()}`}
        breadCrumbsProps={{
          breadcrumbs,
          preview: 1,
        }}
      />
      <ProcessTemplate key={id} id={id as string} />
    </>
  );
};

export default withAuth(TransactionPageReport);
