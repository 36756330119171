import { object, string, TypeOf } from 'yup';

export const CreateTaskSchema = object({
  name: string().required().max(95),
  enabled: string().required(),
  menus: string().required(),
  users: string(),
  error: string(),
});

export type CreateTaskType = TypeOf<typeof CreateTaskSchema>;
