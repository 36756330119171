import axios from 'axios';
import {
  Button, Grid, IconButton, Input, Typography
} from 'components/atoms';
import { useCustomDropZone } from 'hooks';
import { useCallback } from 'react';
import { AiOutlineCloseCircle, AiOutlineUpload } from 'react-icons/ai';
import { UPLOAD_PROJECT } from '../../../../../const';
import { CustomError } from '../../../../../types';
import { getHttpErrorDetail } from '../../../../../utils/errorHandler';
import { rejectedFilesMessage, zoneStyle } from './ProjectUploadTemplate.const';
import { ProjectUploadTemplateProps } from './ProjectUploadTemplate.types';

/**
 * 
 * Este componente implementa el modal para cargar el archivo
 * que crea y edita inmuebles. 
 * 
 * Se usa el customHook useDropZone para gestionar
 * la zona de arrastre de archivos del componente.
 * 
 */
const ProjectUploadTemplate = ({
  waitingResponse,
  setWaitingResponse,
  action,
  setAction,
  open,
  setOpen,
  onClose,
  onOpen,
  setErrorMessage,
  ...props
}: ProjectUploadTemplateProps) => {

  const onUpload = useCallback(
    () => {
      onOpen(0);
      setAction('upload');
    },
    [onOpen],
  );


  const {
    files,
    setFiles,
    rejectedFiles,
    onRemoveFiles,
    hasTypeError,
    getRootProps, getInputProps, isDragActive
  } = useCustomDropZone();

  const checkFiles = (acceptedFiles: File[]): boolean => {
    if (acceptedFiles.length > 0) return true;
    return false;
  }

  const onConfirmUpload = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setWaitingResponse(true);
      const formData = new FormData();

      formData.append('file', acceptedFiles[0]);

      try {
        await axios.post(UPLOAD_PROJECT, formData);
        setFiles([]);
        setOpen('success');
      } catch (error) {
        setFiles([]);
        setErrorMessage(getHttpErrorDetail(error as CustomError));
        setOpen('warning')
      }
      finally {
        onClose();
        setWaitingResponse(false);
      }
    }
  };

  return (
    <Grid>
      <div {...getRootProps({
        style: zoneStyle(isDragActive),
      })}>
        <Input {...getInputProps()} />
        <Typography variant="h2" align="center">Suelta los archivos aquí</Typography>
        <IconButton
          info="Carga archivo excel"
          color="primary"
          fullWidth
          onClick={() => onUpload()}
        >
          <AiOutlineUpload
            size="2em"
          />
        </IconButton>
      </div>
      <Grid>
        {files.map(file => (
          <Grid direction='column' key={file.name} >
            <Typography variant="h3" align="center">{file.name}
              <IconButton
                info="Remueve el archivo cargado"
                color="primary"
                onClick={() => onRemoveFiles()}
              >
                <AiOutlineCloseCircle
                  size="1em"
                />
              </IconButton>
            </Typography>
          </Grid>
        ))}
        {rejectedFilesMessage(rejectedFiles, hasTypeError)}
      </Grid>
      <Grid container spacing={3} justify="flex-end">
        <Grid >
          <Button
            info="Carga el archivo"
            color="primary"
            variant="contained"
            disabled={!checkFiles(files)}
            onClick={() => onConfirmUpload(files)}>
            Editar proyecto
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectUploadTemplate;
