import { array, object, string, TypeOf } from 'yup';

export const CreateGroupingSchema = object({
  projectName: string().required(),
  name: string().required(),
  area: string(),
  companyServiceGroupings: array().of(
    object({
      reference: string(),
      companyServiceCode: string(),
    }),
  ),
});
export type CreateGroupingType = TypeOf<typeof CreateGroupingSchema>;

// The following two types were implemented to use internal types of the hook library.
export type FormValues = {
  name: string | undefined;
  projectName: string | undefined;
  area: string | undefined;
  companyServiceGroupings: Array<{}>;
};

export type CompanyServiceGroupingField =
  `companyServiceGroupings.${number}.companyServiceCode`;
