import { useNavigate } from 'react-router-dom';
import { LinkProps as LinkStyleProps, LinkStyle } from './Link.styles';

export type LinkProps = {
  link: string;
} & LinkStyleProps;

const Link = ({ link, ...props }: LinkProps) => {
  const navigate = useNavigate();
  return <LinkStyle onClick={() => navigate(link)} {...props} />;
};

export default Link;
