import axios, { AxiosRequestConfig } from 'axios';
import { GET_DOCUMENTS_BY_TRANSACTIONS_PER_VERIFICATION } from 'const';
import { Document } from 'types';

export const fetchDocuments = <T extends object>(
  { id }: any,
  config?: AxiosRequestConfig<Document<T>>,
) => {
  return axios.get<Document<T>[]>(
    `${GET_DOCUMENTS_BY_TRANSACTIONS_PER_VERIFICATION}/${id}`,
    config,
  );
};
