/* eslint-disable consistent-return, jsx-a11y/no-noninteractive-tabindex */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ownerDocument } from 'utils';
import { useForkRef } from 'hooks';

export type TrapFocusProps = {
  children: React.ReactNode;
  disableAutoFocus: boolean;
  disableEnforceFocus: boolean;
  disableRestoreFocus: boolean;
  getDoc: any;
  isEnabled: () => boolean;
  open: boolean;
};

function TrapFocus(props: TrapFocusProps) {
  const {
    children,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    disableRestoreFocus = false,
    getDoc,
    isEnabled,
    open,
  } = props;
  const ignoreNextEnforceFocus = React.useRef<boolean>();
  const sentinelStart = React.useRef<HTMLElement | null>(null);
  const sentinelEnd = React.useRef<HTMLElement | null>(null);
  const nodeToRestore = React.useRef<HTMLElement | null>(null);

  const rootRef = React.useRef<Node | null>(null);
  // can be removed once we drop support for non ref forwarding class components
  const handleOwnRef = React.useCallback((instance) => {
    // #StrictMode ready
    rootRef.current = ReactDOM.findDOMNode(instance);
  }, []);
  const handleRef = useForkRef((children as any).ref, handleOwnRef);

  const prevOpenRef = React.useRef<boolean>();
  React.useEffect(() => {
    prevOpenRef.current = open;
  }, [open]);
  if (!prevOpenRef.current && open && typeof window !== 'undefined') {
    // WARNING: Potentially unsafe in concurrent mode.
    // The way the read on `nodeToRestore` is setup could make this actually safe.
    // Say we render `open={false}` -> `open={true}` but never commit.
    // We have now written a state that wasn't committed. But no committed effect
    // will read this wrong value. We only read from `nodeToRestore` in effects
    // that were committed on `open={true}`
    // WARNING: Prevents the instance from being garbage collected. Should only
    // hold a weak ref.
    nodeToRestore.current = getDoc().activeElement;
  }

  React.useEffect(() => {
    if (!open) {
      return;
    }

    const doc = ownerDocument(rootRef.current as Node);

    if (
      !disableAutoFocus &&
      rootRef.current &&
      !rootRef.current.contains(doc.activeElement)
    ) {
      (rootRef.current as HTMLElement).focus();
    }

    const contain = () => {
      if (
        !doc.hasFocus() ||
        disableEnforceFocus ||
        !isEnabled() ||
        ignoreNextEnforceFocus.current
      ) {
        ignoreNextEnforceFocus.current = false;
        return;
      }

      if (rootRef.current && !rootRef.current.contains(doc.activeElement)) {
        (rootRef.current as HTMLElement).focus();
      }
    };

    const loopFocus = (event: React.SyntheticEvent) => {
      // 9 = Tab
      if (disableEnforceFocus || !isEnabled() || (event as any).keyCode !== 9) {
        return;
      }

      // Make sure the next tab starts from the right place.
      if (doc.activeElement === rootRef.current) {
        // We need to ignore the next contain as
        // it will try to move the focus back to the rootRef element.
        ignoreNextEnforceFocus.current = true;
        if ((event as any).shiftKey) {
          (sentinelEnd.current as HTMLElement).focus();
        } else {
          (sentinelStart.current as HTMLElement).focus();
        }
      }
    };

    doc.addEventListener('focus', contain, true);
    doc.addEventListener('keydown', loopFocus as any, true);

    // With Edge, Safari and Firefox, no focus related events are fired when the focused area stops being a focused area
    // e.g. https://bugzilla.mozilla.org/show_bug.cgi?id=559561.
    //
    // The whatwg spec defines how the browser should behave but does not explicitly mention any events:
    // https://html.spec.whatwg.org/multipage/interaction.html#focus-fixup-rule.
    const interval = setInterval(() => {
      contain();
    }, 50);

    return () => {
      clearInterval(interval);

      doc.removeEventListener('focus', contain, true);
      doc.removeEventListener('keydown', loopFocus as any, true);

      if (!disableRestoreFocus) {
        if (
          nodeToRestore.current &&
          (nodeToRestore.current as HTMLElement).focus
        ) {
          (nodeToRestore.current as HTMLElement).focus();
        }

        nodeToRestore.current = null;
      }
    };
  }, [
    disableAutoFocus,
    disableEnforceFocus,
    disableRestoreFocus,
    isEnabled,
    open,
  ]);

  return (
    <React.Fragment>
      <div tabIndex={0} ref={sentinelStart as any} data-test="sentinelStart" />
      {React.cloneElement(children as any, { ref: handleRef })}
      <div tabIndex={0} ref={sentinelEnd as any} data-test="sentinelEnd" />
    </React.Fragment>
  );
}

export default TrapFocus;
