import PerfectScrollbar from 'react-perfect-scrollbar';
import styled, { css } from 'styled-components';

export const Root = styled.div<{ $maxItems?: number }>`
  color: inherit;
  width: 100%;
  box-sizing: border-box;
  background-color: inherit;
  font: inherit;
  ${({ $maxItems }) =>
    $maxItems &&
    css`
      max-height: ${$maxItems * 32}px;
    `}
  overflow-y: auto;
`;

export const CustomPerfectScrollbar = styled(PerfectScrollbar)<{
  $maxItems?: number;
}>`
  ${({ $maxItems }) =>
    $maxItems &&
    css`
      max-height: ${$maxItems * 32}px;
    `}
`;
