import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  Grid,
  Input,
  InputPassword,
  MenuItem,
  Paper,
  Select,
  SnackBar,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { UPDATE_USER } from 'const';
import { states } from 'const/const';
import { useGetTable } from 'hooks';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Role, User } from 'types';
import { cleanInvalidObj, getErrorsFromBackend } from 'utils';
import { fetchRoles, fetchUsers } from './EditUserTemplate.const';
import EditUserTemplateLoading from './EditUserTemplate.loading';
import { EditUserSchema, EditUserType } from './EditUserTemplate.schema';

const EditUserTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm<EditUserType>({
    resolver: yupResolver(EditUserSchema),
    defaultValues: {
      roles: '',
      password: '',
      passwordConfirmation: '',
      enabled: '',
    },
  });

  const { data: roles, loading: loadingRoles } = useGetTable<Role[]>(
    fetchRoles,
    [{}],
  );

  const { data } = useGetTable<User, { id: string }>(fetchUsers, {
    id: id as string,
  });

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/user');
  };

  const onSubmit = async (loginForm: EditUserType) => {
    try {
      setLoading(true);
      const { roles, ...form } = loginForm;
      const plainRoles = roles?.split(',').filter((item) => item !== '');
      const enabled = form.enabled === 'true';

      //TO DO: array to obj
      const data = cleanInvalidObj(form);

      await axios.put<User>(UPDATE_USER.replace(':id', id as string), {
        ...data,
        enabled,
        roles: plainRoles,
      });
      setLoading(false);
      setOpen('success');
    } catch (error) {
      setLoading(false);
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<EditUserType>(error);

      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    }
  };

  useEffect(() => {
    if (data) {
      setValue('roles', data.list_roles);
      setValue('enabled', data.enabled ? states[0].value : states[1].value);
    }
  }, [data, setValue]);

  const password = watch('password');

  return (
    <Paper>
      {!loadingRoles ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={6}>
              <Input
                disabled
                label="Usuario (email)"
                value={data?.username || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value}
                      onChange={field.onChange}
                      label="Estado"
                      fullWidth
                      required
                      enableFilter
                    >
                      {states?.map(({ value, name }, idx) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            {!data?.provider && (
              <>
                <Grid item xs={6}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputPassword
                          label="Nueva contraseña"
                          fullWidth
                          required={!!password}
                          enabledGenerate
                          value={field.value}
                          onChange={field.onChange}
                          onGenerate={(value) =>
                            setValue('passwordConfirmation', value)
                          }
                          error={!!errors.password}
                          helperText={
                            errors.password && errors.password.message
                          }
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="passwordConfirmation"
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputPassword
                          label="Confirma tu nueva contraseña"
                          fullWidth
                          required={!!password}
                          value={field.value}
                          onChange={field.onChange}
                          error={!!errors.passwordConfirmation}
                          helperText={
                            errors.passwordConfirmation &&
                            errors.passwordConfirmation.message
                          }
                        />
                      );
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Controller
                name="roles"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Roles"
                      fullWidth
                      multiple
                      required
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.roles}
                      helperText={errors.roles && errors.roles.message}
                    >
                      {roles &&
                        roles.map(({ id, name }) => (
                          <MenuItem key={id} label={`${name}`} value={`${id}`}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/user"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se edito el usuario correctamente'}
            {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
          </SnackBar>
        </form>
      ) : (
        <EditUserTemplateLoading />
      )}
    </Paper>
  );
};

export default EditUserTemplate;
