import { Header } from 'components/molecules';
import { FixVerificationTemplate } from 'components/templates';
import { withAuth } from 'HOCs';
import { useBreadcrumbs } from 'hooks';

const FixTransactionPage = () => {
  const [breadcrumbs, title] = useBreadcrumbs((pathname) => {
    const copy = pathname.split('/').filter((elem) => elem);
    copy.pop();
    return '/' + copy.join('/');
  });

  return (
    <>
      <Header
        title={breadcrumbs[0]}
        subtitle={`Corrige los errores de los documentos de las ${title?.toLocaleLowerCase()}`}
        breadCrumbsProps={{
          breadcrumbs: [...breadcrumbs, ...(title ? [title] : [])],
        }}
      />
      <FixVerificationTemplate />
    </>
  );
};

export default withAuth(FixTransactionPage);
