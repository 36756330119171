import styled from 'styled-components';
import { invertColor } from 'utils';

export const RootAvatar = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => invertColor(theme.palette.primary, true)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
`;

export const ImgAvatar = styled.img`
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
`;
