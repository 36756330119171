import styled from 'styled-components';

import { Grid } from 'components/atoms';
import { darken } from 'utils';

const GridDivider = styled(Grid)`
  background: ${({ theme }) =>
    theme.isDarkMode
      ? darken(theme.palette.background, 0.1)
      : theme.palette.background};
`;

const GridHeader = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.shape.spacing(6)};
`;

const BlockGrid = styled(Grid)`
  display: block;
`;

export { GridDivider, GridHeader, BlockGrid };
