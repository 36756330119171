import styled from 'styled-components';

import { Button as AtomButton } from 'components/atoms';

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  width: auto;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  top: 5px;
  left: auto;
  right: 20px;
  border-radius: 12px;
  padding: 5px 15px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  backdrop-filter: blur(20px);
  border-style: solid;
  background-color: ${({ theme }) =>
    theme.isDarkMode ? '#99999933' : '#ffffffaa'};
  border-color: ${({ theme }) =>
    theme.isDarkMode ? theme.palette.divider : theme.common.white};
  color: rgb(160, 170, 180);
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 50px;

  & > img {
    cursor: pointer;
  }
`;

export const Toolbar = styled.div`
  flex: 1 1 auto;
`;

export const Button = styled(AtomButton)`
  padding: ${({ theme }) => theme.shape.spacing(1)};
  border-radius: 50%;
  margin-left: ${({ theme }) => theme.shape.spacing(4)};
`;
