import axios from 'axios';
import { CREATE_GROUPING } from 'const';
import { Grouping } from 'types';

export type createGroupingRequestBody = {
  name?: string;
  projectName?: string;
  area?: string;
};

export const createGrouping = (body: createGroupingRequestBody) => {
  return axios.post<Grouping>(CREATE_GROUPING, {
    ...body,
  });
};
